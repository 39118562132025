import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js';

const SalesChart = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const labels = data.map(item => item.week_start_date);
    const sales = data.map(item => item.accumulated_sales);
    const revenue = data.map(item => item.accumulated_revenue);

    const chartCanvas = chartRef.current.getContext('2d');

    new Chart(chartCanvas, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Accumulated Sales',
            data: sales,
            borderColor: 'rgb(75, 192, 192)',
            fill: false,
          },
          {
            label: 'Accumulated Revenue',
            data: revenue,
            borderColor: 'rgb(192, 75, 192)',
            fill: false,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'week',
              tooltipFormat: 'YYYY-MM-DD',
            },
            title: {
              display: true,
              text: 'Week Start Date',
            },
          },
          y: {
            title: {
              display: true,
              text: 'Value',
            },
          },
        },
      },
    });
  }, [data]);

  return <canvas ref={chartRef} style={{width:'500px', height:'400px'}}/>;
};

export default SalesChart;
