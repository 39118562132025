import React from "react";
import Box from "@iso/components/utility/box";
import LayoutWrapper from "@iso/components/utility/layoutWrapper";
import InvoicePageWrapper from "./SingleAirportTransfer.styles";
import AirportTransfer from "./AirportTransfer";
import { useLocation } from "react-router-dom";


const View = (props) => {
  const params = useLocation();
  const locParams = params && params.state ? params.state : { hotel: null };
  return (
    <LayoutWrapper>
      <Box style={{ padding: 20 }}>
        <InvoicePageWrapper className="InvoicePageWrapper">
          <AirportTransfer currentAirportTranfer={locParams.res} ref={(invoice) => invoice} />
        </InvoicePageWrapper>
      </Box>
    </LayoutWrapper>
  );
};

export default View;
