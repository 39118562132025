import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Radio, { RadioGroup } from "@iso/components/uielements/radio";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import _ from "lodash";

import Dropdown from "@iso/components/uielements/CustomDropdown";
import Input from "@iso/components/uielements/input";
import Button from "@iso/components/uielements/button";
import DatePicker from "@iso/components/uielements/datePicker";
import { airportTransferValidation } from "@iso/constants";
import SignUpStyleWrapper from "./BookAirportTransfer.styles";
import TimeKeeper from "react-timekeeper";
import { getAirportList } from "../../../redux/receptionist/actions";
import basicStyle from "@iso/assets/styles/constants";
import { Col, Modal, Row } from "antd";
import moment from "moment";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { EURO } from "../../../constants";

import "../../../assets/styles/bookTour.css";
import Select from "@iso/components/uielements/select";
import * as rdd from "react-device-detect";
import {
  bookAirportTransfer,
  getExtraServiceRec,
  getServicesListRec,
} from "../../../redux/airportTransfer/actions";
import { Textarea } from "../../../components/uielements/input";
import { closingTimeRecView } from "../../../redux/setting/actions";

export default function CreateTours() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useLocation();
  const locParams = params && params.state ? params.state : { tour: null };
  const tour = (locParams && locParams.tour) || null;

  const [tourPrice, setTourPrice] = useState(null);
  const [hotelPrice, setHotelPrice] = useState(null);

  const [time, setTime] = useState('17:30');

  const [direct, setDirection] = useState(null);
  const [extraService, setExtraServiceList] = useState(null);
  const [serviceList, setServiceList] = useState(null);
  const [disable, setDisable] = useState(false);

  const [visible, setVisible] = useState(false);

  // The width and device detect below which view should be rendered

  const tablet = rdd.isTablet;
  const mobile = rdd.isMobile;
  const ipad13 = rdd.isIPad13;
  const [view, setView] = useState(tablet || mobile || ipad13);
  const [width, setWidth] = useState(window.innerWidth);

  const [timeZone, setTimeZone] = useState(null);

  useEffect(() => {
    dispatch(closingTimeRecView()).then((res) => {
      setTimeZone(res.zone);
    });
  }, []);

  useLayoutEffect(() => {
    setView(rdd.isTablet || rdd.isMobile || rdd.isIPad13);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      const currentWindowWidth = window.innerWidth;
      setWidth(currentWindowWidth);
      setView(rdd.isTablet || rdd.isMobile || rdd.isIPad13);
    });
    return () =>
      window.removeEventListener("resize", () => {
        const currentWindowWidth = window.innerWidth;
        setWidth(currentWindowWidth);
        setView(rdd.isTablet || rdd.isMobile || rdd.isIPad13);
      });
  }, [width]);

  // const room =
  //   (tour && tour.room && tour.room[0] && tour.room[0].roomNumber) || [];

  let roomOption = [];

  // const roomLength = (room && room.length) || 0;
  // for (let i = 0; i < roomLength; i++) {
  //   roomOption.push({
  //     value: room[i].value,
  //     label: room[i].label,
  //   });
  // }

  const [ClientRoom, setClientRoom] = useState([]);

  const [airportList, setAirportList] = useState([]);

  // const adults = tour && tour.numOfAdults;
  // const hotelId =
  //   (tour &&
  //     tour.agent &&
  //     tour.agent.receptionist &&
  //     tour.agent.receptionist[0] &&
  //     tour.agent.receptionist[0].hotelId) ||
  //   null;

  // const [noAdults, setAdults] = useState(adults || 0);
  // const [noChild, setChild] = useState(tour ? tour.numOfChildren : 0);
  // const [noBaby, setBaby] = useState(tour ? tour.numOfBabies : 0);

  const handleTags = (value) => {
    for (let i = 0; i < value.length; i++) {
      setClientRoom([...ClientRoom, { value: value[i], label: value[i] }]);
    }
  };

  const handleDeselect = (value) => {
    roomOption = _.filter(ClientRoom, function (o) {
      return o.value !== value;
    });
    setClientRoom(roomOption);
  };

  useEffect(() => {
    dispatch(getAirportList()).then((res) => {
      setAirportList(res);
    });
    dispatch(getExtraServiceRec()).then((res) => {
      setExtraServiceList(res);
    });
    dispatch(getServicesListRec()).then((res) => {
      setServiceList(res);
    });
  }, []);

  const isNightPriceCharged = () => {
    const currentTime = moment().format("HH:mm");
    const fmt = "HH:mm";
    const mcurrentTime = moment.tz(currentTime, fmt, timeZone);
    const hours = mcurrentTime.format("HH");

    if (hours >= 8 && hours < 20) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = (values) => {
    const dte =
      (values.pickUpDate &&
        moment(values.pickUpDate, "DD/MM/YYYY").format("YYYY-MM-DD")) ||
      "";

    setDisable(true);
    let data = {
      clientName: values.clientName,
      clientPhone: values.clientPhone,
      clientEmail: values.clientEmail,
      flightNo: values.flightNo,
      receptionist: values.receptionist,
      noOfLuggage: values.noOfLuggage,
      pickUpDate: dte,
      pickUpTime: values.pickUpTime,
      babySeats: values.babySeats,
      childBSeats: values.childBSeats,
      golfEquipments: values.golfEquipments,
      specialEquipments: values.specialEquipments,
      noOfPeople: values.noOfPeople,
      paymentMethod: values.paymentMethod,
      comment: values.comment,
      airportId: values.airportId.value,
      service: {
        id: values.service.value,
        min: values.service.min,
        max: values.service.max,
        serviceTitle: values.service.title,
        servicePrice: values.service.price,
        serviceNightPrice:
          (isNightPriceCharged() && values.service.nightPrice) || 0,
      },
      direction: values.direction.value,
      rooms: (direct === 1 && ClientRoom) || null,
    };
    dispatch(bookAirportTransfer(data)).then((res) => {
      setDisable(false);
      navigate("/dashboard/airport-transfer-list");
    });
  };

  let createFormValues = {
    clientName: "",
    clientPhone: "",
    clientEmail: "",
    receptionist: "",
    flightNo: "",
    pickUpDate: "",
    pickUpTime: "",
    noOfPeople: "",
    babySeats: 0,
    childBSeats: 0,
    golfEquipments: 0,
    specialEquipments: 0,
    paymentMethod: 1,
    airportId: {},
    direction: {},
    noOfLuggage: "",
    service: {},
    comment: "",
  };

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };
  const { rowStyle, colStyle, gutter } = basicStyle;

  let directionData = [
    { value: 1, label: "Hotel to Airport" },
    { value: 2, label: " Airport to Hotel" },
  ];

  let func;

  const currentDate = moment().format("YYYY-MM-DD");

  const disabledDate = (current) => {
    if (moment(current).format("YYYY-MM-DD") < currentDate) {
      return true;
    }
  };
  const calculatePrice = (
    servPrice,
    nightPric,
    bSeat,
    cbSeat,
    gEquip,
    sEquip
  ) => {
    const sP = (servPrice && parseFloat(servPrice)) || 0;
    const nSP = (isNightPriceCharged() && parseFloat(nightPric)) || 0;
    const exP =
      (extraService &&
        parseFloat(extraService.babySeatPrice) * bSeat +
          cbSeat * parseFloat(extraService.childBoosterSeatPrice) +
          sEquip * parseFloat(extraService.specialEquipmentPrice) +
          gEquip * parseFloat(extraService.golfEquipmentPrice)) ||
      0;
    const total = sP + nSP + exP;
    return total;
  };

  return (
    <SignUpStyleWrapper className="isoSignUpPage">
      <div className="isoSignUpContentWrapper">
        <div className="isoSignUpContent">
          <div className="isoLogoWrapper">
            <Link to="/dashboard/private-tours-booking-list">
              Book Airport Transfer
            </Link>
          </div>

          <Formik
            initialValues={createFormValues}
            onSubmit={handleSubmit}
            validationSchema={() => airportTransferValidation(direct)}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              /* and other goodies */
            }) => {
              const defaultDate = values.pickUpDate
                ? moment(values.pickUpDate, "yyyy-MM-dd").format("MM/DD/yyyy")
                : null;
              func = setFieldValue;
              return (
                <form onSubmit={handleSubmit}>
                  <div className="isoSignUpForm">
                    <div className="isoInputWrapper">
                      <div className="isoInputWrapper">
                        <label className="label-style">
                          Select a direction
                        </label>
                        <Dropdown
                          options={directionData}
                          onChange={(value) => {
                            setFieldValue("direction", value);
                            setDirection(value.value);
                          }}
                          value={values.direction ? values.direction : null}
                          placeholder="Select a direction"
                        />
                        {errors.direction && touched.direction && (
                          <div className="invalid-input">
                            {errors.direction.value}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="isoInputWrapper">
                      <label className="label-style">Select an airport</label>
                      <Dropdown
                        options={airportList}
                        onChange={(value) => {
                          setFieldValue("airportId", value);
                        }}
                        value={values.airportId ? values.airportId : null}
                        placeholder="Select an airport"
                      />
                      {errors.airportId && touched.airportId && (
                        <div className="invalid-input">
                          {errors.airportId.value}
                        </div>
                      )}
                    </div>

                    <div className="isoInputWrapper">
                      <label className="label-style">Select a service</label>
                      <Dropdown
                        options={serviceList}
                        onChange={(value) => {
                          setFieldValue("service", value);
                        }}
                        value={values.service ? values.service : null}
                        placeholder="Select a service"
                      />
                      {errors.service && touched.service && (
                        <div className="invalid-input">
                          {errors.service.value}
                        </div>
                      )}
                    </div>

                    <div className="isoInputWrapper">
                      <label className="label-style">Client Name</label>
                      <Input
                        size="large"
                        type="text"
                        name="clientName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.clientName}
                      />
                      {errors.clientName && touched.clientName && (
                        <div className="invalid-input">{errors.clientName}</div>
                      )}
                    </div>
                    <div className="isoInputWrapper">
                      <label className="label-style">Client Phone</label>
                      <Input
                        size="large"
                        type="text"
                        name="clientPhone"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.clientPhone}
                      />
                      {errors.clientPhone && touched.clientPhone && (
                        <div className="invalid-input">
                          {errors.clientPhone}
                        </div>
                      )}
                    </div>

                    <div className="isoInputWrapper">
                      <label className="label-style">Client Email</label>
                      <Input
                        size="large"
                        type="text"
                        name="clientEmail"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.clientEmail}
                      />
                      {errors.clientEmail && touched.clientEmail && (
                        <div className="invalid-input">
                          {errors.clientEmail}
                        </div>
                      )}
                    </div>
                    <div className="isoInputWrapper">
                        <label className="label-style">No. Of People</label>
                        <Input
                          size="large"
                          type="text"
                          name="noOfPeople"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.noOfPeople}
                        />
                        {errors.noOfPeople && touched.noOfPeople && (
                          <div className="invalid-input">
                            {errors.noOfPeople}
                          </div>
                        )}
                      </div>
                    <div className="isoInputWrapper">
                        <label className="label-style">Receptionist Name</label>
                        <Input
                          size="large"
                          type="text"
                          name="receptionist"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.receptionist}
                        />
                        {errors.receptionist && touched.receptionist && (
                          <div className="invalid-input">
                            {errors.receptionist}
                          </div>
                        )}
                      </div>
                      <div className="isoInputWrapper">
                        <label className="label-style">Flight Number</label>
                        <Input
                          size="large"
                          type="text"
                          name="flightNo"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.flightNo}
                        />
                        {errors.flightNo && touched.flightNo && (
                          <div className="invalid-input">{errors.flightNo}</div>
                        )}
                      </div>
                    <div className="isoInputWrapper">
                      <label className="label-style">Number of Luggage</label>
                      <Input
                        size="large"
                        min={0}
                        type="number"
                        name="noOfLuggage"
                        onChange={(e) => {
                          if (e.target.value >= 0)
                            setFieldValue("noOfLuggage", e.target.value);
                        }}
                        onBlur={handleBlur}
                        value={values.noOfLuggage}
                      />
                      {errors.noOfLuggage && touched.noOfLuggage && (
                        <div className="invalid-input">
                          {errors.noOfLuggage}
                        </div>
                      )}
                    </div>

                    <div className="isoInputWrapper">
                      <label className="label-style">Date</label>
                      <DatePicker
                        inputReadOnly={view}
                        name="pickUpDate"
                        disabledDate={disabledDate}
                        onChange={(date, dateString) => {
                          setFieldValue("pickUpDate", dateString);
                        }}
                        format="DD/MM/YYYY"
                        defaultValue={
                          defaultDate ? moment(defaultDate, "DD/MM/YYYY") : null
                        }
                        // defaultValue={tour && moment(tour.date, "YYYY-MM-DD")}
                      />
                      {errors.pickUpDate && touched.pickUpDate && (
                        <div className="invalid-input">{errors.pickUpDate}</div>
                      )}
                    </div>
                    <div className="RowDiv">
                      <div
                        className="isoInputWrapper"
                        onClick={() => {
                          setVisible(true);
                        }}
                        style={{ marginBottom: 10 }}
                      >
                        <label className="label-style">Pickup Time</label>
                        <Input
                          size="large"
                          type="text"
                          name="pickUpTime"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.pickUpTime}
                        />
                        {errors.pickUpTime && touched.pickUpTime && (
                          <div className="invalid-input">
                            {errors.pickUpTime}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="RowDiv">
                      <div
                        className="isoInputWrapper"
                        style={{ maxWidth: "30%", marginRight: "10px" }}
                      >
                        <label style={{ fontSize: "12px", color: "#191D26" }}>
                          Baby Seats
                        </label>
                        <Input
                          size="large"
                          min={0}
                          type="number"
                          addonAfter={
                            <PlusOutlined
                              style={{ padding: "11px" }}
                              onClick={() => {
                                let babies =
                                  parseInt(values.babySeats || 0) + 1;
                                setFieldValue("babySeats", babies);
                              }}
                            />
                          }
                          addonBefore={
                            <MinusOutlined
                              style={{ padding: "11px" }}
                              onClick={() => {
                                let babies =
                                  parseInt(values.babySeats || 0) - 1;
                                if (parseInt(values.babySeats || 0) !== 0) {
                                  setFieldValue("babySeats", babies);
                                }
                              }}
                            />
                          }
                          name="babySeats"
                          onChange={(e) => {
                            if (e.target.value >= 0)
                              setFieldValue("babySeats", e.target.value);
                          }}
                          onBlur={handleBlur}
                          value={values.babySeats}
                        />
                        {errors.babySeats && touched.babySeats && (
                          <div className="invalid-input">
                            {errors.babySeats}
                          </div>
                        )}
                      </div>

                      <div
                        className="isoInputWrapper"
                        style={{ maxWidth: "30%" }}
                      >
                        <label style={{ fontSize: "12px", color: "#191D26" }}>
                          Child Booster Seats
                        </label>
                        <Input
                          size="large"
                          min={0}
                          type="number"
                          addonAfter={
                            <PlusOutlined
                              style={{ padding: "11px" }}
                              onClick={() => {
                                let childs =
                                  parseInt(values.childBSeats || 0) + 1;
                                setFieldValue("childBSeats", childs);
                              }}
                            />
                          }
                          addonBefore={
                            <MinusOutlined
                              style={{ padding: "11px" }}
                              onClick={() => {
                                let childs =
                                  parseInt(values.childBSeats || 0) - 1;
                                if (parseInt(values.childBSeats || 0) !== 0) {
                                  setFieldValue("childBSeats", childs);
                                }
                              }}
                            />
                          }
                          name="childBSeats"
                          onChange={(e) => {
                            if (e.target.value >= 0)
                              setFieldValue("childBSeats", e.target.value);
                          }}
                          onBlur={handleBlur}
                          value={values.childBSeats}
                        />
                        {errors.childBSeats && touched.childBSeats && (
                          <div className="invalid-input">
                            {errors.childBSeats}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="RowDiv">
                      <div
                        className="isoInputWrapper"
                        style={{ maxWidth: "30%", marginRight: "10px" }}
                      >
                        <label style={{ fontSize: "12px", color: "#191D26" }}>
                          Golf Equipments
                        </label>
                        <Input
                          size="large"
                          min={0}
                          type="number"
                          addonAfter={
                            <PlusOutlined
                              style={{ padding: "11px" }}
                              onClick={() => {
                                let golf =
                                  parseInt(values.golfEquipments || 0) + 1;
                                setFieldValue("golfEquipments", golf);
                              }}
                            />
                          }
                          addonBefore={
                            <MinusOutlined
                              style={{ padding: "11px" }}
                              onClick={() => {
                                let golf =
                                  parseInt(values.golfEquipments || 0) - 1;
                                if (
                                  parseInt(values.golfEquipments || 0) !== 0
                                ) {
                                  setFieldValue("golfEquipments", golf);
                                }
                              }}
                            />
                          }
                          name="golfEquipments"
                          onChange={(e) => {
                            if (e.target.value >= 0)
                              setFieldValue("golfEquipments", e.target.value);
                          }}
                          onBlur={handleBlur}
                          value={values.golfEquipments}
                        />
                        {errors.golfEquipments && touched.golfEquipments && (
                          <div className="invalid-input">
                            {errors.golfEquipments}
                          </div>
                        )}
                      </div>

                      <div
                        className="isoInputWrapper"
                        style={{ maxWidth: "30%" }}
                      >
                        <label style={{ fontSize: "12px", color: "#191D26" }}>
                          Special Equipments
                        </label>
                        <Input
                          size="large"
                          label="ss"
                          min={0}
                          type="number"
                          addonAfter={
                            <PlusOutlined
                              style={{ padding: "11px" }}
                              onClick={() => {
                                let special =
                                  parseInt(values.specialEquipments || 0) + 1;
                                setFieldValue("specialEquipments", special);
                              }}
                            />
                          }
                          addonBefore={
                            <MinusOutlined
                              style={{ padding: "11px" }}
                              onClick={() => {
                                let special =
                                  parseInt(values.specialEquipments || 0) - 1;
                                if (
                                  parseInt(values.specialEquipments || 0) !== 0
                                ) {
                                  setFieldValue("specialEquipments", special);
                                }
                              }}
                            />
                          }
                          name="specialEquipments"
                          onChange={(e) => {
                            if (e.target.value >= 0)
                              setFieldValue(
                                "specialEquipments",
                                e.target.value
                              );
                          }}
                          onBlur={handleBlur}
                          value={values.specialEquipments}
                        />
                        {errors.specialEquipments &&
                          touched.specialEquipments && (
                            <div className="invalid-input">
                              {errors.specialEquipments}
                            </div>
                          )}
                      </div>
                    </div>

                    {(direct === 1 && (
                      <div className="isoInputWrapper">
                        <label className="label-style">Add Room#</label>
                        <Select
                          mode="tags"
                          defaultValue={ClientRoom}
                          style={{ width: "100%" }}
                          onChange={handleTags}
                          // onBlur={handleBlur}
                          onDeselect={handleDeselect}
                          options={ClientRoom}
                        />
                      </div>
                    )) ||
                      null}

                    <div className="isoInputWrapper">
                      <label className="label-style">Comments</label>
                      <Textarea
                        rows={4}
                        size="large"
                        type="text"
                        name="comment"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.comment}
                      />
                      {errors.comment && touched.comment && (
                        <div className="invalid-input">{errors.comment}</div>
                      )}
                      <small class="form-text text-muted">
                        *This does not appear on client voucher - only intended
                        for internal communication with BGT Travel
                      </small>
                    </div>

                    <div className="isoInputWrapper">
                      <Row style={rowStyle} gutter={gutter} justify="start">
                        <Col md={12} sm={12} xs={24} style={colStyle}>
                          <div>
                            <p style={{ fontWeight: "bold" }}>Payment Method</p>
                          </div>
                          <RadioGroup
                            onChange={handleChange}
                            name="paymentMethod"
                            value={values.paymentMethod}
                          >
                            <Radio style={radioStyle} value={1}>
                              Cash
                            </Radio>
                            <Radio style={radioStyle} value={2}>
                              Credit or debit card
                            </Radio>
                            <Radio style={radioStyle} value={3}>
                              Monthly Invoicing
                            </Radio>
                          </RadioGroup>
                        </Col>
                      </Row>
                      {errors.paymentMethod && touched.paymentMethod && (
                        <div className="invalid-input">
                          {errors.paymentMethod}
                        </div>
                      )}
                    </div>
                    {values.service ? (
                      <div className="isoInputWrapper">
                        <div>
                          <p>{`Total Price: 
                        ${EURO.format(
                          calculatePrice(
                            values.service.price,
                            values.service.nightPrice,
                            values.babySeats,
                            values.childBSeats,
                            values.golfEquipments,
                            values.specialEquipments
                          )
                        )}
                        `}</p>
                        </div>
                      </div>
                    ) : null}

                    <Button
                      type="primary"
                      onClick={handleSubmit}
                      disabled={disable}
                    >
                      Book
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
        <Modal
          title="Select your Time"
          centered
          visible={visible}
          onCancel={() => setVisible(false)}
          footer={null}
          width={300}
          bodyStyle={{ height: 500 }}
        >
          <TimeKeeper
            time={time}
            hour24Mode={true}
            onDoneClick={(value) => {
              setTime(value.formatted24);
              func("pickUpTime", value.formatted24);
              setVisible(false);
            }}
          />
        </Modal>
      </div>
    </SignUpStyleWrapper>
  );
}
