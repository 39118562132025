import React, {useEffect} from 'react';


import {useDispatch} from 'react-redux';

import BoardLists from './Board/BoardList/BoardList';
import {getAdminList} from '@iso/redux/superAdmin/actions';

export default function AdminList() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAdminList());
  }, []);
  return (
    <>
      <BoardLists />
    </>
  );
}
