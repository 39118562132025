import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import BoardLists from './Board/BoardList/BoardList';
import {getCityList} from '@iso/redux/city/actions';
import {getBusStopList} from '@iso/redux/busStop/actions';

export default function CityList() {
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(getCityList());
    dispatch(getBusStopList());
  }, []);

  return (
    <>
      <BoardLists />
    </>
  );
}
