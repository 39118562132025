import React, { useEffect, useState } from "react";
import TimeKeeper from "react-timekeeper";
import {
  addTourSettingAdminAdd,
  tourSettingAdmin,
} from "../../../redux/tours/actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import Select, { SelectOption } from "@iso/components/uielements/select";
import { TIMEZONES } from "../../../constants/Timezone";
import { Button, Spin, message } from "antd";
import { useLocation } from "react-router-dom";

const Option = SelectOption;

const Setting = () => {
  const dispatch = useDispatch();

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [zone, setZone] = useState(null);
  const [error, setError] = useState("");
  const [timezone, setTimezone] = useState(null);
  const [loading, setLoading] = useState(true);
  const [time, setTime] = useState(null);

  const { state } = useLocation();
  const { type } = state || {};

  useEffect(() => {
    dispatch(tourSettingAdmin(type))
      .then((res) => {
        setTime(res);
        setStartTime(res.startTime);
        setEndTime(res.endTime);
        setTimezone(res.zone);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const handleTime = (startTime, endTime) => {
    // if (startTime === null || endTime === null) {
    //   message.warn(error);
    //   return;
    // }
    // moment(startTime, 'HH:mm:ss').format('HH:mm')
    // moment(endTime, 'HH:mm:ss').format('HH:mm')

    if (!time.zone) {
      const timeZone = moment.tz.guess();
      dispatch(addTourSettingAdminAdd(time.startTime, time.endTime, type, timeZone));
    } else {
      dispatch(addTourSettingAdminAdd(time.startTime, time.endTime, type, time.zone));
    }
  };

  const handleTimeChange = (newTime, timeString) => {
    if (timeString === "startTime") {
      setStartTime(newTime.formatted24);
      setTime({ ...time, startTime: moment(newTime.formatted24, "HH:mm:ss").format("HH:mm") });
    } else if (timeString === "endTime") {
      setEndTime(newTime.formatted24);
      setTime({ ...time, endTime: moment(newTime.formatted24, "HH:mm:ss").format("HH:mm") });
    }
  };

  console.log('time', time)

  if (loading) {
    <div className="spinner">
      <Spin />
    </div>;
  } else {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <Select
            showSearch
            placeholder="Select Time Zone"
            allowClear
            onChange={(value) => setTime({ ...time, zone: value } || "")}
            filterOption={(input, option) =>
              (option?.children ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            value={(time && time.zone) || ""}
            style={{ width: "200px" }}
          >
            {moment.tz.names() &&
              moment.tz.names().map((data, key) => {
                return (
                  <Option key={key} value={data}>
                    {data}
                  </Option>
                );
              })}
          </Select>
          <h2
            style={{
              fontSize: "24px",
              fontWeight: "300",
              lineHeight: "4",
              textTransform: "uppercase",
              color: "#788195",
            }}
          >
            Select Closing Time
          </h2>
          {error && <p>{error}</p>}
          <div style={{ display: "flex" }}>
            <div style={{ margin: "10px" }}>
              <h1 style={{ color: (startTime && "black") || "red" }}>
                Start Time: {startTime}
              </h1>
              <TimeKeeper
                time={startTime}
                onChange={(newTime) => handleTimeChange(newTime, "startTime")}
              />
            </div>
            <div style={{ margin: "10px" }}>
              <h1 style={{ color: (endTime && "black") || "red" }}>
                End Time: {endTime}
              </h1>
              <TimeKeeper
                time={endTime}
                onChange={(newTime) => handleTimeChange(newTime, "endTime")}
              />
            </div>
          </div>
          <Button
            style={{ width: "500px" }}
            onClick={() => handleTime()}
          >
            Done
          </Button>
        </div>
      </>
    );
  }
};

export default Setting;
