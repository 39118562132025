import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Formik } from "formik";

import Input from "@iso/components/uielements/input";
import Button from "@iso/components/uielements/button";
import { createAirport, updateAirport } from "@iso/redux/airport/actions";
import IntlMessages from "@iso/components/utility/intlMessages";
import SignUpStyleWrapper from "./CreateAirport.styles";
import { airportFormValidation } from "../../../constants";
import { Avatar, Slider } from "antd";
import { Map, getStaticMap } from "../../../services";

export default function CreateAirports() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useLocation();
  const locParams = params && params.state ? params.state : { airport: null };

  const [disable, setDisable] = useState(false);

  const [location, setLocation] = useState({});
  const showScreen =
    (locParams.airport && locParams.airport.map && locParams.airport.map.url) ||
    null;

  const [zoom, setZoom] = useState(13);
  const [avatarImg, setAvatarImg] = useState(null);
  const [imageFile, setImageFile] = useState(
    (locParams.airport &&
      locParams.airport.photo &&
      locParams.airport.photo.url) ||
      null
  );
  const [radius, setRadius] = useState(
    locParams.airport ? locParams.airport.radius : 0
  );
  const handleSubmit = async (values) => {
    const stopMapImage = await screenShotMap(location.lat, location.lng);
    setDisable(true);
    values.locationLat = location.lat;
    values.locationLon = location.lng;
    if (locParams.airport) {
      if (values.location === 0) {
        let updatedAirport = {
          ...values,
          radius,
          id: locParams.airport.id,
          photo: values.photo,
          map: stopMapImage,
          zoom,
        };
        dispatch(updateAirport(updatedAirport)).then((res) => {
          setDisable(false);
          navigate("/dashboard/airport-list");
        });
      } else {
        let updatedAirport = {
          ...values,
          radius,
          id: locParams.airport.id,
          photo: values.photo,
          map: stopMapImage,
          zoom,
        };
        dispatch(updateAirport(updatedAirport)).then((res) => {
          setDisable(false);
          navigate("/dashboard/airport-list");
        });
      }
    } else {
      let data = {
        ...values,
        radius,
        photo: values.photo,
        map: stopMapImage,
        zoom,
      };

      dispatch(createAirport(data)).then((res) => {
        setDisable(false);
        navigate("/dashboard/airport-list");
      });
    }
  };

  const onChangeRadius = (val) => {
    setRadius(val);
  };

  async function url2File(url, fileName) {
    const blob = await (await fetch(url)).blob();
    return new File([blob], fileName, { type: blob.type });
  }

  const screenShotMap = async (lat, lng) => {
    try {
      const staticMapImage = getStaticMap(lat, lng, zoom);
      const fileImage = await url2File(staticMapImage, "smaple.png");

      return fileImage;
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <SignUpStyleWrapper className="isoSignUpPage">
      <div className="isoSignUpContentWrapper">
        <div className="isoSignUpContent">
          <div className="isoLogoWrapper">
            <Link to="/dashboard">
              <IntlMessages
                id={locParams.airport ? "Edit Airport" : "Add a New Airport"}
              />
            </Link>
          </div>

          <Formik
            initialValues={{
              name: locParams.airport ? locParams.airport.name : "",

              country: locParams.airport ? locParams.airport.country : "",
              state: locParams.airport ? locParams.airport.state : "",
              address: locParams.airport ? locParams.airport.address : "",
              city: locParams.airport ? locParams.airport.city : "",
              location: {},
              photo:
                (locParams.airport &&
                  locParams.airport.photo &&
                  locParams.airport.photo.url) ||
                null,
              map:
                (locParams.airport &&
                  locParams.airport.map &&
                  locParams.airport.map.url) ||
                null,
            }}
            onSubmit={handleSubmit}
            validationSchema={airportFormValidation}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              /* and other goodies */
            }) => {
              const onFileChange = (e) => {
                setAvatarImg(null);
                setFieldValue("photo", e.target.files[0]);
                setImageFile(URL.createObjectURL(e.target.files[0]));
              };
              return (
                <form onSubmit={handleSubmit}>
                  <div className="isoSignUpForm">
                    <div className="RowDiv">
                      <div className="isoInputWrapper">
                      <label className="label-style">Name</label>
                        <Input
                          size="large"
                          type="text"
                          name="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                        />
                        {errors.name && touched.name && (
                          <div className="invalid-input">{errors.name}</div>
                        )}
                      </div>

                      <div className="isoInputWrapper">
                      <label className="label-style">City</label>
                        <Input
                          size="large"
                          type="text"
                          name="city"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.city}
                        />
                        {errors.city && touched.city && (
                          <div className="invalid-input">{errors.city}</div>
                        )}
                      </div>
                    </div>

                    <div className="isoInputWrapper">
                    <label className="label-style">Address</label>
                      <Input
                        size="large"
                        type="text"
                        name="address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.address}
                      />
                      {errors.address && touched.address && (
                        <div className="invalid-input">{errors.address}</div>
                      )}
                    </div>

                    <div className="RowDiv">
                      <div className="isoInputWrapper">
                      <label className="label-style">Country</label>
                        <Input
                          size="large"
                          type="text"
                          name="country"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.country}
                        />
                        {errors.country && touched.country && (
                          <div className="invalid-input">{errors.country}</div>
                        )}
                      </div>

                      <div className="isoInputWrapper">
                      <label className="label-style">State</label>
                        <Input
                          size="large"
                          type="text"
                          name="state"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.state}
                        />
                        {errors.state && touched.state && (
                          <div className="invalid-input">{errors.state}</div>
                        )}
                      </div>
                    </div>
                    <div className="isoInputWrapper">
                    <label className="label-style">Image</label>
                      <Input
                        type="file"
                        name="photo"
                        accept="image/x-png,image/jpeg,image/jpg"
                        data-max-size="2048"
                        // onChange={(e) =>
                        //   handleChange(
                        //     ((e.target.files[0].size / 1048576).toFixed(2) >
                        //       2 &&
                        //       console.log("size")) ||
                        //       setFieldValue("photo", e.target.files[0]),
                        //       setImageFile(URL.createObjectURL(e.target.files[0]))
                        //   )
                        // }
                        onChange={(e) => onFileChange(e)}
                        // onBlur={handleBlur}
                        value={undefined}
                      />
                      {
                        <Avatar
                          src={
                            imageFile && imageFile
                            //  (locParams &&
                            //    locParams.hotel &&
                            //    locParams.hotel.avatar &&
                            //    locParams.hotel.avatar.url)
                          }
                          style={{ marginTop: "10px" }}
                          shape="square"
                          size={100}
                        />
                      }

                      {avatarImg && (
                        <div className="invalid-input">{avatarImg}</div>
                      )}
                    </div>
                    {showScreen ? (
                      <div className="isoInputWrapper">
                        <Avatar
                          src={showScreen}
                          style={{ marginTop: "10px" }}
                          shape="square"
                          size={100}
                        />
                      </div>
                    ) : null}
                    <div style={{ padding: "0 7px" }}>
                      <p
                        style={{
                          fontWeight: 400,
                          paddingBottom: 10,
                          fontSize: "25px",
                          color: "black",
                        }}
                      >
                        Airport Location
                      </p>
                    </div>
                    <div style={{ padding: "0 7px" }}>
                      <label style={{ paddingBottom: 10, color: "#788195" }}>
                        Radius
                      </label>
                      <Slider
                        defaultValue={radius}
                        style={{ width: "50%" }}
                        handleStyle={{
                          marginTop: "-10px",
                          width: "22px",
                          height: "22px",
                        }}
                        max={1000}
                        onChange={onChangeRadius}
                      />
                    </div>
                    <div className="mapWrapper">
                      <Map
                        radius={radius}
                        getLocation={(loc) => {
                          setLocation(loc);
                          setFieldValue("location", loc);
                        }}
                        setZoom={setZoom}
                        setLocation={
                          locParams.airport
                            ? {
                                lat: locParams.airport.locationLat,
                                lng: locParams.airport.locationLon,
                              }
                            : null
                        }
                        getQuery={() => {}}
                      />
                      {errors.location && touched.location && (
                        <div className="invalid-input">{errors.location}</div>
                      )}
                    </div>

                    <Button
                      type="primary"
                      onClick={handleSubmit}
                      disabled={disable}
                    >
                      <IntlMessages
                        id={locParams.airport ? "Update" : "page.signUpButton"}
                      />
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </SignUpStyleWrapper>
  );
}
