import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {Formik} from 'formik';

import Input from '@iso/components/uielements/input';
import Button from '@iso/components/uielements/button';
import {createAdmin} from '@iso/redux/superAdmin/actions';
import IntlMessages from '@iso/components/utility/intlMessages';
import SignUpStyleWrapper from './createAdmin.styles';
import {registerValidation} from '../../../constants';

export default function CreateAdmin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = (values) => {
    dispatch(createAdmin(values)).then((res) => {
      navigate('/dashboard/admin-list');
    });
  };
  return (
    <SignUpStyleWrapper className="isoSignUpPage">
      <div className="isoSignUpContentWrapper">
        <div className="isoSignUpContent">
          <div className="isoLogoWrapper">
            <Link to="/dashboard">
              <IntlMessages id="Add a new Admin" />
            </Link>
          </div>
          <Formik
            initialValues={{
              email: '',
              password: '',
              firstName: '',
              lastName: '',
              confirmPassword: '',
            }}
            validationSchema={registerValidation}
            onSubmit={handleLogin}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="isoSignUpForm">
                  <div className="isoInputWrapper">
                    <Input
                      size="large"
                      placeholder="First name"
                      type="text"
                      name="firstName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                    />
                    {errors.firstName && touched.firstName && (
                      <div className="invalid-input">{errors.firstName}</div>
                    )}
                  </div>
                  <div className="isoInputWrapper">
                    <Input
                      size="large"
                      placeholder="Last name"
                      type="text"
                      name="lastName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                    />
                    {errors.lastName && touched.lastName && (
                      <div className="invalid-input">{errors.lastName}</div>
                    )}
                  </div>

                  {/* <div className="isoInputWrapper">
                    <Input size="large" placeholder="Username" />
                  </div> */}

                  <div className="isoInputWrapper">
                    <Input
                      size="large"
                      placeholder="Email"
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    {errors.email && touched.email && (
                      <div className="invalid-input">{errors.email}</div>
                    )}
                  </div>

                  <div className="isoInputWrapper">
                    <Input
                      size="large"
                      type="password"
                      placeholder="Password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    {errors.password && touched.password && (
                      <div className="invalid-input">{errors.password}</div>
                    )}
                  </div>

                  <div className="isoInputWrapper">
                    <Button type="primary" onClick={handleSubmit}>
                      <IntlMessages id="page.signUpButton" />
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </SignUpStyleWrapper>
  );
}
