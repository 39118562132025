import '../assets/styles/bookTour.css';
export default {
  siteName: 'BGT Travel',
  siteIcon: 'ion-flash',
  footerText: `BGT Travel @ ${new Date().getFullYear()} Developed by `,
  developers: <p class="developers"><a className='link' target="_blank" href="https://www.intellicel.com">intelliCEL</a></p>,
  enableAnimatedRoute: false,
  apiUrl: 'http://yoursite.com/api/',
  google: {
    analyticsKey: 'UA-xxxxxxxxx-1',
  },
  dashboard: '/dashboard',
};
