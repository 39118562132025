import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import StickerWidget from "@iso/containers/Widgets/Sticker/StickerWidget";
import IsoWidgetsWrapper from "@iso/containers/Widgets/WidgetsWrapper";
import basicStyle from "@iso/assets/styles/constants";
import schedule from "@iso/assets/images/schedule.jpg";
import { Row, Col, Image } from "antd";
import {
  getRecHotelBookingCount,
  getRecAirportTransferBookingCount,
  getTourScheduleAtRec,
  getDefaultHotelBannerAtRec,
} from "@iso/redux/dashboard/actions";
import { getHotelView } from "../../../redux/dashboard/actions";
import { Banner } from "./Dashboard.styles.js";
import List from "./schedule/List.js";

const styles = {
  wisgetPageStyle: {
    display: "flex",
    flexFlow: "row wrap",
    alignItems: "flex-start",
    overflow: "hidden",
    margin: "10px 0",
  },
};
const Dashboard = () => {
  const { rowStyle, colStyle } = basicStyle;
  const dispatch = useDispatch();

  const [hotelBookingCount, setHotelBookingCount] = useState(null);
  const [AirportBookingCount, setAirportBookingCount] = useState(null);
  const [hotelData, setHotel] = useState(null);
  const [scheduleList, setScheduleList] = useState(null);
  const [defaultBanner, setDefaultBanner] = useState(null);
  useEffect(() => {
    dispatch(getHotelView()).then((res) => {
      setHotel(res);
    });
    dispatch(getDefaultHotelBannerAtRec()).then((res) => {
      setDefaultBanner(res);

    });
    dispatch(getRecHotelBookingCount()).then((res) => {
      setHotelBookingCount(res);
    });
    dispatch(getRecAirportTransferBookingCount()).then((res) => {
      setAirportBookingCount(res);
    });
    dispatch(getTourScheduleAtRec()).then((res) => {
      setScheduleList(res);
    });
  }, []);

  const defaultBannerUrl = (defaultBanner && defaultBanner.url) || null;

  const banner =
    (hotelData && hotelData.banner && hotelData.banner.url) || null;

    console.log('banner', banner);
    console.log('defaultBannerUrl', defaultBannerUrl);

  const finalBanner = banner || defaultBannerUrl;

  console.log('finalBanner', finalBanner);
  return (
    <div style={styles.wisgetPageStyle}>
      {(finalBanner && (
        <Banner
          className="profile-banner"
          style={{
            backgroundImage: `url(${finalBanner})`,
            width: "100%",
            margin: "15px",
          }}
        ></Banner>
      )) ||
        null}
      <div style={{ margin: "5px 15px" }}>
        <h2
          style={{
            fontSize: "21px",
            fontFamily: "Roboto",
            color: "#323332",
            fontWeight: "400",
          }}
        >
          Hotels
        </h2>
      </div>

      <Row style={rowStyle} gutter={0} justify="start">
        <Col lg={4} md={12} sm={12} xs={24} style={colStyle}>
          <IsoWidgetsWrapper>
            <StickerWidget
              number={(hotelBookingCount && hotelBookingCount.today) || 0}
              text="Today Bookings"
              fontColor={"white"}
              bgColor={"#7266BA"}
            />
          </IsoWidgetsWrapper>
        </Col>
        <Col lg={4} md={12} sm={12} xs={24} style={colStyle}>
          <IsoWidgetsWrapper>
            <StickerWidget
              number={(hotelBookingCount && hotelBookingCount.past) || 0}
              text="Past Bookings"
              fontColor={"white"}
              bgColor={"#42A5F6"}
            />
          </IsoWidgetsWrapper>
        </Col>
        <Col lg={4} md={12} sm={12} xs={24} style={colStyle}>
          <IsoWidgetsWrapper>
            <StickerWidget
              number={(hotelBookingCount && hotelBookingCount.upcoming) || 0}
              text="Upcoming Bookings"
              fontColor={"white"}
              bgColor={"#7ED320"}
            />
          </IsoWidgetsWrapper>
        </Col>
      </Row>
      <div style={{ margin: "5px 15px" }}>
        <h2
          style={{
            fontSize: "21px",
            fontFamily: "Roboto",
            color: "#323332",
            fontWeight: "400",
          }}
        >
          Airport Transfers
        </h2>
      </div>
      <Row style={rowStyle} gutter={0} justify="start">
        <Col lg={4} md={12} sm={12} xs={24} style={colStyle}>
          <IsoWidgetsWrapper>
            <StickerWidget
              number={(AirportBookingCount && AirportBookingCount.today) || 0}
              text="Today Bookings"
              fontColor={"white"}
              bgColor={"#42A5F6"}
            />
          </IsoWidgetsWrapper>
        </Col>
        <Col lg={4} md={12} sm={12} xs={24} style={colStyle}>
          <IsoWidgetsWrapper>
            <StickerWidget
              number={(AirportBookingCount && AirportBookingCount.past) || 0}
              text="Past Bookings"
              fontColor={"white"}
              bgColor={"#FEAC01"}
            />
          </IsoWidgetsWrapper>
        </Col>
        <Col lg={4} md={12} sm={12} xs={24} style={colStyle}>
          <IsoWidgetsWrapper>
            <StickerWidget
              number={
                (AirportBookingCount && AirportBookingCount.upcoming) || 0
              }
              text="Upcoming Bookings"
              fontColor={"white"}
              bgColor={"rgb(247, 93, 129)"}
            />
          </IsoWidgetsWrapper>
        </Col>
      </Row>

      <Row style={{ margin: "5px 15px" }} gutter={0} justify="start">
        <Col lg={16} md={12} sm={12} xs={24} style={colStyle}>
          {/* <Image src={schedule} /> */}
          <div style={{ margin: "5px 0" }}>
            <h2
              style={{
                fontSize: "21px",
                fontFamily: "Roboto",
                color: "#323332",
                fontWeight: "400",
              }}
            >
              Tour Schedule
            </h2>
          </div>
          {(schedule && <List schedule={scheduleList} />) || null}
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
