import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Radio, { RadioGroup } from "@iso/components/uielements/radio";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import _ from "lodash";

import Dropdown from "@iso/components/uielements/CustomDropdown";
import Input from "@iso/components/uielements/input";
import Button from "@iso/components/uielements/button";
import DatePicker from "@iso/components/uielements/datePicker";
import { transportBookingValidation } from "@iso/constants";
import SignUpStyleWrapper from "./BookTransportTours.styles";
import {
  getTourBookingTypeList,
  bookSharedTour,
  getToursList,
  tourClientSetting,
  bookUpdateSharedTour,
  getPaymentIntent,
} from "../../../redux/receptionist/actions";
import {
  fetchTourCloseDayByRec,
  fetchTourSettingByRec,
} from "../../../redux/tours/actions";
import appActions from "@iso/redux/app/actions";
import basicStyle from "@iso/assets/styles/constants";
import { Col, Modal, Row } from "antd";
import moment from "moment";
import {
  GUESTS,
  TOUR_TYPES,
  EURO,
  LANGUAGES,
  PLATFORM,
} from "../../../constants";
import TimeKeeper from "react-timekeeper";
import { Textarea } from "../../../components/uielements/input";
import "../../../assets/styles/bookTour.css";
import Select from "@iso/components/uielements/select";
import NATIONS from "../../../constants/Nationality";
import * as rdd from "react-device-detect";
import { fillTourSetting, validationClient } from "../../../services";
import { BOOKING_CLIENT_DETAILS } from "../../../constants/Tour";
import ClientDetailsForm from "../../../components/TourBooking/ClientDetailsForm";

export default function CreateTours() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { getSettingTime } = appActions;

  const params = useLocation();
  const locParams = params && params.state ? params.state : { tour: null };
  const tourData = (locParams && locParams.tour) || null;

  const bookId = (locParams && locParams.tour && locParams.tour.id) || null;
  const isUpdate = (locParams && locParams.flag) || 0;

  const [tsetting, setSetting] = useState(null);
  const [visible, setVisible] = useState(false);
  const [time, setTime] = useState(null);
  const [typeList, setTypeList] = useState([]);
  const [disable, setDisable] = useState(false);

  const fetchNoOfClients = (tourData && tourData.noOfPersons) || null;

  const [closeDay, setCloseDay] = useState([]);
  const [client, setClients] = useState(fetchNoOfClients);

  // The width and device detect below which view should be rendered
  const tablet = rdd.isTablet;
  const mobile = rdd.isMobile;
  const ipad13 = rdd.isIPad13;

  const [view, setView] = useState(tablet || mobile || ipad13);
  const [width, setWidth] = useState(window.innerWidth);

  useLayoutEffect(() => {
    setView(rdd.isTablet || rdd.isMobile || rdd.isIPad13);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      const currentWindowWidth = window.innerWidth;
      setWidth(currentWindowWidth);
      setView(rdd.isTablet || rdd.isMobile || rdd.isIPad13);
    });
    return () =>
      window.removeEventListener("resize", () => {
        const currentWindowWidth = window.innerWidth;
        setWidth(currentWindowWidth);
        setView(rdd.isTablet || rdd.isMobile || rdd.isIPad13);
      });
  }, [width]);

  const room =
    (tourData &&
      tourData.room &&
      tourData.room[0] &&
      tourData.room[0].roomNumber) ||
    [];

  let roomOption = [];

  const roomLength = (room && room.length) || 0;
  for (let i = 0; i < roomLength; i++) {
    roomOption.push({
      value: room[i].value,
      label: room[i].label,
    });
  }

  const [ClientRoom, setClientRoom] = useState(roomOption);
  //   const [noAdults, setAdults] = useState(0);
  //   const [noChild, setChild] = useState(0);
  //   const [noBaby, setBaby] = useState(0);

  const [clientDetail, setClientDetail] = useState(
    (tourData && tourData.bookingClient) || []
  );

  const [clientMessage, setclientMessage] = useState(false);
  const [clientMessageFlag, setclientMessageFlag] = useState(false);

  //   const [clientRemoveFlag, setclientRemoveFlag] = useState([]);

  //   const addElement = () => {
  //     if (!client) {
  //       return;
  //     } else if (client.value === 0) {
  //       setAdults(noAdults + 1);
  //       setclientRemoveFlag([...clientRemoveFlag, client.value]);
  //       setclientMessageFlag(false);
  //     } else if (client.value === 1) {
  //       setclientRemoveFlag([...clientRemoveFlag, client.value]);
  //       setChild(noChild + 1);
  //       setclientMessageFlag(false);
  //     } else if (client.value === 2) {
  //       setclientRemoveFlag([...clientRemoveFlag, client.value]);
  //       setBaby(noBaby + 1);
  //       setclientMessageFlag(false);
  //     }
  //     setClientDetail([
  //       ...clientDetail,
  //       BOOKING_CLIENT_DETAILS,
  //     ]);
  //   };

  //   const removeFormFields = (i) => {
  //     if (clientRemoveFlag[i] === 0) {
  //       clientRemoveFlag.splice(i,1);
  //       setclientRemoveFlag(clientRemoveFlag)
  //       setAdults(noAdults - 1);
  //     } else if (clientRemoveFlag[i] === 1) {
  //       clientRemoveFlag.splice(i,1);
  //       setclientRemoveFlag(clientRemoveFlag)
  //       setChild(noChild - 1);
  //     } else if (clientRemoveFlag[i] === 2) {
  //       clientRemoveFlag.splice(i,1);
  //       setclientRemoveFlag(clientRemoveFlag)
  //       setBaby(noBaby - 1);
  //     }
  //     let newFormValues = [...clientDetail];
  //     newFormValues.splice(i, 1);
  //     setClientDetail(newFormValues);
  //   };

  const handleChangeF = (i, name, value) => {

      setClientDetail((prevClientDetail) => {
        const newClientDetail = [...prevClientDetail];
        newClientDetail[i] = {
          ...newClientDetail[i],
          [name]: value,
        };
        return newClientDetail;
      });

  };

  const handleNationality = (i, e) => {
    setClientDetail((prevClientDetail) => {
      const newClientDetail = [...prevClientDetail];
      newClientDetail[i] = {
        ...newClientDetail[i],
        nationality: e.label,
      };
      return newClientDetail;
    });
  };

  const handleDocumentType = (i, e) => {
    setClientDetail((prevClientDetail) => {
      const newClientDetail = [...prevClientDetail];
      newClientDetail[i] = {
        ...newClientDetail[i],
        documentType: e.label,
      };
      return newClientDetail;
    });
  };

  useEffect(() => {
    dispatch(getToursList());
    dispatch(getTourBookingTypeList()).then((res) => {
      setTypeList(res);
    });
    dispatch(fetchTourSettingByRec(TOUR_TYPES.tpt_tkt_lcl_acc_guide)).then(
      (res) => {
        setSetting(res);
      }
    );
  }, []);

  const handleTags = (value) => {
    for (let i = 0; i < value.length; i++) {
      setClientRoom([...ClientRoom, { value: value[i], label: value[i] }]);
    }
  };

  const handleDeselect = (value) => {
    roomOption = _.filter(ClientRoom, function (o) {
      return o.value !== value;
    });
    setClientRoom(roomOption);
  };

  const [clientSecret, setClientSecret] = useState("");

  const handleSubmit = (values) => {
    // if (!client || clientMessageFlag) {
    //   setclientMessage(true);
    //   return;
    // }
    // setclientMessage(false);
    let dte = null;
    if (values.date.includes("/")) {
      dte = moment(values.date, "DD/MM/YYYY").format("YYYY-MM-DD");
    } else {
      // The input is already in YYYY-MM-DD format
      dte = moment(values.date).format("YYYY-MM-DD");
    }

    if (clientDetail && clientDetail.length === 0) {
      return;
    }
    if (validationClient(clientDetail, clientSetting)) {
      return;
    }
    setDisable(true);
    if (isUpdate) {
      let data = {
        clientName: values.clientName,
        clientPhone: values.clientPhone,
        clientEmail: values.clientEmail,
        receptionist: values.receptionist,
        date: dte,
        pickUpTime: values.pickUpTime,
        typeId: values.typeId.value,
        tourId: values.tourId.value,
        clientDetails: (clientDetail &&
          clientDetail.length > 0 &&
          clientDetail) || [BOOKING_CLIENT_DETAILS],
        description: values.description,
        type: TOUR_TYPES.tpt_tkt_lcl_acc_guide,
        lang: values.language.value,
        rooms: ClientRoom,
        platform:
          (tourData && tourData.platform === PLATFORM.app && PLATFORM.app) ||
          PLATFORM.web,
        uId: tourData && tourData.uId,
      };
      dispatch(bookUpdateSharedTour(data, bookId)).then((res) => {
        setDisable(false);
        navigate("/dashboard/private-tours-booking-list");
      });
    } else {
      let data = {
        clientName: values.clientName,
        clientPhone: values.clientPhone,
        clientEmail: values.clientEmail,
        receptionist: values.receptionist,
        date: dte,
        noOfPersons: clientDetail.length,
        //   numOfChildren: noChild,
        //   numOfBabies: noBaby,
        extraCharges: values.extraCharges,
        paymentMethod: values.paymentMethod,
        pickUpTime: values.pickUpTime,
        typeId: values.typeId.value,
        tourId: values.tourId.value,
        clientDetails: (clientDetail &&
          clientDetail.length > 0 &&
          clientDetail) || [BOOKING_CLIENT_DETAILS],
        description: values.description,
        type: TOUR_TYPES.tpt_tkt_lcl_acc_guide,
        lang: values.language.value,
        rooms: ClientRoom,
        platform:
          (tourData && tourData.platform === PLATFORM.app && PLATFORM.app) ||
          PLATFORM.web,
        uId: tourData && tourData.uId,
      };
      dispatch(bookSharedTour(data)).then((bookRes) => {
        if (values.paymentMethod === 4) {
          dispatch(getPaymentIntent(bookRes.bT.id)).then((res) => {
            setDisable(false);
            setClientSecret(res.paymentIntent);
            navigate(`/dashboard/payment-stripe/${res.paymentIntent}`, {
              state: {
                bookId: bookRes.bT.id,
                res,
                path: "private-tours-booking-list",
              },
            });
          });
        } else {
          setDisable(false);
          navigate("/dashboard/private-tours-booking-list");
        }
      });
    }
  };

  let createFormValues = {
    clientName: tourData ? tourData.clientName : "",
    clientPhone: tourData ? tourData.clientPhone : "",
    clientEmail: tourData ? tourData.clientEmail : "",
    receptionist: tourData ? tourData.receptionist : "",
    date: tourData ? tourData.date : "",
    numOfAdults: "",
    pickUpTime: tourData
      ? moment(tourData.pickUpTime, "HH:mm:ss").format("HH:mm")
      : "",
    // numOfChildren: "",
    // numOfBabies: "",
    extraCharges: tourData ? tourData.extraCharges : null,
    paymentMethod:
      (tourData && tourData.paymentMethod && tourData.paymentMethod) || 1,
    typeId: typeList && typeList.length > 0 && typeList[0],
    tourId:
      (tourData &&
        tourData.tour && {
          label: tourData.tour.destination,
          value: tourData.tour.id,
        }) ||
      {},
    description: tourData ? tourData.description : "",
    language: tourData
      ? _.find(LANGUAGES, function (o) {
          return o.value === tourData.lang;
        })
      : "",
  };

  const tourList = useSelector((state) => {
    return state.Receptionist.tours;
  });

  let privateTours = _.filter(tourList, function (o) {
    return o.type === TOUR_TYPES.tpt_tkt_lcl_acc_guide;
  });

  let arr = [];
  privateTours.forEach((element) => {
    arr.push({
      value: element.id,
      label: element.destination,
      description: element.description,
      tourPricing: element.tourPricing,
      // currencyCode: element.price.currencyCode,
    });
  });

  const singleTour =
    arr &&
    arr.length > 0 &&
    tourData &&
    _.filter(arr, function (o) {
      return o.value === tourData.tour.id;
    });

  const pricing =
    singleTour && singleTour[0]
      ? singleTour[0].tourPricing[
          _.findIndex(singleTour[0].tourPricing, ["guests", fetchNoOfClients])
        ].price
      : null || null;

  const [priceSelected, setPriceSelected] = useState(pricing);

  const [tour, settour] = useState(
    (singleTour && singleTour[0] && singleTour[0]) || null
  );

  const guestsFields = (values) => {
    const fetchTour =
      privateTours.length >= 0 && tour
        ? _.find(privateTours, ["id", tour.value])
        : null;

    setClients(values);
    setPriceSelected(
      fetchTour
        ? fetchTour.tourPricing[
            _.findIndex(fetchTour.tourPricing, ["guests", values.value])
          ].price
        : null
    );
    setClients(values);
    clientDetail.length = 0;
    for (let i = 0; i < values.value; i++) {
      clientDetail.push(BOOKING_CLIENT_DETAILS);
    }
  };

  const [clientSetting, setClienSetting] = useState(fillTourSetting());

  const tourID = (tour && tour.value) || null;

  useEffect(() => {
    if (tourID) {
      dispatch(tourClientSetting(tourID)).then((res) => {
        setClienSetting(fillTourSetting(res));
      });
    }
  }, [tourID]);

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  let setField;

  const getPrice = (extraCharges = 0) => {
    const actualPrice = priceSelected || 0;
    const extra = parseFloat(extraCharges || 0);

    return EURO.format(actualPrice + extra);
  };

  const datesNotAllowed = [];

  useEffect(() => {
    dispatch(getSettingTime());
  }, []);

  const setting = useSelector((state) => {
    return state.App.time;
  });

  closeDay.forEach((day, index) => {
    datesNotAllowed.push(day.closeDate);
  });

  const currentDate = moment().format("YYYY-MM-DD");

  const closingTime =
    setting && moment(setting.closingAt, "HH:mm:ss").format("HH:mm");

  if (closingTime < moment().format("HH:mm")) {
    moment().add(1, "days").format("YYYY-MM-DD");
    datesNotAllowed.push(moment().add(1, "days").format("YYYY-MM-DD"));
  }

  const disabledDate = (current) => {
    if (moment(current).format("YYYY-MM-DD") <= currentDate) {
      return true;
    } else {
      return datesNotAllowed.includes(moment(current).format("YYYY-MM-DD"));
    }
  };

  const { rowStyle, colStyle, gutter } = basicStyle;

  const startTime =
    (tsetting && moment(tsetting.startTime, "HH:mm:ss").format("HH:mm")) ||
    null;
  const endTime =
    (tsetting && moment(tsetting.endTime, "HH:mm:ss").format("HH:mm")) || null;

  return (
    <SignUpStyleWrapper className="isoSignUpPage">
      <div className="isoSignUpContentWrapper">
        <div className="isoSignUpContent">
          <div className="isoLogoWrapper">
            <Link to="/dashboard/private-tours-booking-list">
              Book Private Tour
            </Link>
          </div>

          <Formik
            initialValues={createFormValues}
            onSubmit={handleSubmit}
            validationSchema={transportBookingValidation}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              /* and other goodies */
            }) => {
              setField = setFieldValue;
              const defaultDate = values.date
                ? moment(values.date).format("DD/MM/YYYY")
                : null;
              return (
                <form onSubmit={handleSubmit}>
                  <div className="isoSignUpForm">
                    <div className="isoInputWrapper">
                      <label className="label-style">Select a Tour</label>
                      <Dropdown
                        showSearch={!view}
                        options={arr}
                        disable={(isUpdate && true) || false}
                        onChange={(value) => {
                          setFieldValue("tourId", value);
                          settour(value);
                          dispatch(fetchTourCloseDayByRec(value.value)).then(
                            (res) => {
                              setCloseDay(res);
                            }
                          );
                        }}
                        value={values.tourId ? values.tourId : null}
                        placeholder="Select a Tour"
                      />
                      {errors.tourId && touched.tourId && (
                        <div className="invalid-input">
                          {errors.tourId.value}
                        </div>
                      )}
                    </div>
                    <div className="isoInputWrapper">
                      <label className="label-style">Client Name</label>
                      <Input
                        size="large"
                        type="text"
                        name="clientName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.clientName}
                      />
                      {errors.clientName && touched.clientName && (
                        <div className="invalid-input">{errors.clientName}</div>
                      )}
                    </div>
                    <div className="isoInputWrapper">
                      <label className="label-style">Client Phone</label>
                      <Input
                        size="large"
                        type="text"
                        name="clientPhone"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.clientPhone}
                      />
                      {errors.clientPhone && touched.clientPhone && (
                        <div className="invalid-input">
                          {errors.clientPhone}
                        </div>
                      )}
                    </div>

                    <div className="isoInputWrapper">
                      <label className="label-style">Client Email</label>
                      <Input
                        size="large"
                        type="text"
                        name="clientEmail"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.clientEmail}
                      />
                      {errors.clientEmail && touched.clientEmail && (
                        <div className="invalid-input">
                          {errors.clientEmail}
                        </div>
                      )}
                    </div>

                    <div className="isoInputWrapper">
                      <label className="label-style">Receptionist Name</label>
                      <Input
                        size="large"
                        type="text"
                        name="receptionist"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.receptionist}
                      />
                      {errors.receptionist && touched.receptionist && (
                        <div className="invalid-input">
                          {errors.receptionist}
                        </div>
                      )}
                    </div>

                    <div className="isoInputWrapper">
                      <label className="label-style">Date</label>
                      <DatePicker
                        inputReadOnly={view}
                        // size="large"
                        // type="text"
                        name="date"
                        disabledDate={disabledDate}
                        onChange={(date, dateString) => {
                          // console.log(date, dateString);
                          setFieldValue("date", dateString);
                        }}
                        format="DD/MM/YYYY"
                        defaultValue={
                          defaultDate ? moment(defaultDate, "DD/MM/YYYY") : null
                        }
                        // onBlur={handleBlur}
                        // value={values.date}
                      />
                      {errors.date && touched.date && (
                        <div className="invalid-input">{errors.date}</div>
                      )}
                    </div>

                    {/* <div className="isoInputWrapper">
                    <Input
                      size="large"
                      placeholder="Number of Adults"
                      type="number"
                      name="numOfAdults"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.numOfAdults}
                    />
                    {errors.numOfAdults && touched.numOfAdults && (
                      <div className="invalid-input">{errors.numOfAdults}</div>
                    )}
                  </div>

                  <div className="isoInputWrapper">
                    <Input
                      size="large"
                      placeholder="Number of Children"
                      type="number"
                      name="numOfChildren"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.numOfChildren}
                    />
                    {errors.numOfChildren && touched.numOfChildren && (
                      <div className="invalid-input">
                        {errors.numOfChildren}
                      </div>
                    )}
                  </div>

                  <div className="isoInputWrapper">
                    <Input
                      size="large"
                      placeholder="Number of Babies"
                      type="number"
                      name="numOfBabies"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.numOfBabies}
                    />
                    {errors.numOfBabies && touched.numOfBabies && (
                      <div className="invalid-input">{errors.numOfBabies}</div>
                    )}
                  </div> */}

                    <div className="isoInputWrapper">
                      <label className="label-style">
                        Select No. Of Guests
                      </label>
                      <div style={{ marginBottom: "8px", display: "flex" }}>
                        <Dropdown
                          showSearch={view.isDesiredWidth}
                          options={GUESTS}
                          onChange={guestsFields}
                          disable={(isUpdate && true) || false}
                          // onChange={(value) => {
                          //   console.log("value", value);
                          //   setClients(value.value);
                          //   setClientDetail([
                          //     ...clientDetail,
                          //     BOOKING_CLIENT_DETAILS,
                          //   ]);
                          //   setclientMessageFlag(true);
                          // }}
                          value={client ? client : null}
                          placeholder="Select No. Of Guests"
                        />
                        {/* <PlusOutlined
                        onClick={addElement}
                        style={{
                          backgroundColor: "#4482FF",
                          color: "white",
                          padding: "10px",
                          borderRadius: "5px",
                        }}
                      /> */}
                      </div>
                      {clientDetail && clientDetail.length === 0 && (
                        <div className="invalid-input">Required</div>
                      )}
                    </div>
                    {clientDetail &&
                      clientDetail.length > 0 &&
                      clientDetail.map((data, index) => {
                        return (
                          <ClientDetailsForm
                            key={index}
                            index={index}
                            view={view}
                            data={data}
                            clientSetting={clientSetting}
                            handleBlur={handleBlur}
                            handleChangeF={handleChangeF}
                            handleNationality={handleNationality}
                            handleDocumentType={handleDocumentType}
                          />
                        );
                      })}
                    <div
                      className="isoInputWrapper"
                      onClick={() => {
                        setVisible(true);
                      }}
                      style={{ marginBottom: 10 }}
                    >
                      <label className="label-style">Pickup Time</label>
                      <Input
                        size="large"
                        type="text"
                        name="pickUpTime"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.pickUpTime}
                      />
                      {errors.pickUpTime && touched.pickUpTime && (
                        <div className="invalid-input">{errors.pickUpTime}</div>
                      )}
                    </div>
                    <div className="isoInputWrapper">
                      <label className="label-style">Select a Language</label>
                      <Dropdown
                        showSearch={view.isDesiredWidth}
                        options={LANGUAGES}
                        onChange={(value) => {
                          setFieldValue("language", value);
                        }}
                        value={values.language ? values.language : null}
                        placeholder="Select a Language"
                      />
                      {errors.language && touched.language && (
                        <div className="invalid-input">
                          {errors.language.value}
                        </div>
                      )}
                    </div>
                    <div className="isoInputWrapper">
                      <label className="label-style">Extra Charges</label>
                      <Input
                        size="large"
                        type="number"
                        name="extraCharges"
                        onChange={handleChange}
                        disabled={(isUpdate && true) || false}
                        onBlur={handleBlur}
                        value={values.extraCharges}
                      />
                      {errors.extraCharges && touched.extraCharges && (
                        <div className="invalid-input">
                          {errors.extraCharges}
                        </div>
                      )}
                    </div>
                    <div className="isoInputWrapper">
                      <label className="label-style">Select an operator</label>
                      <Dropdown
                        showSearch={view.isDesiredWidth}
                        options={typeList}
                        onChange={(value) => {
                          setFieldValue("typeId", value);
                        }}
                        value={values.typeId ? values.typeId : null}
                        placeholder="Select an operator"
                      />
                      {errors.typeId && touched.typeId && (
                        <div className="invalid-input">
                          {errors.typeId.value}
                        </div>
                      )}
                    </div>
                    <div className="isoInputWrapper">
                      <label className="label-style">Add Room#</label>
                      <Select
                        mode="tags"
                        defaultValue={ClientRoom}
                        style={{ width: "100%" }}
                        onChange={handleTags}
                        // onBlur={handleBlur}
                        onDeselect={handleDeselect}
                        options={ClientRoom}
                      />
                    </div>
                    <div className="isoInputWrapper">
                      <label className="label-style">Comments</label>
                      <Textarea
                        rows={4}
                        size="large"
                        type="text"
                        name="description"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                      />
                      {errors.description && touched.description && (
                        <div className="invalid-input">
                          {errors.description}
                        </div>
                      )}
                      <small class="form-text text-muted">
                        *This does not appear on client voucher - only intended
                        for internal communication with BGT Travel
                      </small>
                    </div>
                    <div className="isoInputWrapper">
                      <Row style={rowStyle} gutter={gutter} justify="start">
                        <Col md={12} sm={12} xs={24} style={colStyle}>
                          <div>
                            <p style={{ fontWeight: "bold" }}>Payment Method</p>
                          </div>
                          <RadioGroup
                            onChange={handleChange}
                            name="paymentMethod"
                            value={values.paymentMethod}
                            disabled={(isUpdate && true) || false}
                          >
                            <Radio style={radioStyle} value={1}>
                              Cash
                            </Radio>
                            <Radio style={radioStyle} value={2}>
                              Credit or debit card
                            </Radio>
                            <Radio style={radioStyle} value={3}>
                              Monthly Invoicing
                            </Radio>
                            <Radio style={radioStyle} value={4}>
                              Pay Here With Card Now
                            </Radio>
                          </RadioGroup>
                        </Col>
                      </Row>
                      {errors.paymentMethod && touched.paymentMethod && (
                        <div className="invalid-input">
                          {errors.paymentMethod}
                        </div>
                      )}
                    </div>
                    {priceSelected ? (
                      <div className="isoInputWrapper">
                        <div>
                          <p>{`Total Price: ${getPrice(
                            values.extraCharges
                          )}`}</p>
                        </div>
                      </div>
                    ) : null}

                    <Button
                      type="primary"
                      onClick={handleSubmit}
                      disabled={disable}
                    >
                      Book
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
        <div className="rightbar">
          <div className="rightbar-inner">
            <h3>Description</h3>
            <p>{(tour && tour.description) || "description not available!"}</p>
          </div>
        </div>
      </div>
      <Modal
        title="Select your Time"
        centered
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width={300}
        bodyStyle={{ height: 500 }}
      >
        <TimeKeeper
          time={time}
          onDoneClick={(value) => {
            setTime(value.formatted24);
            if (
              startTime >
                moment(value.formatted24, "HH:mm:ss").format("HH:mm") ||
              endTime < moment(value.formatted24, "HH:mm:ss").format("HH:mm")
            ) {
              setField("pickUpTime", "");
            } else {
              setField("pickUpTime", value.formatted24);
            }
            setVisible(false);
          }}
          disabledTimeRange={{ from: endTime, to: startTime }}
        />
      </Modal>
    </SignUpStyleWrapper>
  );
}
