import * as api from '../../constants/Api';
import {API} from '../../services';
import * as T from '../ActionTypes';

export const getHotelView = () => {
  return (dispatch) => {
    return API.execute(api.GET_REC_HOTEL_VIEW).then(
      (response) => {
          return response.data;
      },
      (error) => {
        dispatch({type: T.GET_REC_HOTEL_VIEW_ERR, payload: error});
        return error;
      },
    );
  };
};

export const getAdminHotelBookingCount = () => {
    return (dispatch) => {
      return API.execute(api.GET_ADMIN_HOTEL_BOOKING_COUNT).then(
        (response) => {
            return response.data;
        },
        (error) => {
          dispatch({type: T.GET_ADMIN_HOTEL_BOOKING_COUNT_ERROR, payload: error});
          return error;
        },
      );
    };
  };

  export const getAdminHotelBookingCancelCount = () => {
    return (dispatch) => {
      return API.execute(api.GET_ADMIN_HOTEL_BOOKING_CANCEL_COUNT).then(
        (response) => {
            return response.data;
        },
        (error) => {
          dispatch({type: T.GET_ADMIN_HOTEL_BOOKING_CANCEL_COUNT_ERROR, payload: error});
          return error;
        },
      );
    };
  };

export const getAirportTransferBookingCount = () => {
    return (dispatch) => {
      return API.execute(api.GET_ADMIN_AIRPORT_TRANSFER_BOOKING_COUNT).then(
        (response) => {
            return response.data;
        },
        (error) => {
          dispatch({type: T.GET_ADMIN_AIRPORT_TRANSFER_BOOKING_COUNT_ERROR, payload: error});
          return error;
        },
      );
    };
  };

  export const getAdminHotelBookingListCount = () => {
    return (dispatch) => {
      return API.execute(api.GET_ADMIN_HOTEL_BOOKING_LIST_COUNT).then(
        (response) => {
          // console.log('response', response)
            return response.data;
        },
        (error) => {
          dispatch({type: T.GET_ADMIN_HOTEL_BOOKING_LIST_COUNT_ERROR, payload: error});
          return error;
        },
      );
    };
  };

  export const getRecHotelBookingCount = () => {
    return (dispatch) => {
      return API.execute(api.GET_REC_HOTEL_BOOKING_COUNT).then(
        (response) => {
            return response.data;
        },
        (error) => {
          dispatch({type: T.GET_REC_HOTEL_BOOKING_COUNT_ERROR, payload: error});
          return error;
        },
      );
    };
  };

  export const getRecAirportTransferBookingCount = () => {
    return (dispatch) => {
      return API.execute(api.GET_REC_AIRPORT_TRANSFER_BOOKING_COUNT).then(
        (response) => {
            return response.data;
        },
        (error) => {
          dispatch({type: T.GET_REC_AIRPORT_TRANSFER_BOOKING_COUNT_ERROR, payload: error});
          return error;
        },
      );
    };
  };

  export const getSAdminHotelListCount = () => {
    return (dispatch) => {
      return API.execute(api.GET_SA_HOTEL_COUNT).then(
        (response) => {
            return response.data;
        },
        (error) => {
          dispatch({type: T.GET_SA_HOTEL_COUNT_ERROR, payload: error});
          return error;
        },
      );
    };
  };

  export const getSAdminTourBookingListCount = () => {
    return (dispatch) => {
      return API.execute(api.GET_SA_TOUR_BOOKING_COUNT).then(
        (response) => {
            return response.data;
        },
        (error) => {
          dispatch({type: T.GET_SA_TOUR_BOOKING_COUNT_ERROR, payload: error});
          return error;
        },
      );
    };
  };

  export const getSAdminAirportTransferBookingListCount = () => {
    return (dispatch) => {
      return API.execute(api.GET_SA_AIRPORT_TRANSFER_BOOKING_COUNT).then(
        (response) => {
            return response.data;
        },
        (error) => {
          dispatch({type: T.GET_SA_AIRPORT_TRANSFER_BOOKING_COUNT_ERROR, payload: error});
          return error;
        },
      );
    };
  };

  export const getAdminBookingSum = () => {
    return (dispatch) => {
      return API.execute(api.GET_ADMIN_BOOKING_SUM).then(
        (response) => {
            return response.data;
        },
        (error) => {
          dispatch({type: T.GET_ADMIN_BOOKING_SUM, payload: error});
          return error;
        },
      );
    };
  };

  export const getPeopleLifeTime = () => {
    return (dispatch) => {
      return API.execute(api.GET_ADMIN_PEOPLE_LIFETIME).then(
        (response) => {
            return response.data;
        },
        (error) => {
          dispatch({type: T.GET_ADMIN_PEOPLE_LIFETIME, payload: error});
          return error;
        },
      );
    };
  };

  export const getTourByHotel = () => {
    return (dispatch) => {
      return API.execute(api.GET_ADMIN_TOUR_BY_HOTEL).then(
        (response) => {
            return response.data;
        },
        (error) => {
          dispatch({type: T.GET_ADMIN_TOUR_BY_HOTEL, payload: error});
          return error;
        },
      );
    };
  };

  export const weeklyAccumulatedSalesRevenue = () => {
    return (dispatch) => {
      return API.execute(api.GET_ADMIN_WEEKLY_ACCUMULATED_SALES_REVENUE).then(
        (response) => {
            return response.data;
        },
        (error) => {
          dispatch({type: T.GET_ADMIN_TOUR_BY_HOTEL, payload: error});
          return error;
        },
      );
    };
  };

  export const getTourScheduleAtRec = () => {
    return (dispatch) => {
      return API.execute(api.GET_RECEPTIONIST_TOUR_SCHEDULE).then(
        (response) => {
          return response.data;
        },
      ).catch((error)=>{
        return error;
      })
    };
  };

  export const getDefaultHotelBannerAtRec = () => {
    return (dispatch) => {
      return API.execute(api.GET_RECEPTIONIST_DEFAULT_HOTEL_BANNER).then(
        (response) => {
          return response.data;
        },
      ).catch((error)=>{
        return error;
      })
    };
  };