import { Modal } from "antd";
import { Formik } from "formik";
import React from "react";
import { tourBookingRequestValidation } from "../../constants";
import CancelRequestForm from "../CancelRequestForm/CancelRequestForm.js";
import { useDispatch } from "react-redux";

import { cancelRequestBooking, cancelRequestTourBooking } from "../../redux/receptionist/actions";

const BookingCancellation = ({
  isModalVisible,
  cancellationData,
  handleCancel,
  title,
  buttonTitle,
}) => {
  const dispatch = useDispatch();


  const initialValues = {
    reason:
      (cancellationData && cancellationData.cancelledBooking && cancellationData.cancelledBooking.reason) || "",
    bookingId:
    (cancellationData && cancellationData && cancellationData.id) ||
      "",
    request:
    (cancellationData && cancellationData.cancelledBooking && cancellationData.cancelledBooking.request) ||
      "",
    approve:
    (cancellationData && cancellationData.cancelledBooking && cancellationData.cancelledBooking.approve) ||
      "",
  };

  const onSubmit = (values) => {

      dispatch(cancelRequestBooking(values.bookingId));
      dispatch(cancelRequestTourBooking(values));
    

    handleCancel();
  };

  return (
    <Modal
      title={title}
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose={true}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={tourBookingRequestValidation}
        onSubmit={onSubmit}
        component={(props) => (
          <CancelRequestForm
            {...props}
            cancellationData={cancellationData}
            buttonTitle={buttonTitle}
          />
        )}
      ></Formik>
    </Modal>
  );
};

export default BookingCancellation;
