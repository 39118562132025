import React from "react";
import logoPic from '@iso/assets/Be-App-Logo-shadow-small-white.png';
import './airportTransfer.css'

const Report = ({ data }) => {
    
  return (
    // <div style={{ display: "flex", justifyContent:"space-between" }}>
    //   <p>sadquain</p>
    //   <p>arif</p>
    // </div>
    <body className="airportTransfer" style={{ padding: "10px 10px" }}>
      <div>
        <img
          class="imgLogo"
          alt="logo"
          src={logoPic}
        />
      </div>
      <div class="heading">
        <h1>Airport Transfer Bookings</h1>
      </div>
      <table>
        <tr>
          <th>Client Name</th>
          <th>Client Phone</th>
          <th>Date</th>
          <th>Pick Up Time</th>
          <th>Airport</th>
          <th>Hotel</th>
          <th>Vehicle Price</th>
        </tr>
        <tr>
          <td>{data.clientName}</td>
          <td>{data.clientPhone}</td>
          <td>{data.date}</td>
          <td>{data.pickUpTime}</td>
          <td>{data.airport.name}</td>
          <td>{data.hotel.name}</td>
          <td>{data.vehiclePrice.farePrice}</td>
        </tr>
      </table>
    </body>
  );
};

export default Report;
