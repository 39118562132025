import React from "react";
import logoPic from "@iso/assets/Be-App-Logo-shadow-small-white.png";
import "./airportTransfer.css";

const ReportList = ({ data }) => {
  return (
    <body className="airportTransfer" style={{ padding: "10px 10px" }}>
      <div>
        <img class="imgLogo" alt="logo" src={logoPic} />
      </div>
      <div class="heading">
        <h1>Airport Transfer Bookings</h1>
      </div>
      <table>
        <tr>
          <th>Client Name</th>
          <th>Pick Up Date</th>
          <th>Pick Up Time</th>
          <th>Airport</th>
          <th>Hotel</th>
          <th>Flight No</th>
          <th>Luggage</th>
        </tr>
        {data &&
          data.map((d, k) => {
            return (
              <tr key={k}>
                <td>{d.clientName}</td>
                <td>{d.pickUpDate}</td>
                <td>{d.pickUpTime}</td>
                <td>{d.airport.name}</td>
                <td>{d.hotel}</td>
                <td>{d.flightNo}</td>
                <td>{d.noOfLuggage}</td>
              </tr>
            );
          })}
      </table>
    </body>
  );
};

export default ReportList;
