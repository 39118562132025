const POST = {
  type: "post",
  headers: {
    "content-type": "multipart/form-data",
  },
  dataFormat: "formData",
};
const GET = {
  type: "get",
  dataFormat: "raw",
};
const PUT = {
  type: "put",
  dataFormat: "formData",
};

const updateRaw = {
  type: "put",
  dataFormat: "raw",
};

const DEL = {
  type: "delete",
};

// --------------- APIs --------------- //

export const SIGN_IN = {
  method: POST,
  path: "/auth/signin",
};

export const FORGOT_PASSWORD = {
  method: POST,
  path: "/auth/cm/forgot",
};

export const RESET_PASSWORD = {
  method: POST,
  path: "/auth/cm/reset",
};

export const CHANGE_PASSWORD = {
  method: POST,
  path: "/cm/user/changePassword",
};

export const UPDATE_PROFILE = {
  method: PUT,
  path: "/cm/user/profile/update",
};

export const GET_USER_VIEW = (id) => {
  return {
    method: GET,
    path: `/cm/user/view/${id}`,
  };
};

export const UPDATE_AVATAR = {
  method: POST,
  path: "/cm/user/updateAvatar",
};

export const HOTEL_TYPE_LIST = {
  method: GET,
  path: "/a/hotel/types",
};

export const TOUR_SETTING = (type) => {
  return {
    method: GET,
    path: `/a/tour/setting/view?type=${type}`,
  };
};

export const ADD_TOUR_SETTING_ADMIN = {
  method: POST,
  path: "/a/tour/setting/create",
};

export const UPDATE_COMMISSION = (id) => {
  return {
    method: PUT,
    path: `/a/hotel/commision/update/${id}`,
  };
};

export const CREATE_HOTEL = {
  method: POST,
  path: "/a/hotel/create",
};

export const GET_HOTELS_LIST = {
  method: GET,
  path: "/a/hotel/list",
};

export const UPDATE_HOTEL = (id) => {
  return {
    method: PUT,
    path: `/a/hotel/update/${id}`,
  };
};

export const DELETE_HOTEL = (id) => {
  return {
    method: DEL,
    path: `/a/hotel/delete/${id}`,
  };
};

export const CREATE_AIRPORT = {
  method: POST,
  path: "/a/airport/create",
};

export const GET_AIRPORTS_LIST = {
  method: GET,
  path: "/a/airport/list",
};

export const UPDATE_AIRPORT = (id) => {
  return {
    method: PUT,
    path: `/a/airport/update/${id}`,
  };
};

export const DELETE_AIRPORT = (id) => {
  return {
    method: DEL,
    path: `/a/airport/delete/${id}`,
  };
};

export const GET_RECEPTIONISTS_LIST = {
  method: GET,
  path: "/a/receptionist/list",
};

export const CREATE_RECEPTIONIST = (id) => {
  return {
    method: POST,
    path: `/a/receptionist/create?hotelId=${id}`,
  };
};

export const ACTIVATE_RECEPTIONIST = (id) => {
  return {
    method: GET,
    path: `/a/receptionist/activate/${id}`,
  };
};

export const DEACTIVATE_RECEPTIONIST = (id) => {
  return {
    method: GET,
    path: `/a/receptionist/deactivate/${id}`,
  };
};

export const UPDATE_RECEPTIONIST = (id) => {
  return {
    method: PUT,
    path: `/a/receptionist/update/${id}`,
  };
};

export const GET_GUIDE_TICKET_TOURS_LIST = {
  method: GET,
  path: "/a/tour/guide/list",
};

export const CREATE_TICKET_GUIDE_TOURS = {
  method: POST,
  path: "/a/tour/guide/create",
};

export const UPDATE_TICKET_GUIDE_TOURS = (id) => {
  return {
    method: PUT,
    path: `/a/tour/guide/update/${id}`,
  };
};

export const GET_TICKET_TOURS_LIST = {
  method: GET,
  path: "/a/tour/ticket/list",
};

export const CREATE_TICKET_TOURS = {
  method: POST,
  path: "/a/tour/ticket/create",
};

export const UPDATE_TICKET_TOURS = (id) => {
  return {
    method: PUT,
    path: `/a/tour/ticket/update/${id}`,
  };
};

export const GET_TOURS_LIST = (type) => {
  console.log("type", type);
  return {
    method: GET,
    path: `/a/tour/list${(type && `?type=${type}`) || ""}`,
  };
};

export const GET_BOOKED_TOURS_LIST = {
  method: GET,
  path: "/a/tour/booking/reference",
};

export const GET_CANCEL_BOOKING_LIST = {
  method: GET,
  path: "/a/tour/booking/cancel/list",
};

export const CREATE_TOURS = {
  method: POST,
  path: "/a/tour/shared/create",
};

export const UPDATE_TOURS = (id) => {
  return {
    method: PUT,
    path: `/a/tour/shared/update/${id}`,
  };
};

export const SEARCH_PRIVATE_TOURS = (query) => {
  return {
    method: GET,
    path: `/a/tour/private/user/search?search=${query}`,
  };
};

export const FETCH_TOUR_SCHEDULE = (id) => {
  return {
    method: GET,
    path: `/a/tourStops/fetch?tourId=${id}`,
  };
};

export const FETCH_TOUR_CLOSE_DAY = (id) => {
  return {
    method: GET,
    path: `/a/tour/shared/closeDay/list/${id}`,
  };
};

export const FETCH_TOUR_STOP = (id) => {
  return {
    method: GET,
    path: `/a/tour/stop?tourId=${id}`,
  };
};

export const CREATE_TOUR_STOP = (hotelId, tourId) => {
  return {
    method: POST,
    path: `/a/tour/stop?tourId=${tourId}&hotelId=${hotelId}`,
  };
};

export const DUP_TOUR_STOP = () => {
  return {
    method: POST,
    path: `/a/tour/duplicate/stop`,
  };
};

export const UPDATE_TOUR_STOP = (id, tourId, hotelId, stopId) => {
  return {
    method: updateRaw,
    path: `/a/tour/stop/${id}?tourId=${tourId}&hotelId=${hotelId}&stopId=${stopId}`,
  };
};

export const DELETE_TOUR_STOP = (id) => {
  return {
    method: DEL,
    path: `/a/tour/stop/${id}`,
  };
};

export const CREATE_HOTEL_PRICE = (hotelId, tourId) => {
  return {
    method: POST,
    path: `/a/tour/hotel/price/create?tourId=${tourId}&hotelId=${hotelId}`,
  };
};

export const UPDATE_HOTEL_PRICE = (id, tourId, hotelId) => {
  return {
    method: updateRaw,
    path: `/a/tour/hotel/price/update/${id}?tourId=${tourId}&hotelId=${hotelId}`,
  };
};

export const FETCH_HOTEL_PRICE = (id) => {
  return {
    method: GET,
    path: `/a/tour/hotel/price/list?tourId=${id}`,
  };
};

export const DELETE_HOTEL_PRICE = (id) => {
  return {
    method: DEL,
    path: `/a/tour/hotel/price/delete/${id}`,
  };
};

export const VIEW_SRD_TKT_TOUR = (id) => {
  return {
    method: GET,
    path: `/a/tour/view/shared/ticket/${id}`,
  };
};

export const VIEW_TOUR_SETTING = (id) => {
  return {
    method: GET,
    path: `/a/setting/client/view?id=${id}`,
  };
};

export const VIEW_TKTGD_PVT_TOUR = (id) => {
  return {
    method: GET,
    path: `/a/tour/view/private/ticketGuide/${id}`,
  };
};

export const GET_SERVICE_LIST = {
  method: GET,
  path: "/a/airportTransfer/service/list",
};

export const CREATE_SERVICE = {
  method: POST,
  path: "/a/airportTransfer/service/create",
};

export const UPDATE_SERVICE = (id) => {
  return {
    method: PUT,
    path: `/a/airportTransfer/service/update/${id}`,
  };
};

export const DELETE_SERVICE = (id) => {
  return {
    method: DEL,
    path: `/a/airportTransfer/service/delete/${id}`,
  };
};

export const GET_EXTRA_SERVICE_LIST = {
  method: GET,
  path: "/a/airportTransfer/extraService/list",
};

export const CREATE_EXTRA_SERVICE = {
  method: POST,
  path: "/a/airportTransfer/extraService/create",
};

export const UPDATE_EXTRA_SERVICE = (id) => {
  return {
    method: PUT,
    path: `/a/airportTransfer/extraService/update/${id}`,
  };
};

export const DELETE_EXTRA_SERVICE = (id) => {
  return {
    method: DEL,
    path: `/a/airportTransfer/extraService/delete/${id}`,
  };
};

export const GET_CITY_PRICE_LIST = {
  method: GET,
  path: "/a/airportTransfer/cityPrice/list",
};

export const CREATE_CITY_PRICE = {
  method: POST,
  path: "/a/airportTransfer/cityPrice/create",
};

export const UPDATE_CITY_PRICE = (id) => {
  return {
    method: PUT,
    path: `/a/airportTransfer/cityPrice/update/${id}`,
  };
};

export const DELETE_CITY_PRICE = (id) => {
  return {
    method: DEL,
    path: `/a/airportTransfer/cityPrice/delete/${id}`,
  };
};

export const GET_AIRPORT_HOTEL_PRICE_LIST = {
  method: GET,
  path: "/a/airportTransfer/hotelPrice/list",
};

export const CREATE_AIRPORT_HOTEL_PRICE = {
  method: POST,
  path: "/a/airportTransfer/hotelPrice/create",
};

export const UPDATE_AIRPORT_HOTEL_PRICE = (id) => {
  return {
    method: PUT,
    path: `/a/airportTransfer/hotelPrice/update/${id}`,
  };
};

export const DELETE_AIRPORT_HOTEL_PRICE = (id) => {
  return {
    method: DEL,
    path: `/a/airportTransfer/hotelPrice/delete/${id}`,
  };
};

//receptionist

export const CANCEL_REQUEST_BOOKING_AT = (id) => {
  return {
    method: PUT,
    path: `/r/at/booking/cancel/request/${id}`,
  };
};

export const CANCEL_BOOKING_AIRPORT_TRANSFER = {
  method: POST,
  path: `/r/at/cancellation/request`,
};

export const GENERATE_AIRPORT_TRANSFER_RECEIPT = (id) => {
  return {
    method: GET,
    path: `/r/at/voucher/${id}`,
  };
};

export const GET_AIRPORT_TRANSFER_LIST = {
    method: POST,
    path: `/r/at/list`,
};

export const BOOK_AIRPORT_TRANSFER = {
  method: POST,
  path: "/r/at/booking",
};

export const GET_EXTRA_SERVICE_REC = {
  method: GET,
  path: "/r/at/extraService",
};

export const GET_SERVICE_LIST_REC = {
  method: GET,
  path: "/r/at/service/list",
};

export const VIEW_TOUR_SETTING_REC = (id) => {
  return {
    method: GET,
    path: `/r/tour/booking/setting/view?id=${id}`,
  };
};

export const CANCEL_REQUEST_BOOKING_TOUR = (id) => {
  return {
    method: PUT,
    path: `/r/tour/booking/cancel/request/${id}`,
  };
};

export const FETCH_TOUR_CLOSE_DAY_REC = (id) => {
  return {
    method: GET,
    path: `/r/tour/booking/shared/closeDay/list/${id}`,
  };
};

export const FETCH_TOUR_SETTING_REC = (type) => {
  return {
    method: GET,
    path: `/r/tour/setting?type=${type}`,
  };
};

export const CREATE_TOUR_CLOSE_DAY = (tourId) => {
  return {
    method: POST,
    path: `/a/tour/shared/closeDay/save/${tourId}`,
  };
};

export const UPDATE_TOUR_SCHEDULE = (id) => {
  return {
    method: updateRaw,
    path: `/a/tourStops/update/${id}`,
  };
};

export const CREATE_TOUR_SCHEDULE = (hotelId, tourId) => {
  return {
    method: POST,
    path: `/a/tourStops/create?tourId=${tourId}&hotelId=${hotelId}`,
  };
};

export const DUPLICATE_TOUR_SCHEDULE = () => {
  return {
    method: POST,
    path: `/a/tourStops/duplicate/schedule`,
  };
};

export const DELETE_TOUR_SCHEDULE = (id) => {
  return {
    method: DEL,
    path: `/a/tourStops/delete/${id}`,
  };
};

export const FETCH_TOUR_PRICING = (id) => {
  return {
    method: GET,
    path: `/a/tour/pricing/list/${id}`,
  };
};

export const SAVE_TOUR_PRICING = (id) => {
  return {
    method: POST,
    path: `/a/tour/pricing/save/${id}`,
  };
};

export const CREATE_PRIVATE_TOURS = {
  method: POST,
  path: "/a/tour/private/create",
};

export const UPDATE_PRIVATE_TOURS = (id) => {
  return {
    method: PUT,
    path: `/a/tour/private/update/${id}`,
  };
};

export const DELETE_TOURS = (id) => {
  return {
    method: DEL,
    path: `/a/tour/delete/${id}`,
  };
};

export const CREATE_CITY = {
  method: POST,
  path: "/a/city/create",
};

export const GET_CITY_LIST = {
  method: GET,
  path: "/a/city/list",
};

export const UPDATE_CITY = (id) => {
  return {
    method: PUT,
    path: `/a/city/update/${id}`,
  };
};

export const DELETE_CITY = (id) => {
  return {
    method: DEL,
    path: `/a/city/delete/${id}`,
  };
};

export const CREATE_BUS_STOP = {
  method: POST,
  path: "/a/busStop/create",
};

export const GET_BUS_STOP_LIST = {
  method: GET,
  path: "/a/busStop/list",
};

export const UPDATE_BUS_STOP = (id) => {
  return {
    method: PUT,
    path: `/a/busStop/update/${id}`,
  };
};

export const DELETE_BUS_STOP = (id) => {
  return {
    method: DEL,
    path: `/a/busStop/delete/${id}`,
  };
};

export const DELETE_BOOKING_TOUR = (id) => {
  return {
    method: PUT,
    path: `/a/tour/booking/delete/${id}`,
  };
};

export const CANCEL_BOOKING_TOUR = (id) => {
  return {
    method: PUT,
    path: `/a/tour/booking/cancel/${id}`,
  };
};

export const GENERATE_TOUR_ADMIN_RECEIPT = (id) => {
  return {
    method: GET,
    path: `/a/tour/generate/${id}`,
  };
};

export const GENERATE_TOUR_S_ADMIN_RECEIPT = (id) => {
  return {
    method: GET,
    path: `/s/tour/booking/report/${id}`,
  };
};

export const GET_ADMIN_LIST = {
  method: GET,
  path: "/s/admin/list",
};

export const GET_HOTEL_LIST = {
  method: GET,
  path: "/s/hotel/list",
};

export const HOTEL_VIEW = (id) => {
  return {
    method: GET,
    path: `/s/hotel/view?id=${id}`,
  };
};

export const HOTEL_COMMISION_VIEW = (id) => {
  return {
    method: GET,
    path: `/s/hotel/commision/view/${id}`,
  };
};

export const UPDATE_COMMISSION_SUPER_ADMIN = (id) => {
  return {
    method: PUT,
    path: `/s/hotel/commision/update/${id}`,
  };
};

export const CREATE_ADMIN = {
  method: POST,
  path: "/s/admin/create",
};

export const ACTIVATE_ADMIN = (id) => {
  return {
    method: GET,
    path: `/s/admin/activate/${id}`,
  };
};

export const DEACTIVATE_ADMIN = (id) => {
  return {
    method: GET,
    path: `/s/admin/deactivate/${id}`,
  };
};

export const FETCH_CURRENCY = {
  method: GET,
  path: "/cm/currency/list",
};

export const GET_AVAILABLE_EXCURSION_LIST = (tourId, persons) => {
  return { method: GET, path: `/cm/booking/available/excursion?tourId=${tourId}&persons=${persons}` };
};

export const CREATE_TRANSPORT_COMPANY = {
  method: POST,
  path: "/a/transportCompany/create",
};

export const GET_TRANSPORT_COMPANY_LIST = {
  method: GET,
  path: "/a/transportCompany/list",
};

export const UPDATE_TRANSPORT_COMPANY = (id) => {
  return {
    method: PUT,
    path: `/a/transportCompany/update/${id}`,
  };
};

export const DELETE_TRANSPORT_COMPANY = (id) => {
  return {
    method: DEL,
    path: `/a/transportCompany/delete/${id}`,
  };
};

export const UPDATE_TRANSPORT_COMPANY_COMMISSION = (id) => {
  return {
    method: PUT,
    path: `/a/transportCompany/commision/update/${id}`,
  };
};

export const VEHICLE_TYPE_LIST = {
  method: GET,
  path: "/tc/vehicle/type/list",
};

export const HOTELS_TYPE_LIST = {
  method: GET,
  path: "/a/transportCompany/hotel/list",
};

export const AIRPORTS_TYPE_LIST = {
  method: GET,
  path: "/a/transportCompany/airport/list",
};

export const CREATE_VEHICLE = {
  method: POST,
  path: "/tc/vehicle/create",
};

export const GET_VEHICLES_LIST = {
  method: GET,
  path: "/tc/vehicle/list",
};

export const UPDATE_VEHICLE = (id) => {
  return {
    method: PUT,
    path: `/tc/vehicle/update/${id}`,
  };
};

export const DELETE_VEHICLE = (id) => {
  return {
    method: DEL,
    path: `/tc/vehicle/delete/${id}`,
  };
};

export const CREATE_VEHICLE_PRICE = {
  method: POST,
  path: "/a/transportCompany/vehicle/price/create",
};

export const GET_VEHICLE_PRICE_LIST = {
  method: GET,
  path: "/a/transportCompany/vehicle/price/list",
};

export const UPDATE_VEHICLE_PRICE = (id) => {
  return {
    method: PUT,
    path: `/a/transportCompany/vehicle/price/update/${id}`,
  };
};

export const DELETE_VEHICLE_PRICE = (id) => {
  return {
    method: DEL,
    path: `/a/transportCompany/vehicle/price/delete/${id}`,
  };
};

export const DELETE_AIRPORT_TRANSFER = (id) => {
  return {
    method: DEL,
    path: `/r/airportTransfer/delete/${id}`,
  };
};

export const GET_RECEPTIONIST_TOURS_LIST = {
  method: GET,
  path: "/r/tour/list",
};

export const GET_RECEPTIONIST_PRIVATE_TOURS_LIST = {
  method: GET,
  path: "/r/tour/booking/private/list",
};

export const GET_RECEPTIONIST_CITY_LIST = {
  method: GET,
  path: "/r/city/list",
};

export const GET_RECEPTIONIST_STOP_LIST = (id, tourId) => {
  return { method: GET, path: `/r/stop/list/${id}?tourId=${tourId}` };
};

export const GET_RECEPTIONIST_AIRPORT_LIST = {
  method: GET,
  path: "/r/airport/list",
};

export const GET_RECEPTIONIST_VEHICLE_LIST = (id) => {
  return {
    method: GET,
    path: `/r/vehicle/price/list?airportId=${id}`,
  };
};

export const GET_RECEPTIONIST_BOOKED_AIRPORT_TRANSPORT_LIST = {
  method: GET,
  path: "/r/airportTransfer/list",
};

export const GET_RECEPTIONIST_DEFAULT_HOTEL_BANNER = {
  method: GET,
  path: `/r/hotel/banner/`,
};

export const GET_RECEPTIONIST_TOUR_SCHEDULE = {
    method: GET,
    path: `/r/tour/schedule`,
};

export const VIEW_DEFAULT_PRICE = (id) => {
  return {
    method: GET,
    path: `/r/tour/price/view/default/${id}`,
  };
};

export const VIEW_HOTEL_PRICE = (id, hotelId) => {
  return {
    method: GET,
    path: `/r/tour/price/view/hotel/${id}?hotelId=${hotelId}`,
  };
};

export const TOUR_BOOKING_TYPE = {
  method: GET,
  path: `/r/tour/booking/type/list`,
};

export const BOOK_PRIVATE_TOUR = () => {
  return {
    method: POST,
    path: `/r/tour/booking/private/book`,
  };
};

export const BOOK_UPDATE_PRIVATE_TOUR = (bId) => {
  return {
    method: PUT,
    path: `/r/tour/booking/private/update/book?bId=${bId}`,
  };
};

export const PAYMENT_INTENT = (id) => {
  return {
    method: POST,
    path: `/cm/booking/payment?bookingId=${id}`,
  };
};

export const PAYMENT_CONFIRM = (bId, uid) => {
  return {
    method: POST,
    path: `/cm/booking/add/payment?id=${bId}&uId=`,
  };
};

export const BOOK_SHARED_TOUR = (tourId) => {
  return {
    method: POST,
    path: `/r/tour/booking/shared/book?tourId=${tourId}`,
  };
};

export const BOOK_UPDATE_SHARED_TOUR = (tourId, bId) => {
  return {
    method: PUT,
    path: `/r/tour/booking/shared/update/book?tourId=${tourId}&bId=${bId}`,
  };
};

export const BOOK_TICKET_TOUR = (tourId, cityId, stopId) => {
  return {
    method: POST,
    path: `/r/tour/booking/ticket/book?tourId=${tourId}&cityId=${cityId}&stopId=${stopId}`,
  };
};

export const BOOK_TICKET_GUIDE_TOUR = (tourId, cityId, stopId) => {
  return {
    method: POST,
    path: `/r/tour/booking/guide/book?tourId=${tourId}&cityId=${cityId}&stopId=${stopId}`,
  };
};

export const GET_RECEPTIONIST_BOOKED_TOURS_LIST = {
  method: GET,
  path: "/r/tour/booking/list",
};

export const GET_RECEPTIONIST_TIME_SETTING = {
  method: GET,
  path: "/r/tour/booking/time/setting/view",
};

export const BOOK_TOUR_TRANSPORT = (airportId, vehicleId) => {
  return {
    method: POST,
    path: `/r/airportTransfer/booking?airportId=${airportId}&vehiclePriceId=${vehicleId}`,
  };
};

export const GENERATE_TOUR_RECEIPT = (id) => {
  return {
    method: GET,
    path: `/r/tour/booking/receipt/generate/${id}`,
  };
};

export const CLOSING_TIME_REC = {
  method: GET,
  path: "/r/time/view",
};

export const GET_TOUR_BOOKING = (date, type, tourId) => {
  return {
    method: GET,
    path: `/s/tour/booking/list?date=${date}&type=${type}&tourId=${tourId}`,
  };
};

export const TOUR_LIST_SA = (type) => {
  return {
    method: GET,
    path: `/s/tour/list${(type && `?type=${type}`) || ""}`,
  };
};

export const TOUR_BOOKING_VIEW_SA = (id) => {
  return {
    method: GET,
    path: `/s/tour/booking/view?id=${id}`,
  };
};

export const TOUR_BOOKING_VIEW_A = (id) => {
  return {
    method: GET,
    path: `/a/tour/booking/view?id=${id}`,
  };
};

export const GET_HOTEL_BANNER_ADMIN = {
  method: GET,
  path: "/a/setting/hotel/banner/view",
};

export const HOTEL_BANNER_ADD_ADMIN = {
  method: POST,
  path: "/a/setting/hotel/banner/create",
};

export const ADD_AT_VOUCHER_PRICE_STATUS_BY_ADMIN = {
  method: POST,
  path: "/a/setting/voucher/create",
};

export const GET_AT_VOUCHER_PRICE_STATUS_BY_ADMIN = {
  method: GET,
  path: "/a/setting/voucher/view",
};

export const CLOSING_TIME_ADMIN = {
  method: GET,
  path: "/a/setting/closing/time/view",
};

export const CLOSING_TIME_ADD_ADMIN = {
  method: POST,
  path: "/a/setting/closing/time/create",
};

export const GET_AIRPORT_TRANSFERS = (
  date,
  hotelId,
  airportId,
  vehiclePrice
) => {
  return {
    method: GET,
    path: `/s/tour/airport/booking/list?date=${date}&hotelId=${hotelId}&airportId=${airportId}&vehiclePrice=${vehiclePrice}`,
  };
};

export const AIRPORT_TRANSFERS_VIEW_SA = (id) => {
  return {
    method: GET,
    path: `/s/tour/airport/booking/view?id=${id}`,
  };
};

export const AIRPORT_LIST_SA = () => {
  return {
    method: GET,
    path: `/s/tour/airport/list`,
  };
};

export const HOTEL_LIST_SA = () => {
  return {
    method: GET,
    path: `/s/hotel/list`,
  };
};

export const VEHICLE_PRICE_LIST_SA = (hotelId, airportId) => {
  return {
    method: GET,
    path: `/s/tour/vehiclePrice/list?hotelId=${hotelId}&airportId=${airportId}`,
  };
};

export const GET_CANCEL_BOOKING_LIST_AT = {
  method: GET,
  path: "/a/airportTransfer/list/cancel/request",
};

export const CANCEL_BOOKING_AT = (id) => {
  return {
    method: PUT,
    path: `/a/airportTransfer/booking/cancel/${id}`,
  };
};

export const CANCEL_BOOKING_TOUR_BOOKING = {
    method: POST,
    path: `/r/tour/booking/cancellation/request`,
};

export const DELETE_BOOKING_AT = (id) => {
  return {
    method: PUT,
    path: `/a/airportTransfer/booking/delete/${id}`,
  };
};

export const GET_AIRPORT_TRANSFERS_ADMIN = (date, startDate, endDate) => {
  return {
    method: GET,
    path: `/a/airportTransfer/list?date=${date}&startDate=${startDate}&endDate=${endDate}`,
  };
};

export const VIEW_AIRPORT_TRANSFERS_ADMIN = (id) => {
  return {
    method: GET,
    path: `/a/airportTransfer/view/${id}`,
  };
};

export const GENERATE_AIRPORT_TRANSFERS_ADMIN_RECEIPT = (id) => {
  return {
    method: GET,
    path: `/a/airportTransfer/voucher/${id}`,
  };
};

export const GET_TOUR_BOOKING_ADMIN = {
    method: POST,
    path: `/a/tour/booking/list`,
};

export const TOUR_BOOKING_PERSONS = {
    method: POST,
    path: `/a/tour/booking/guests`,
};

export const GET_VEHICLE_PRICE_LIST_ADMIN = (hotelId, airportId) => {
  return {
    method: GET,
    path: `/a/transportCompany/vehicle/price/list/ids?hotelId=${hotelId}&airportId=${airportId}`,
  };
};

export const GET_ADMIN_BOOKING_SUM = {
  method: GET,
  path: "/a/tour/booking/sum",
};

export const GET_ADMIN_PEOPLE_LIFETIME = {
  method: GET,
  path: "/a/tour/booking/people",
};

export const GET_ADMIN_TOUR_BY_HOTEL = {
  method: GET,
  path: "/a/tour/hotel",
};

export const GET_ADMIN_WEEKLY_ACCUMULATED_SALES_REVENUE = {
  method: GET,
  path: "/a/tour/accumulated/sales/revenue",
};

export const GET_ADMIN_HOTEL_BOOKING_COUNT = {
  method: GET,
  path: "/a/hotel/booking",
};

export const GET_ADMIN_HOTEL_BOOKING_CANCEL_COUNT = {
  method: GET,
  path: "/a/hotel/cancel/booking",
};

export const GET_ADMIN_AIRPORT_TRANSFER_BOOKING_COUNT = {
  method: GET,
  path: "/a/airport/transfers/booking",
};

export const GET_ADMIN_HOTEL_BOOKING_LIST_COUNT = {
  method: GET,
  path: "/a/hotel/booking/list",
};

export const GET_REC_HOTEL_BOOKING_COUNT = {
  method: GET,
  path: "/r/hotel/booking",
};

export const GET_REC_HOTEL_VIEW = {
  method: GET,
  path: "/r/hotel/view",
};

export const GET_REC_AIRPORT_TRANSFER_BOOKING_COUNT = {
  method: GET,
  path: "/r/airportTransfer/booking/list",
};

export const GET_SA_HOTEL_COUNT = {
  method: GET,
  path: "/s/hotel/count/list",
};

export const GET_SA_TOUR_BOOKING_COUNT = {
  method: GET,
  path: "/s/tour/count/booking/list",
};

export const GET_SA_AIRPORT_TRANSFER_BOOKING_COUNT = {
  method: GET,
  path: "/s/airportTransfer/count/list",
};
