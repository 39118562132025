import * as api from '../../constants/Api';
import {API, storeSession} from '../../services';
import * as T from '../ActionTypes';
import {SNACKBAR_MSG_TYPE} from '../../constants';
import {signInSuccess} from '../auth/actions';
import moment from "moment";


export const fetchavailableExc = (tourId, persons) => {
  return (dispatch) => {
    dispatch({type: T.COMMON_LOADING});
    return API.execute(api.GET_AVAILABLE_EXCURSION_LIST(tourId, persons)).then(
      (response) => {
        let arr = [];
        response.data.forEach((data) => {
          const date = moment(data.DIA, 'YYYYMMDD').format('YYYY-MM-DD')
          arr.push({date});
        });
        dispatch({type: T.COMMON_ERROR});

        return arr;
      },
      (error) => {
        dispatch({type: T.COMMON_ERROR, payload: error});
        return [];

      },
    ).catch((err)=>{
      dispatch({type: T.COMMON_ERROR, payload: err});
        return [];
    });
  };
};

export const fetchCurrency = () => {
  return (dispatch) => {
    const arr = [];
    return API.execute(api.FETCH_CURRENCY).then(
      (response) => {
        dispatch({type: T.FETCH_CURRENCY, payload: response.data});
        return response.data;
      },
      (error) => {
        dispatch({type: T.COMMON_ERROR, payload: error});
        return arr;
      },
    );
  };
};

export const changePassword = (data) => {
  return (dispatch) => {
    return API.execute(api.CHANGE_PASSWORD, {data}).then(
      (response) => {
        const message = 'Password changed successfully';
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        return true;
      },
      (error) => {
        dispatch({type: T.LOGIN_ERROR, payload: error});
        return false;
      },
    );
  };
};

export const updateAvatar = (data) => {
  return (dispatch) => {
    return API.execute(api.UPDATE_AVATAR, {data}).then(
      (response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        // storeSession(response.data);
        // dispatch(signInSuccess(response.data));
        return true;
      },
      (error) => {
        dispatch({type: T.LOGIN_ERROR, payload: error});
        return false;
      },
    );
  };
};

export const updateProfile = (data) => {
  return (dispatch) => {
    return API.execute(api.UPDATE_PROFILE, {data}).then(
      (response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        dispatch(signInSuccess(response.data));
        return true;
      },
      (error) => {
        dispatch({type: T.LOGIN_ERROR, payload: error});
        return false;
      },
    );
  };
};
