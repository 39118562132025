import * as api from "../../constants/Api";
import { API } from "../../services";
import * as T from "../ActionTypes";
import { SNACKBAR_MSG_TYPE, SNACKBAR_MSG } from "../../constants";

export const createAdmin = (data) => {
  delete data.confirmPassword;
  return (dispatch) => {
    dispatch({ type: T.ADMIN_LOADING });
    return API.execute(api.CREATE_ADMIN, { data })
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        dispatch(getAdminList());
        // dispatch({type: T.CREATE_ADMIN, payload: response.data});
        return true;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({
          type: T.ADMIN_ERROR,
          payload: error,
        });
      });
  };
};

export const getAdminList = () => {
  return (dispatch) => {
    dispatch({ type: T.ADMIN_LOADING });
    return API.execute(api.GET_ADMIN_LIST).then(
      (response) => {
        dispatch({ type: T.GET_ADMIN_LIST, payload: response.data });
        return response.data;
      },
      (error) => {
        dispatch({ type: T.ADMIN_ERROR, payload: error });
        return error;
      }
    );
  };
};

export const activateAdmin = (id) => {
  return (dispatch) => {
    dispatch({ type: T.ADMIN_LOADING });
    return API.execute(api.ACTIVATE_ADMIN(id)).then(
      (response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        dispatch({ type: T.ACTIVATE_ADMIN, id });
        return true;
      },
      (error) => {
        dispatch({ type: T.ADMIN_ERROR, payload: error });
        return false;
      }
    );
  };
};

export const deactivateAdmin = (id) => {
  return (dispatch) => {
    dispatch({ type: T.ADMIN_LOADING });
    return API.execute(api.DEACTIVATE_ADMIN(id)).then(
      (response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        dispatch({ type: T.DEACTIVATE_ADMIN, id });
        return true;
      },
      (error) => {
        dispatch({ type: T.ADMIN_ERROR, payload: error });
        return false;
      }
    );
  };
};

export const getHotelList = () => {
  return (dispatch) => {
    dispatch({ type: T.ADMIN_LOADING });
    return API.execute(api.GET_HOTEL_LIST).then(
      (response) => {
        // dispatch({type: T.GET_HOTEL_LIST, payload: response.data});
        return response.data;
      },
      (error) => {
        dispatch({ type: T.ADMIN_ERROR, payload: error });
        return error;
      }
    );
  };
};

export const hotelView = (id) => {
  return (dispatch) => {
    dispatch({ type: T.ADMIN_LOADING });
    return API.execute(api.HOTEL_VIEW(id)).then(
      (response) => {
        // dispatch({type: T.GET_HOTEL_LIST, payload: response.data});
        return response.data;
      },
      (error) => {
        dispatch({ type: T.ADMIN_ERROR, payload: error });
        return error;
      }
    );
  };
};

export const hotelCommisionView = (id) => {
  return (dispatch) => {
    dispatch({ type: T.ADMIN_LOADING });
    return API.execute(api.HOTEL_COMMISION_VIEW(id)).then(
      (response) => {
        // dispatch({type: T.GET_HOTEL_LIST, payload: response.data});
        return response.data;
      },
      (error) => {
        dispatch({ type: T.ADMIN_ERROR, payload: error });
        return error;
      }
    );
  };
};

export const updateCommission = (id, data) => {
  return (dispatch) => {
    dispatch({ type: T.HOTEL_LOADING });
    return API.execute(api.UPDATE_COMMISSION_SUPER_ADMIN(id), { data })
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        dispatch(getHotelList());
        return true;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });

        dispatch({ type: T.HOTELS_ERROR, payload: error });
      });
  };
};

export const getTourBooking = (type, date, id) => {
  return (dispatch) => {
    dispatch({ type: T.SUPER_ADMIN_TOUR_LOADING, load:true });
    return API.execute(api.GET_TOUR_BOOKING(date, type, id)).then(
      (response) => {
        dispatch({ type: T.SUPER_ADMIN_TOUR_LOADING, load:false });
        return response;
      },
      (error) => {
        dispatch({ type: T.ADMIN_ERROR, payload: error });
        return error;
      }
    );
  };
};

export const tourList = (type = null) => {
  
  return (dispatch) => {
    dispatch({ type: T.ADMIN_LOADING });
    return API.execute(api.TOUR_LIST_SA(type)).then(
      (response) => {
        dispatch({ type: T.GET_TOUR_LIST_ADMIN, payload: response.data });
        return response.data;
      },
      (error) => {
        dispatch({ type: T.ADMIN_ERROR, payload: error });
        return error;
      }
    );
  };
};

export const tourBookingView = (id) => {
  return (dispatch) => {
    dispatch({ type: T.ADMIN_LOADING });
    return API.execute(api.TOUR_BOOKING_VIEW_SA(id)).then(
      (response) => {
        // dispatch({type: T.GET_HOTEL_LIST, payload: response.data});
        return response.data;
      },
      (error) => {
        dispatch({ type: T.ADMIN_ERROR, payload: error });
        return error;
      }
    );
  };
};

export const getAirportTransfers = (
  date,
  hotelId,
  airportId,
  vehiclePrice
) => {
  return (dispatch) => {
    dispatch({ type: T.SUPER_ADMIN_TOUR_LOADING, load:true });
    return API.execute(api.GET_AIRPORT_TRANSFERS(date, hotelId, airportId, vehiclePrice)).then(
      (response) => {
        dispatch({ type: T.SUPER_ADMIN_TOUR_LOADING, load:false });
        return response.data;
      },
      (error) => {
        dispatch({ type: T.ADMIN_ERROR, payload: error });
        return error;
      }
    );
  };
};

export const airportTransfersView = (id) => {
  return (dispatch) => {
    dispatch({ type: T.ADMIN_LOADING });
    return API.execute(api.AIRPORT_TRANSFERS_VIEW_SA(id)).then(
      (response) => {
        // dispatch({type: T.GET_HOTEL_LIST, payload: response.data});
        return response.data;
      },
      (error) => {
        dispatch({ type: T.ADMIN_ERROR, payload: error });
        return error;
      }
    );
  };
};

export const airportList = () => {
  return (dispatch) => {
    dispatch({ type: T.ADMIN_LOADING });
    return API.execute(api.AIRPORT_LIST_SA()).then(
      (response) => {
        // dispatch({type: T.GET_HOTEL_LIST, payload: response.data});
        return response.data;
      },
      (error) => {
        dispatch({ type: T.ADMIN_ERROR, payload: error });
        return error;
      }
    );
  };
};

export const hotelList = () => {
  return (dispatch) => {
    dispatch({ type: T.ADMIN_LOADING });
    return API.execute(api.HOTEL_LIST_SA()).then(
      (response) => {
        // dispatch({type: T.GET_HOTEL_LIST, payload: response.data});
        return response.data;
      },
      (error) => {
        dispatch({ type: T.ADMIN_ERROR, payload: error });
        return error;
      }
    );
  };
};

export const vehiclePriceList = (hotelId, airportId) => {
  return (dispatch) => {
    dispatch({ type: T.ADMIN_LOADING });
    return API.execute(api.VEHICLE_PRICE_LIST_SA(hotelId, airportId)).then(
      (response) => {
        return response.data;
      },
      (error) => {
        dispatch({ type: T.ADMIN_ERROR, payload: error });
        return error;
      }
    );
  };
};
