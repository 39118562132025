import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import _ from "lodash";
import { Avatar, Spin } from "antd";

import "./CreatePrivateTours.css";
import basicStyle from "@iso/assets/styles/constants";
import Dropdown from "@iso/components/uielements/CustomDropdown";
import Input, { Textarea } from "@iso/components/uielements/input";
import Button from "@iso/components/uielements/button";
import { createPrivateTour, updatePrivateTour } from "@iso/redux/tours/actions";
import IntlMessages from "@iso/components/utility/intlMessages";
import SignUpStyleWrapper from "./CreatePrivateTours.styles";
import { fetchCurrency } from "../../../redux/common/actions";
import { privateTourValidation } from "../../../constants";
import TourSettingForm from "../../../components/TourSetting/Form";
import { Map, fillTourSetting } from "../../../services";
import {
  view_Srd_Tkt_Tour,
  tourClientSetting,
} from "../../../redux/tours/actions";

export default function CreatePrivateTours() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useLocation();
  const locParams =
    params && params.state ? params.state : { privateTour: null };

  const tourID = locParams.privateTour ? locParams.privateTour.id : null;

  const [loading, setLoading] = useState(true);
  const [tourData, setTourData] = useState(null);
  const [countryTypes, setCountryTypes] = useState([]);
  const [imageFile, setImageFile] = useState();
  const [location, setLocation] = useState({});
  const [radius, setRadius] = useState(
    (locParams &&
      locParams.privateTour &&
      locParams.privateTour.DeparturePlace &&
      locParams.privateTour.DeparturePlace.length > 0 &&
      locParams.privateTour.DeparturePlace[0].radius) ||
      0
  );
  const [disable, setDisable] = useState(false);

  const [client, setclient] = useState(fillTourSetting());

  useEffect(() => {
    dispatch(fetchCurrency()).then((res) => {
      let arr = [];
      res.forEach((item) => {
        arr.push({ value: item.code, label: item.name });
      });
      setCountryTypes(arr);
    });
  }, []);

  useEffect(() => {
    if (tourID) {
      dispatch(view_Srd_Tkt_Tour(tourID)).then((res) => {
        setTourData(res);
        setLoading(false);
      });
      dispatch(tourClientSetting(tourID)).then((res) => {
        setclient(fillTourSetting(res));
      });
    }
    setLoading(false);
  }, [tourID]);

  const onChangeRadius = (val) => {
    setRadius(val);
  };

  const handleSubmit = (values) => {
    setDisable(true);
    values.location.lat = location.lat;
    values.location.lng = location.lng;
    if (tourData) {
      let obj = {
        id: tourData.id,

        tour: {
          destination: values.destination,
          tagline: values.tagline,
          duration: values.duration,
          includes: values.includes,
          observations: values.observations,
          description: values.description,
        },
        price: {
          privatePrice: values.privatePrice,
          currencyCode: values.countryId.value,
        },
        departurePlace: {
          name: values.location.name,
          latitude: values.location.lat,
          longitude: values.location.lng,
          address: values.location.address,
          radius: 0,
        },
        clientSetting: {
          client,
        },
        photo: values.photo,
      };

      dispatch(updatePrivateTour(obj)).then((res) => {
        setDisable(false);
        navigate("/dashboard/ticket-tours-list");
      });
    } else {
      let data = {
        tour: {
          destination: values.destination,
          tagline: values.tagline,
          duration: values.duration,
          includes: values.includes,
          observations: values.observations,
          description: values.description,
        },
        price: {
          privatePrice: values.privatePrice,
          currencyCode: values.countryId.value,
        },
        departurePlace: {
          name: values.location.name,
          latitude: values.location.lat,
          longitude: values.location.lng,
          address: values.location.address,
          radius: 0,
        },
        clientSetting: {
          client,
        },
        photo: values.photo,
      };
      dispatch(createPrivateTour(data)).then((res) => {
        setDisable(false);
        navigate("/dashboard/ticket-tours-list");
      });
    }
  };

  let createFormValues = {
    destination: "",
    tagline: "",
    duration: "",
    includes: "",
    observations: "",
    privatePrice: "",
    countryId: { value: "EUR", label: "Euro" },
    location: {},
    photo: null,
    description: "",
  };

  let updateFormValues = {
    destination: tourData ? tourData.destination : "",
    photo: (tourData && tourData.photo && tourData.photo.url) || null,
    duration: tourData ? tourData.duration : "",
    includes: tourData ? tourData.includes : "",
    description: tourData ? tourData.description : "",
    observations: tourData ? tourData.observations : "",
    tagline: tourData ? tourData.tagline : "",
    privatePrice: tourData ? tourData.price.privatePrice : "",
    countryId: tourData
      ? _.find(countryTypes, function (o) {
          return `${o.value}` === `${tourData.price.currencyCode}`;
        })
      : null,
    location: tourData
      ? tourData.DeparturePlace
        ? tourData.DeparturePlace.length !== 0
          ? {
              lat: tourData.DeparturePlace[0].latitude,
              lng: tourData.DeparturePlace[0].longitude,
              name: tourData.DeparturePlace[0].name
                ? tourData.DeparturePlace[0].name
                : "name",
              location: tourData.DeparturePlace[0].location
                ? tourData.DeparturePlace[0].location
                : "location",
            }
          : {}
        : {}
      : {},
  };

  console.log("tourData", tourData);

  // const [loading, setLoading] = useState(false);
  // const [imageUrl, setImageUrl] = useState();
  // const getBase64 = (img, callback) => {
  //   const reader = new FileReader();
  //   reader.addEventListener("load", () => callback(reader.result));
  //   reader.readAsDataURL(img);
  // };
  // const beforeUpload = (file) => {
  //   const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  //   if (!isJpgOrPng) {
  //     message.error("You can only upload JPG/PNG file!");
  //   }
  //   const isLt2M = file.size / 1024 / 1024 < 2;
  //   if (!isLt2M) {
  //     message.error("Image must smaller than 2MB!");
  //   }
  //   return isJpgOrPng && isLt2M;
  // };

  // const handlePhotoChange = (info) => {
  //   if (info.file.status === "uploading") {
  //     setLoading(true);
  //     return;
  //   }
  //   if (info.file.status === "done") {
  //     setImageFile(info.file.originFileObj)
  //     // Get this url from response in real world.
  //     getBase64(info.file.originFileObj, (url) => {
  //       setLoading(false);
  //       setImageUrl(url);
  //     });
  //   }
  // };

  // console.log('imageFile', imageFile)

  // const uploadButton = (
  //   <div>
  //     {loading ? <LoadingOutlined /> : <PlusOutlined />}
  //     <div
  //       style={{
  //         marginTop: 8,
  //       }}
  //     >
  //       Upload
  //     </div>
  //   </div>
  // );

  const { rowStyle, colStyle, gutter } = basicStyle;

  return (
    <>
      {loading ? (
        <div className="spinner">
          <Spin />
        </div>
      ) : (
        <SignUpStyleWrapper className="isoSignUpPage">
          <div className="isoSignUpContentWrapper">
            <div className="isoSignUpContent">
              <div className="isoLogoWrapper">
                <Link to="/dashboard">
                  <IntlMessages
                    id={tourData ? "Edit Tour" : "Add a new Tour"}
                  />
                </Link>
              </div>

              <Formik
                initialValues={tourData ? updateFormValues : createFormValues}
                onSubmit={handleSubmit}
                validationSchema={privateTourValidation}
                enableReinitialize
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  /* and other goodies */
                }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="isoSignUpForm">
                        <div className="RowDiv">
                          <div className="isoInputWrapper">
                            <label className="label-style">Destination</label>
                            <Input
                              size="large"
                              type="text"
                              name="destination"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.destination}
                            />
                            {errors.destination && touched.destination && (
                              <div className="invalid-input">
                                {errors.destination}
                              </div>
                            )}
                          </div>
                          <div className="isoInputWrapper">
                            <label className="label-style">Price</label>
                            <Input
                              size="large"
                              type="number"
                              name="privatePrice"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.privatePrice}
                            />
                            {errors.privatePrice && touched.privatePrice && (
                              <div className="invalid-input">
                                {errors.privatePrice}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="RowDiv">
                          <div className="isoInputWrapper">
                            <label className="label-style">Duration</label>
                            <Input
                              size="large"
                              type="number"
                              name="duration"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.duration}
                            />
                            {errors.duration && touched.duration && (
                              <div className="invalid-input">
                                {errors.duration}
                              </div>
                            )}
                          </div>

                          <div className="isoInputWrapper">
                            <label className="label-style">Tagline</label>
                            <Input
                              size="large"
                              type="text"
                              name="tagline"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.tagline}
                            />
                            {errors.tagline && touched.tagline && (
                              <div className="invalid-input">
                                {errors.tagline}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="RowDiv">
                          <div className="isoInputWrapper">
                            <label className="label-style">Includes</label>
                            <Input
                              size="large"
                              type="text"
                              name="includes"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.includes}
                            />
                            {errors.includes && touched.includes && (
                              <div className="invalid-input">
                                {errors.includes}
                              </div>
                            )}
                          </div>
                          <div className="isoInputWrapper">
                            <label className="label-style">
                              Select a Currency
                            </label>
                            <Dropdown
                              options={countryTypes}
                              onChange={(value) => {
                                setFieldValue("countryId", value);
                              }}
                              value={values.countryId ? values.countryId : null}
                            />
                            {errors.countryId && touched.countryId && (
                              <div className="invalid-input">
                                {errors.countryId.value}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="RowDiv">
                          <div className="isoInputWrapper">
                            <label className="label-style">Observations</label>
                            <Textarea
                              rows={4}
                              size="large"
                              type="text"
                              name="observations"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.observations}
                            />
                            {errors.observations && touched.observations && (
                              <div className="invalid-input">
                                {errors.observations}
                              </div>
                            )}
                          </div>
                          <div className="isoInputWrapper">
                            <label className="label-style">Description</label>
                            <Textarea
                              rows={4}
                              size="large"
                              type="text"
                              name="description"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.description}
                            />
                            {errors.description && touched.description && (
                              <div className="invalid-input">
                                {errors.description}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="isoInputWrapper">
                          <label className="label-style">Image</label>
                          <Input
                            type="file"
                            name="photo"
                            accept="image/x-png,image/jpeg,image/jpg"
                            onChange={(e) =>
                              handleChange(
                                setFieldValue("photo", e.target.files[0]),
                                setImageFile(
                                  URL.createObjectURL(e.target.files[0])
                                )
                              )
                            }
                            onBlur={handleBlur}
                            value={undefined}
                          />
                          <Avatar
                            src={
                              (imageFile && imageFile) ||
                              (tourData && tourData.photo && tourData.photo.url)
                            }
                            style={{ marginTop: "10px" }}
                            shape="square"
                            size={100}
                          />

                          {/* {errors.countryId && touched.countryId && (
                        <div className="invalid-input">
                          {errors.countryId.value}
                        </div>
                      )} */}
                        </div>
                        <div className="isoInputWrapper">
                          <TourSettingForm
                            client={client}
                            setclient={setclient}
                          />
                          {errors.paymentMethod && touched.paymentMethod && (
                            <div className="invalid-input">
                              {errors.paymentMethod}
                            </div>
                          )}
                        </div>
                        <div style={{ padding: "0 7px" }}>
                          <p
                            style={{
                              fontWeight: 400,
                              paddingBottom: 10,
                              fontSize: "25px",
                              color: "black",
                            }}
                          >
                            Departure Place Location
                          </p>
                        </div>
                        {/* <div style={{ padding: "0 7px" }}>
                      <label style={{ paddingBottom: 10, color: "#788195" }}>
                        Radius
                      </label>
                      <Slider
                        defaultValue={radius}
                        style={{ width: "50%" }}
                        handleStyle={{
                          marginTop: "-10px",
                          width: "22px",
                          height: "22px",
                        }}
                        max={1000}
                        onChange={onChangeRadius}
                      />
                    </div> */}
                        <div className="mapWrapper">
                          <Map
                            radius={radius}
                            getLocation={(loc) => {
                              setLocation(loc);
                              setFieldValue("location", loc);
                            }}
                            setLocation={
                              tourData && tourData.DeparturePlace
                                ? tourData.DeparturePlace.length !== 0
                                  ? {
                                      lat:
                                        (tourData &&
                                          tourData.DeparturePlace &&
                                          tourData.DeparturePlace.length !==
                                            0 &&
                                          tourData.DeparturePlace[0] &&
                                          tourData.DeparturePlace[0]
                                            .latitude) ||
                                        location.lat,
                                      lng:
                                        (tourData &&
                                          tourData.DeparturePlace &&
                                          tourData.DeparturePlace.length !==
                                            0 &&
                                          tourData.DeparturePlace[0] &&
                                          tourData.DeparturePlace[0]
                                            .longitude) ||
                                        location.lng,
                                      name:
                                        tourData &&
                                        tourData.DeparturePlace &&
                                        tourData.DeparturePlace.length !== 0 &&
                                        tourData.DeparturePlace[0] &&
                                        tourData.DeparturePlace[0].name,
                                    }
                                  : null
                                : null
                            }
                            getQuery={() => {}}
                          />
                          {errors.location && touched.location && (
                            <div className="invalid-input">
                              {errors.location}
                            </div>
                          )}
                        </div>

                        <Button
                          type="primary"
                          onClick={handleSubmit}
                          disabled={disable}
                        >
                          <IntlMessages
                            id={locParams.tour ? "Update" : "page.signUpButton"}
                          />
                        </Button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </SignUpStyleWrapper>
      )}
    </>
  );
}
