import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Popconfirm,
  Table,
  Typography,
  Modal,
  Button,
  InputNumber,
} from "antd";
import { useDispatch } from "react-redux";
import TimeKeeper from "react-timekeeper";
import moment from "moment";
import _ from "lodash";
import {
  fetchHotelPrice,
  deleteSchedule,
  createHotelPrice,
  updateHotelPrice,
  deleteHotelPrice,
} from "../../../redux/tours/actions";
import { getHotelsList } from "../../../redux/hotels/actions";

const TourSchedule = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [form] = Form.useForm();
  const tourData = useLocation();
  const tour = tourData && tourData.state ? tourData.state : { data: null };
  const activePickupTime = tour && tour.data && tour.data.pickUpDate;

  const dayAllowed = moment(activePickupTime).format("dddd").toLowerCase();

  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [editingRowItem, setEditingRowItem] = useState("");
  const [visible, setVisible] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);

  const [hotelModel, sethotelModel] = useState(false);
  const [hotelList, setHotelList] = useState(null);

  const [day, setday] = useState(null);

  useEffect(() => {
    dispatch(fetchHotelPrice(params.id)).then((res) => {
      setOriginalData(res);
      let arr = [];
      res.forEach((item) => {
        arr.push({ ...item, name: item.hotel.name });
      });
      setData(arr);
    });
  }, [day]);

  const addDay = (hotel) => {
    const isHotel = _.find(data, function (o) {
      return o.hotel.name === hotel.name;
    });
    if (isHotel === undefined) {
      dispatch(createHotelPrice(hotel.id, params.id)).then((res) => {
        // navigate("/dashboard/transport-tours-list");
        setday(res);
      });
      sethotelModel(false);
    }
  };

  const openHotelModel = () => {
    dispatch(getHotelsList()).then((res) => {
      setHotelList(res);
    });
    sethotelModel(true);
  };

  const isEditing = (record) => record.id === editingKey;

  const getDayValue = (index, objKey) => {
    let newObj = { ...data[index] };
    if (newObj[`${objKey}`]) {
      return moment(newObj[`${objKey}`], "HH:mm:ss").format("HH:mm");
    } else {
      return null;
    }
  };

  const save = async (key) => {
    // try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.id);
      const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
          
        });
        setData(newData);
        setEditingKey('');
    //   if (index > -1) {
    //     const item = newData[index];
    //     newData.splice(index, 1, {
    //       ...item,
    //       ...row,
    //     });
    //     console.log('newData', newData)

    //     setData(newData);
    //     setEditingKey('');
    //   } else {
    //     newData.push(row);
    //     setData(newData);
    //     setEditingKey('');
    //   }
    // } catch (errInfo) {
    //   console.log('Validate Failed:', errInfo);
    // }
    dispatch(updateHotelPrice(newData[editingIndex].id, newData[editingIndex])).then(
      (res) => {
        setEditingKey("");
      }
    );
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const edit = (record) => {
    form.setFieldsValue({
      adultPerPrice: "",
      childrenPerPrice: "",
      ...record,
    });
    const index = originalData.findIndex((object) => {
      return object.id === record.id;
    });
    setEditingIndex(index);
    setEditingKey(record.id);
    
  };

  const cancel = () => {
    setEditingKey("");
  };

  const ondeleteSchedule = (id) => {
    dispatch(deleteHotelPrice(id)).then((res) => {
      setday(res);
    });
  };

  const columns = [
    {
      title: "Hotel Name",
      dataIndex: "name",
      indexType: "text",
      // editable: true,
    },
    {
      title: "Adult Price",
      dataIndex: "adultPerPrice",
      editable: true,
    },
    {
      title: "Children Price",
      dataIndex: "childrenPerPrice",
      editable: true,
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={()=>save(record.id)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <>
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
              style={{ margin: "0 8px" }}
            >
              Edit
            </Typography.Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => ondeleteSchedule(record.id)}
            >
              <a style={{ margin: "0 8px", color: "red" }}>Delete</a>
            </Popconfirm>
          </>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <div>
      <Form form={form} component={false}>
        <div style={{ float: "right", padding: "10px 10px" }}>
          <Button
            onClick={openHotelModel}
            type="primary"
            style={{
              marginBottom: 16,
            }}
          >
            Add Hotel
          </Button>
        </div>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
        />
      </Form>
      {/* <Modal
        title="Select your Time"
        centered
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width={300}
        bodyStyle={{ height: 500 }}
      >
        <TimeKeeper
          time={getDayValue(editingIndex, `${editingRowItem}`)}
          onDoneClick={(value) => {
            let newData = [...data];
            let newObj = { ...data[editingIndex] };
            newObj[`${editingRowItem}`] = value.formatted24;
            newData[editingIndex] = newObj;
            setData(newData);
            setVisible(false);
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#F4F4F4",
            border: "none",
            color: "#686868",
            cursor: "pointer",
            margin: "10px 0 5px 5px",
            padding: "10px",
            fontSize: "14px",
            fontWeight: "500",
          }}
          onClick={() => {
            let newData = [...data];
            let newObj = { ...data[editingIndex] };
            newObj[`${editingRowItem}`] = null;
            newData[editingIndex] = newObj;
            setData(newData);
            setVisible(false);
          }}
        >
          Clear
        </div>
      </Modal> */}
      <Modal
        title="Select Hotel"
        centered
        visible={hotelModel}
        onCancel={() => sethotelModel(false)}
        footer={null}
        width={300}
        bodyStyle={{ height: 500, overflowY: "auto" }}
      >
        <div>
          {(hotelList &&
            hotelList.length > 0 &&
            hotelList.map((data, key) => {
              return (
                <div
                  style={{
                    padding: "5px 10px",
                    fontSize: "15px",
                    cursor: "pointer",
                  }}
                  key={key}
                  onClick={() => addDay(data)}
                >
                  <span>
                    {key + 1}
                    {". "}
                    {data.displayName}
                  </span>
                </div>
              );
            })) ||
            null}
        </div>
      </Modal>
      {/* <Button >Add</Button> */}
    </div>
  );
};

export default TourSchedule;
