import * as api from "../../constants/Api";
import { API } from "../../services";
import * as T from "../ActionTypes";
import { SNACKBAR_MSG_TYPE, SNACKBAR_MSG } from "../../constants";
import moment from "moment";

export const createTour = (data) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.CREATE_TOURS, { data })
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        // dispatch({type: T.CREATE_TOUR, payload: response.data});
        dispatch(getToursList(data.type));
        return true;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.TOURS_ERROR, payload: error });
      });
  };
};

export const getToursList = (type = null) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.GET_TOURS_LIST(type))
      .then(
        (response) => {
          dispatch({ type: T.GET_TOURS_LIST, payload: response.data });
          return response.data;
        }
        // (error) => {
        //   dispatch({ type: T.TOURS_ERROR, payload: error });
        //   return error;
        // }
      )
      .catch((error) => {
        dispatch({ type: T.TOURS_ERROR, payload: error });
      });
  };
};

export const getBookedTours = () => {
  return (dispatch) => {
    dispatch({ type: T.ADMIN_LOADING });
    return API.execute(api.GET_BOOKED_TOURS_LIST).then(
      (response) => {
        return response;
      },
      (error) => {
        dispatch({ type: T.ADMIN_ERROR, payload: error });
        return error;
      }
    );
  };
};

export const getCancelBookings = () => {
  return (dispatch) => {
    dispatch({ type: T.ADMIN_LOADING });
    return API.execute(api.GET_CANCEL_BOOKING_LIST).then(
      (response) => {
        return response;
      },
      (error) => {
        dispatch({ type: T.ADMIN_ERROR, payload: error });
        return error;
      }
    );
  };
};

export const deleteTour = (id) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.DELETE_TOURS(id)).then(
      (response) => {
        dispatch({ type: T.DELETE_TOUR, id });
        return true;
      },
      (error) => {
        dispatch({ type: T.TOURS_ERROR, payload: error });
        return false;
      }
    );
  };
};

export const updateTour = (data) => {
  let newData = { ...data };
  let id = newData.id;
  delete data.id;

  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.UPDATE_TOURS(id), { data })
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        dispatch(getToursList());
        // dispatch({type: T.UPDATE_TOUR, payload: response.data, id});
        return true;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.TOURS_ERROR, payload: error });
      });
  };
};

export const deleteBooking = (id) => {
  return (dispatch) => {
    dispatch({ type: T.ADMIN_LOADING });
    return API.execute(api.DELETE_BOOKING_TOUR(id), {})
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        // dispatch(getTourBooking(type, date, bid, reference));
        // dispatch({type: T.UPDATE_TOUR, payload: response.data, id});
        return response.data;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.ADMIN_ERROR, payload: error });
      });
  };
};

export const cancelBooking = (id) => {
  return (dispatch) => {
    dispatch({ type: T.ADMIN_LOADING });
    return API.execute(api.CANCEL_BOOKING_TOUR(id), {})
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        // dispatch(getCancelBookings());
        // dispatch({type: T.UPDATE_TOUR, payload: response.data, id});
        return response.data;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.ADMIN_ERROR, payload: error });
      });
  };
};

export const searchPrivateTour = (query) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.SEARCH_PRIVATE_TOURS(query)).then(
      (response) => {
        dispatch({ type: T.SEARCH_PRIVATE_TOURS, payload: response.data });
        return true;
      },
      (error) => {
        dispatch({ type: T.TOURS_ERROR, payload: error });
        return false;
      }
    );
  };
};

export const createPrivateTour = (data) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.CREATE_PRIVATE_TOURS, { data })
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        dispatch(getToursList());
        return true;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.TOURS_ERROR, payload: error });
      });
  };
};

export const updatePrivateTour = (data) => {
  let newData = { ...data };
  let id = newData.id;
  delete data.id;

  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.UPDATE_PRIVATE_TOURS(id), { data })
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        dispatch(getToursList());
        // dispatch({type: T.UPDATE_TOUR, payload: response.data, id});
        return response.data;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.TOURS_ERROR, payload: error });
      });
  };
};

export const fetchTourSchedule = (id) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.FETCH_TOUR_SCHEDULE(id))
      .then((response) => {
        // console.log('response', response);
        // const message = response.message ? response.message : 'fetched successfully';
        // const messageType = SNACKBAR_MSG_TYPE.success;
        // dispatch({
        //   type: T.SNACKBAR_SUCCESS,
        //   payload: {message, messageType},
        // });
        // dispatch(getToursList());
        return response.data;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.TOURS_ERROR, payload: error });
      });
  };
};

export const updateTourSchedule = (id, data) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.UPDATE_TOUR_SCHEDULE(id), { data })
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        // dispatch(getToursList());
        return response.data;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.TOURS_ERROR, payload: error });
      });
  };
};

export const createTourSchedule = (hotelId, tourId) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.CREATE_TOUR_SCHEDULE(hotelId, tourId))
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        // dispatch(getToursList());
        return response.data;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.TOURS_ERROR, payload: error });
      });
  };
};

export const duplicateTourSchedule = (data) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.DUPLICATE_TOUR_SCHEDULE(), { data })
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        // dispatch(getToursList());
        return response.data;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.TOURS_ERROR, payload: error });
      });
  };
};

export const deleteSchedule = (id) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.DELETE_TOUR_SCHEDULE(id)).then(
      (response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        // dispatch(getToursList());
        return true;
      },
      (error) => {
        dispatch({ type: T.TOURS_ERROR, payload: error });
        return false;
      }
    );
  };
};

export const fetchTourCloseDay = (id) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.FETCH_TOUR_CLOSE_DAY(id))
      .then((response) => {
        // console.log('response', response);
        // const message = response.message ? response.message : 'fetched successfully';
        // const messageType = SNACKBAR_MSG_TYPE.success;
        // dispatch({
        //   type: T.SNACKBAR_SUCCESS,
        //   payload: {message, messageType},
        // });
        // dispatch(getToursList());
        // let arr = [];
        // response.data.forEach((item) => {
        //   arr.push({reason: item.reason, closeDate: item.closeDate});
        // });
        return response.data;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.TOURS_ERROR, payload: error });
      });
  };
};

export const createTourCloseDay = (tourId, arr) => {
  let closeDay = [];
  arr.forEach((item) => {
    closeDay.push({
      id: item.id,
      reason: item.reason,
      closeDate: item.closeDate,
    });
  });
  const data = {
    closeDay,
  };
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.CREATE_TOUR_CLOSE_DAY(tourId), { data })
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        // dispatch(fetchTourCloseDay());
        return response.data;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.TOURS_ERROR, payload: error });
      });
  };
};

export const fetchTourCloseDayByRec = (id) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.FETCH_TOUR_CLOSE_DAY_REC(id))
      .then((response) => {
        // console.log('response', response);
        // const message = response.message ? response.message : 'fetched successfully';
        // const messageType = SNACKBAR_MSG_TYPE.success;
        // dispatch({
        //   type: T.SNACKBAR_SUCCESS,
        //   payload: {message, messageType},
        // });
        // dispatch(getToursList());
        // let arr = [];
        // response.data.forEach((item) => {
        //   arr.push({reason: item.reason, closeDate: item.closeDate});
        // });
        return response.data;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.TOURS_ERROR, payload: error });
      });
  };
};

export const getTourBooking = (
  type,
  date,
  id,
  reference,
  dateRange,
  hotel,
  paymentMethod,
  pagination
) => {
  let startDate = "";
  let endDate = "";

  if (dateRange) {
    startDate = moment(dateRange[0]).format("YYYY-MM-DD");
    endDate = moment(dateRange[1]).format("YYYY-MM-DD");
  }

  const filter = {
    date,
    type,
    tourId: id,
    referenceNo: reference,
    startDate,
    endDate,
    hotelId: hotel,
    paymentMethod,
  };

  return (dispatch) => {
    dispatch({ type: T.TOUR_BOOKING_LOADING });
    return API.execute(api.GET_TOUR_BOOKING_ADMIN, {
      data: { pagination, filter },
    })
      .then(async(response) => {
        dispatch({ type: T.TOUR_BOOKING_ERR });

        return {booking: response};
      })
      .catch((err) => {
        dispatch({ type: T.TOUR_BOOKING_ERR, payload: err });
        console.log("err", err);
      });
  };
};

// const tourBookingPersons = (bookings) => {
//   return () => {
//     return API.execute(api.TOUR_BOOKING_PERSONS, {data: {bookings}}).then(
//       (response) => {
//         return response.data;
//       },
//     ).catch((err)=>{
//       console.log("err", err);

//     })
//   };
// };

export const tourBookingView = (id) => {
  return (dispatch) => {
    dispatch({ type: T.ADMIN_LOADING });
    return API.execute(api.TOUR_BOOKING_VIEW_A(id)).then(
      (response) => {
        // dispatch({type: T.GET_HOTEL_LIST, payload: response.data});
        return response.data;
      },
      (error) => {
        dispatch({ type: T.ADMIN_ERROR, payload: error });
        return error;
      }
    );
  };
};

export const getticketToursList = () => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.GET_TICKET_TOURS_LIST).then(
      (response) => {
        dispatch({ type: T.GET_TICKET_TOURS_LIST, payload: response.data });
        return response.data;
      },
      (error) => {
        dispatch({ type: T.TOURS_ERROR, payload: error });
        return error;
      }
    );
  };
};

export const createTicketTour = (data) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.CREATE_TICKET_TOURS, { data })
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        // dispatch({type: T.CREATE_TOUR, payload: response.data});
        dispatch(getticketToursList());
        return true;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.TOURS_ERROR, payload: error });
      });
  };
};

export const updateTicketTour = (data) => {
  let newData = { ...data };
  let id = newData.id;
  delete data.id;
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.UPDATE_TICKET_TOURS(id), { data })
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        dispatch(getticketToursList());
        // dispatch({type: T.UPDATE_TOUR, payload: response.data, id});
        return true;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.TOURS_ERROR, payload: error });
      });
  };
};

export const deleteTTour = (id) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.DELETE_TOURS(id)).then(
      (response) => {
        dispatch({ type: T.DELETE_T_TOUR, id });
        return true;
      },
      (error) => {
        dispatch({ type: T.TOURS_ERROR, payload: error });
        return false;
      }
    );
  };
};

export const getTicketGuideToursList = () => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.GET_GUIDE_TICKET_TOURS_LIST).then(
      (response) => {
        dispatch({
          type: T.GET_TICKET_GUIDE_TOURS_LIST,
          payload: response.data,
        });
        return response.data;
      },
      (error) => {
        dispatch({ type: T.TOURS_ERROR, payload: error });
        return error;
      }
    );
  };
};

export const deleteTGTour = (id) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.DELETE_TOURS(id)).then(
      (response) => {
        dispatch({ type: T.DELETE_TG_TOUR, id });
        return true;
      },
      (error) => {
        dispatch({ type: T.TOURS_ERROR, payload: error });
        return false;
      }
    );
  };
};

export const createTicketGuideTour = (data) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.CREATE_TICKET_GUIDE_TOURS, { data })
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        // dispatch({type: T.CREATE_TOUR, payload: response.data});
        dispatch(getTicketGuideToursList());
        return true;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.TOURS_ERROR, payload: error });
      });
  };
};

export const updateTicketGuideTour = (data) => {
  let newData = { ...data };
  let id = newData.id;
  delete data.id;
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.UPDATE_TICKET_GUIDE_TOURS(id), { data })
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        dispatch(getTicketGuideToursList());
        // dispatch({type: T.UPDATE_TOUR, payload: response.data, id});
        return true;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.TOURS_ERROR, payload: error });
      });
  };
};

export const generateTourAdminReceipt = (id) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.GENERATE_TOUR_ADMIN_RECEIPT(id))
      .then((response) => {
        // var bufferArray = base64ToArrayBuffer(response.data);
        // var blobStore = new Blob([bufferArray], {type: 'application/pdf'});
        // const fileURL = URL.createObjectURL(blobStore);
        window.open(response.url, "_blank", "noreferrer");

        dispatch({ type: T.GENERATE_TOUR_ADMIN_RECEIPT });
        return response.url;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.TOURS_ERROR, payload: error });
      });
  };
};

export const generateTourSuperAdminReceipt = (id) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.GENERATE_TOUR_S_ADMIN_RECEIPT(id))
      .then((response) => {
        // var bufferArray = base64ToArrayBuffer(response.data);
        // var blobStore = new Blob([bufferArray], {type: 'application/pdf'});
        // const fileURL = URL.createObjectURL(blobStore);
        window.open(response.url, "_blank", "noreferrer");

        dispatch({ type: T.GENERATE_TOUR_S_ADMIN_RECEIPT });
        return response.url;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.TOURS_ERROR, payload: error });
      });
  };
};

export const fetchTourPricing = (id) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.FETCH_TOUR_PRICING(id))
      .then((response) => {
        // console.log('response', response);
        // const message = response.message ? response.message : 'fetched successfully';
        // const messageType = SNACKBAR_MSG_TYPE.success;
        // dispatch({
        //   type: T.SNACKBAR_SUCCESS,
        //   payload: {message, messageType},
        // });
        // dispatch(getToursList());
        return response.data;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.TOURS_ERROR, payload: error });
      });
  };
};

export const saveTourPricing = (id, data) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.SAVE_TOUR_PRICING(id), { data })
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        // dispatch(getToursList());
        return response.data;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.TOURS_ERROR, payload: error });
      });
  };
};

export const fetchHotelPrice = (id) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.FETCH_HOTEL_PRICE(id))
      .then((response) => {
        // console.log('response', response);
        // const message = response.message ? response.message : 'fetched successfully';
        // const messageType = SNACKBAR_MSG_TYPE.success;
        // dispatch({
        //   type: T.SNACKBAR_SUCCESS,
        //   payload: {message, messageType},
        // });
        // dispatch(getToursList());
        return response.data;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.TOURS_ERROR, payload: error });
      });
  };
};

export const createHotelPrice = (hotelId, tourId) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.CREATE_HOTEL_PRICE(hotelId, tourId), {})
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        // dispatch(getToursList());
        return response.data;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.TOURS_ERROR, payload: error });
      });
  };
};

export const updateHotelPrice = (id, data) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.UPDATE_HOTEL_PRICE(id, data.tourId, data.hotelId), {
      data,
    })
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        // dispatch(getToursList());
        return response.data;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.TOURS_ERROR, payload: error });
      });
  };
};

export const deleteHotelPrice = (id) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.DELETE_HOTEL_PRICE(id)).then(
      (response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        // dispatch(getToursList());
        return true;
      },
      (error) => {
        dispatch({ type: T.TOURS_ERROR, payload: error });
        return false;
      }
    );
  };
};

export const view_Srd_Tkt_Tour = (id) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.VIEW_SRD_TKT_TOUR(id))
      .then((response) => {
        // console.log('response', response);
        // const message = response.message ? response.message : 'fetched successfully';
        // const messageType = SNACKBAR_MSG_TYPE.success;
        // dispatch({
        //   type: T.SNACKBAR_SUCCESS,
        //   payload: {message, messageType},
        // });
        // dispatch(getToursList());
        return response.data;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.TOURS_ERROR, payload: error });
      });
  };
};

export const tourClientSetting = (id) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.VIEW_TOUR_SETTING(id))
      .then((response) => {
        // console.log('response', response);
        // const message = response.message ? response.message : 'fetched successfully';
        // const messageType = SNACKBAR_MSG_TYPE.success;
        // dispatch({
        //   type: T.SNACKBAR_SUCCESS,
        //   payload: {message, messageType},
        // });
        // dispatch(getToursList());
        return response.data;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.TOURS_ERROR, payload: error });
      });
  };
};

export const view_TktGd_Pvt_Tour = (id) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.VIEW_TKTGD_PVT_TOUR(id))
      .then((response) => {
        // console.log('response', response);
        // const message = response.message ? response.message : 'fetched successfully';
        // const messageType = SNACKBAR_MSG_TYPE.success;
        // dispatch({
        //   type: T.SNACKBAR_SUCCESS,
        //   payload: {message, messageType},
        // });
        // dispatch(getToursList());
        return response.data;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.TOURS_ERROR, payload: error });
      });
  };
};

export const fetchTourStop = (id) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.FETCH_TOUR_STOP(id))
      .then((response) => {
        // console.log('response', response);
        // const message = response.message ? response.message : 'fetched successfully';
        // const messageType = SNACKBAR_MSG_TYPE.success;
        // dispatch({
        //   type: T.SNACKBAR_SUCCESS,
        //   payload: {message, messageType},
        // });
        // dispatch(getToursList());

        return response.data;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.TOURS_ERROR, payload: error });
      });
  };
};

export const createTourStop = (hotelId, tourId) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.CREATE_TOUR_STOP(hotelId, tourId), {})
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        // dispatch(getToursList());
        return response.data;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.TOURS_ERROR, payload: error });
      });
  };
};

export const duplicateTourStop = (data) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.DUP_TOUR_STOP(), { data })
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        // dispatch(getToursList());
        return response.data;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.TOURS_ERROR, payload: error });
      });
  };
};

export const updateTourStop = (id, data) => {
  // console.log('data', data);
  // return;
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(
      api.UPDATE_TOUR_STOP(id, data.tourId, data.hotelId, data.stop.id),
      {
        data,
      }
    )
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        // dispatch(getToursList());
        return response.data;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.TOURS_ERROR, payload: error });
      });
  };
};

export const deleteTourStop = (id) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.DELETE_TOUR_STOP(id)).then(
      (response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        // dispatch(getToursList());
        return true;
      },
      (error) => {
        dispatch({ type: T.TOURS_ERROR, payload: error });
        return false;
      }
    );
  };
};

export const tourSettingAdmin = (type) => {
  return (dispatch) => {
    return API.execute(api.TOUR_SETTING(type)).then(
      (response) => {
        return response.data;
      },
      (error) => {
        dispatch({ type: T.TOURS_ERROR, payload: error });
        return error;
      }
    );
  };
};

export const addTourSettingAdminAdd = (startTime, endTime, type, zone) => {
  const data = {
    startTime,
    endTime,
    type,
    zone,
  };
  return (dispatch) => {
    return API.execute(api.ADD_TOUR_SETTING_ADMIN, { data }).then(
      (response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        return response.data;
      },
      (error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.TOURS_ERROR, payload: error });
      }
    );
  };
};

export const fetchTourSettingByRec = (type) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.FETCH_TOUR_SETTING_REC(type))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: T.TOURS_ERROR, payload: error });
      });
  };
};
