import _ from "lodash";

import * as T from "../ActionTypes";

const initState = {
  service: null,
  extraService: null,
  cityPrice: null,
  hotelPrice: null,
  airportTransfer: null,
  loading: false,
};

export default function AirportReducer(state = initState, action) {
  switch (action.type) {
    case T.CREATE_SERVICE: {
      let newServiceList = [...state.service];
      newServiceList.push(action.payload);
      return { ...state, service: newServiceList, loading: false };
    }
    case T.UPDATE_SERVICE: {
      const index = _.findIndex(state.service, (item) => item.id === action.id);

      const newArray = [...state.service];
      newArray[index] = action.payload;
      return {
        ...state,
        service: newArray,
        loading: false,
      };
    }

    case T.GET_SERVICE_LIST: {
      return { ...state, service: action.payload, loading: false };
    }

    case T.DELETE_SERVICE: {
      const serviceArray = _.filter(state.service, (o) => {
        return o.id !== action.id;
      });
      return { ...state, service: serviceArray, loading: false };
    }

    case T.GET_CITY_PRICE_LIST: {
      return { ...state, cityPrice: action.payload, loading: false };
    }

    case T.CREATE_CITY_PRICE: {
      const { cityId, price, nightPrice, service, response } = action.payload;

      const cityIndex = state.cityPrice.findIndex((item) => item.id === cityId);

      const serviceData = state.service.find((item) => item.id === service);

      const newCityPrice = {
        id: response.id, // Generate a unique ID (you may use a different approach)
        price,
        nightPrice,
        cityId,
        service: {
          id: serviceData.id,
          min: serviceData.min,
          max: serviceData.max,
          title: serviceData.title,
          createdBy: serviceData.createdBy,
          createdAt: serviceData.createdAt,
        },
      };

      const updatedCityPrice = [...state.cityPrice];
      updatedCityPrice[cityIndex].cityPrice.push(newCityPrice);

      return { ...state, cityPrice: updatedCityPrice, loading: false };
    }

    case T.UPDATE_CITY_PRICE: {
      const { cityId, price, nightPrice, serviceId, id } = action.payload;

      const serviceData = state.service.find((item) => item.id === serviceId);

      // Find the city in state.cityPrice array
      const updatedCityPrice = state.cityPrice.map((city) => {
        if (city.id === cityId) {
          // Find the city price object within the city's cityPrice array
          const updatedCityPrices = city.cityPrice.map((cityPrice) => {
            if (cityPrice.id === id) {
              // Update the price and service properties
              return {
                ...cityPrice,
                price,
                nightPrice,
                service: serviceData,
              };
            }
            return cityPrice;
          });

          // Return the updated city with the modified cityPrice array
          return {
            ...city,
            cityPrice: updatedCityPrices,
          };
        }
        return city;
      });

      return { ...state, cityPrice: updatedCityPrice, loading: false };
    }

    case T.DELETE_CITY_PRICE: {
      const indexCity = _.findIndex(
        state.cityPrice,
        (item) => item.id === action.payload.cityId
      );

      const newArray = [...state.cityPrice];
      newArray[indexCity].cityPrice = newArray[indexCity].cityPrice.filter(
        (o) => o.id !== action.payload.id
      );

      return { ...state, cityPrice: newArray, loading: false };
    }

    case T.GET_HOTEL_PRICE_LIST: {
      return { ...state, hotelPrice: action.payload, loading: false };
    }

    case T.CREATE_HOTEL_PRICE: {
      const { hotelId, price, nightPrice, service, response } = action.payload;

      const hotelIndex = state.hotelPrice.findIndex(
        (item) => item.id === hotelId
      );

      const serviceData = state.service.find((item) => item.id === service);

      const newHotelPrice = {
        id: response.id, // Generate a unique ID (you may use a different approach)
        price,
        nightPrice,
        hotelId,
        service: {
          id: serviceData.id,
          min: serviceData.min,
          max: serviceData.max,
          title: serviceData.title,
          createdBy: serviceData.createdBy,
          createdAt: serviceData.createdAt,
        },
      };

      const updatedHotelPrice = [...state.hotelPrice];
      updatedHotelPrice[hotelIndex].hotelPriceAt.push(newHotelPrice);

      return { ...state, hotelPrice: updatedHotelPrice, loading: false };
    }

    case T.DELETE_HOTEL_PRICE: {
      const hotelIndex = _.findIndex(
        state.hotelPrice,
        (item) => item.id === action.payload.hotelId
      );

      const newArray = [...state.hotelPrice];
      newArray[hotelIndex].hotelPriceAt = newArray[
        hotelIndex
      ].hotelPriceAt.filter((o) => o.id !== action.payload.id);

      return { ...state, hotelPrice: newArray, loading: false };
    }

    case T.UPDATE_HOTEL_PRICE: {
      const { hotelId, price, nightPrice, serviceId, id } = action.payload;

      const serviceData = state.service.find((item) => item.id === serviceId);

      // Find the hotel in state.hotelPrice array
      const updatedHotelPrice = state.hotelPrice.map((hotel) => {
        if (hotel.id === hotelId) {
          // Find the hotel price object within the hotel's hotelPrice array
          const updatedHotelPrice = hotel.hotelPriceAt.map((hotelPriceAt) => {
            if (hotelPriceAt.id === id) {
              // Update the price and service properties
              return {
                ...hotelPriceAt,
                price,
                nightPrice,
                service: serviceData,
              };
            }
            return hotelPriceAt;
          });

          // Return the updated hotel with the modified hotelPrice array
          return {
            ...hotel,
            hotelPriceAt: updatedHotelPrice,
          };
        }
        return hotel;
      });

      return { ...state, hotelPrice: updatedHotelPrice, loading: false };
    }

    case T.GET_EXTRA_SERVICE_LIST: {
      return { ...state, extraService: action.payload, loading: false };
    }

    case T.CREATE_EXTRA_SERVICE: {
      let newExtraServiceList = [...state.extraService];
      newExtraServiceList.push(action.payload);
      return { ...state, extraService: newExtraServiceList, loading: false };
    }
    case T.UPDATE_EXTRA_SERVICE: {
      const index = _.findIndex(state.extraService, (item) => item.id === action.id);

      const newArray = [...state.extraService];
      newArray[index] = action.payload;
      return {
        ...state,
        extraService: newArray,
        loading: false,
      };
    }

    case T.DELETE_EXTRA_SERVICE: {
      const extraServiceArray = _.filter(state.extraService, (o) => {
        return o.id !== action.id;
      });
      return { ...state, extraService: extraServiceArray, loading: false };
    }

    case T.GET_AIRPORT_TRANSFER_LIST: {
      return { ...state, airportTransfer: action.payload, loading: false };
    }

    case T.AIRPORT_TRANSFER_LOADING: {
      return { ...state, loading: true };
    }

    case T.AIRPORT_TRANSFER_ERROR: {
      return { ...state, loading: false };
    }

    case T.GENERATE_AIRPORT_TRANSFER_RECEIPT: {
      return {...state, loading: false};
    }

    default:
      return state;
  }
}
