import React from 'react';
// import {Button} from 'antd';
import {Wrapper, Title, Icon} from './NoBoardFounds.style';
import emptyProjectPlaceHolder from '@iso/assets/images/icon/12.svg';
export default function NoBoardFounds({history, match}) {
  return (
    <Wrapper>
      <Icon src={emptyProjectPlaceHolder} />
      <Title>You Currently have no tour bookings</Title>
      {/* <Button type="primary" onClick={() => history.push(`createBusStop`)}>
        Create Bus Stop
      </Button> */}
    </Wrapper>
  );
}
