import React from "react";
import { connect } from "react-redux";
import { Form } from "formik";
import Input from "@iso/components/uielements/input";
import { Button } from "antd";
import SignUpStyleWrapper from "../../pages/admin/airportTransfer/service/Board/BoardList/BoardList.style";

const JobForm = ({
  values,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  handleChange,
  submitForm,
  buttonTitle,
  ...props
}) => {
  handleChange = (e) => {
    setFieldValue(e.target.name, e.target.value);
  };

  return (
    <SignUpStyleWrapper className="isoSignUpPage">
      <div className="isoSignUpContentWrapper">
        <div className="isoSignUpContent">
          <Form className="form-fields">
            <div className="isoSignUpForm">
              <div className="isoInputWrapper">
                <label className="label-style">Min</label>
                <Input
                  size="large"
                  type="number"
                  name="min"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.min}
                />
                {errors.min && touched.min && (
                  <div className="invalid-input">{errors.min}</div>
                )}
              </div>
              <div className="isoInputWrapper">
                <label className="label-style">Max</label>
                <Input
                  size="large"
                  type="number"
                  name="max"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.max}
                />
                {errors.max && touched.max && (
                  <div className="invalid-input">{errors.max}</div>
                )}
              </div>
              <div className="isoInputWrapper">
                <label className="label-style">Title</label>
                <Input
                  size="large"
                  type="text"
                  name="title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                />
                {errors.title && touched.title && (
                  <div className="invalid-input">{errors.title}</div>
                )}
              </div>
              <div className="isoInputWrapper">
                <label className="label-style">Email</label>
                <Input
                  size="large"
                  type="text"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                {errors.email && touched.email && (
                  <div className="invalid-input">{errors.email}</div>
                )}
              </div>
              <div className="isoInputWrapper">
                <label className="label-style">Phone# (For Emergencies)</label>
                <Input
                  size="large"
                  type="text"
                  name="phoneNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phoneNumber}
                />
                {errors.phoneNumber && touched.phoneNumber && (
                  <div className="invalid-input">{errors.phoneNumber}</div>
                )}
              </div>
              <div className="isoInputWrapper">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    margin: "10px",
                  }}
                >
                  <Button type="primary" onClick={submitForm}>
                    {buttonTitle}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </SignUpStyleWrapper>
  );
};

// const mapStateToProps = (state) => {
//   return {
//     citiesList: state.dashboard.citiesList,
//   };
// };

export default connect(null, {})(JobForm);
