import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";

import Button from "@iso/components/uielements/button";
import {
  getvoucherPriceAT,
  voucherPriceATAdd,
} from "../../../../redux/setting/actions";
import { Radio, RadioGroup } from "@material-ui/core";
import { Checkbox } from "antd";

const TransfersVoucher = () => {
  const dispatch = useDispatch();

//   const [disable, setDisable] = useState(true);
  const [status, setStatus] = useState(0);

  useEffect(() => {
    dispatch(getvoucherPriceAT()).then((res) => {
      setStatus((res && res.priceStatus && 1) || 0);
    });
  }, []);

  const handleSubmit = (values) => {
    // setDisable(true);
    dispatch(voucherPriceATAdd(values.status))
    //   .then((res) => {
    //     setDisable(true);
    //   })
    //   .catch((err) => {
    //     setDisable(false);
    //   });
  };

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  return (
    <div>
      <Formik
        initialValues={{
          status: (status && status) || 0,
        }}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form onSubmit={handleSubmit} style={{ margin: "10px" }}>
              <div className="isoInputWrapper">
                <Checkbox
                  style={{ margin: "0" }}
                  name="status"
                  checked={values.status}
                  onChange={(e) => {
                    setStatus((e.target.checked && 1) || 0);
                  }}
                >
                </Checkbox>
                <label className="label-style">Hide Airport Transfers Voucher Pricing</label>

              </div>
              <Button
                style={{ margin: "10px 0" }}
                type="primary"
                onClick={handleSubmit}
                // disabled={disable}
              >
                Save
              </Button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default TransfersVoucher;
