/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Spin, Space, Table, Popconfirm, Popover, Tag } from "antd";
import { IconSvg } from "@iso/components/ScrumBoard/IconSvg/IconSvg";
import { MoreActionWrapper } from "./BoardListCard/BoardListCard.style.js";
import MoreIcon from "@iso/assets/images/icon/16.svg";

import NoBoardFounds from "../BoardNotFound/BoardNotFound";
import AppLayout from "../../AppLayout/AppLayout";

import {
  cancelRequestBooking,
  generateAirportTransferReceipt,
} from "../../../../../redux/airportTransfer/actions.js";
import CityPriceModal from "../../../../../components/Modal/CityPriceModel";
import Lists from "../../../../../containers/Lists/Lists";
import { StatusTag } from "../../../../../containers/Lists/Lists.styles";
import { getAirportTransferList } from "@iso/redux/airportTransfer/actions";
import ColumnGroup from "antd/lib/table/ColumnGroup.js";
import Column from "antd/lib/table/Column.js";
import AirportTransferCancellation from "../../../../../components/Modal/AirportTransfersCancellation.js";

function BoardLists({ history, match }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [dateTour, setDate] = useState("");
  const [dateFilter, setDateFilter] = React.useState("");
  const [search, setSearch] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tourSelectedData, setTourSelectedData] = useState(null);
  useEffect(() => {
    dispatch(getAirportTransferList(dateTour, dateFilter, pagination)).then((res)=>{
      setPagination({
        ...pagination,
        total: (res.booking && res.booking.pagination && res.booking.pagination.total) || 0,
      });
    });
  }, [dateTour, dateFilter, pagination.current]);

  const handlePaginationChange = (page) => {
    setPagination({
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
    });
  };

  let aTList = useSelector((state) => {
    return state.AirportTransfer.airportTransfer;
  });

  console.log('aTList', aTList)

  let Loading = useSelector((state) => {
    return state.AirportTransfer.loading;
  });

  const handleReceipt = (at) => {
    dispatch(generateAirportTransferReceipt(at.id));
  };

  const showModal = (data) => {
    setTourSelectedData(data);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    // dispatch(deleteVehicle(selectedModalBoard.id)).then((res) => {
    //   setIsModalVisible(false);
    // });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getRowClassName = (record) => {
    return record.status === 1 ? "pink-row" : "";
  };

  const columns = [
    {
      title: "Action",
      dataIndex: "id",
      rowKey: "id",
      fixed: "left",
      render: (text, data) => (
        <div className="isoInvoiceBtnView">
          <Popover
            placement="bottom"
            content={
              <MoreActionWrapper>
                {Loading ? (
                  <Spin />
                ) : (
                  <p onClick={() => handleReceipt(data)}>Generate Receipt</p>
                )}
                {/*{<p onClick={() => onDuplicate(data)}>Duplicate</p>} */}
                {
                  <p
                    onClick={() => {
                      showModal(data);
                    }}
                  >
                    Cancel Request
                  </p>
                }
              </MoreActionWrapper>
            }
            trigger="click"
          >
            <IconSvg src={MoreIcon} border={"none"} padding={"0"} />
          </Popover>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      rowKey: "status",
      width: 6,
      render: (text) => (
        <p>
          {(text === 0 && (
            <Tag color={"geekblue"} key={text}>
              Active
            </Tag>
          )) || (
            <Tag color={"volcano"} key={text}>
              Cancelled
            </Tag>
          )}
        </p>
      ),
    },
    {
      title: "Res#",
      dataIndex: "id",
      rowKey: "id",
    },
    {
      title: "Direction",
      dataIndex: "direction",
      rowKey: "direction",
      render: (text) => (
        <p>
          {(text === 1 && (
            <StatusTag
              style={{
                backgroundColor: "#7ED320",
                padding: "10px 6px",
                borderRadius: "3px",
              }}
            >
              Hotel To Airport
            </StatusTag>
          )) || (
            <StatusTag
              style={{
                backgroundColor: "#FEAC01",
                padding: "10px 6px",
                borderRadius: "3px",
              }}
            >
              Airport To Hotel
            </StatusTag>
          )}
        </p>
      ),
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      rowKey: "clientName",
    },
    {
      title: "Client Email",
      dataIndex: "clientEmail",
      rowKey: "clientEmail",
    },
    {
      title: "Client Phone",
      dataIndex: "clientPhone",
      rowKey: "clientPhone",
    },
    {
      title: "Pickup Time",
      dataIndex: "pickUpTime",
      rowKey: "pickUpTime",
      render: (text) => <p> {moment(text, "HH:mm:ss").format("HH:mm")}</p>,
    },
    {
      title: "Date",
      dataIndex: "pickUpDate",
      rowKey: "pickUpDate",
      render: (text) => <p> {moment(text).format("DD/MM/YYYY")}</p>,
    },
    {
      title: "Flight Number",
      dataIndex: "flightNo",
      rowKey: "flightNo",
      render: (text) => <p> {text || '-'}</p>,
    },

    {
      title: "People",
      dataIndex: "noOfPeople",
      rowKey: "noOfPeople",
      render: (text) => <p> {text || 0}</p>,
    },
    {
      title: "Min - Max",
      dataIndex: "id",
      rowKey: "id",
      render: (text, data) => (
        <p>
          {" "}
          {data.min} - {data.max}
        </p>
      ),
    },
    {
      title: "Service Title",
      dataIndex: "id",
      rowKey: "id",
      render: (text, data) => (
        <p>
          {" "}
          {data.serviceTitle}
        </p>
      ),
    },
    {
      title: "Total Price",
      dataIndex: "totalPrice",
      rowKey: "totalPrice",
      render: (text) => <p> €{text}</p>,
    },
    {
      title: 'Exta Equipment',
          children: [
            {
              title: 'Baby Seat',
              dataIndex: 'babySeats',
              key: 'babySeats',
            },
            {
              title: 'Child Seat',
              dataIndex: 'childBSeats',
              key: 'childBSeats',
            },
            {
              title: 'Golf Equipment',
              dataIndex: 'golfEquipments',
              key: 'golfEquipments',
            },
            {
              title: 'Special Equipment',
              dataIndex: 'specialEquipments',
              key: 'specialEquipments',
            },
          ],
    },
    {
      title: "Airport",
      dataIndex: "airport",
      rowKey: "airport",
      render: (text) => <p> {text.name}</p>,
    },
    {
      title: "No of Luggage",
      dataIndex: "noOfLuggage",
      rowKey: "noOfLuggage",
    },
    {
      title: "Platform",
      dataIndex: "platform",
      rowKey: "platform",
      width: "10%",
      render: (text) => (
        <p>
          {(text === "web" && (
            <Tag color={"purple"} key={text}>
              WEB
            </Tag>
          )) || (
            <Tag color={"cyan"} key={text}>
              APP
            </Tag>
          )}
        </p>
      ),
    },


  ];

  const handleDateRange = (data) => {
    setDateFilter(data);
    handlePaginationChange({current: 1, pageSize: 10})
  };

  if (search) {
    let searched = aTList.filter((i) => {
      const clientNameMatch = i.clientName
        .toLowerCase()
        .includes(search.toLowerCase());
      const clientEmailMatch = i.clientEmail
        .toLowerCase()
        .includes(search.toLowerCase());
        const clientPhoneMatch = i.clientPhone
        .includes(search);
        const flightNoMatch = i.flightNo
        .toLowerCase()
        .includes(search.toLowerCase());

      return clientNameMatch || clientEmailMatch || clientPhoneMatch || flightNoMatch;
    });
    aTList = searched;
  }

  return (
    <>
      <AppLayout
        dateFilter={(value) => {
          setDate(value);
          handlePaginationChange({current: 1, pageSize: 10})
        }}
        setSearchText={(value) => {
          setSearch(value);
          handlePaginationChange({current: 1, pageSize: 10})
        }}
        handleDateRange={handleDateRange}
        history={history}
        match={match}
      >
        {Loading ? (
          <div className="spinner">
            <Spin />
          </div>
        ) : (
          <>
            {!isEmpty(aTList) ? (
              <Lists
                title={"Airport Transfers"}
                Columns={columns}
                Rows={aTList}
                rowClassName={getRowClassName}
                pagination={{
                  current: pagination.current,
                  pageSize: pagination.pageSize,
                  total: pagination.total,
                }}
                handleTableChange={handlePaginationChange}
              />
            ) : (
              <NoBoardFounds history={history} match={match} />
            )}
          </>
        )}
      </AppLayout>
      {isModalVisible ? (
        <AirportTransferCancellation
          isModalVisible={isModalVisible}
          handleCancel={handleCancel}
          title={"Cancellation Request"}
          buttonTitle="Save"
          cancellationData={tourSelectedData}
        />
      ) : null}
    </>
  );
}

export default BoardLists;
