import React, { Component } from "react";
import LayoutContentWrapper from "@iso/components/utility/layoutWrapper";
import LayoutContent from "@iso/components/utility/layoutContent";
import PageHeader from "@iso/components/utility/pageHeader";

const index = () => {
  return (
    <LayoutContentWrapper>
      <PageHeader>
        <h1>PRIVACY POLICY</h1>
      </PageHeader>
      <LayoutContent>
        <div style={{ margin: "20px 10px" }}>
          <h1>1.- IDENTIFICATION OF THE RESPONSIBLE FOR THE FILE</h1>
          <h3 style={{ margin: "10px 0" }}>
            BESPOKE GUIDED TOURS SL, with VAT number : B-93735546 and with
            andalusian accreditation C.I.AN-297469-3, informs the user and
            client of its website of the existence of an automated personal data
            file, whose manager is BESPOKE GUIDED TOURS SL, with address at
            Avenida Pablo Picasso 1 Local E Bajo. Urb. Cortijo Blanco San Pedro
            Alcántara, Marbella (Málaga, Spain), where the personal data that
            the user and the client communicate to BESPOKE GUIDED TOURS SL are
            collected and stored.
          </h3>
        </div>
        <div style={{ margin: "20px 10px" }}>
          <h1>2.- UPDATE OF THE POLICIES</h1>
          <h3 style={{ margin: "10px 0" }}>
            BESPOKE GUIDED TOURS S.L.will modify, without prior notice, this
            privacy policy whenever it is necessary to adapt it to any
            legislative, regulatory, jurisprudential, administrative change or
            with the purpose of adapting said policy to the instructions issued
            by the Data Protection Agency or object legitimate of BESPOKE GUIDED
            TOURS SL. Any modification of this policy, notwithstanding the
            foregoing, will be published and warned on the website of BESPOKE
            GUIDED TOURS S.L. and in policy itself. For all the above, BESPOKE
            GUIDED TOURS S.L. recommends that users periodically read these
            policies in order to know the changes that are made to them.
          </h3>
        </div>
        <div style={{ margin: "20px 10px" }}>
          <h1>3.- PURPOSE OF THE FILE</h1>
          <h3 style={{ margin: "10px 0" }}>
            BESPOKE GUIDED TOURS S.L. does not request data from users who visit
            it on its website, therefore, the communication of personal data by
            the user to BESPOKE GUIDED TOURS S.L. through its website can only
            take place when they voluntarily use the request a quote form to
            contact MARKETING DESARROLLO Y EXPANSIÓN S.L., given that in these
            cases the treatment of the data is inevitable and implicit in the
            contracting system. For these cases and those described in the
            following section, the entity informs the client that the data is
            processed for the following purposes: Carry out all the procedures
            related to the preparation of budgets, contracting and provision of
            BESPOKE GUIDED TOURS SL services, to the company to which it belongs
            or, where appropriate, to the interested party who requests it. As
            well as attending and answering the communications received and the
            maintenance of commercial relationship histories.
          </h3>
        </div>
        <div style={{ margin: "20px 10px" }}>
          <h1>4.- CONSENT</h1>
          <h3 style={{ margin: "10px 0" }}>
            BESPOKE GUIDED TOURS S.L. informs that this privacy policy contains
            all aspects related to the processing of personal data that BESPOKE
            GUIDED TOURS S.L. carried out as responsible for it. Thus, it is
            reported that when the user does not maintain commercial relations
            with BESPOKE GUIDED TOURS SL, and sends an email or a communication
            to BESPOKE GUIDED TOURS SL, indicating other personal data, said
            user will be giving their free, unequivocal consent , specific,
            informed and express for the treatment of your personal data by
            BESPOKE GUIDED TOURS SL, with the purposes established above, as
            well as attend to your communication or send documentation.
          </h3>
          <h3 style={{ margin: "10px 0" }}>
            For the same purposes, BESPOKE GUIDED TOURS S.L. informs that, if
            the client sends an email or communicates to BESPOKE GUIDED TOURS
            S.L. your personal data by reason of the position you occupy in a
            company, either as administrator, manager, representative and / or
            any other position as contact person in the company, it will be
            understood that such communication involves the provision of your
            free, unequivocal, specific, informed and express consent for the
            treatment of your personal data by BESPOKE GUIDED TOURS SL, with the
            purposes established above, as well as attend to your communication
            or send documentation.
          </h3>
        </div>
        <div style={{ margin: "20px 10px" }}>
          <h1>
            5.- IDENTIFICATION OF THE RECIPIENTS REGARDING THOSE WHO BESPOKE
            GUIDED TOURS S.L.ALLOW FOR THE MAKING OF ASSIGNMENTS OR ACCESS TO
            DATA ON THE ACCOUNT OF THIRD PARTIES
          </h1>
          <h3 style={{ margin: "10px 0" }}>
            BESPOKE GUIDED TOURS S.L. only plans to carry out assignments or
            communications of data that by reason of the European Data
            Protection Regulation 2016/679 of April 27, 2016 must be carried out
            to meet its obligations with Public Administrations in cases that
            are required in accordance with Legislation in force in each matter
            at all times and, where appropriate, equally, to other bodies such
            as Judges, Public Prosecutor, Courts, Court of Accounts or
            Ombudsman.
          </h3>
          <h3 style={{ margin: "10px 0" }}>
            Likewise, BESPOKE GUIDED TOURS S.L. informs the user that any other
            transfer of data that must be made, will be brought to their
            knowledge when provided by the RGPD, informing them expressly,
            precisely and unequivocally of the recipients of the information, of
            the purpose for which they will be used, the data, and the nature of
            the data transferred, or where appropriate, when the RGPD
            establishes it, the specific and informed consent of the user will
            be previously requested
          </h3>
          <h3 style={{ margin: "10px 0" }}>
            Likewise, BESPOKE GUIDED TOURS S.L.is not responsible for the data
            processing carried out by third parties that establish hyperlinks
            with BESPOKE GUIDED TOURS S.L. nor of those responsible for whom
            through hyperlinks BESPOKE GUIDED TOURS S.L. refers your visitors.
          </h3>
        </div>
        <div style={{ margin: "20px 10px" }}>
          <h1>6.- DATA QUALITY</h1>
          <h3 style={{ margin: "10px 0" }}>
            BESPOKE GUIDED TOURS S.L. warns the user that, except for the
            existence of a legally constituted representation, no user can use
            the identity of another person and communicate their personal data,
            so the user must at all times bear in mind that they can only
            include personal data corresponding to your own identity and that
            they are adequate, relevant, current, accurate and true. For these
            purposes, the user will be solely responsible for any direct and /
            or indirect damage caused to third parties or to BESPOKE GUIDED
            TOURS SL, for the use of personal data of another person, or their
            own personal data when they are false, erroneous, not current,
            inappropriate or impertinent. Likewise, the user who uses the
            personal data of a third party, will respond to the latter for the
            information obligation established in article 5.4 of the RGPD for
            when the personal data has not been collected from the interested
            party, and / or the consequences of not having informed him.
          </h3>
        </div>
        <div style={{ margin: "20px 10px" }}>
          <h1>
            7.- EXERCISE OF THE RIGHTS OF ACCESS, OPPOSITION, RECTIFICATION,
            LIMITATION OF TREATMENT, PORTABILITY AND CANCELLATION OF DATA
          </h1>
          <h3 style={{ margin: "10px 0" }}>
            BESPOKE GUIDED TOURS S.L. informs the user of the possibility of
            exercising their rights of access, rectification, cancellation,
            limitation of treatment, portability and opposition by email to
            info@bgt-travel.com To exercise their rights, you must send us the
            duly completed form that we will provide you, attaching a photocopy
            of your ID signed by the interested party.
          </h3>
        </div>
        <div style={{ margin: "20px 10px" }}>
          <h1>
            8.- USE OF FORMS FOR THE COLLECTION OF PERSONAL DATA BY MARKETING
            DESARROLLO Y EXPANSIÓN S.L.
          </h1>
          <h3 style={{ margin: "10px 0" }}>
            BESPOKE GUIDED TOURS S.L. complies with the guidelines of the
            European Data Protection Regulation 2016/679 of April 27, 2016 and
            other regulations in force at all times, and ensures the correct use
            and treatment of the user’s personal data. We inform you that you
            can exercise your rights of access, rectification, deletion,
            limitation of treatment, portability or opposition, by email to
            info@bgt-travel.com. The purpose of the treatment will be to contact
            users to provide information on the services offered or, in the case
            of the “Work with us” form, to include the data you provide us in
            our personnel selection processes. Through this clause we inform you
            that you can make any type of claim before the control authority,
            the Spanish Agency for Data Protection. In addition.
          </h3>
        </div>
        <div style={{ margin: "20px 10px" }}>
          <h1>
            9.- SECURITY MEASURES ADOPTED IN RELATION TO THE PROCESSING OF
            PERSONAL DATA
          </h1>
          <h3 style={{ margin: "10px 0" }}>
            BESPOKE GUIDED TOURS S.L. informs the user that, in accordance with
            the provisions of the RGPD and the Development Regulation, it has
            adopted the technical and organizational measures necessary to
            guarantee the security of personal data and avoid the alteration,
            loss, treatment or access not authorized, taking into account the
            state of technology, the nature of the stored data and the risks to
            which they are exposed. Likewise, BESPOKE GUIDED TOURS S.L.
            guarantees the user compliance with the duty of professional secrecy
            regarding the personal data of users and the duty to keep them.
          </h3>
          <h3 style={{ margin: "10px 0" }}>
            BESPOKE GUIDED TOURS S.L. informs users of the website that security
            tools are installed on its website, including antivirus,
            antispyware, and antispam
          </h3>
        </div>
        <div style={{ margin: "20px 10px" }}>
          <h1>10.- COOKIES AND IPS</h1>
          <h3 style={{ margin: "10px 0" }}>
            This website informs that cookies are used. BESPOKE GUIDED TOURS
            S.L. does not use spamming techniques.
          </h3>
        </div>
        <div style={{ margin: "20px 10px" }}>
          <h1>11.- DURATION OF TREATMENT</h1>
          <h3 style={{ margin: "10px 0" }}>
            The treatment will be carried out for the duration of the provision
            of the service, the customer service process, the human resource
            selection processes and as long as it is necessary for compliance
            with legal and tax requirements.
          </h3>
        </div>
        <div style={{ margin: "20px 10px" }}>
          <h1>12.- CONTROL ENTITY</h1>
          <h3 style={{ margin: "10px 0" }}>
            BESPOKE GUIDED TOURS S.L. informs you that you can make any type of
            claim regarding the processing of your personal data before the
            control entity, the Spanish Agency for Data Protection through its
            website.
          </h3>
        </div>
      </LayoutContent>
    </LayoutContentWrapper>
  );
};

export default index;
