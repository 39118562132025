import React, {useState} from 'react';
import isEmpty from 'lodash/isEmpty';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Modal, Spin} from 'antd';

import NoBoardFounds from '../BoardNotFound/BoardNotFound';
import BoardListCard from './BoardListCard/BoardListCard';
import AppLayout from '../../AppLayout/AppLayout';
import {Table} from './BoardList.style';
import {deleteVehicle} from '@iso/redux/vehicle/actions';

function BoardLists({history, match}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedModalBoard, setSelectedModalBoard] = useState(null);

  const showModal = (vehicle) => {
    setSelectedModalBoard(vehicle);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    dispatch(deleteVehicle(selectedModalBoard.id)).then((res) => {
      setIsModalVisible(false);
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleEdit = (vehicle) => {
    navigate(`/dashboard/createVehicle/${vehicle.id}`, {state: {vehicle}});
  };

  const vehiclesList = useSelector((state) => {
    return state.Vehicle.vehicles;
  });
  let Loading = useSelector((state) => {
    return state.Vehicle.loading;
  });

  return (
    <>
      <AppLayout history={history} match={match}>
        {Loading ? (
          <div className="spinner">
            <Spin />
          </div>
        ) : (
          <>
            {!isEmpty(vehiclesList) ? (
              <Table>
                {vehiclesList.map((board) => {
                  return (
                    <BoardListCard
                      key={board.id}
                      item={board}
                      onDelete={() => {
                        showModal(board);
                      }}
                      onEdit={() => handleEdit(board)}
                    />
                  );
                })}
              </Table>
            ) : (
              <NoBoardFounds history={history} match={match} />
            )}
          </>
        )}
      </AppLayout>
      <Modal title="Delete Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>Are you sure you want to delete this vehicle?</p>
      </Modal>
    </>
  );
}

export default BoardLists;
