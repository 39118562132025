import * as api from "../../constants/Api";
import { API } from "../../services";
import moment from 'moment';
import * as T from "../ActionTypes";
import { SNACKBAR_MSG_TYPE, SNACKBAR_MSG } from "../../constants";

export const createService = (data) => {
  return (dispatch) => {
    dispatch({ type: T.AIRPORT_TRANSFER_LOADING });
    return API.execute(api.CREATE_SERVICE, { data })
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        dispatch({ type: T.CREATE_SERVICE, payload: response.data });
        return true;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.AIRPORT_TRANSFER_ERROR, payload: error });
      });
  };
};

export const getServiceList = () => {
  return (dispatch) => {
    dispatch({ type: T.AIRPORT_TRANSFER_LOADING });
    return API.execute(api.GET_SERVICE_LIST).then(
      (response) => {
        dispatch({ type: T.GET_SERVICE_LIST, payload: response.data });
        let arr = [];
        response.data.forEach((item) => {
          arr.push({
            value: item.id,
            label: `${item.min} - ${item.max} / ${item.title}`,
          });
        });
        return arr;
      },
      (error) => {
        dispatch({ type: T.AIRPORT_TRANSFER_ERROR, payload: error });
        return error;
      }
    );
  };
};

export const updateService = (data) => {
  let newData = { ...data };
  let id = newData.id;
  delete data.id;

  return (dispatch) => {
    dispatch({ type: T.AIRPORT_TRANSFER_LOADING });
    return API.execute(api.UPDATE_SERVICE(id), { data })
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        dispatch({ type: T.UPDATE_SERVICE, payload: response.data, id });
        return true;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });

        dispatch({ type: T.AIRPORT_TRANSFER_ERROR, payload: error });
      });
  };
};

export const deleteService = (id) => {
  return (dispatch) => {
    dispatch({ type: T.AIRPORT_TRANSFER_LOADING });
    return API.execute(api.DELETE_SERVICE(id))
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        dispatch({ type: T.DELETE_SERVICE, id });
        return true;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });

        dispatch({ type: T.AIRPORT_TRANSFER_ERROR, payload: error });
      });
  };
};

export const createCityPrice = (data) => {
  return (dispatch) => {
    dispatch({ type: T.AIRPORT_TRANSFER_LOADING });
    return API.execute(api.CREATE_CITY_PRICE, { data })
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        dispatch({
          type: T.CREATE_CITY_PRICE,
          payload: {
            cityId: data.cityId,
            price: data.price,
            nightPrice: data.nightPrice,
            service: data.serviceId,
            response: response.data,
          },
        });
        return response.data;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.AIRPORT_TRANSFER_ERROR, payload: error });
      });
  };
};

export const updateCityPrice = (data) => {
  let newData = { ...data };
  let id = newData.id;
  delete data.id;

  return (dispatch) => {
    dispatch({ type: T.AIRPORT_TRANSFER_LOADING });
    return API.execute(api.UPDATE_CITY_PRICE(id), { data })
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        dispatch({
          type: T.UPDATE_CITY_PRICE,
          payload: {
            cityId: data.cityId,
            price: data.price,
            nightPrice: data.nightPrice,
            serviceId: data.serviceId,
            id,
            response: response.data,
          },
        });
        return response.data;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });

        dispatch({ type: T.AIRPORT_TRANSFER_ERROR, payload: error });
      });
  };
};

export const getCityPriceList = () => {
  return (dispatch) => {
    dispatch({ type: T.AIRPORT_TRANSFER_LOADING });
    return API.execute(api.GET_CITY_PRICE_LIST).then(
      (response) => {
        dispatch({ type: T.GET_CITY_PRICE_LIST, payload: response.data });
        return response.data;
      },
      (error) => {
        dispatch({ type: T.AIRPORT_TRANSFER_ERROR, payload: error });
        return error;
      }
    );
  };
};

export const deleteCityPrice = (id, cityId) => {
  return (dispatch) => {
    dispatch({ type: T.AIRPORT_TRANSFER_LOADING });
    return API.execute(api.DELETE_CITY_PRICE(id))
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        dispatch({ type: T.DELETE_CITY_PRICE, payload: { id, cityId } });
        return true;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });

        dispatch({ type: T.AIRPORT_TRANSFER_ERROR, payload: error });
      });
  };
};

export const getHotelPriceList = () => {
  return (dispatch) => {
    dispatch({ type: T.AIRPORT_TRANSFER_LOADING });
    return API.execute(api.GET_AIRPORT_HOTEL_PRICE_LIST).then(
      (response) => {
        dispatch({ type: T.GET_HOTEL_PRICE_LIST, payload: response.data });
        return response.data;
      },
      (error) => {
        dispatch({ type: T.AIRPORT_TRANSFER_ERROR, payload: error });
        return error;
      }
    );
  };
};

export const createHotelPrice = (data) => {
  return (dispatch) => {
    dispatch({ type: T.AIRPORT_TRANSFER_LOADING });
    return API.execute(api.CREATE_AIRPORT_HOTEL_PRICE, { data })
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        dispatch({
          type: T.CREATE_HOTEL_PRICE,
          payload: {
            hotelId: data.hotelId,
            price: data.price,
            nightPrice: data.nightPrice,
            service: data.serviceId,
            response: response.data,
          },
        });
        return response.data;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.AIRPORT_TRANSFER_ERROR, payload: error });
      });
  };
};

export const updateHotelPrice = (data) => {
  let newData = { ...data };
  let id = newData.id;
  delete data.id;

  return (dispatch) => {
    dispatch({ type: T.AIRPORT_TRANSFER_LOADING });
    return API.execute(api.UPDATE_AIRPORT_HOTEL_PRICE(id), { data })
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        dispatch({
          type: T.UPDATE_HOTEL_PRICE,
          payload: {
            hotelId: data.hotelId,
            price: data.price,
            nightPrice: data.nightPrice,
            serviceId: data.serviceId,
            id,
            response: response.data,
          },
        });
        return response.data;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });

        dispatch({ type: T.AIRPORT_TRANSFER_ERROR, payload: error });
      });
  };
};

export const deleteHotelPrice = (id, hotelId) => {
  return (dispatch) => {
    dispatch({ type: T.AIRPORT_TRANSFER_LOADING });
    return API.execute(api.DELETE_AIRPORT_HOTEL_PRICE(id))
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        dispatch({ type: T.DELETE_HOTEL_PRICE, payload: { id, hotelId } });
        return true;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });

        dispatch({ type: T.AIRPORT_TRANSFER_ERROR, payload: error });
      });
  };
};

export const getExtraServiceList = () => {
  return (dispatch) => {
    dispatch({ type: T.AIRPORT_TRANSFER_LOADING });
    return API.execute(api.GET_EXTRA_SERVICE_LIST).then(
      (response) => {
        dispatch({ type: T.GET_EXTRA_SERVICE_LIST, payload: response.data });
        return response.data;
      },
      (error) => {
        dispatch({ type: T.AIRPORT_TRANSFER_ERROR, payload: error });
        return error;
      }
    );
  };
};

export const createExtraService = (data) => {
  return (dispatch) => {
    dispatch({ type: T.AIRPORT_TRANSFER_LOADING });
    return API.execute(api.CREATE_EXTRA_SERVICE, { data })
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        dispatch({ type: T.CREATE_EXTRA_SERVICE, payload: response.data });
        return true;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.AIRPORT_TRANSFER_ERROR, payload: error });
      });
  };
};

export const updateExtraService = (data) => {
  let newData = { ...data };
  let id = newData.id;
  delete data.id;

  return (dispatch) => {
    dispatch({ type: T.AIRPORT_TRANSFER_LOADING });
    return API.execute(api.UPDATE_EXTRA_SERVICE(id), { data })
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        dispatch({ type: T.UPDATE_EXTRA_SERVICE, payload: response.data, id });
        return true;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });

        dispatch({ type: T.AIRPORT_TRANSFER_ERROR, payload: error });
      });
  };
};

export const deleteExtraService = (id) => {
  return (dispatch) => {
    dispatch({ type: T.AIRPORT_TRANSFER_LOADING });
    return API.execute(api.DELETE_EXTRA_SERVICE(id))
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        dispatch({ type: T.DELETE_EXTRA_SERVICE, id });
        return true;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });

        dispatch({ type: T.AIRPORT_TRANSFER_ERROR, payload: error });
      });
  };
};

/* Receptionist */

export const getAirportTransferList = (date, dateRange, pagination) => {

  let startDate = "";
  let endDate = "";
  
  if(dateRange) {
    startDate = moment(dateRange[0]).format('YYYY-MM-DD');
    endDate = moment(dateRange[1]).format('YYYY-MM-DD')
  }

  const filter = {
    date,
    startDate,
    endDate,
  };

  return (dispatch) => {
    dispatch({ type: T.AIRPORT_TRANSFER_LOADING });
    return API.execute(api.GET_AIRPORT_TRANSFER_LIST, {
      data: { pagination, filter },
    }).then(
      (response) => {
        dispatch({ type: T.GET_AIRPORT_TRANSFER_LIST, payload: response.data });
        return {booking: response};
      },
      (error) => {
        dispatch({ type: T.AIRPORT_TRANSFER_ERROR, payload: error });
        return error;
      }
    );
  };
};

export const bookAirportTransfer = (data) => {
  return (dispatch) => {
    return API.execute(api.BOOK_AIRPORT_TRANSFER, { data })
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        return true;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.AIRPORT_TRANSFER_ERROR, payload: error });
      });
  };
};

export const getExtraServiceRec = () => {
  return (dispatch) => {
    dispatch({ type: T.AIRPORT_TRANSFER_LOADING });
    return API.execute(api.GET_EXTRA_SERVICE_REC).then(
      (response) => {
        return response.data;
      },
      (error) => {
        dispatch({ type: T.AIRPORT_TRANSFER_ERROR, payload: error });
        return error;
      }
    );
  };
};

export const getServicesListRec = () => {
  return (dispatch) => {
    dispatch({ type: T.AIRPORT_TRANSFER_LOADING });
    return API.execute(api.GET_SERVICE_LIST_REC).then(
      (response) => {
        let arr = [];
        response.data.forEach((item) => {
          arr.push({
            value: item.id,
            label: `${item.min} - ${item.max} / ${item.title}`,
            min: item.min,
            max: item.max,
            price: item.price,
            nightPrice: (item.nightPrice && item.nightPrice) || 0,
            title: item.title,
          });
        });
        return arr;
      },
      (error) => {
        dispatch({ type: T.AIRPORT_TRANSFER_ERROR, payload: error });
        return error;
      }
    );
  };
};

export const generateAirportTransferReceipt = (id) => {
  return (dispatch) => {
    dispatch({ type: T.AIRPORT_TRANSFER_LOADING });
    return API.execute(api.GENERATE_AIRPORT_TRANSFER_RECEIPT(id))
      .then((response) => {
        // var bufferArray = base64ToArrayBuffer(response.data);
        // var blobStore = new Blob([bufferArray], {type: 'application/pdf'});
        // const fileURL = URL.createObjectURL(blobStore);
        window.open(response.url, "_blank", "noreferrer");
        // pdfWindow.location.href = response.url;
        dispatch({ type: T.GENERATE_AIRPORT_TRANSFER_RECEIPT });
        return response.url;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.AIRPORT_TRANSFER_ERROR, payload: error });
      });
  };
};

export const cancelRequestBooking = (id) => {
  return (dispatch) => {
    return API.execute(api.CANCEL_REQUEST_BOOKING_AT(id), {})
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        return true;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.AIRPORT_TRANSFER_ERROR, payload: error });
      });
  };
};

export const cancelRequestAirportTransfers = (data) => {
  return (dispatch) => {
    return API.execute(api.CANCEL_BOOKING_AIRPORT_TRANSFER, { data })
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        return true;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.RECEPTIONIST_ERROR, payload: error });
      });
  };
};

export const getCancelBookings = () => {
  return (dispatch) => {
    dispatch({ type: T.AIRPORT_TRANSFER_LOADING });
    return API.execute(api.GET_CANCEL_BOOKING_LIST_AT).then(
      (response) => {
        dispatch({ type: T.GENERATE_AIRPORT_TRANSFER_RECEIPT });
        return response;
      },
      (error) => {
        dispatch({ type: T.AIRPORT_TRANSFER_ERROR, payload: error });
        return error;
      }
    );
  };
};

export const cancelBooking = (id) => {
  return (dispatch) => {
    dispatch({ type: T.AIRPORT_TRANSFER_LOADING });
    return API.execute(api.CANCEL_BOOKING_AT(id), {})
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        dispatch({ type: T.GENERATE_AIRPORT_TRANSFER_RECEIPT });
        return response.data;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.AIRPORT_TRANSFER_ERROR, payload: error });
      });
  };
};

export const deleteBooking = (id) => {
  return (dispatch) => {
    dispatch({ type: T.AIRPORT_TRANSFER_LOADING });
    return API.execute(api.DELETE_BOOKING_AT(id), {})
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        dispatch({ type: T.GENERATE_AIRPORT_TRANSFER_RECEIPT });
        return response.data;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.AIRPORT_TRANSFER_ERROR, payload: error });
      });
  };
};
