import _ from 'lodash';

import * as T from '../ActionTypes';

const initState = {admins: null, loading: false, loadTour: true, tour: null};

export default function adminsReducer(state = initState, action) {
  switch (action.type) {
    case T.CREATE_ADMIN: {
      let newAdminsList = state.admins ? [...state.admins] : [];
      newAdminsList.push(action.payload);
      return {...state, admins: newAdminsList, loading: false};
    }

    case T.GET_ADMIN_LIST: {
      return {...state, admins: action.payload, loading: false};
    }

    case T.GET_TOUR_LIST_ADMIN: {
      return {...state, tour: action.payload, loading: false};
    }

    case T.ACTIVATE_ADMIN: {
      const statusChangedIndex = _.findIndex(state.admins, (o) => {
        return o.id === action.id;
      });

      const newArray = [...state.admins];

      newArray[statusChangedIndex].status = 1;

      return {...state, admins: newArray, loading: false};
    }

    case T.DEACTIVATE_ADMIN: {
      const statusChangedIndex = _.findIndex(state.admins, (o) => {
        return o.id === action.id;
      });

      const newArray = [...state.admins];

      newArray[statusChangedIndex].status = 0;

      return {...state, admins: newArray, loading: false};
    }
    case T.ADMIN_LOADING: {
      return {...state, loading: true};
    }
    case T.SUPER_ADMIN_TOUR_LOADING: {
      return {...state, loadTour: action.load};
    }

    default:
      return state;
  }
}
