import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {Formik} from 'formik';

import Input from '@iso/components/uielements/input';
import Button from '@iso/components/uielements/button';
import IntlMessages from '@iso/components/utility/intlMessages';
import ResetPasswordStyleWrapper from './ResetPassword.styles';
import {resetPassword} from '@iso/redux/auth/actions';
import {changePassword} from '../../redux/common/actions';
import {resetPassValidation} from '../../constants';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const handleSubmit = (values) => {
    if(params.code) {
      dispatch(resetPassword(values)).then((res) => {
        navigate('/signin');
      })
    }
    else {
      try {
       dispatch(
          changePassword({
            oldPassword: values.token,
            newPassword: values.password,
            confirmPassword: values.confirmPassword,
          })
        );
        navigate('/dashboard');
      } catch (error) {
        // Handle error if necessary
        console.error(error);
      }
    }

  };

  let initialResetValue = {
    token: params.code ? params.code : '',
    password: '',
    confirmPassword: '',
  };

  return (
    <ResetPasswordStyleWrapper className="isoResetPassPage">
      <div className="isoFormContentWrapper">
        <div className="isoFormContent">
          <div className="isoLogoWrapper">
            <IntlMessages id="page.resetPassTitle" />
          </div>

          <div className="isoFormHeadText">
            <h3>
              <IntlMessages id={params.code ? 'page.resetPassSubTitle' : 'Change Password'} />
            </h3>
            <p>
              <IntlMessages id="page.resetPassDescription" />
            </p>
          </div>

          <Formik
            initialValues={initialResetValue}
            validationSchema={resetPassValidation}
            onSubmit={handleSubmit}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="isoResetPassForm">
                  {
                    <div className="isoInputWrapper">
                      <Input
                        size="large"
                        type={params.code ? 'text' : 'password'}
                        placeholder={params.code ? 'Security Code' : 'Old Password'}
                        name="token"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.token}
                      />
                      {errors.token && touched.token && (
                        <div className="invalid-input">{errors.token}</div>
                      )}
                    </div>
                  }

                  <div className="isoInputWrapper">
                    <Input
                      size="large"
                      type="password"
                      placeholder="New Password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    {errors.password && touched.password && (
                      <div className="invalid-input">{errors.password}</div>
                    )}
                  </div>

                  <div className="isoInputWrapper">
                    <Input
                      size="large"
                      type="password"
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirmPassword}
                    />
                    {errors.confirmPassword && touched.confirmPassword && (
                      <div className="invalid-input">{errors.confirmPassword}</div>
                    )}
                  </div>

                  <div className="isoInputWrapper">
                    <Button type="submit" onClick={handleSubmit}>
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </ResetPasswordStyleWrapper>
  );
};

export default ResetPassword;
