import React, {useState} from 'react';
import isEmpty from 'lodash/isEmpty';
import {useDispatch, useSelector} from 'react-redux';
import {Modal, Spin} from 'antd';

import NoBoardFounds from '../BoardNotFound/BoardNotFound';
import BoardListCard from './BoardListCard/BoardListCard';
import AppLayout from '../../AppLayout/AppLayout';
import {Table} from './BoardList.style';
import {activateAdmin, deactivateAdmin} from '@iso/redux/superAdmin/actions';
function BoardLists({history, match}) {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedModalBoard, setSelectedModalBoard] = useState(null);

  const showModal = (admin) => {
    setSelectedModalBoard(admin);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (selectedModalBoard.status === 1) {
      dispatch(deactivateAdmin(selectedModalBoard.id)).then((res) => {
        setIsModalVisible(false);
      });
    } else {
      dispatch(activateAdmin(selectedModalBoard.id)).then((res) => {
        setIsModalVisible(false);
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  // const handleEdit = (admin) => {
  //   history.push({pathname: `/dashboard/createAdmin/${admin.id}`, admin});
  // };

  const adminList = useSelector((state) => {
    return state.SuperAdmin.admins;
  });
  let Loading = useSelector((state) => {
    return state.SuperAdmin.loading;
  });
  return (
    <>
      <AppLayout history={history} match={match}>
        {Loading ? (
          <div className="spinner">
            <Spin />
          </div>
        ) : (
          <>
            {!isEmpty(adminList) ? (
              <Table>
                {adminList.map((board) => {
                  return (
                    <BoardListCard
                      key={board.id}
                      item={board}
                      onStatus={() => {
                        showModal(board);
                      }}
                      onEdit={() => {}}
                    />
                  );
                })}
              </Table>
            ) : (
              <NoBoardFounds history={history} match={match} />
            )}
          </>
        )}
      </AppLayout>
      <Modal title="Delete Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>
          {`Are you sure you want to ${
            selectedModalBoard && selectedModalBoard.status === 1 ? 'deactivate' : 'activate'
          } this admin`}
        </p>
      </Modal>
    </>
  );
}

export default BoardLists;
