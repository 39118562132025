import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import _ from "lodash";

import Dropdown from "@iso/components/uielements/CustomDropdown";
import Input from "@iso/components/uielements/input";
import Button from "@iso/components/uielements/button";
import { createBusStop, updateBusStop } from "@iso/redux/busStop/actions";
import IntlMessages from "@iso/components/utility/intlMessages";
import SignUpStyleWrapper from "./CreateBusStop.styles";
import MapModal from "../../../containers/MapModal";
import { busStopValidation } from "../../../constants";
import { Avatar } from "antd";
import { Map, getStaticMap } from "../../../services";
import html2canvas from "html2canvas";

export default function CreateBusStop() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useLocation();
  const locParams = params && params.state ? params.state : { busStop: null };
  const [disable, setDisable] = useState(false);
  const [imageFile, setImageFile] = useState(
    (locParams.busStop &&
      locParams.busStop.photo &&
      locParams.busStop.photo.url) ||
      null
  );
  const [mapImage, setMapImage] = useState({ photo: null, name: "" });
  const [mapScreen, setmapScreen] = useState();
  const [showScreen, setShowScreen] = useState(
    (locParams.busStop && locParams.busStop.map && locParams.busStop.map.url) ||
      null
  );
  const [avatarImg, setAvatarImg] = useState(null);
  const [location, setLocation] = useState({});
  const [zoom, setZoom] = useState(13)
  // const dataURLtoFile = (dataurl, filename) => {
  //   var arr = dataurl.split(","),
  //     mime = arr[0].match(/:(.*?);/)[1],
  //     bstr = atob(arr[1]),
  //     n = bstr.length,
  //     u8arr = new Uint8Array(n);
  //   while (n--) {
  //     u8arr[n] = bstr.charCodeAt(n);
  //   }
  //   const mapFile = new File([u8arr], filename, { type: mime });
  //   console.log('mapFile', mapFile)
  //   return mapFile;
  // };

  // const screenShotMap = async () => {
  //   html2canvas(document.getElementById("mapG"), { useCORS: true })
  //     .then((canvas) => {
  //       const contentDataURL = canvas.toDataURL("image/png");
  //       const img = new Image();
  //       img.src = contentDataURL;
  //       // setShowScreen(img.src);
  //       const fileImage = dataURLtoFile(img.src, "imageName.jpg");
  //       console.log('fileImage', fileImage)
  //       return fileImage;
  //       // setmapScreen(fileImage);
  //     })
  //     .catch((err) => {});
  // };

  async function url2File(url, fileName){
    const blob = await (await fetch(url)).blob()
    return new File([blob], fileName, {type: blob.type})
}

  const screenShotMap = async (lat, lng) => {
    // try {
    //   const canvas = await html2canvas(document.getElementById("mapG"), {
    //     x: 420, // this are our custom x y properties
    //     y: 40,
    //     width: 330, // final width and height
    //     height: 330,
    //     useCORS: true,
    //   });
    //   const contentDataURL = canvas.toDataURL("image/png");
    //   const img = new Image();
    //   img.src = contentDataURL;
    //   const fileImage = dataURLtoFile(img.src, "imageName.jpg");
    //   console.log("fileImage", fileImage);
    //   return fileImage;
    // } catch (error) {
    //   console.error(error);
    // }

    try {
      const staticMapImage = getStaticMap(lat, lng, zoom);
      const fileImage = await url2File(staticMapImage, 'smaple.png')

      return fileImage;

    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSubmit = async (values) => {
    setDisable(true);
    const stopMapImage = await screenShotMap(location.lat, location.lng);

    values.latitude = location.lat;
    values.longitude = location.lng;
    if (locParams.busStop) {
      let data = {};
      if (values.location === 0) {
        data = {
          ...values,
          id: locParams.busStop.id,
          name: values.name,
          photo: values.photo,
          map: stopMapImage,
          zoom
        };
        dispatch(updateBusStop(data)).then((res) => {
          setDisable(false);
          navigate("/dashboard/bus-stop-list");
        });
      } else {
        data = {
          ...values,
          id: locParams.busStop.id,
          name: values.name,
          photo: values.photo,
          map: stopMapImage,
          zoom
        };
        dispatch(updateBusStop(data)).then((res) => {
          setDisable(false);
          navigate("/dashboard/bus-stop-list");
        });
      }
    } else {
      let data = {
        ...values,
        name: values.name,
        photo: values.photo,
        map: stopMapImage,
        zoom
      };
      dispatch(createBusStop(data)).then((res) => {
        setDisable(false);
        navigate("/dashboard/bus-stop-list");
      });
    }
  };

  // const cityList = useSelector((state) => {
  //   const city = state.City.city;
  //   if (city) {
  //     let newCity = [];
  //     city.forEach((item) => {
  //       newCity.push({ value: item.id, label: item.name });
  //     });
  //     return newCity;
  //   }
  // });

  const callBackMapImage = (photo, name) => {
    setMapImage({
      photo,
      name,
    });
  };

  // console.log("imageFile", imageFile);
  // console.log("showScreen", showScreen);

  return (
    <SignUpStyleWrapper className="isoSignUpPage">
      <div className="isoSignUpContentWrapper">
        <div className="isoSignUpContent">
          <div className="isoLogoWrapper">
            <Link to="/dashboard">
              <IntlMessages id="Add a New Bus Stop" />
            </Link>
          </div>

          <Formik
            initialValues={{
              busStopPlace: { latitude: "" },
              name: locParams.busStop ? locParams.busStop.name : "",
              photo:
                (locParams.busStop &&
                  locParams.busStop.photo &&
                  locParams.busStop.photo.url) ||
                null,
              map:
                (locParams.busStop &&
                  locParams.busStop.map &&
                  locParams.busStop.map.url) ||
                null,
            }}
            onSubmit={handleSubmit}
            validationSchema={busStopValidation}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              /* and other goodies */
            }) => {
              const onFileChange = (e) => {
                // if ((e.target.files[0].size / 1048576).toFixed(2) > 2){
                //   setAvatarImg('Image size must be smaller than 2mb')
                //   setImageFile(null)
                // }
                // else {
                setAvatarImg(null);
                setFieldValue("photo", e.target.files[0]);
                setImageFile(URL.createObjectURL(e.target.files[0]));
                // }
                // console.log('e', e.target.files[0])
                // setFieldValue("photo", e.target.files[0]);
              };

              return (
                <form onSubmit={handleSubmit}>
                  <div className="isoSignUpForm">
                    <div className="isoInputWrapper">
                      {/* <MapModal
                        getValuesObject={(arr) => {
                          setFieldValue("busStopPlace", arr[0]);
                        }}
                        setLocation={
                          locParams.busStop
                            ? {
                                lat: locParams.busStop.latitude,
                                lng: locParams.busStop.longitude,
                                name: locParams.busStop.name,
                              }
                            : null
                        }
                        callBackMapImage={callBackMapImage}
                        single
                        setmapScreen={setmapScreen}
                        setShowScreen={setShowScreen}
                      />
                      {errors.busStopPlace && touched.busStopPlace && (
                        <div className="invalid-input">Required</div>
                      )} */}
                    </div>

                    <div className="isoInputWrapper">
                    <label className="label-style">Name</label>
                      <Input
                        size="large"
                        type="text"
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                      />
                      {errors.name && touched.name && (
                        <div className="invalid-input">{errors.name}</div>
                      )}
                    </div>

                    <div className="isoInputWrapper">
                    <label className="label-style">Image</label>
                      <Input
                        type="file"
                        name="photo"
                        accept="image/x-png,image/jpeg,image/jpg"
                        data-max-size="2048"
                        // onChange={(e) =>
                        //   handleChange(
                        //     ((e.target.files[0].size / 1048576).toFixed(2) >
                        //       2 &&
                        //       console.log("size")) ||
                        //       setFieldValue("photo", e.target.files[0]),
                        //       setImageFile(URL.createObjectURL(e.target.files[0]))
                        //   )
                        // }
                        onChange={(e) => onFileChange(e)}
                        // onBlur={handleBlur}
                        value={undefined}
                      />
                      {
                        <Avatar
                          src={
                            imageFile && imageFile
                            //  (locParams &&
                            //    locParams.hotel &&
                            //    locParams.hotel.avatar &&
                            //    locParams.hotel.avatar.url)
                          }
                          style={{ marginTop: "10px" }}
                          shape="square"
                          size={100}
                        />
                      }

                      {avatarImg && (
                        <div className="invalid-input">{avatarImg}</div>
                      )}
                    </div>

                    {/* <div className="isoInputWrapper">
                      <Dropdown
                        options={cityList}
                        onChange={(value) => {
                          setFieldValue("cityId", value);
                        }}
                        value={values.cityId ? values.cityId : null}
                        placeholder="Select a City"
                      />
                      {errors.cityId && touched.cityId && (
                        <div className="invalid-input">
                          {errors.cityId.value}
                        </div>
                      )}
                    </div> */}
                    {/* {(mapImage && mapImage.photo) ||
                    (locParams &&
                      locParams.busStop &&
                      locParams.busStop.photo &&
                      locParams.busStop.photo.url) ? (
                      <div className="isoInputWrapper">
                        <Avatar
                          src={
                            (mapImage && mapImage.photo) ||
                            (locParams &&
                              locParams.busStop &&
                              locParams.busStop.photo &&
                              locParams.busStop.photo.url)
                          }
                          style={{ marginTop: "10px" }}
                          shape="square"
                          size={100}
                        />
                      </div>
                    ) : null} */}
                    {showScreen ? (
                      <div className="isoInputWrapper">
                        <Avatar
                          src={showScreen}
                          style={{ marginTop: "10px" }}
                          shape="square"
                          size={100}
                        />
                      </div>
                    ) : null}
                    <div className="mapWrapper">
                      <Map
                        getLocation={(loc) => {
                          setLocation(loc);
                          setFieldValue("location", loc);
                        }}
                        setZoom={setZoom}
                        setLocation={
                          locParams.busStop
                            ? {
                                lat: locParams.busStop.latitude,
                                lng: locParams.busStop.longitude,
                                name: locParams.busStop.name,
                              }
                            : null
                        }
                        getQuery={() => {}}
                      />
                      {errors.location && touched.location && (
                        <div className="invalid-input">{errors.location}</div>
                      )}
                    </div>
                    <div className="isoInputWrapper">
                      <Button
                        type="primary"
                        onClick={handleSubmit}
                        disabled={disable}
                      >
                        <IntlMessages
                          id={
                            locParams.busStop ? "Update" : "page.signUpButton"
                          }
                        />
                      </Button>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </SignUpStyleWrapper>
  );
}
