import React from 'react';
import {Layout, Button} from 'antd';
import {useNavigate} from 'react-router';

import SearchInput from '@iso/components/ScrumBoard/SearchInput/SearchInput';
import {Title, Header, HeaderSecondary} from './AppLayout.style';
import { TOUR_TYPES, TOUR_NAMES } from '../../../../constants';
import Scrollbars from 'react-custom-scrollbars';

const {Content} = Layout;

const AppLayout = ({children, setSearchText, history, match}) => {
  const navigate = useNavigate();
  return (
    <Layout style={{backgroundColor: '#fff'}}>
      <Header>
      <Title>{TOUR_NAMES[TOUR_TYPES.shared].name}</Title>

        <Button
          type="primary"
          onClick={() => {
            navigate('/dashboard/createTours', {state: {selectedType: 'public'}});
          }}>
          Add new
        </Button>
      </Header>
      <HeaderSecondary>
        <SearchInput
          onChange={(value) => setSearchText(value)}
          onCross={(value) => {
            setSearchText(value);
          }}
        />
      </HeaderSecondary>

      <Content
        style={{
          padding: '0 24px',
        }}>
          <Scrollbars style={{width: '100%', height: '80vh'}}>{children}</Scrollbars>
      </Content>
    </Layout>
  );
};

export default AppLayout;
