import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Popconfirm,
  Table,
  Typography,
  Modal,
  Button,
  InputNumber,
  // Dropdown,
  // Select,
} from "antd";
import Select, { SelectOption } from "@iso/components/uielements/select";
import Dropdown from "@iso/components/uielements/CustomDropdown";

import { useDispatch, useSelector } from "react-redux";
import TimeKeeper from "react-timekeeper";
import moment from "moment";
import _ from "lodash";
import {
  createTourStop,
  updateTourStop,
  deleteTourStop,
  fetchTourStop,
  getToursList,
  duplicateTourStop,
} from "../../../redux/tours/actions";
import { getHotelsList } from "../../../redux/hotels/actions";
import { getBusStopList } from "../../../redux/busStop/actions";
import { TOUR_TYPES } from "../../../constants";

const Option = SelectOption;

const TourStop = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [form] = Form.useForm();

  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState("");

  const [originalData, setOriginalData] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);

  const [hotelModel, sethotelModel] = useState(false);
  const [hotelList, setHotelList] = useState(null);

  const [stopList, setStopList] = useState(null);

  const [day, setday] = useState(null);

  const tourData = useLocation();
  const tour = tourData && tourData.state ? tourData.state : { data: null };

  useEffect(() => {
    dispatch(fetchTourStop(params.id)).then((res) => {
      setOriginalData(res);
      let arr = [];
      res.forEach((item) => {
        arr.push({
          ...item,
          name: item.hotel.name,
          stopName: (item && item.stop && item.stop.name) || null,
        });
      });
      setData(arr);
    });
  }, [day]);

  useEffect(() => {
    dispatch(getBusStopList()).then((res) => {
      setStopList(res);
    });
    dispatch(getToursList(TOUR_TYPES.monthly_excursion));
  }, []);

  const tourList = useSelector((state) => {
    return state.Tours.tours;
  });

  const currentDate = moment().format("YYYY-MM-DD");
  const currentTourDate =
    (tour.data && tour.data.createdAt && moment(tour.data.createdAt)) || null;

  let tours = _.filter(tourList, function (o) {
    return (
      o.type === TOUR_TYPES.monthly_excursion &&
      o.pickUpDate >= currentDate &&
      moment(o.createdAt) < currentTourDate
    );
  });

  let arr = [];

  tours.forEach((element) => {
    arr.push({
      value: element.id,
      label: element.destination,
      tourStop: element.tourStop,
    });
  });

  const addDay = (hotel) => {
    const isHotel = _.find(data, function (o) {
      return o.hotel.name === hotel.name;
    });
    if (isHotel === undefined) {
      dispatch(createTourStop(hotel.id, params.id)).then((res) => {
        // navigate("/dashboard/transport-tours-list");
        setday(res);
      });
      sethotelModel(false);
    }
  };

  const openHotelModel = () => {
    dispatch(getHotelsList()).then((res) => {
      setHotelList(res);
    });
    sethotelModel(true);
  };

  const isEditing = (record) => record.id === editingKey;

  const getDayValue = (index, objKey) => {
    let newObj = { ...data[index] };
    if (newObj[`${objKey}`]) {
      return moment(newObj[`${objKey}`], "HH:mm:ss").format("HH:mm");
    } else {
      return null;
    }
  };

  const [selectedStop, setselectedStop] = useState({ stop: null });
  const [editStop, setEditStop] = useState(null);

  const save = async (key) => {
    const row = await form.validateFields();
    const newData = [...data];

    const index = newData.findIndex((item) => key === item.id);
    const item = newData[index];

    const stopData = (selectedStop && selectedStop.stop && selectedStop) || {
      stop: editStop,
    };

    newData.splice(index, 1, {
      ...item,
      ...stopData,
      stopName: stopData.stop.name,
    });

    setData(newData);
    setselectedStop({ stop: null });
    setEditingKey("");
    dispatch(
      updateTourStop(newData[editingIndex].id, newData[editingIndex])
    ).then((res) => {
      setEditingKey("");
    });
  };

  const handleSelectChange = (value) => {
    const selectedObject = stopList.find((item) => item.value === value);
    setselectedStop({ stop: selectedObject.stop });
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = (
      <Select
        onChange={(value) => handleSelectChange(value)}
        options={stopList}
        // value= {{ value: editStop.id, label: editStop.name }}
      />
    );
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please select ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const edit = (record) => {
    form.setFieldsValue({
      stopName: "",
      ...record,
    });
    const index = originalData.findIndex((object) => {
      return object.id === record.id;
    });

    setEditStop(record.stop);

    setEditingIndex(index);
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const ondeleteSchedule = (id) => {
    dispatch(deleteTourStop(id)).then((res) => {
      setday(res);
    });
  };

  const columns = [
    {
      title: "Hotel Name",
      dataIndex: "name",
      indexType: "text",
      // editable: true,
    },
    {
      title: "Stop",
      dataIndex: "stopName",
      editable: true,
      render: (text) => <Typography.Text ellipsis>{text}</Typography.Text>,
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.id)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <>
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
              style={{ margin: "0 8px" }}
            >
              Edit
            </Typography.Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => ondeleteSchedule(record.id)}
            >
              <a style={{ margin: "0 8px", color: "red" }}>Delete</a>
            </Popconfirm>
          </>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const tourType = tour && tour.data && tour.data.type;

  return (
    <div>
      <Form form={form} component={false}>
        <div
          style={{
            float: "right",
            padding: "10px 10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {(TOUR_TYPES.monthly_excursion === tourType && (
            <Dropdown
              options={arr}
              onChange={(data) => {
                // Show a prompt to confirm the selection
                const confirmed = window.confirm(
                  `Are you sure you want to duplicate stops for ${data.label}?`
                );

                if (confirmed) {
                  dispatch(
                    duplicateTourStop({ data, tourId: params.id })
                  ).then((res) => {
                    setday(res);
                  });
                }
              }}
              placeholder="Select Tours to Duplicate Stops"
            />
          )) ||
            null}
          <Button
            onClick={openHotelModel}
            type="primary"
            style={{
              marginLeft: 16,
            }}
          >
            Add Hotel
          </Button>
        </div>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
        />
      </Form>
      <Modal
        title="Select Hotel"
        centered
        visible={hotelModel}
        onCancel={() => sethotelModel(false)}
        footer={null}
        width={300}
        bodyStyle={{ height: 500, overflowY: "auto" }}
      >
        <div>
          {(hotelList &&
            hotelList.length > 0 &&
            hotelList.map((data, key) => {
              return (
                <div
                  style={{
                    padding: "5px 10px",
                    fontSize: "15px",
                    cursor: "pointer",
                  }}
                  key={key}
                  onClick={() => addDay(data)}
                >
                  <span>
                    {key + 1}
                    {". "}
                    {data.displayName}
                  </span>
                </div>
              );
            })) ||
            null}
        </div>
      </Modal>
      {/* <Button >Add</Button> */}
    </div>
  );
};

export default TourStop;

// import React, { useState, useEffect } from "react";
// import { useParams, useLocation } from "react-router-dom";
// import {
//   Form,
//   Input,
//   Popconfirm,
//   Table,
//   Typography,
//   Modal,
//   Button,
//   InputNumber,
//   Dropdown,
//   Select,
// } from "antd";
// import { useDispatch } from "react-redux";
// import TimeKeeper from "react-timekeeper";
// import moment from "moment";
// import _ from "lodash";
// import {
//   deleteSchedule,
//   createHotelPrice,
//   updateHotelPrice,
//   deleteHotelPrice,
//   fetchTourStop,
// } from "../../../redux/tours/actions";
// import { getHotelsList } from "../../../redux/hotels/actions";
// import { getBusStopList } from "../../../redux/busStop/actions";

// const originData = [];
// const num = [];
// for (let i = 0; i < 100; i++) {
//   originData.push({
//     key: i.toString(),
//     name: `Edward ${i}`,
//     age: 32,
//     address: `London Park no. ${i}`,
//   });
//   num.push({
//     value: i.toString(),
//     label: `Edward ${i}`,
//   });
// }
// const EditableCell = ({
//   editing,
//   dataIndex,
//   title,
//   inputType,
//   record,
//   index,
//   children,
//   stopList,
//   ...restProps
// }) => {
//   console.log("recording", record);
//   const inputNode = <Select options={stopList} />;
//   return (
//     <td {...restProps}>
//       {editing ? (
//         <Form.Item
//           name={dataIndex}
//           style={{
//             margin: 0,
//           }}
//           rules={[
//             {
//               required: true,
//               message: `Please Input ${title}!`,
//             },
//           ]}
//         >
//           {inputNode}
//         </Form.Item>
//       ) : (
//         children
//       )}
//     </td>
//   );
// };
// const TourStop = () => {
//   const dispatch = useDispatch();
//   const params = useParams();

//   const [originalData, setOriginalData] = useState([]);
//   const [editingIndex, setEditingIndex] = useState(null);

//   const [hotelModel, sethotelModel] = useState(false);
//   const [hotelList, setHotelList] = useState(null);

//   const [stopList, setStopList] = useState(null);

//   const [day, setday] = useState(null);

//   const [form] = Form.useForm();
//   const [data, setData] = useState(originalData);
//   const [editingKey, setEditingKey] = useState("");

//   useEffect(() => {
//     dispatch(fetchTourStop(params.id)).then((res) => {
//       setOriginalData(res);
//       let arr = [];
//       res.forEach((item) => {
//         arr.push({ ...item, name: item.hotel.name, stop: item.stop.name });
//       });
//       setData(arr);
//     });
//   }, []);

//   useEffect(() => {
//     dispatch(getBusStopList()).then((res) => {
//       setStopList(res);
//     });
//   }, []);

//   const isEditing = (record) => record.id === editingKey;
//   const edit = (record) => {
//     form.setFieldsValue({
//       name: "",
//       stop: "",
//       ...record,
//     });
//     // setEditingKey(record.key);
//     const index = originalData.findIndex((object) => {
//       return object.id === record.id;
//     });
//     // setEditingIndex(index);
//     setEditingKey(record.id);
//   };
//   const cancel = () => {
//     setEditingKey("");
//   };
//   const save = async (key) => {
//     try {
//       const row = await form.validateFields();
//       const newData = [...data];
//       const index = newData.findIndex((item) => key === item.key);
//       if (index > -1) {
//         const item = newData[index];
//         newData.splice(index, 1, {
//           ...item,
//           ...row,
//         });
//         setData(newData);
//         console.log('newData', newData)
//         setEditingKey("");
//       } else {
//         console.log('row', row)
//         newData.push(row);
//         setData(newData);
//         setEditingKey("");
//       }
//     } catch (errInfo) {
//       console.log("Validate Failed:", errInfo);
//     }
//   };
//   const columns = [
//     {
//       title: "Hotel",
//       dataIndex: "name",
//       width: "25%",
//       editable: false,
//     },
//     {
//       title: "Stop",
//       dataIndex: "stop",
//       width: "15%",
//       editable: true,
//     },
//     {
//       title: "operation",
//       dataIndex: "operation",
//       render: (_, record) => {
//         const editable = isEditing(record);
//         return editable ? (
//           <span>
//             <Typography.Link
//               onClick={() => save(record.id)}
//               style={{
//                 marginRight: 8,
//               }}
//             >
//               Save
//             </Typography.Link>
//             <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
//               <a>Cancel</a>
//             </Popconfirm>
//           </span>
//         ) : (
//           <Typography.Link
//             disabled={editingKey !== ""}
//             onClick={() => edit(record)}
//           >
//             Edit
//           </Typography.Link>
//         );
//       },
//     },
//   ];
//   const mergedColumns = columns.map((col) => {
//     if (!col.editable) {
//       return col;
//     }
//     return {
//       ...col,
//       onCell: (record) => ({
//         record,
//         inputType: col.dataIndex === "text",
//         dataIndex: col.dataIndex,
//         title: col.title,
//         editing: isEditing(record),
//         stopList,
//       }),
//     };
//   });
//   return (
//     <Form form={form} component={false}>
//       <Table
//         components={{
//           body: {
//             cell: EditableCell,
//           },
//         }}
//         bordered
//         dataSource={data}
//         columns={mergedColumns}
//         rowClassName="editable-row"
//         // pagination={{
//         //   onChange: cancel,
//         // }}
//       />
//     </Form>
//   );
// };
// export default TourStop;
