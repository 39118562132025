import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Lists from "../../../containers/Lists/Lists";
import { getHotelList, hotelView } from "../../../redux/superAdmin/actions";

const HotelList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [hotels, setHotels] = useState([]);

  useEffect(() => {
    dispatch(getHotelList()).then((res) => {
      setHotels(res);
    });
  }, []);

  const handleView = (hotel) => {
    dispatch(hotelView(hotel)).then((res) => {
      navigate(`/dashboard/hotels-view/${res.id}`, { state: { res } });
    });
  };

  const columns = [
    {
      title: "Hotel Name",
      dataIndex: "displayName",
      rowKey: "displayName",
      width: "15%",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      rowKey: "email",
      width: "25%",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "City",
      dataIndex: "city",
      rowKey: "city",
      width: "22%",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "State",
      dataIndex: "state",
      rowKey: "state",
      width: "15%",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Country",
      dataIndex: "country",
      rowKey: "country",
      width: "13%",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "",
      dataIndex: "id",
      rowKey: "id",
      width: "10%",
      render: (text, data) => (
        <div className="isoInvoiceBtnView">
          <Button
            color="primary"
            className="invoiceViewBtn"
            onClick={() => handleView(text)}
          >
            View
          </Button>
        </div>
      ),
    },
  ];
  return <Lists title={"Hotels List"} Columns={columns} Rows={hotels} />;
};

export default HotelList;
