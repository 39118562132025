import React from "react";
import { Link } from "react-router-dom";
import siteConfig from "@iso/config/site.config";

export default ({ collapsed, handleClick, dispatch, changeCurrent }) => {
  handleClick = (e) => {
    dispatch(changeCurrent([e.key]));
  };
  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <div>
          <h3>
            <Link to="/dashboard">
              <i className={siteConfig.siteIcon} />
            </Link>
          </h3>
        </div>
      ) : (
        <h3 onClick={handleClick}>
          <Link to="/dashboard">{siteConfig.siteName}</Link>
        </h3>
      )}
    </div>
  );
};
