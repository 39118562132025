import { Modal } from "antd";
import { Formik } from "formik";
import React from "react";
import { serviceValidation } from "../../constants";
import ServiceForm from "../AirportTransfer/ServiceForm";
import { useSelector, useDispatch } from "react-redux";
import {
  createService,
  updateService,
} from "../../redux/airportTransfer/actions";

const ServiceModal = ({
  isModalVisible,
  serviceData,
  handleCancel,
  title,
  buttonTitle,
}) => {
  const dispatch = useDispatch();

  const initialValues = {
    min: (serviceData && serviceData.data && serviceData.data.min) || "",
    max: (serviceData && serviceData.data && serviceData.data.max) || "",
    title: (serviceData && serviceData.data && serviceData.data.title) || "",
    email: (serviceData && serviceData.data && serviceData.data.email) || "",
    phoneNumber:
      (serviceData && serviceData.data && serviceData.data.phoneNumber) || "",
  };

  const onSubmit = (values) => {
    if (serviceData && serviceData.id) {
      const { min, max, title, email, phoneNumber } = values;
      const data = {
        id: serviceData.id,
        min,
        max,
        title,
        email,
        phoneNumber,
      };
      dispatch(updateService(data));
    } else {
      dispatch(createService(values));
    }

    handleCancel();
  };

  return (
    <Modal
      style={{
        top: 20,
      }}
      title={title}
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose={true}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={serviceValidation}
        onSubmit={onSubmit}
        component={(props) => (
          <ServiceForm
            {...props}
            serviceData={serviceData}
            buttonTitle={buttonTitle}
          />
        )}
      ></Formik>
    </Modal>
  );
};

export default ServiceModal;
