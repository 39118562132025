import React from 'react';
import {useSelector} from 'react-redux';
import {Navigate} from 'react-router-dom';

const ProtectedRoute = (props) => {
  const isLoggedIn = useSelector((state) => {
    return state.Auth.user;
  });
  if (!props.logged) {
    return <Navigate to="/signIn" />;
  }
  return props.children;
};

export default ProtectedRoute;
