import React, {useState} from 'react';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {Formik} from 'formik';

import Input from '@iso/components/uielements/input';
import Button from '@iso/components/uielements/button';
import {updateCommission} from '@iso/redux/hotels/actions';
import IntlMessages from '@iso/components/utility/intlMessages';
import SignUpStyleWrapper from './SetCommission.styles';

export default function CreateHotels() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useLocation();
  const locParams = params && params.state ? params.state : {hotelCommission: null};
  const [disable, setDisable] = useState(false);

  const handleSubmit = (values) => {
    setDisable(true);
    if (locParams.hotelCommission) {
      dispatch(updateCommission(locParams.hotelCommission.id, values)).then((res) => {
        setDisable(false);
        navigate('/dashboard/hotels-list');
      });
    }
  };

  return (
    <SignUpStyleWrapper className="isoSignUpPage">
      <div className="isoSignUpContentWrapper">
        <div className="isoSignUpContent">
          <div className="isoLogoWrapper">
            <Link to="/dashboard">
              <IntlMessages id="Set Commissions" />
            </Link>
          </div>

          <Formik
            initialValues={{
              hotel: locParams.hotelCommission ? `${locParams.hotelCommission.hotel}` : '',
              be: locParams.hotelCommission ? `${locParams.hotelCommission.be}` : '',
              concierge: locParams.hotelCommission ? `${locParams.hotelCommission.concierge}` : '',
              paymentProvider: locParams.hotelCommission
                ? `${locParams.hotelCommission.paymentProvider}`
                : '',
            }}
            onSubmit={handleSubmit}
            enableReinitialize>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="isoSignUpForm">
                  <div className="isoInputWrapper">
                    <label>Hotel</label>
                    <Input
                      size="large"
                      placeholder="Hotel"
                      type="text"
                      name="hotel"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.hotel}
                    />
                  </div>
                  <div className="isoInputWrapper">
                    <label>Concierge</label>
                    <Input
                      size="large"
                      placeholder="Concierge"
                      type="text"
                      name="concierge"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.concierge}
                    />
                  </div>

                  <div className="isoInputWrapper">
                    <label>Be</label>
                    <Input
                      size="large"
                      placeholder="Be"
                      type="text"
                      name="be"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.be}
                    />
                  </div>
                  <div className="isoInputWrapper">
                    <label>Payment Provider</label>
                    <Input
                      size="large"
                      placeholder="Payment Provider"
                      type="text"
                      name="paymentProvider"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.paymentProvider}
                    />
                  </div>

                  <div className="isoInputWrapper">
                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      className="btnAccountKit"
                      disabled={disable}>
                      <IntlMessages id={locParams.hotel ? 'Update' : 'Save'} />
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </SignUpStyleWrapper>
  );
}
