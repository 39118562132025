import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import BoardLists from './Board/BoardList/BoardList';
import {getBookedToursList, getToursList} from '@iso/redux/receptionist/actions';

export default function ToursList() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBookedToursList());
    dispatch(getToursList());
  }, []);

  return (
    <>
      <BoardLists />
    </>
  );
}
