import { Modal } from "antd";
import { Formik } from "formik";
import React from "react";
import { extraServiceValidation } from "../../constants";
import ExtraServiceForm from "../AirportTransfer/ExtraServiceForm";
import { useDispatch } from "react-redux";
import {
  createExtraService,
  updateExtraService,
} from "../../redux/airportTransfer/actions";

const ExtraServiceModal = ({
  isModalVisible,
  serviceData,
  handleCancel,
  title,
  buttonTitle,
}) => {
  const dispatch = useDispatch();

  const initialValues = {
    babySeatPrice:
      (serviceData && serviceData.data && serviceData.data.babySeatPrice) || "",
    childBoosterSeatPrice:
      (serviceData &&
        serviceData.data &&
        serviceData.data.childBoosterSeatPrice) ||
      "",
    golfEquipmentPrice:
      (serviceData &&
        serviceData.data &&
        serviceData.data.golfEquipmentPrice) ||
      "",
    specialEquipmentPrice:
      (serviceData &&
        serviceData.data &&
        serviceData.data.specialEquipmentPrice) ||
      "",
  };

  const onSubmit = (values) => {
    if (serviceData && serviceData.id) {
      const {
        babySeatPrice,
        childBoosterSeatPrice,
        golfEquipmentPrice,
        specialEquipmentPrice,
      } = values;
      const data = {
        id: serviceData.id,
        babySeatPrice,
        childBoosterSeatPrice,
        golfEquipmentPrice,
        specialEquipmentPrice,
      };
      dispatch(updateExtraService(data));
    } else {
      dispatch(createExtraService(values));
    }

    handleCancel();
  };

  return (
    <Modal
      title={title}
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose={true}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={extraServiceValidation}
        onSubmit={onSubmit}
        component={(props) => (
          <ExtraServiceForm
            {...props}
            serviceData={serviceData}
            buttonTitle={buttonTitle}
          />
        )}
      ></Formik>
    </Modal>
  );
};

export default ExtraServiceModal;
