import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Popconfirm, Typography, Spin, Popover, Modal } from "antd";
import moment from "moment";
import { MoreActionWrapper } from "./BoardListCard/BoardListCard.style.js";
import { IconSvg } from "@iso/components/ScrumBoard/IconSvg/IconSvg";
import MoreIcon from "@iso/assets/images/icon/16.svg";

import NoBoardFounds from "../BoardNotFound/BoardNotFound";
import AppLayout from "../../AppLayout/AppLayout";

import Lists from "../../../../../../containers/Lists/Lists";
import ServiceModal from "../../../../../../components/Modal/ServiceModal.js";
import { deleteService } from "../../../../../../redux/airportTransfer/actions.js";

function BoardLists({ history, match }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);

  const [serviceData, setServiceData] = useState({ id: null, data: null });

  const showModal = () => {
    setIsModalVisible(true);
    setServiceData(null);
  };

  function showUpdateModal(id, data) {
    setIsUpdateModalVisible(!isUpdateModalVisible);
    setServiceData({ id, data });
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleUpCancel = () => {
    setIsUpdateModalVisible(false);
  };

  // const handleReceipt = (tour) => {
  //   dispatch(generateTourReceipt(tour.id));
  // };

  const onCancel = (id) => {
    dispatch(deleteService(id));
  };

  // const onDuplicate = (tour) => {
  //   navigate(`/dashboard/shared-tours-booking/shared/`, { state: { tour } });
  // };

  const serviceList = useSelector((state) => {
    return state.AirportTransfer.service;
  });

  let Loading = useSelector((state) => {
    return state.AirportTransfer.loading;
  });

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      rowKey: "id",
      width: 10,
      render: (text) => <Typography.Text ellipsis>{text}</Typography.Text>,
      sorter: (a, b) => moment(a.id) - moment(b.id),
    },
    {
      title: "Persons",
      dataIndex: "id",
      rowKey: "id",
      width: 10,
      render: (text, data) => (
        <Typography.Text ellipsis>
          {data.min} - {data.max}
        </Typography.Text>
      ),
    },
    {
      title: "Service",
      dataIndex: "title",
      rowKey: "title",
      width: 10,
      render: (text) => <Typography.Text ellipsis>{text}</Typography.Text>,
    },
    {
      title: "Action",
      dataIndex: "id",
      rowKey: "id",
      width: 5,
      render: (text, data) => (
        <div className="isoInvoiceBtnView">
          <Popover
            placement="bottom"
            content={
              <MoreActionWrapper>
                {Loading ? (
                  <Spin />
                ) : (
                  <p onClick={() => showUpdateModal(text, data)}>Edit</p>
                )}
                {Loading ? (
                  <Spin />
                ) : (
                  <Popconfirm
                    title="Sure to delete?"
                    onConfirm={() => onCancel(text)}
                  >
                    <p  style={{color:'#DC3545'}}>Delete</p>
                  </Popconfirm>
                )}
              </MoreActionWrapper>
            }
            trigger="click"
          >
            <IconSvg src={MoreIcon} border={"none"} padding={"0"} />
          </Popover>
        </div>
      ),
    },
  ];

  return (
    <>
      <AppLayout
        history={history}
        match={match}
        onCreate={() => {
          showModal();
        }}
      >
        {Loading ? (
          <div className="spinner">
            <Spin />
          </div>
        ) : (
          <>
            {!isEmpty(serviceList) ? (
              <Lists title={"Services"} Columns={columns} Rows={serviceList} />
            ) : (
              <NoBoardFounds history={history} match={match} />
            )}

            {/* <div style={{width: 600}}>
          <Document file="/example.pdf" onLoadSuccess={this.onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} width={600} />
          </Document>
        </div> */}
          </>
        )}
      </AppLayout>
      {isModalVisible ? (
        <ServiceModal
          isModalVisible={isModalVisible}
          handleCancel={handleCancel}
          title={'Create a service'}
          buttonTitle='Create'
          serviceData={serviceData}
        />
      ) : null}
      {isUpdateModalVisible ? (
        <ServiceModal
          isModalVisible={isUpdateModalVisible}
          serviceData={serviceData}
          handleCancel={handleUpCancel}
          title={'Update a service'}
          buttonTitle='Update'
        />
      ) : null}
    </>
  );
}

export default BoardLists;
