import { Button } from "antd";
import React from "react";
import { ContactCardWrapper } from "./ContactCard.style";
import { useDispatch, useSelector } from "react-redux";
import { generateAirportTransferAdminReceipt } from "@iso/redux/airport/actions";
import { Spin } from "antd";
import moment from "moment";

const Invoice = React.forwardRef(({ currentTour }, ref) => {
  const dispatch = useDispatch();

  const handleReceipt = (tour) => {
    dispatch(generateAirportTransferAdminReceipt(tour));
  };

  let Loading = useSelector((state) => {
    return state.Airport.loading;
  });

  return (
    <>
      <div className="PageContent" style={{ padding: "20" }} ref={ref}>
        <div className="OrderInfo">
          <div className="LeftSideContent">
            <h3 className="Title">Airport Transfer Booking</h3>
            <span className="InvoiceNumber">
              {(currentTour &&
                currentTour.direction === 1 &&
                "Hotel to Airport") ||
                (currentTour &&
                  currentTour.direction === 2 &&
                  "Airport to Hotel")}
            </span>
          </div>
          <div className="RightSideContent">
            <Button
              onClick={() => handleReceipt(currentTour.id)}
              type="primary"
              className="mateInvoPrint"
            >
              {Loading ? <Spin /> : <span>Invoice</span>}
            </Button>
          </div>
        </div>
        <div className="BillingInformation">
          <div className="LeftSideContent">
            <h3 className="Title">Details</h3>
            <ContactCardWrapper
              className="isoContactCard"
              style={{ padding: "0" }}
            >
              <div className="isoContactInfoWrapper">
                <div
                  className="isoContactCardInfos"
                  key={currentTour.clientName}
                >
                  <p className="isoInfoLabel"> Client Name </p>
                  <p className="isoInfoDetails">{currentTour.clientName}</p>
                </div>
                <div
                  className="isoContactCardInfos"
                  key={currentTour.clientEmail}
                >
                  <p className="isoInfoLabel"> Client Email </p>
                  <p className="isoInfoDetails">{currentTour.clientEmail}</p>
                </div>
                <div
                  className="isoContactCardInfos"
                  key={currentTour.clientPhone}
                >
                  <p className="isoInfoLabel"> Phone Number </p>
                  <p className="isoInfoDetails">{currentTour.clientPhone}</p>
                </div>
                <div
                  className="isoContactCardInfos"
                  key={currentTour.pickUpDate}
                >
                  <p className="isoInfoLabel"> Pickup Date </p>
                  <p className="isoInfoDetails">{moment(currentTour.pickUpDate).format("DD/MM/yyyy")}</p>
                </div>
                <div
                  className="isoContactCardInfos"
                  key={currentTour.pickUpTime}
                >
                  <p className="isoInfoLabel"> pickUp Time </p>
                  <p className="isoInfoDetails">{currentTour.pickUpTime}</p>
                </div>
                {(currentTour.flightNo && (
                  <div
                    className="isoContactCardInfos"
                    key={currentTour.flightNo}
                  >
                    <p className="isoInfoLabel"> Flight No. </p>
                    <p className="isoInfoDetails">{currentTour.flightNo}</p>
                  </div>
                )) ||
                  null}

                <div
                  className="isoContactCardInfos"
                  key={currentTour.noOfLuggage}
                >
                  <p className="isoInfoLabel"> No. of Luggage </p>
                  <p className="isoInfoDetails">{currentTour.noOfLuggage}</p>
                </div>
                <div
                  className="isoContactCardInfos"
                  key={currentTour.totalPrice}
                >
                  <p className="isoInfoLabel"> Price </p>
                  <p className="isoInfoDetails">{currentTour.totalPrice}</p>
                </div>
                <div className="isoContactCardInfos" key={currentTour.hotel}>
                  <p className="isoInfoLabel"> Hotel </p>
                  <p className="isoInfoDetails">{currentTour.hotel}</p>
                </div>
                <div
                  className="isoContactCardInfos"
                  key={currentTour.airport.name}
                >
                  <p className="isoInfoLabel"> Airport </p>
                  <p className="isoInfoDetails">{currentTour.airport.name}</p>
                </div>
                <div
                  className="isoContactCardInfos"
                  key={currentTour.babySeats}
                >
                  <p className="isoInfoLabel"> Baby Seats </p>
                  <p className="isoInfoDetails">{currentTour.babySeats}</p>
                </div>
                <div
                  className="isoContactCardInfos"
                  key={currentTour.childBSeats}
                >
                  <p className="isoInfoLabel"> Child Booster Seats </p>
                  <p className="isoInfoDetails">{currentTour.childBSeats}</p>
                </div>
                <div
                  className="isoContactCardInfos"
                  key={currentTour.golfEquipments}
                >
                  <p className="isoInfoLabel"> Golf Equipments </p>
                  <p className="isoInfoDetails">{currentTour.golfEquipments}</p>
                </div>
                <div
                  className="isoContactCardInfos"
                  key={currentTour.specialEquipments}
                >
                  <p className="isoInfoLabel"> Special Equipments </p>
                  <p className="isoInfoDetails">
                    {currentTour.specialEquipments}
                  </p>
                </div>
                <div className="isoContactCardInfos" key={currentTour.id}>
                  <p className="isoInfoLabel"> Service </p>
                  <p className="isoInfoDetails">
                    {currentTour.min}-{currentTour.max} / (
                    {currentTour.serviceTitle})
                  </p>
                </div>
                <div
                  className="isoContactCardInfos"
                  key={currentTour.receptionist}
                >
                  <p className="isoInfoLabel"> Receptionist </p>
                  <p className="isoInfoDetails">{currentTour.receptionist}</p>
                </div>
                {(currentTour.rooms && (
                  <div className="isoContactCardInfos" key={currentTour.id}>
                    <p className="isoInfoLabel"> Rooms </p>
                    <p className="isoInfoDetails">
                      {(currentTour.rooms &&
                        currentTour.rooms.map((data) => {
                          return <div>{data.label}</div>;
                        })) ||
                        0}
                    </p>
                  </div>
                )) ||
                  null}
                  <div className="isoContactCardInfos" key={currentTour.id}>
                  <p className="isoInfoLabel"> Description </p>
                  <p className="isoInfoDetails">{currentTour.comment}</p>
                </div>
              </div>
            </ContactCardWrapper>
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
});
export default Invoice;
