/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import { useSelector, useDispatch } from "react-redux";
import {
  Spin,
  Space,
  Table,
  Popconfirm,
} from "antd";

import NoBoardFounds from "../BoardNotFound/BoardNotFound";
import AppLayout from "../../AppLayout/AppLayout";

import { deleteHotelPrice, getServiceList } from "../../../../../../redux/airportTransfer/actions.js";
import HotelPriceModal from "../../../../../../components/Modal/HotelPriceModel";

function BoardLists({ history, match }) {
  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);

  const [hotelPriceData, setHotelPriceData] = useState({ id: null, data: null });
  const [services, setServices] = useState([]);
  const [hotelId, setHotelId] = useState(null)

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);


  useEffect(() => {
    dispatch(getServiceList()).then((res) => {
      setServices(res);
    });
  }, []);

  const showModal = (hotelId) => {
    setIsModalVisible(true);
    setHotelId(hotelId);
    setHotelPriceData(null);
  };

  function showUpdateModal(id, data, city) {
    setIsUpdateModalVisible(!isUpdateModalVisible);
    setHotelPriceData({ id, data });
    setHotelId(data.hotelId);

  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleUpCancel = () => {
    setIsUpdateModalVisible(false);
  };

  // const handleReceipt = (tour) => {
  //   dispatch(generateTourReceipt(tour.id));
  // };

  const onCancel = (id, hotelId) => {
    dispatch(deleteHotelPrice(id, hotelId));
  };


  const hotelPriceList = useSelector((state) => {
    return state.AirportTransfer.hotelPrice;
  });

  console.log('hotelPriceList', hotelPriceList)

  let Loading = useSelector((state) => {
    return state.AirportTransfer.loading;
  });

  const columns = [
    {
      title: "Hotel",
      dataIndex: "name",
      rowKey: "name",
    },
    {
      title: "Action",
      dataIndex: "id",
      rowKey: "id",
      render: (text, data) => (
        <Space size="middle">
          <a onClick={()=> showModal(text)}>Add Service</a>
        </Space>
      ),
    },
  ];

  const expandedRowRender = (d, hotel) => {
    const columns = [
      {
        title: "Persons",
        dataIndex: "service",
        rowKey: "service",
        width: "22%",
        render: (text) => (
          <p>
            {text.min} - {text.max}
          </p>
        ),
      },
      {
        title: "Service",
        dataIndex: "service",
        rowKey: "service",
        width: "22%",
        render: (text) => (
          <p>
            {text.title}
          </p>
        ),
      },
      {
        title: "Price",
        dataIndex: "price",
        rowKey: "price",
      },
      {
        title: "Night Price",
        dataIndex: "nightPrice",
        rowKey: "nightPrice",
      },
      {
        title: "Email",
        dataIndex: "email",
        rowKey: "email",
      },
      {
        title: "Phone Number",
        dataIndex: "phoneNumber",
        rowKey: "phoneNumber",
      },
      {
        title: "Action",
        dataIndex: "id",
        rowKey: "id",
        render: (text, data) => (
          <Space size="middle">
            <a onClick={()=> showUpdateModal(text, data, hotel)}>Edit</a>
            {Loading ? (
                  <Spin />
                ) : (
                  <Popconfirm
                    title="Sure to delete?"
                    onConfirm={() => onCancel(text, data.hotelId)}
                  >
                    <a  style={{color:'#DC3545'}}>Delete</a>
                  </Popconfirm>
                )}
            {/* <Dropdown
              menu={{
                items
              }}
            >
            More
            </Dropdown> */}
          </Space>
        ),
      },
    ];
    return <Table columns={columns} dataSource={d} pagination={false} />;
  };

  return (
    <>
      <AppLayout
        history={history}
        match={match}
        title={'Hotel Prices'}
      >
        {Loading ? (
          <div className="spinner">
            <Spin />
          </div>
        ) : (
          <>
            {!isEmpty(hotelPriceList) ? (
              <Table
                columns={columns}
                rowKey="id"
                expandable={{
                  onExpandedRowsChange: (er) => {
                    setExpandedRowKeys(er);
                  },
                  expandedRowKeys,
                }}
                expandedRowRender={(r, i) =>
                  expandedRowRender(hotelPriceList[i].hotelPriceAt, hotelPriceList[i])
                }
                dataSource={hotelPriceList}
                pagination={false}
              />
            ) : (
              <NoBoardFounds history={history} match={match} />
            )}
          </>
        )}
      </AppLayout>
      {isModalVisible ? (
        <HotelPriceModal
          isModalVisible={isModalVisible}
          handleCancel={handleCancel}
          title={"Add service"}
          buttonTitle="Add"
          hotelPriceData={hotelPriceData}
          hotelId={hotelId}
          services={services}
        />
      ) : null}
      {isUpdateModalVisible ? (
        <HotelPriceModal
          isModalVisible={isUpdateModalVisible}
          hotelPriceData={hotelPriceData}
          handleCancel={handleUpCancel}
          title={"Edit a service"}
          buttonTitle="Edit"
          hotelId={hotelId}
          services={services}
          disable={true}
        />
      ) : null}
    </>
  );
}

export default BoardLists;
