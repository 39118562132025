import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import BoardLists from './Board/BoardList/BoardList';
import {getVehiclesList} from '@iso/redux/vehicle/actions';

export default function VehicleList() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getVehiclesList());
  }, []);
  return (
    <>
      <BoardLists />
    </>
  );
}
