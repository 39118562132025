import React, {useState} from 'react';
import isEmpty from 'lodash/isEmpty';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Modal, Spin} from 'antd';
import _ from 'lodash';

import NoBoardFounds from '../BoardNotFound/BoardNotFound';
import BoardListCard from './BoardListCard/BoardListCard';
import AppLayout from '../../AppLayout/AppLayout';
import {Table} from './BoardList.style';
import {deleteVehiclePrice} from '@iso/redux/vehicle/actions';

function BoardLists({history, match}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedModalBoard, setSelectedModalBoard] = useState(null);
  const [airportSearch, setAirportSearch] = useState([]);
  const [hotelSearch, setHotelSearch] = useState([]);
  const [search, setSearch] = useState(null);

  const showModal = (vehiclePrice) => {
    setSelectedModalBoard(vehiclePrice);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    dispatch(deleteVehiclePrice(selectedModalBoard.id)).then((res) => {
      setIsModalVisible(false);
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleEdit = (vehiclePrice) => {
    navigate(`/dashboard/createVehiclePrice/${vehiclePrice.id}`, {
      state: {vehiclePrice, pricingType: vehiclePrice.pricingType},
    });
  };

  let ResultList = [];

  let vehiclesPriceList = useSelector((state) => {
    return state.Vehicle.vehiclesPrice;
  });


  if (search) {
    let searched = vehiclesPriceList.filter((i) => {
      return (
        i.airport.name.toLowerCase().includes(search.toLowerCase())
      );
    });
    vehiclesPriceList = searched;
  }

  if (airportSearch.length >= 1 && hotelSearch.length >= 1) {
    ResultList = [];
    hotelSearch.forEach((hotel) => {
      airportSearch.forEach((airport) => {
        let arr = _.filter(vehiclesPriceList, function (o) {
          return o.airportId === airport && o.hotelId === hotel;
        });
        ResultList = [...ResultList, ...arr];
      });
    });
  } else if (airportSearch.length >= 1) {
    ResultList = [];
    airportSearch.forEach((item) => {
      let arr = _.filter(vehiclesPriceList, function (o) {
        return o.airportId === item;
      });
      ResultList = [...ResultList, ...arr];
    });
  } else if (hotelSearch.length >= 1) {
    ResultList = [];
    hotelSearch.forEach((item) => {
      let arr = _.filter(vehiclesPriceList, function (o) {
        return o.hotelId === item;
      });
      ResultList = [...ResultList, ...arr];
    });
  } else {
    ResultList = vehiclesPriceList;
  }

  let Loading = useSelector((state) => {
    return state.Vehicle.loading;
  });

  return (
    <>
      <AppLayout
        airportFilter={(value) => {
          setAirportSearch(value);
        }}
        hotelFilter={(value) => {
          setHotelSearch(value);
        }}
        setSearchText={(value) => setSearch(value)}>
        {Loading ? (
          <div className="spinner">
            <Spin />
          </div>
        ) : (
          <>
            {!isEmpty(ResultList) ? (
              <Table>
                {ResultList.map((board) => {
                  return (
                    <BoardListCard
                      key={board.id}
                      item={board}
                      onDelete={() => {
                        showModal(board);
                      }}
                      onEdit={() => handleEdit(board)}
                    />
                  );
                })}
              </Table>
            ) : (
              <NoBoardFounds history={history} match={match} />
            )}
          </>
        )}
      </AppLayout>
      <Modal title="Delete Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>Are you sure you want to delete this Airport Transfer?</p>
      </Modal>
    </>
  );
}

export default BoardLists;
