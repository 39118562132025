import React from "react";
import { Form } from "formik";
import Input from "@iso/components/uielements/input";
import { Button } from "antd";
import SignUpStyleWrapper from "../../pages/admin/airportTransfer/service/Board/BoardList/BoardList.style";

const ExtraServiceModal = ({
  values,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  handleChange,
  submitForm,
  buttonTitle,
  ...props
}) => {
  handleChange = (e) => {
    setFieldValue(e.target.name, e.target.value);
  };

  return (
    <SignUpStyleWrapper className="isoSignUpPage">
      <div className="isoSignUpContentWrapper">
        <div className="isoSignUpContent">
          <Form className="form-fields">
            <div className="isoSignUpForm">
              <div className="isoInputWrapper">
              <label style={{fontSize:'12px', color:'#191D26'}}>Baby Seat Price</label>
                <Input
                  size="large"
                  type="number"
                  name="babySeatPrice"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.babySeatPrice}
                />
                {errors.babySeatPrice && touched.babySeatPrice && (
                  <div className="invalid-input">{errors.babySeatPrice}</div>
                )}
              </div>
              <div className="isoInputWrapper">
              <label style={{fontSize:'12px', color:'#191D26'}}>Child Booster Seat Price</label>
                <Input
                  size="large"
                  type="number"
                  name="childBoosterSeatPrice"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.childBoosterSeatPrice}
                />
                {errors.childBoosterSeatPrice && touched.childBoosterSeatPrice && (
                  <div className="invalid-input">{errors.childBoosterSeatPrice}</div>
                )}
              </div>
              <div className="isoInputWrapper">
              <label style={{fontSize:'12px', color:'#191D26'}}>Golf Equipment Price</label>
                <Input
                  size="large"
                  type="number"
                  name="golfEquipmentPrice"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.golfEquipmentPrice}
                />
                {errors.golfEquipmentPrice && touched.golfEquipmentPrice && (
                  <div className="invalid-input">{errors.golfEquipmentPrice}</div>
                )}
              </div>
              <div className="isoInputWrapper">
              <label style={{fontSize:'12px', color:'#191D26'}}>Special Equipment Price</label>
                <Input
                  size="large"
                  type="number"
                  name="specialEquipmentPrice"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.specialEquipmentPrice}
                />
                {errors.specialEquipmentPrice && touched.specialEquipmentPrice && (
                  <div className="invalid-input">{errors.specialEquipmentPrice}</div>
                )}
              </div>
              <div className="isoInputWrapper">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    margin: "10px",
                  }}
                >
                  <Button type="primary" onClick={submitForm}>
                    {buttonTitle}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </SignUpStyleWrapper>
  );
};


export default (ExtraServiceModal);
