import React, { useState, useEffect } from "react";
import { Button, Popconfirm, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Lists from "../../../../containers/Lists/Lists";
import {
  cancelBooking,
  getCancelBookings,
} from "../../../../redux/airportTransfer/actions";
import { PAYMENT_METHODS } from "../../../../constants";
import AppLayout from "./AppLayout/AppLayout";
import isEmpty from "lodash/isEmpty";
import { Spin } from "antd";
import NoBoardFounds from "./Board/BoardNotFound/BoardNotFound";
import { StatusTag } from "../../../../containers/Lists/Lists.styles";
import _ from "lodash";
import moment from 'moment';

const List = ({ history, match }) => {
  const dispatch = useDispatch();

  const [hotels, setHotels] = useState([]);
  const [loading, setloading] = useState(true)

  useEffect(() => {
    setloading(true)
    dispatch(getCancelBookings()).then((res) => {
      setloading(false)
      setHotels(res.data);
    });
  }, []);


  const onCancelBooking = (id) => {
    dispatch(cancelBooking(id)).then((res) => {
      const tourArray = _.filter(hotels, (o) => {
        return o.id !== id;
      });
      setHotels(tourArray);
    });
  };

  const columns = [
    {
      title: "Res#",
      dataIndex: "id",
      rowKey: "id",
      width: 5,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Reason",
      dataIndex: "reason",
      rowKey: "reason",
      width: '35%',
      render: (text, data) => <span style={{whiteSpace:'wrap'}}>{data && data.cancelledBooking && data.cancelledBooking.reason}</span>,
    },
    {
      title: "Name",
      dataIndex: "clientName",
      rowKey: "clientName",
      width: '10%',
      render: (text) => <span >{text}</span>,
    },
    {
      title: "Email",
      dataIndex: "clientEmail",
      rowKey: "clientEmail",
      width: '10%',
      render: (text) => <span >{text}</span>,
    },

    {
      title: "Date",
      dataIndex: "pickUpDate",
      rowKey: "pickUpDate",
      width: '10%',
      render: (text) => <span >{moment(text).format("DD/MM/yyyy")}</span>,
    },
    {
      title: "Payment",
      dataIndex: "paymentMethod",
      rowKey: "paymentMethod",
      width: '10%',
      render: (text) => (
        <span >
          {(text === PAYMENT_METHODS.cash && "Cash") ||
            (text === PAYMENT_METHODS.card && "Card")}
        </span>
      ),
    },
    {
      title: "Price",
      dataIndex: "totalPrice",
      rowKey: "totalPrice",
      width: '5%',
      render: (text) => <span >{text}</span>,
    },
    {
      title: "Platform",
      dataIndex: "platform",
      rowKey: "platform",
      width: 15,
      render: (text) => <span >{text}</span>,
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   rowKey: "status",
    //   width: "13%",
    //   render: (text) => (
    //     <span>
    //       {(text === 0 && "-") || (
    //         <StatusTag
    //           style={{
    //             backgroundColor: "#FEAC01",
    //             padding: "10px 6px",
    //             borderRadius: "3px",
    //           }}
    //         >
    //           Cancelled
    //         </StatusTag>
    //       )}
    //     </span>
    //   ),
    // },
    // {
    //   title: "",
    //   dataIndex: "id",
    //   rowKey: "id",
    //   width: "10%",
    //   render: (text, data) => (
    //     <div className="isoInvoiceBtnView">
    //       <Button
    //         color="primary"
    //         className="invoiceViewBtn"
    //         onClick={() => handleReceipt(data.id)}
    //       >
    //         Invoice
    //       </Button>
    //     </div>
    //   ),
    // },
    {
      title: "",
      dataIndex: "id",
      rowKey: "id",
      width: "10%",
      render: (text, data) => (
        <div className="isoInvoiceBtnView">
          <div className="isoInvoiceBtnView">
            <Popconfirm
              title="Sure to cancel?"
              onConfirm={() => onCancelBooking(text)}
            >
              <Button className="invoiceViewBtn">Cancel</Button>
            </Popconfirm>
          </div>
        </div>
      ),
    },
  ];
  return (
    <>
      <AppLayout>
        {loading ? (
          <div className="spinner">
            <Spin />
          </div>
        ) : !isEmpty(hotels) ? (
          <Lists title={"Cancel Requests"} Columns={columns} Rows={hotels} />
        ) : (
          <NoBoardFounds history={history} match={match} />
        )}
      </AppLayout>
    </>
  );
};

export default List;
