const SuperAdminOption = [
  {
    key: "admin-list",
    label: "Admin List",
    leftIcon: "ion-android-checkbox-outline",
  },
  {
    key: "hotels-list",
    label: "Hotels",
    leftIcon: "ion-android-checkbox-outline",
  },
  {
    key: "booking",
    label: "Booking",
    leftIcon: "ion-android-checkbox-outline",
    children: [
      {
        key: "booking/tour-reports",
        label: "Tour",
        // withoutDashboard: true
      },
      {
        key: "booking/airport-reports",
        label: "Airport Transfer",
        // withoutDashboard: true
      },
    ],
  },
];

const AdminOption = [
  {
    key: "hotels-list",
    label: "Hotels",
    leftIcon: "ion-android-checkbox-outline",
  },
  {
    key: "receptionists-list",
    label: "Receptionists",
    leftIcon: "ion-android-checkbox-outline",
  },
  {
    space: true,
  },
  {
    key: "ticket-tours-list",
    label: "Ticket",
    leftIcon: "ion-android-checkbox-outline",
  },
  {
    key: "ticket-guide-tours-list",
    label: "Ticket + Guide",
    leftIcon: "ion-android-checkbox-outline",
  },
  {
    key: "tours-list",
    label: "Shared Tours",
    leftIcon: "ion-android-checkbox-outline",
  },
  {
    key: "private-tours-list",
    label: "Private Tours",
    leftIcon: "ion-android-checkbox-outline",
  },
  // {
  //   key: 'transport-ticket-tours-list',
  //   label: 'Transport Ticket',
  //   leftIcon: 'ion-android-checkbox-outline',
  // },

  // {
  //   key: 'private-tours-list',
  //   label: 'Private Tours',
  //   leftIcon: 'ion-android-checkbox-outline',
  // },
  // {
  //   key: 'vehicle-price',
  //   label: 'Airport Transfers',
  //   leftIcon: 'ion-android-checkbox-outline',
  // },
  {
    key: "general_transfer",
    label: "General Transfer",
    leftIcon: "ion-android-checkbox-outline",
  },
  {
    key: "special-monthly-excursion",
    label: "Special Excursion",
    leftIcon: "ion-android-checkbox-outline",
  },
  {
    space: true,
  },
  {
    key: "airport-list",
    label: "Airports",
    leftIcon: "ion-android-checkbox-outline",
  },
  {
    key: "airportTransfer",
    label: "Airport Transfers",
    leftIcon: "ion-android-checkbox-outline",
    children: [
      {
        key: "airportTransfer/services",
        label: "Service",
        // withoutDashboard: true
      },
      {
        key: "airportTransfer/extraServices",
        label: "Extra Service",
        // withoutDashboard: true
      },
      {
        key: "airportTransfer/city-price",
        label: "City Price",
        // withoutDashboard: true
      },
      {
        key: "airportTransfer/hotel-price",
        label: "Hotel Price",
        // withoutDashboard: true
      },
    ],
  },
  {
    key: "city-list",
    label: "Cities",
    leftIcon: "ion-android-checkbox-outline",
  },
  {
    key: "bus-stop-list",
    label: "Bus Stops",
    leftIcon: "ion-android-checkbox-outline",
  },
  {
    key: "booking",
    label: "Bookings",
    leftIcon: "ion-android-checkbox-outline",
    children: [
      {
        key: "booking/tour-reports",
        label: "Tours",
        // withoutDashboard: true
      },
      {
        key: "booking/airport-reports",
        label: "Airport Transfers",
        // withoutDashboard: true
      },
    ],
  },
  {
    key: "cancel-requests",
    label: "Cancel Requests",
    leftIcon: "ion-android-checkbox-outline",
    children: [
      {
        key: "cancel-requests/tour",
        label: "Tours",
        // withoutDashboard: true
      },
      {
        key: "cancel-requests/airportTransfer",
        label: "Airport Transfers",
        // withoutDashboard: true
      },
    ],
  },
  {
    key: "setting",
    label: "Setting",
    leftIcon: "ion-android-checkbox-outline",
    children: [
      {
        key: "setting/closing-time",
        label: "Closing Time",
        // withoutDashboard: true
      },
      {
        key: "setting/hotel-banner",
        label: "Hotel Banner",
        // withoutDashboard: true
      },
      {
        key: "setting/airport-transfers-voucher",
        label: "Airport Transfer Voucher",
        // withoutDashboard: true
      },
    ],
  },
];

const TCAdminOption = [
  {
    key: "vehicle-list",
    label: "Vehicle List",
    leftIcon: "ion-android-checkbox-outline",
  },
];

const ReceptionistOptions = (hotel) => [
  // {
  //   key: 'private-tours-booking-list',
  //   label: 'Private Tour',
  //   leftIcon: 'ion-android-checkbox-outline',
  // },
  {
    key: "ticket-tours-booking-list",
    label: "Ticket",
    leftIcon: "ion-android-checkbox-outline",
  },
  {
    key: "ticket-guide-tours-booking-list",
    label: "Ticket + Guide",
    leftIcon: "ion-android-checkbox-outline",
  },
  {
    key: "shared-tours-booking-list",
    label: "Shared Tour",
    leftIcon: "ion-android-checkbox-outline",
  },
  {
    key: "private-tours-booking-list",
    label: "Private Tour",
    leftIcon: "ion-android-checkbox-outline",
  },
  {
    key: "monthly-excursion-booking-list",
    label: "Special Excursion",
    leftIcon: "ion-android-checkbox-outline",
  },
  // {
  //   key: 'tours-transport-list',
  //   label: 'Airport Transfers',
  //   leftIcon: 'ion-android-checkbox-outline',
  // },
  hotel
    ? {
        key: "airport-transfer-list",
        label: "Airport Transfers",
        leftIcon: "ion-android-checkbox-outline",
        withoutDashboard: hotel,
      }
    : { space: true },
];

export { SuperAdminOption, AdminOption, TCAdminOption, ReceptionistOptions };
