import React from "react";
import { Layout, Button } from "antd";
import { useNavigate } from "react-router";

import SearchInput from "@iso/components/ScrumBoard/SearchInput/SearchInput";
import { Title, Header, HeaderSecondary } from "./AppLayout.style";
import { TOUR_NAMES, TOUR_TYPES } from "../../../../constants";
import { SettingFilled } from '@ant-design/icons';

const { Content } = Layout;

const AppLayout = ({ children, setSearchText, history, match }) => {
  const navigate = useNavigate();
  return (
    <Layout style={{ backgroundColor: "#fff" }}>
      <Header>
        <Title>{TOUR_NAMES[TOUR_TYPES.tpt_tkt_lcl_acc_guide].name}</Title>
        <div>
          <Button
          icon={<SettingFilled />}
            type="secondary"
            style={{margin:'0 5px 0 0', backgroundColor:'#aaaaa1', color:'#ffffff'}}
            onClick={() => {
              navigate(`/dashboard/tour-setting`, {
                state: { type: TOUR_TYPES.tpt_tkt_lcl_acc_guide },
              });
            }}
          >
            Setting
          </Button>
          <Button
            type="primary"
            onClick={() => {
              navigate("/dashboard/createPrivateTours", {
                state: { selectedType: "public" },
              });
            }}
          >
            Add new
          </Button>
        </div>
      </Header>
      <HeaderSecondary>
        <SearchInput
          onChange={(value) => setSearchText(value)}
          onCross={(value) => {
            setSearchText(value);
          }}
        />
      </HeaderSecondary>

      <Content
        style={{
          padding: "0 24px",
        }}
      >
        {children}
      </Content>
    </Layout>
  );
};

export default AppLayout;
