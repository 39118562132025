import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Radio, { RadioGroup } from "@iso/components/uielements/radio";
// import Collapse from "@iso/components/uielements/collapse";

import { Formik } from "formik";
import _ from "lodash";
import TimeKeeper from "react-timekeeper";
import { Col, Modal, Row } from "antd";

import Dropdown from "@iso/components/uielements/CustomDropdown";
import Input from "@iso/components/uielements/input";
import Button from "@iso/components/uielements/button";
import DatePicker from "@iso/components/uielements/datePicker";
import { privateBookingValidation2 } from "@iso/constants";
import SignUpStyleWrapper from "./BookPrivateTours.styles";
import {
  getTourBookingTypeList,
  bookPrivateTour,
  getPrivateToursList,
  tourClientSetting,
  bookUpdatePrivateTour,
  getPaymentIntent,
} from "../../../redux/receptionist/actions";
import appActions from "@iso/redux/app/actions";
import { fetchCurrency } from "../../../redux/common/actions";
import { fillTourSetting, validationClient } from "../../../services";
import basicStyle from "@iso/assets/styles/constants";
import moment from "moment";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { EURO, PLATFORM, TOUR_NAMES, TOUR_TYPES } from "../../../constants";
import { Textarea } from "../../../components/uielements/input";
import "../../../assets/styles/bookTour.css";
import Select from "@iso/components/uielements/select";
import * as rdd from "react-device-detect";
import { BOOKING_CLIENT_DETAILS } from "../../../constants/Tour";
import ClientDetailsForm from "../../../components/TourBooking/ClientDetailsForm";

export default function CreateTours() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getSettingTime } = appActions;

  const params = useLocation();
  const locParams = params && params.state ? params.state : { tour: null };
  const tour = (locParams && locParams.tour) || null;

  const bookId = (locParams && locParams.tour && locParams.tour.id) || null;
  const isUpdate = (locParams && locParams.flag) || 0;

  const [typeList, setTypeList] = useState([]);
  const [countryTypes, setCountryTypes] = useState([]);
  const [visible, setVisible] = useState(false);
  const [time, setTime] = useState(null);
  const [disable, setDisable] = useState(false);
  const [toursList, setToursList] = useState([]);

  // The width and device detect below which view should be rendered
  const tablet = rdd.isTablet;
  const mobile = rdd.isMobile;
  const ipad13 = rdd.isIPad13;

  const [view, setView] = useState(tablet || mobile || ipad13);
  const [width, setWidth] = useState(window.innerWidth);

  useLayoutEffect(() => {
    setView(rdd.isTablet || rdd.isMobile || rdd.isIPad13);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      const currentWindowWidth = window.innerWidth;
      setWidth(currentWindowWidth);
      setView(rdd.isTablet || rdd.isMobile || rdd.isIPad13);
    });
    return () =>
      window.removeEventListener("resize", () => {
        const currentWindowWidth = window.innerWidth;
        setWidth(currentWindowWidth);
        setView(rdd.isTablet || rdd.isMobile || rdd.isIPad13);
      });
  }, [width]);

  // const [selectToursList, setSelectToursList] = useState({
  //   value: toursList && toursList.length > 0 && toursList[0].id,
  //   label: toursList && toursList.length > 0 && toursList[0].destination,
  // });
  const room =
    (tour && tour.room && tour.room[0] && tour.room[0].roomNumber) || [];

  let roomOption = [];

  const roomLength = (room && room.length) || 0;
  for (let i = 0; i < roomLength; i++) {
    roomOption.push({
      value: room[i].value,
      label: room[i].label,
    });
  }

  const [ClientRoom, setClientRoom] = useState(roomOption);

  const adults = tour && tour.noOfPersons;

  const [noAdults, setAdults] = useState(adults || 0);
  const [clientMessage, setclientMessage] = useState(false);

  const [clientDetail, setClientDetail] = useState(
    (tour && tour.bookingClient) || []
  );

  const addElement = () => {
    if (isUpdate) {
      return;
    }
    setAdults(noAdults + 1);
    setclientMessage(false);
    setClientDetail([...clientDetail, BOOKING_CLIENT_DETAILS]);
  };

  const removeFormFields = (i) => {
    if (isUpdate) {
      return;
    }
    setAdults(noAdults - 1);
    let newFormValues = [...clientDetail];
    newFormValues.splice(i, 1);
    setClientDetail(newFormValues);
  };

  // const singleTour =
  //   toursList &&
  //   toursList.length > 0 &&
  //   tour &&
  //   _.filter(toursList, function (o) {
  //     return o.value === tour.tour.id;
  //   });

  const [selectToursList, setSelectToursList] = useState({});

  useEffect(() => {
    dispatch(getTourBookingTypeList()).then((res) => {
      setTypeList(res);
    });
    dispatch(fetchCurrency()).then((res) => {
      let arr = [];
      res.forEach((item) => {
        arr.push({ value: item.code, label: item.name });
      });
      setCountryTypes(arr);
    });
  }, []);

  const tr = (tour && tour.tour) || null;

  useEffect(() => {
    dispatch(getPrivateToursList()).then((res) => {
      setSelectToursList(tr);
      setToursList(res);
    });
  }, []);

  let privatePrc =
    (selectToursList &&
      selectToursList.price &&
      selectToursList.price.privatePrice) ||
    (selectToursList && selectToursList.value && selectToursList.privatePrice);

  const handleTags = (value) => {
    for (let i = 0; i < value.length; i++) {
      setClientRoom([...ClientRoom, { value: value[i], label: value[i] }]);
    }
  };

  const handleDeselect = (value) => {
    roomOption = _.filter(ClientRoom, function (o) {
      return o.value !== value;
    });
    setClientRoom(roomOption);
  };

  const [clientSecret, setClientSecret] = useState("");

  const handleSubmit = (values) => {
    // console.log('clientDetail', clientDetail);
    // return;
    let dte = null;
    if (values.date.includes("/")) {
      dte = moment(values.date, "DD/MM/YYYY").format("YYYY-MM-DD");
    } else {
      // The input is already in YYYY-MM-DD format
      dte = moment(values.date).format("YYYY-MM-DD");
    }
    // if (noAdults === 0) {
    //   setclientMessage(true);
    //   return;
    // }
    if (clientDetail && clientDetail.length === 0) {
      setclientMessage(true);
      return;
    }
    if (validationClient(clientDetail, clientSetting)) {
      return;
    }
    setclientMessage(false);
    setDisable(true);
    let data = null;
    if (isUpdate) {
      let data = {
        clientName: values.clientName,
        clientPhone: values.clientPhone,
        clientEmail: values.clientEmail,
        receptionist: values.receptionist,
        date: dte,
        pickUpTime: values.pickUpTime,
        typeId: values.typeId.value,
        tourId: values.tourId.value,
        clientDetails: (clientDetail &&
          clientDetail.length > 0 &&
          clientDetail) || [BOOKING_CLIENT_DETAILS],
        description: values.description,
        rooms: ClientRoom,
        platform:
          (tour && tour.platform === PLATFORM.app && PLATFORM.app) ||
          PLATFORM.web,
        uId: tour && tour.uId,
      };
      dispatch(bookUpdatePrivateTour(data, bookId)).then((res) => {
        setDisable(false);
        navigate("/dashboard/ticket-tours-booking-list");
      });
    } else {
      if (values.tourId) {
        data = {
          clientName: values.clientName,
          clientPhone: values.clientPhone,
          clientEmail: values.clientEmail,
          receptionist: values.receptionist,
          date: dte,
          pickUpTime: values.pickUpTime,
          extraCharges: null,
          paymentMethod: values.paymentMethod,
          typeId: values.typeId.value,
          tourId: values.tourId.value,
          noOfPersons: noAdults,
          clientDetails: (clientDetail &&
            clientDetail.length > 0 &&
            clientDetail) || [BOOKING_CLIENT_DETAILS],
          description: values.description,
          rooms: ClientRoom,
          platform:
            (tour && tour.platform === PLATFORM.app && PLATFORM.app) ||
            PLATFORM.web,
          uId: tour && tour.uId,
        };
        dispatch(bookPrivateTour(data)).then((bookRes) => {
          if (values.paymentMethod === 4) {
            dispatch(getPaymentIntent(bookRes.bT.id)).then((res) => {
              setDisable(false);
              setClientSecret(res.paymentIntent);
              navigate(`/dashboard/payment-stripe/${res.paymentIntent}`, {
                state: {
                  bookId: bookRes.bT.id,
                  res,
                  path: "ticket-tours-booking-list",
                },
              });
            });
          } else {
            setDisable(false);
            navigate("/dashboard/ticket-tours-booking-list");
          }
        });
      }
    }

    // let data = selectToursList.value
    //   ? {
    //       clientName: values.clientName,
    //       clientPhone: values.clientPhone,
    //       clientEmail: values.clientEmail,
    //       date: values.date,
    //       pickUpTime: values.pickUpTime,
    //       extraCharges: values.extraCharges,
    //       paymentMethod: values.paymentMethod,
    //       typeId: values.typeId.value,
    //       tourId: selectToursList.value,
    //       noOfPersons: values.noOfPersons,
    //     }
    //   : {
    //       clientName: values.clientName,
    //       clientPhone: values.clientPhone,
    //       clientEmail: values.clientEmail,
    //       date: values.date,
    //       pickUpTime: values.pickUpTime,
    //       extraCharges: values.extraCharges,
    //       paymentMethod: values.paymentMethod,
    //       typeId: values.typeId.value,
    //       tour: {
    //         destination: values.destination,
    //         tagline: values.tagline,
    //         duration: values.duration,
    //         includes: values.includes,
    //         observations: values.observations,
    //         remarks: values.remarks,
    //         noOfPersons: values.noOfPersons,
    //       },
    //       price: {
    //         privatePrice: values.privatePrice,
    //         currencyCode: values.countryId.value,
    //       },
    //       departurePlace: {
    //         name: (values && values.location && values.location.name) || null,
    //         latitude:
    //           (values && values.location && values.location.lat) || null,
    //         longitude:
    //           (values && values.location && values.location.lng) || null,
    //         address:
    //           (values && values.location && values.location.address) || null,
    //         radius: radius,
    //       },
    //     };

    // dispatch(bookPrivateTour(data)).then((res) => {
    //   setDisable(false);
    //   navigate("/dashboard/private-tours-booking-list");
    // });
  };

  // let createFormValues = {
  //   clientName: "",
  //   clientPhone: "",
  //   clientEmail: "",
  //   date: "",
  //   pickUpTime: "",
  //   extraCharges: "",
  //   paymentMethod: 1,
  //   typeId: {},
  //   location: {},
  //   countryId: { value: "EUR", label: "Euro" },
  //   destination: "",
  //   tagline: "",
  //   duration: "",
  //   includes: "",
  //   observations: "",
  //   remarks: "",
  //   noOfPersons: "",
  //   privatePrice: "",
  // };

  let createSelectedFormValues = {
    clientName: tour ? tour.clientName : "",
    clientPhone: tour ? tour.clientPhone : "",
    clientEmail: tour ? tour.clientEmail : "",
    receptionist: tour ? tour.receptionist : "",
    date: tour ? tour.date : "",
    pickUpTime: tour ? moment(tour.pickUpTime, "HH:mm:ss").format("HH:mm") : "",
    extraCharges: tour ? tour.extraCharges : null,
    paymentMethod: (tour && tour.paymentMethod && tour.paymentMethod) || 1,
    noOfPersons: (tour && tour.noAdults) || "",
    typeId: typeList && typeList.length > 0 && typeList[0],
    countryId: { value: "EUR", label: "Euro" },
    tourId: (tour &&
      tour.tour && { label: tour.tour.destination, value: tour.tour.id }) || {
      value: "",
      label: "",
    },
    description: tour ? tour.description : "",
  };

  const tourList = useSelector((state) => {
    return state.Receptionist.tours;
  });

  let privateTours = _.filter(tourList, function (o) {
    return o.type === TOUR_TYPES.ticket;
  });

  let arr = [];
  privateTours.forEach((element) => {
    arr.push({
      value: element.id,
      label: element.destination,
      privatePrice: element.price.privatePrice,
    });
  });
  let func;

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };
  const { rowStyle, colStyle, gutter } = basicStyle;

  const tourID = (selectToursList && selectToursList.id) || null;

  const [clientSetting, setClienSetting] = useState(fillTourSetting());

  useEffect(() => {
    if (tourID) {
      dispatch(tourClientSetting(tourID)).then((res) => {
        setClienSetting(fillTourSetting(res));
      });
    }
  }, [tourID]);


  const handleChangeF = (i, name, value) => {

      setClientDetail((prevClientDetail) => {
        const newClientDetail = [...prevClientDetail];
        newClientDetail[i] = {
          ...newClientDetail[i],
          [name]: value,
        };
        return newClientDetail;
      });

  };

  const handleNationality = (i, e) => {
    setClientDetail((prevClientDetail) => {
      const newClientDetail = [...prevClientDetail];
      newClientDetail[i] = {
        ...newClientDetail[i],
        nationality: e.label,
      };
      return newClientDetail;
    });
  };

  const handleDocumentType = (i, e) => {
    setClientDetail((prevClientDetail) => {
      const newClientDetail = [...prevClientDetail];
      newClientDetail[i] = {
        ...newClientDetail[i],
        documentType: e.label,
      };
      return newClientDetail;
    });
  };

  const datesNotAllowed = [];

  useEffect(() => {
    dispatch(getSettingTime());
  }, []);

  const setting = useSelector((state) => {
    return state.App.time;
  });

  const currentDate = moment().format("YYYY-MM-DD");

  const closingTime = setting && setting.closingAt;

  if (closingTime < moment().format("HH:mm")) {
    moment().add(1, "days").format("YYYY-MM-DD");
    datesNotAllowed.push(moment().add(1, "days").format("YYYY-MM-DD"));
  }

  const disabledDate = (current) => {
    if (moment(current).format("YYYY-MM-DD") <= currentDate) {
      return true;
    } else {
      return datesNotAllowed.includes(moment(current).format("YYYY-MM-DD"));
    }
  };

  return (
    <div className="bookingFormWrapper">
      <SignUpStyleWrapper className="isoSignUpPage">
        <div className="isoSignUpContentWrapper">
          <div className="isoSignUpContent">
            <div className="isoLogoWrapper">
              <Link to="/dashboard">
                Book {TOUR_NAMES[TOUR_TYPES.ticket].name}
              </Link>
            </div>

            <Formik
              initialValues={
                createSelectedFormValues
                // selectToursList.value
                //   ? createSelectedFormValues
                //   : createFormValues
              }
              onSubmit={handleSubmit}
              // validationSchema={
              //   selectToursList.value
              //     ? privateBookingValidation2
              //     : privateBookingValidation
              // }
              validationSchema={privateBookingValidation2}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => {
                func = setFieldValue;
                const defaultDate = values.date
                  ? moment(values.date).format("DD/MM/YYYY")
                  : null;
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="isoSignUpForm">
                      <div className="isoInputWrapper">
                        <label className="label-style">Select a Tour</label>
                        <Dropdown
                          showSearch={!view}
                          options={toursList}
                          disable={(isUpdate && true) || false}
                          onChange={(value) => {
                            setFieldValue("tourId", value);
                            setSelectToursList(value);
                          }}
                          value={values.tourId ? values.tourId : null}
                          placeholder="Select a Tour"
                        />
                        {errors.tourId && touched.tourId && (
                          <div className="invalid-input">
                            {errors.tourId.value}
                          </div>
                        )}
                      </div>
                      <div className="RowDiv">
                        <div className="isoInputWrapper">
                          <label className="label-style">Client Name</label>
                          <Input
                            size="large"
                            type="text"
                            name="clientName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.clientName}
                          />
                          {errors.clientName && touched.clientName && (
                            <div className="invalid-input">
                              {errors.clientName}
                            </div>
                          )}
                        </div>
                        <div className="isoInputWrapper">
                          <label className="label-style">Client Phone</label>
                          <Input
                            size="large"
                            type="text"
                            name="clientPhone"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.clientPhone}
                          />
                          {errors.clientPhone && touched.clientPhone && (
                            <div className="invalid-input">
                              {errors.clientPhone}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="RowDiv">
                        <div className="isoInputWrapper">
                          <label className="label-style">Client Email</label>
                          <Input
                            size="large"
                            type="text"
                            name="clientEmail"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.clientEmail}
                          />
                          {errors.clientEmail && touched.clientEmail && (
                            <div className="invalid-input">
                              {errors.clientEmail}
                            </div>
                          )}
                        </div>
                        <div className="isoInputWrapper">
                          <label className="label-style">
                            Receptionist Name
                          </label>
                          <Input
                            size="large"
                            type="text"
                            name="receptionist"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.receptionist}
                          />
                          {errors.receptionist && touched.receptionist && (
                            <div className="invalid-input">
                              {errors.receptionist}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="isoInputWrapper">
                        <label className="label-style">Date</label>
                        <DatePicker
                          inputReadOnly={view}
                          // size="large"
                          // type="text"
                          name="date"
                          disabledDate={disabledDate}
                          onChange={(date, dateString) => {
                            // console.log(date, dateString);
                            setFieldValue("date", dateString);
                          }}
                          format="DD/MM/YYYY"
                          defaultValue={
                            defaultDate
                              ? moment(defaultDate, "DD/MM/YYYY")
                              : null
                          }
                          // onBlur={handleBlur}
                          // value={values.date}
                        />
                        {errors.date && touched.date && (
                          <div className="invalid-input">{errors.date}</div>
                        )}
                      </div>
                      <div className="RowDiv">
                        <div
                          className="isoInputWrapper"
                          onClick={() => {
                            setVisible(true);
                          }}
                          style={{ marginBottom: 10 }}
                        >
                          <label className="label-style">Pickup Time</label>

                          <Input
                            size="large"
                            type="text"
                            name="pickUpTime"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.pickUpTime}
                          />
                          {errors.pickUpTime && touched.pickUpTime && (
                            <div className="invalid-input">
                              {errors.pickUpTime}
                            </div>
                          )}
                        </div>
                        <div className="isoInputWrapper">
                          <label className="label-style">
                            Number of persons
                          </label>
                          <Input
                            size="large"
                            type="number"
                            name="noOfPersons"
                            disabled
                            onChange={(value) => {
                              setFieldValue("noOfPersons", value.target.value);
                            }}
                            onBlur={handleBlur}
                            value={(noAdults && noAdults) || null}
                            addonAfter={
                              <PlusOutlined
                                style={{ padding: "11px" }}
                                onClick={addElement}
                              />
                            }
                          />
                          {clientMessage && (
                            <div className="invalid-input">Required</div>
                          )}
                        </div>
                        {/* <div className="InvoiceTableBtn">
                          <Button
                            onClick={addElement}
                            type="primary"
                            icon={<PlusOutlined />}
                            size={10}
                          >
                            Add
                          </Button>
                        </div> */}
                        {/* </div> */}
                      </div>
                      {clientDetail &&
                        clientDetail.length > 0 &&
                        clientDetail.map((data, index) => {
                          return (
                            <ClientDetailsForm
                              key={index}
                              index={index}
                              view={view}
                              data={data}
                              clientSetting={clientSetting}
                              handleBlur={handleBlur}
                              handleChangeF={handleChangeF}
                              handleNationality={handleNationality}
                              handleDocumentType={handleDocumentType}
                              removeFormFields={removeFormFields}
                            />
                          );
                        })}
                      {/* {selectToursList.value ? null : (
                      <div className="isoInputWrapper">
                        <Input
                          size="large"
                          placeholder="Destination"
                          type="text"
                          name="destination"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.destination}
                        />
                        {errors.destination && touched.destination && (
                          <div className="invalid-input">
                            {errors.destination}
                          </div>
                        )}
                      </div>
                    )} */}
                      <div className="RowDiv">
                        <div
                          className="isoInputWrapper"
                          style={{ marginBottom: 10 }}
                        >
                          <label className="label-style">
                            Select an operator
                          </label>
                          <Dropdown
                            showSearch={view.isDesiredWidth}
                            options={typeList}
                            onChange={(value) => {
                              setFieldValue("typeId", value);
                            }}
                            value={values.typeId ? values.typeId : null}
                            // defaultValue={ {value: 6, label: 'Bespoke Guided Tour'}}
                            placeholder="Select an operator"
                          />
                          {errors.typeId && touched.typeId && (
                            <div className="invalid-input">
                              {errors.typeId.value}
                            </div>
                          )}
                        </div>
                        <div className="isoInputWrapper">
                          <label className="label-style">Add Room#</label>
                          <Select
                            mode="tags"
                            defaultValue={ClientRoom}
                            style={{ width: "100%" }}
                            onChange={handleTags}
                            // onBlur={handleBlur}
                            onDeselect={handleDeselect}
                            options={ClientRoom}
                          />
                        </div>
                        {/* {selectToursList.value ? null : (
                        <div className="isoInputWrapper">
                          <Input
                            size="large"
                            placeholder="Price"
                            type="number"
                            name="privatePrice"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.privatePrice}
                          />
                          {errors.privatePrice && touched.privatePrice && (
                            <div className="invalid-input">
                              {errors.privatePrice}
                            </div>
                          )}
                        </div>
                      )} */}
                      </div>

                      {/* {selectToursList.value ? null : (
                      <div className="RowDiv">
                        <div className="isoInputWrapper">
                          <Input
                            size="large"
                            placeholder="Duration (hours)"
                            type="number"
                            name="duration"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.duration}
                          />
                          {errors.duration && touched.duration && (
                            <div className="invalid-input">
                              {errors.duration}
                            </div>
                          )}
                        </div>

                        <div className="isoInputWrapper">
                          <Input
                            size="large"
                            placeholder="Tagline"
                            type="text"
                            name="tagline"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.tagline}
                          />
                          {errors.tagline && touched.tagline && (
                            <div className="invalid-input">
                              {errors.tagline}
                            </div>
                          )}
                        </div>
                      </div>
                    )} */}

                      {/* {selectToursList.value ? null : (
                      <div className="RowDiv">
                        <div className="isoInputWrapper">
                          <Input
                            size="large"
                            placeholder="Includes"
                            type="text"
                            name="includes"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.includes}
                          />
                          {errors.includes && touched.includes && (
                            <div className="invalid-input">
                              {errors.includes}
                            </div>
                          )}
                        </div>

                        <div className="isoInputWrapper">
                          <Input
                            size="large"
                            placeholder="Observations"
                            type="text"
                            name="observations"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.observations}
                          />
                          {errors.observations && touched.observations && (
                            <div className="invalid-input">
                              {errors.observations}
                            </div>
                          )}
                        </div>
                      </div>
                    )} */}

                      {/* {selectToursList.value ? null : (
                      <div className="RowDiv">
                        <div className="isoInputWrapper">
                          <Input
                            size="large"
                            placeholder="Remarks"
                            type="text"
                            name="remarks"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.remarks}
                          />
                          {errors.remarks && touched.remarks && (
                            <div className="invalid-input">
                              {errors.remarks}
                            </div>
                          )}
                        </div>
                        <div className="isoInputWrapper"></div>
                      </div>
                    )} */}

                      <div className="RowDiv">
                        {/* <div className="isoInputWrapper">
                          <Input
                            size="large"
                            placeholder="Extra Charges"
                            type="number"
                            name="extraCharges"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.extraCharges}
                          />
                          
                        </div> */}

                        <div className="isoInputWrapper">
                          <label className="label-style">
                            Select a Currency
                          </label>
                          <Dropdown
                            showSearch={view.isDesiredWidth}
                            options={countryTypes}
                            disable={(isUpdate && true) || false}
                            onChange={(value) => {
                              setFieldValue("countryId", value);
                            }}
                            value={values.countryId ? values.countryId : null}
                            placeholder="Select a Currency"
                          />
                          {errors.countryId && touched.countryId && (
                            <div className="invalid-input">
                              {errors.countryId.value}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="isoInputWrapper">
                        <label className="label-style">Comments</label>
                        <Textarea
                          rows={4}
                          size="large"
                          type="text"
                          name="description"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.description}
                        />
                        {errors.description && touched.description && (
                          <div className="invalid-input">
                            {errors.description}
                          </div>
                        )}
                        <small class="form-text text-muted">
                          *This does not appear on client voucher - only
                          intended for internal communication with BGT Travel
                        </small>
                      </div>
                      <div className="RowDiv">
                        <div className="isoInputWrapper">
                          <Row style={rowStyle} gutter={gutter} justify="start">
                            <Col md={12} sm={12} xs={24} style={colStyle}>
                              <div>
                                <p style={{ fontWeight: "bold" }}>
                                  Payment Method
                                </p>
                              </div>
                              <RadioGroup
                                onChange={handleChange}
                                name="paymentMethod"
                                value={values.paymentMethod}
                                disabled={(isUpdate && true) || false}
                              >
                                <Radio style={radioStyle} value={1}>
                                  Cash
                                </Radio>
                                <Radio style={radioStyle} value={2}>
                                  Credit or debit card
                                </Radio>
                                <Radio style={radioStyle} value={3}>
                                  Monthly Invoicing
                                </Radio>
                                <Radio style={radioStyle} value={4}>
                                  Pay Here With Card Now
                                </Radio>
                              </RadioGroup>
                            </Col>
                          </Row>
                          {errors.paymentMethod && touched.paymentMethod && (
                            <div className="invalid-input">
                              {errors.paymentMethod}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="isoInputWrapper">
                        <div>
                          <p>{`Total Price: ${
                            noAdults
                              ? (selectToursList && selectToursList.value) ||
                                selectToursList.id
                                ? EURO.format(
                                    parseInt(privatePrc) *
                                      parseInt(noAdults ? noAdults : "1") +
                                      parseFloat(
                                        values.extraCharges
                                          ? values.extraCharges
                                          : "0"
                                      )
                                  )
                                : "0"
                              : "0"
                          }`}</p>
                          {/* <p>{`Total Price: ${
                          values.noOfPersons
                            ? selectToursList.value
                              ? parseInt(selectToursList.privatePrice) *
                                  parseInt(
                                    values.noOfPersons
                                      ? values.noOfPersons
                                      : "1"
                                  ) +
                                parseFloat(
                                  values.extraCharges
                                    ? values.extraCharges
                                    : "0"
                                )
                              : values.noOfPersons && values.privatePrice
                              ? parseInt(values.privatePrice) *
                                  parseInt(values.noOfPersons) +
                                parseFloat(
                                  values.extraCharges
                                    ? values.extraCharges
                                    : "0"
                                )
                              : parseInt(
                                  values.privatePrice
                                    ? values.privatePrice
                                    : "0"
                                ) +
                                parseFloat(
                                  values.extraCharges
                                    ? values.extraCharges
                                    : "0"
                                )
                            : "0"
                        } ${values.countryId.label}`}</p> */}
                        </div>
                      </div>

                      {/* {selectToursList.value ? null : (
                      <>
                        <div style={{ padding: "0 7px" }}>
                          <p
                            style={{
                              fontWeight: 400,
                              paddingBottom: 10,
                              fontSize: "25px",
                              color: "black",
                            }}
                          >
                            Departure Place Location
                          </p>
                        </div>
                        <div style={{ padding: "0 7px" }}>
                          <label
                            style={{ paddingBottom: 10, color: "#788195" }}
                          >
                            Radius
                          </label>
                          <Slider
                            defaultValue={radius}
                            style={{ width: "50%" }}
                            handleStyle={{
                              marginTop: "-10px",
                              width: "22px",
                              height: "22px",
                            }}
                            max={1000}
                            onChange={onChangeRadius}
                          />
                        </div>
                      </>
                    )} */}

                      {/* {selectToursList.value ? null : (
                      <div className="mapWrapper">
                        <Map
                          radius={radius}
                          getLocation={(loc) => {
                            console.log("loc", loc);
                            setFieldValue("location", (loc && loc) || null);
                          }}
                          setLocation={null}
                          getQuery={() => {}}
                        />
                        {errors.location && touched.location && (
                          <div className="invalid-input">{errors.location}</div>
                        )}
                      </div>
                    )} */}

                      <Button
                        type="primary"
                        onClick={handleSubmit}
                        disabled={disable}
                      >
                        Book
                      </Button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
          <Modal
            title="Select your Time"
            centered
            visible={visible}
            onCancel={() => setVisible(false)}
            footer={null}
            width={300}
            bodyStyle={{ height: 500 }}
          >
            <TimeKeeper
              time={time}
              onDoneClick={(value) => {
                setTime(value.formatted24);
                func("pickUpTime", value.formatted24);
                setVisible(false);
              }}
            />
          </Modal>
          <div className="rightbar">
            <div className="rightbar-inner">
              <h3>Description</h3>
              <p>
                {(selectToursList &&
                  selectToursList.id &&
                  selectToursList.description) ||
                  "description not available!"}
              </p>
            </div>
          </div>
        </div>
      </SignUpStyleWrapper>
    </div>
  );
}
