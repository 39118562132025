import * as api from '../../constants/Api';
import moment from 'moment';
import {API} from '../../services';
import * as T from '../ActionTypes';
import {SNACKBAR_MSG_TYPE, SNACKBAR_MSG} from '../../constants';

export const createAirport = (data) => {
  return (dispatch) => {
    dispatch({type: T.AIRPORT_LOADING});
    return API.execute(api.CREATE_AIRPORT, {data})
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        dispatch({type: T.CREATE_AIRPORT, payload: response.data});
        return true;
      })
      .catch((error) => {
        let {message} = error;
        if (message === 'Network Error') {
          message = SNACKBAR_MSG.networkError;
        }
        const {messageType} = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: {message, messageType},
        });
        dispatch({type: T.AIRPORTS_ERROR, payload: error});
      });
  };
};

export const getAirportsList = () => {
  return (dispatch) => {
    dispatch({type: T.AIRPORT_LOADING});
    return API.execute(api.GET_AIRPORTS_LIST).then(
      (response) => {
        dispatch({type: T.GET_AIRPORTS_LIST, payload: response.data});
        return response.data;
      },
      (error) => {
        dispatch({type: T.AIRPORTS_ERROR, payload: error});
        return error;
      },
    );
  };
};

export const updateAirport = (data) => {
  let newData = {...data};
  let id = newData.id;
  delete data.id;

  return (dispatch) => {
    dispatch({type: T.AIRPORT_LOADING});
    return API.execute(api.UPDATE_AIRPORT(id), {data})
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        dispatch({type: T.UPDATE_AIRPORT, payload: response.data, id});
        return true;
      })
      .catch((error) => {
        let {message} = error;
        if (message === 'Network Error') {
          message = SNACKBAR_MSG.networkError;
        }
        const {messageType} = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: {message, messageType},
        });

        dispatch({type: T.AIRPORTS_ERROR, payload: error});
      });
  };
};

export const deleteAirport = (id) => {
  return (dispatch) => {
    dispatch({type: T.AIRPORT_LOADING});
    return API.execute(api.DELETE_AIRPORT(id))
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        dispatch({type: T.DELETE_AIRPORT, id});
        return true;
      })
      .catch((error) => {
        let {message} = error;
        if (message === 'Network Error') {
          message = SNACKBAR_MSG.networkError;
        }
        const {messageType} = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: {message, messageType},
        });

        dispatch({type: T.AIRPORTS_ERROR, payload: error});
      });
  };
};

// export const getAirportTransfers = (date,
//   hotelId,
//   airportId,
//   vehiclePrice) => {
//   return (dispatch) => {
//     // dispatch({ type: T.AIRPORT_LOADING });
//     return API.execute(api.GET_AIRPORT_TRANSFERS_ADMIN(date, hotelId, airportId, vehiclePrice)).then(
//       (response) => {
//         return response.data;
//       },
//       (error) => {
//         dispatch({ type: T.ADMIN_ERROR, payload: error });
//         return error;
//       }
//     );
//   };
// };


export const getAirportTransfers = (date, dateRange) => {

  let startDate = "";
  let endDate = "";
  
  if(dateRange) {
    startDate = moment(dateRange[0]).format('YYYY-MM-DD');
    endDate = moment(dateRange[1]).format('YYYY-MM-DD')
  }
  
  return (dispatch) => {
    dispatch({ type: T.AIRPORT_LOADING });
    return API.execute(api.GET_AIRPORT_TRANSFERS_ADMIN(date, startDate, endDate )).then(
      (response) => {
        dispatch({ type: T.GENERATE_AIRPORT_TRANSFERS_ADMIN_RECEIPT });
        return response.data;
      },
      (error) => {
        dispatch({ type: T.AIRPORT_ERROR, payload: error });
        return error;
      }
    );
  };
};

export const airportTransfersView = (id) => {
  return (dispatch) => {
    dispatch({ type: T.AIRPORT_LOADING });
    return API.execute(api.VIEW_AIRPORT_TRANSFERS_ADMIN(id)).then(
      (response) => {
        dispatch({ type: T.GENERATE_AIRPORT_TRANSFERS_ADMIN_RECEIPT });
        return response.data;
      },
      (error) => {
        dispatch({ type: T.AIRPORT_ERROR, payload: error });
        return error;
      }
    );
  };
};

export const generateAirportTransferAdminReceipt = (id) => {
  return (dispatch) => {
    dispatch({ type: T.AIRPORT_LOADING });
    return API.execute(api.GENERATE_AIRPORT_TRANSFERS_ADMIN_RECEIPT(id))
      .then((response) => {
        window.open(response.url, "_blank", "noreferrer");

        dispatch({ type: T.GENERATE_AIRPORT_TRANSFERS_ADMIN_RECEIPT });
        return response.url;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.AIRPORT_ERROR, payload: error });
      });
  };
};