import React from 'react';
// import {Link} from 'react-router-dom';
import {Popover} from 'antd';
import moment from 'moment';
import {
  Avatar,
  InfoWrapper,
  Title,
  CreatedAt,
  ProjectInfo,
  MoreActionWrapper,
} from './BoardListCard.style';

import {IconSvg} from '@iso/components/ScrumBoard/IconSvg/IconSvg';
import MoreIcon from '@iso/assets/images/icon/16.svg';
import AvatarIcon from '@iso/assets/airporttransfer.png';

export default function BoardListCard({item, onDelete}) {
  const MoreActions = (
    <MoreActionWrapper>
      <p onClick={onDelete}>Delete Airport Transfer</p>
    </MoreActionWrapper>
  );

  return (
    <tbody>
      <tr>
        <td>
          {/* <Link to={`/dashboard/scrum-board/project/${item.id}`}> */}
          <ProjectInfo>
            <Avatar src={AvatarIcon} alt={item.name} style={{backgroundColor: 'white'}}/>
            <InfoWrapper>
              <Title>{`${item.clientName}`}</Title>
              <CreatedAt>{`${item.clientEmail}`}</CreatedAt>
            </InfoWrapper>
          </ProjectInfo>
          {/* </Link> */}
        </td>
        <td>
          <div style={{width: 180}}>{`${item.pickUpTime} - ${moment(item.createdAt).format('MMM D, YYYY')}`}</div>
        </td>
        <td>{item.airport.name}</td>
        <td>
          {item.farePrice} {item.currencyCode}
        </td>
        <td>
          <>
            {/* <IconSvg src={PrivateIcon} border={'none'} />
            {item.country} */}
          </>
        </td>
        <td>{/* <IconSvg src={PlusIcon} radius={'10% 30% 25%'} padding={8} /> */}</td>
        <td>
          <Popover placement="bottom" content={MoreActions} trigger="click">
            <IconSvg src={MoreIcon} border={'none'} padding={'0'} />
          </Popover>
        </td>
      </tr>
    </tbody>
  );
}
