import React from 'react';
import {Layout, Button} from 'antd';
// import {Scrollbars} from 'react-custom-scrollbars';
import {useNavigate} from 'react-router-dom';

import {Title, Header} from './AppLayout.style';

const {Content} = Layout;

const AppLayout = ({children, setSearchText, history, match}) => {
  const navigate = useNavigate();

  return (
    <Layout style={{backgroundColor: '#fff'}}>
      <Header>
        <Title>Vehicles List</Title>

        <Button type="primary" onClick={() => navigate('/dashboard/createVehicle')}>
          Add new
        </Button>
      </Header>

      <Content
        style={{
          padding: '0 24px',
        }}>
        {children}
        {/* <Scrollbars style={{width: '100%', height: '500px'}}>{children}</Scrollbars> */}
      </Content>
    </Layout>
  );
};

export default AppLayout;
