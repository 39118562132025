import React from "react";
import { Route } from "react-router-dom";

import BookPrivateTours from "../pages/receptionist/bookPrivateTours/BookPrivateTours";
import BookSharedTours from "../pages/receptionist/bookSharedTours/BookSharedTours";
import BookTicketTours from "../pages/receptionist/bookTicketTours/BookTicketTours";
import BookTicketGuideTours from "../pages/receptionist/bookTicketGuideTours/BookTicketGuideTours";
import BookTransportTours from "../pages/receptionist/bookTransportTour/BookTransportTour";
import BookTransportTicketTours from "../pages/receptionist/bookTransportTicketTour/BookTransportTicketTour";

import BookedSharedToursList from "../pages/receptionist/sharedBookedTours";
import BookedTicketToursList from "../pages/receptionist/ticketBookedTours";
import BookedTicketGuideToursList from "../pages/receptionist/ticketGuideBookedTours";
import BookedTransportToursList from "../pages/receptionist/transportBookedTours";
import BookedTransportTicketToursList from "../pages/receptionist/transportTicketBookedTours";

import MonthlyExcursionList from "@iso/pages/receptionist/monthlyExcursionBooked";
import BookMonthlyExcursion from "../pages/receptionist/bookMonthlyExcursion/BookMonthlyExcursion";

import BookedPrivateToursList from "../pages/receptionist/privateBookedTours";
import BookVehicleTransport from "../pages/receptionist/bookVehicleTransport/BookVehicleTransport";
import TransportList from "../pages/receptionist/transportList";
import ResetPassword from "@iso/pages/ResetPassword/ResetPassword";
import Dashboard from "@iso/pages/receptionist/dashboard/Dashboard";
import Profile from "@iso/pages/profile/Profile";

import BookAirportTransfer from "@iso/pages/receptionist/bookAirportTransfer/BookAirportTransfer";

import AirportTransferList from "@iso/pages/receptionist/airportTransferBooked";
import { useSelector } from "react-redux";
import Payment from "../components/PaymentStripe/Payment";

export default function ReceptionistRoutes(hotel) {
  return (
    <>
      <Route exact path="" element={<Dashboard />} />
      <Route exact path="my-profile" element={<Profile />} />

      <Route
        exact
        path="ticket-tours-booking/:id"
        element={<BookPrivateTours />}
      />
      <Route
        exact
        path="shared-tours-booking/:id"
        element={<BookSharedTours />}
      />
      <Route
        exact
        path="ticket-tours-booking-list"
        element={<BookedPrivateToursList />}
      />
      <Route
        exact
        path="shared-tours-booking-list"
        element={<BookedSharedToursList />}
      />

      <Route exact path="payment-stripe/:paymentIntentClientSecret" element={<Payment />} />

      <Route
        exact
        path="monthly-excursion/:id"
        element={<BookMonthlyExcursion />}
      />

      <Route
        exact
        path="monthly-excursion-booking-list/:id"
        element={<MonthlyExcursionList />}
      />

      <Route
        exact
        path="monthly-excursion-booking-list"
        element={<MonthlyExcursionList />}
      />

      {/* <Route
        exact
        path="ticket-tours-booking/:id"
        element={<BookTicketTours />}
      /> */}

      <Route
        exact
        path="private-tours-booking-list"
        element={<BookedTransportToursList />}
      />

      <Route
        exact
        path="private-tours-booking/:id"
        element={<BookTransportTours />}
      />

      <Route
        exact
        path="ticket-guide-tours-booking-list"
        element={<BookedTransportTicketToursList />}
      />

      <Route
        exact
        path="ticket-guide-tours-booking/:id"
        element={<BookTransportTicketTours />}
      />
      {hotel ? (
        <>
          <Route
            exact
            path="airport-transfer/:id"
            element={<BookAirportTransfer />}
          />
          <Route
            exact
            path="airport-transfer-list"
            element={<AirportTransferList />}
          />
        </>
      ) : null}

      {/* <Route
        exact
        path="ticket-guide-tours-booking-list"
        element={<BookedTicketGuideToursList />}
      />

      <Route
        exact
        path="ticket-guide-tours-booking/:id"
        element={<BookTicketGuideTours />}
      /> */}

      {/* <Route
        exact
        path="ticket-tours-booking-list"
        element={<BookedTicketToursList />}
      /> */}

      <Route
        exact
        path="tours-transport-booking"
        element={<BookVehicleTransport />}
      />
      <Route exact path="tours-transport-list" element={<TransportList />} />
      <Route path="resetPassword" element={<ResetPassword />} />
    </>
  );
}
