import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Formik } from "formik";

import Dropdown from "@iso/components/uielements/CustomDropdown";
import Input from "@iso/components/uielements/input";
import Button from "@iso/components/uielements/button";
import {
  createReceptionist,
  updateReceptionist,
} from "@iso/redux/receptionists/actions";
import IntlMessages from "@iso/components/utility/intlMessages";
import SignUpStyleWrapper from "./CreateReceptionists.styles";
import {
  receptionistUpdateValidation,
  receptionistValidation,
} from "../../../constants";
import { getHotelsList } from "@iso/redux/hotels/actions";

export default function CreateReceptionists() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useLocation();
  const locParams =
    params && params.state ? params.state : { receptionist: null };

  const [hotelsList, setHotelsList] = useState([]);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    dispatch(getHotelsList()).then((res) => {
      let arr = [];
      res.forEach((item) => {
        arr.push({ value: item.id, label: item.name });
      });
      setHotelsList(arr);
    });
  }, []);

  const handleSubmit = (values) => {
    setDisable(true);
    if (locParams.receptionist) {
      let obj = {
        id: locParams.receptionist.userId,
        firstName: values.firstName,
        lastName: values.lastName,
        profile: {
          city: values.city,
          country: values.country,
        },
      };
      dispatch(updateReceptionist(obj)).then((res) => {
        setDisable(false);
        navigate("/dashboard/receptionists-list");
      });
    } else {
      dispatch(createReceptionist(values)).then((res) => {
        setDisable(false);
        navigate("/dashboard/receptionists-list");
      });
    }
  };

  let createFormValues = {
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    hotelId: { value: "" },
  };

  let updateFormValues = {
    firstName: locParams.receptionist
      ? locParams.receptionist.user.firstName
      : "",
    lastName: locParams.receptionist
      ? locParams.receptionist.user.lastName
      : "",
    city:
      (locParams.receptionist &&
        locParams.receptionist.user &&
        locParams.receptionist.user.profile &&
        locParams.receptionist.user.profile.city) ||
      "",
    country:
      (locParams.receptionist &&
        locParams.receptionist.user &&
        locParams.receptionist.user.profile &&
        locParams.receptionist.user.profile.country) ||
      "",
  };

  return (
    <SignUpStyleWrapper className="isoSignUpPage">
      <div className="isoSignUpContentWrapper">
        <div className="isoSignUpContent">
          <div className="isoLogoWrapper">
            <Link to="/dashboard">
              <IntlMessages
                id={
                  (locParams.receptionist && "Edit Receptionist") ||
                  "Add a New Receptionist"
                }
              />
            </Link>
          </div>

          <Formik
            initialValues={
              locParams.receptionist ? updateFormValues : createFormValues
            }
            onSubmit={handleSubmit}
            validationSchema={
              locParams.receptionist
                ? receptionistUpdateValidation
                : receptionistValidation
            }
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="isoSignUpForm">
                  <div className="isoInputWrapper">
                    <label
                      style={{
                        fontSize: "13px",
                        fontWeight: "500",
                        color: "#7881A3",
                        padding: "0 5px",
                      }}
                    >
                      First Name
                    </label>
                    <Input
                      size="large"
                      type="text"
                      name="firstName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                    />
                    {errors.firstName && touched.firstName && (
                      <div className="invalid-input">{errors.firstName}</div>
                    )}
                  </div>

                  <div className="isoInputWrapper">
                    <label
                      style={{
                        fontSize: "13px",
                        fontWeight: "500",
                        color: "#7881A3",
                        padding: "0 5px",
                      }}
                    >
                      Last Name
                    </label>
                    <Input
                      size="large"
                      type="text"
                      name="lastName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                    />
                    {errors.lastName && touched.lastName && (
                      <div className="invalid-input">{errors.lastName}</div>
                    )}
                  </div>

                  {locParams.receptionist ? null : (
                    <div className="isoInputWrapper">
                      <label
                        style={{
                          fontSize: "13px",
                          fontWeight: "500",
                          color: "#7881A3",
                          padding: "0 5px",
                        }}
                      >
                        Email
                      </label>
                      <Input
                        size="large"
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {errors.email && touched.email && (
                        <div className="invalid-input">{errors.email}</div>
                      )}
                    </div>
                  )}

                  {locParams.receptionist ? (
                    <>
                      <div className="isoInputWrapper isoLeftRightComponent">
                        <label className="label-style">City</label>
                        <Input
                          size="large"
                          type="text"
                          name="city"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.city}
                        />
                        <label className="label-style">Country</label>
                        <Input
                          size="large"
                          type="text"
                          name="country"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.country}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="isoInputWrapper">
                        <label className="label-style">Password</label>
                        <Input
                          size="large"
                          type="password"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                        />
                        {errors.password && touched.password && (
                          <div className="invalid-input">{errors.password}</div>
                        )}
                      </div>
                      <div className="isoInputWrapper">
                        <label className="label-style">Confirm Password</label>
                        <Input
                          size="large"
                          type="password"
                          name="confirmPassword"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirmPassword}
                        />
                        {errors.confirmPassword && touched.confirmPassword && (
                          <div className="invalid-input">
                            {errors.confirmPassword}
                          </div>
                        )}
                      </div>
                      <div className="isoInputWrapper">
                        <label className="label-style">Select a Hotel</label>
                        <Dropdown
                          placeholder="Select a Hotel"
                          options={hotelsList}
                          onChange={(value) => {
                            setFieldValue("hotelId", value);
                          }}
                          value={values.hotelId ? values.hotelId : null}
                        />
                        {errors.hotelId && touched.hotelId && (
                          <div className="invalid-input">
                            {errors.hotelId.value}
                          </div>
                        )}
                      </div>
                    </>
                  )}

                  <div className="isoInputWrapper">
                    <Button
                      type="primary"
                      onClick={handleSubmit}
                      disabled={disable}
                    >
                      <IntlMessages
                        id={
                          locParams.receptionist
                            ? "Update"
                            : "page.signUpButton"
                        }
                      />
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </SignUpStyleWrapper>
  );
}
