import React from "react";
import { Layout } from "antd";
import { Scrollbars } from "react-custom-scrollbars";

import { Header, Title } from "./AppLayout.style";

const { Content } = Layout;

const AppLayout = ({ children, title }) => {
  return (
    <Layout style={{ backgroundColor: "#fff" }}>
      <Header>
        <Title>{title}</Title>
      </Header>
      <Content
        style={{
          padding: "0 24px",
        }}
      >
        <Scrollbars style={{ width: "100%", height: "80vh" }}>
          {children}
        </Scrollbars>
      </Content>
    </Layout>
  );
};

export default AppLayout;
