import _ from 'lodash';

import * as T from '../ActionTypes';

const initState = {receptionists: null, loading: false};

export default function receptionistsReducer(state = initState, action) {
  switch (action.type) {
    case T.CREATE_RECEPTIONIST: {
      let newReceptionistsList = state.receptionists ? [...state.receptionists] : [];
      newReceptionistsList.push(action.payload);
      return {...state, receptionists: newReceptionistsList, loading: false};
    }
    case T.UPDATE_RECEPTIONIST: {
      const receptionistsArray = _.filter(state.receptionists, (o) => {
        return o.id !== action.id;
      });
      receptionistsArray.push(action.payload);
      return {...state, receptionists: receptionistsArray, loading: false};
    }

    case T.GET_RECEPTIONISTS_LIST: {
      return {...state, receptionists: action.payload, loading: false};
    }

    case T.ACTIVATE_RECEPTIONIST: {
      const statusChangedIndex = _.findIndex(state.receptionists, (o) => {
        return o.id === action.id;
      });

      const newArray = [...state.receptionists];

      newArray[statusChangedIndex].status = 1;

      return {...state, receptionists: newArray, loading: false};
    }

    case T.DEACTIVATE_RECEPTIONIST: {
      const statusChangedIndex = _.findIndex(state.receptionists, (o) => {
        return o.id === action.id;
      });

      const newArray = [...state.receptionists];

      newArray[statusChangedIndex].status = 0;

      return {...state, receptionists: newArray, loading: false};
    }

    case T.RECEPTIONISTS_LOADING: {
      return {...state, loading: true};
    }

    default:
      return state;
  }
}
