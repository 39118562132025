import React, { useState, useEffect, useLayoutEffect, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Radio, { RadioGroup } from "@iso/components/uielements/radio";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import _ from "lodash";

import Dropdown from "@iso/components/uielements/CustomDropdown";
import Input from "@iso/components/uielements/input";
import Button from "@iso/components/uielements/button";
import DatePicker from "@iso/components/uielements/datePicker";
import { sharedBookingValidation } from "@iso/constants";
import SignUpStyleWrapper from "./BookSharedTours.styles";
import {
  getTourBookingTypeList,
  bookSharedTour,
  getToursList,
  viewDefaultPrice,
  viewHotelPrice,
  tourClientSetting,
  bookUpdateSharedTour,
  getPaymentIntent,
} from "../../../redux/receptionist/actions";
import basicStyle from "@iso/assets/styles/constants";
import { Col, Row, Spin, Tag } from "antd";
import moment from "moment";
import {
  PlusOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  EURO,
  LANGUAGES,
  nonPrivateUsers,
  PLATFORM,
  TOUR_TYPES,
} from "../../../constants";
import { fetchTourCloseDayByRec } from "../../../redux/tours/actions";
import appActions from "@iso/redux/app/actions";

import { Textarea } from "../../../components/uielements/input";
import "../../../assets/styles/bookTour.css";
import Select from "@iso/components/uielements/select";
import NATIONS from "../../../constants/Nationality";
import * as rdd from "react-device-detect";
import { fillTourSetting, validationClient } from "../../../services";
import { BOOKING_CLIENT_DETAILS } from "../../../constants/Tour";
import ClientDetailsForm from "../../../components/TourBooking/ClientDetailsForm";
import { fetchavailableExc } from "../../../redux/common/actions";
const { getSettingTime } = appActions;

export default function CreateTours() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useLocation();
  const locParams =
    params && params.state ? params.state : { tour: null, flag: 0 };
  const tour = (locParams && locParams.tour) || null;

  const closeDayData = (locParams && locParams.closeDate) || [];

  const bookId = (locParams && locParams.tour && locParams.tour.id) || null;
  const existingTourId =
    (locParams && locParams.tour && locParams.tour.tourId) || (locParams && locParams.tour && locParams.tour.tour && locParams.tour.tour.id) || null;

  const isUpdate = (locParams && locParams.flag) || 0;

  const [tourPrice, setTourPrice] = useState(null);
  const [hotelPrice, setHotelPrice] = useState(null);

  const [closeDay, setCloseDay] = useState(closeDayData);
  const [typeList, setTypeList] = useState([]);
  const [disable, setDisable] = useState(false);
  const [tour_id, setTourId] = useState(existingTourId);
  const [pickData, setpickData] = useState(null);
  const [availableExc, setAvailableExc] = useState(null);

  // The width and device detect below which view should be rendered

  const tablet = rdd.isTablet;
  const mobile = rdd.isMobile;
  const ipad13 = rdd.isIPad13;
  const [view, setView] = useState(tablet || mobile || ipad13);
  const [width, setWidth] = useState(window.innerWidth);
  const [loading, setLoading] = useState(true);

  // const breakpoint = 810;

  // const [view, setView] = useState({
  //   windowWidth: window.innerWidth,
  //   isDesiredWidth: false,
  // });

  useLayoutEffect(() => {
    setView(rdd.isTablet || rdd.isMobile || rdd.isIPad13);
    // const currentWindowWidth = window.innerWidth;
    // const isDesiredWidth = currentWindowWidth > breakpoint;
    // setView({ windowWidth: currentWindowWidth, isDesiredWidth });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      const currentWindowWidth = window.innerWidth;
      setWidth(currentWindowWidth);
      // const isDesiredWidth = currentWindowWidth > breakpoint;
      setView(rdd.isTablet || rdd.isMobile || rdd.isIPad13);
    });
    return () =>
      window.removeEventListener("resize", () => {
        const currentWindowWidth = window.innerWidth;
        setWidth(currentWindowWidth);
        // const isDesiredWidth = currentWindowWidth > breakpoint;
        setView(rdd.isTablet || rdd.isMobile || rdd.isIPad13);
      });
  }, [width]);

  const room =
    (tour && tour.room && tour.room[0] && tour.room[0].roomNumber) || [];

  let roomOption = [];

  const roomLength = (room && room.length) || 0;
  for (let i = 0; i < roomLength; i++) {
    roomOption.push({
      value: room[i].value,
      label: room[i].label,
    });
  }

  const [ClientRoom, setClientRoom] = useState(roomOption);

  const [client, setClients] = useState(null);

  const adults = tour && tour.numOfAdults;
  const hotelId =
    (tour &&
      tour.agent &&
      tour.agent.receptionist &&
      tour.agent.receptionist[0] &&
      tour.agent.receptionist[0].hotelId) ||
    null;

  const [noAdults, setAdults] = useState(adults || 0);
  const [noChild, setChild] = useState((tour && tour.numOfChildren) || 0);
  const [noBaby, setBaby] = useState((tour && tour.numOfBabies) || 0);

  const [clientDetail, setClientDetail] = useState(
    (tour && tour.bookingClient) || []
  );

  const [clientMessage, setclientMessage] = useState(
    (tour && tour.bookingClient) || []
  );

  const [clientMessageFlag, setclientMessageFlag] = useState(false);

  const [clientRemoveFlag, setclientRemoveFlag] = useState([]);

  const [clientSetting, setClienSetting] = useState(fillTourSetting());

  const addElement = (setFieldValue) => {
    let updatedClientDetails = null;
    if (!client) {
      return;
    } else if (client.value === 0) {
      setAdults(noAdults + 1);
      setclientRemoveFlag([...clientRemoveFlag, client.value]);
      setclientMessageFlag(false);
      updatedClientDetails = { ...BOOKING_CLIENT_DETAILS, type: "Adult" };
    } else if (client.value === 1) {
      setclientRemoveFlag([...clientRemoveFlag, client.value]);
      setChild(noChild + 1);
      setclientMessageFlag(false);
      updatedClientDetails = { ...BOOKING_CLIENT_DETAILS, type: "Child" };
    } else if (client.value === 2) {
      setclientRemoveFlag([...clientRemoveFlag, client.value]);
      setBaby(noBaby + 1);
      setclientMessageFlag(false);
      updatedClientDetails = { ...BOOKING_CLIENT_DETAILS, type: "Baby" };
    }
    setClientDetail([...clientDetail, updatedClientDetails]);

    const persons = clientDetail.length + 1;
    setFieldValue("date", "");
    setpickData(null);
    dispatch(fetchavailableExc(tour_id, persons)).then((res) => {
      setAvailableExc(res);
      
    });
  };

  const removeFormFields = (i, setFieldValue) => {
    if (isUpdate) {
      return;
    }
    if (clientRemoveFlag[i] === 0) {
      clientRemoveFlag.splice(i, 1);
      setclientRemoveFlag(clientRemoveFlag);
      setAdults(noAdults - 1);
    } else if (clientRemoveFlag[i] === 1) {
      clientRemoveFlag.splice(i, 1);
      setclientRemoveFlag(clientRemoveFlag);
      setChild(noChild - 1);
    } else if (clientRemoveFlag[i] === 2) {
      clientRemoveFlag.splice(i, 1);
      setclientRemoveFlag(clientRemoveFlag);
      setBaby(noBaby - 1);
    }
    let newFormValues = [...clientDetail];
    newFormValues.splice(i, 1);
    const persons = newFormValues.length;
    setClientDetail(newFormValues);
    setFieldValue("date", "");
      setpickData(null);
    dispatch(fetchavailableExc(tour_id, persons)).then((res) => {
      setAvailableExc(res);
    });
    
  };

  const handleChangeF = (i, name, value) => {
    setClientDetail((prevClientDetail) => {
      const newClientDetail = [...prevClientDetail];
      newClientDetail[i] = {
        ...newClientDetail[i],
        [name]: value,
      };
      return newClientDetail;
    });
  };

  const handleNationality = (i, e) => {
    setClientDetail((prevClientDetail) => {
      const newClientDetail = [...prevClientDetail];
      newClientDetail[i] = {
        ...newClientDetail[i],
        nationality: e.label,
      };
      return newClientDetail;
    });

    // let newFormValues = [...clientDetail];
    // newFormValues[i]["nationality"] = e.label;
    // setClientDetail(newFormValues);
  };

  const handleDocumentType = (i, e) => {
    // let newFormValues = [...clientDetail];
    // newFormValues[i]["documentType"] = e.label;
    // setClientDetail(newFormValues);

    setClientDetail((prevClientDetail) => {
      const newClientDetail = [...prevClientDetail];
      newClientDetail[i] = {
        ...newClientDetail[i],
        documentType: e.label,
      };
      return newClientDetail;
    });
  };

  const handleTags = (value) => {
    for (let i = 0; i < value.length; i++) {
      setClientRoom([...ClientRoom, { value: value[i], label: value[i] }]);
    }
  };

  const handleDeselect = (value) => {
    roomOption = _.filter(ClientRoom, function (o) {
      return o.value !== value;
    });
    setClientRoom(roomOption);
  };

  useEffect(() => {
    dispatch(getToursList());
    // dispatch(getCityList()).then((res) => {
    //   setCityList(res);
    // });
    dispatch(getTourBookingTypeList()).then((res) => {
      setTypeList(res);
    });
  }, []);

  const [clientSecret, setClientSecret] = useState("");

  const handleSubmit = (values) => {
    let dte = null;
    if (values.date.includes("/")) {
      console.log('first');
      dte = moment(values.date, "DD/MM/YYYY").format("YYYY-MM-DD");
    } else {
      console.log('second');
      dte = moment(values.date).format("YYYY-MM-DD");
    }

    if (datesAllowed.length !== 0 && !datesAllowed.includes(dte)) {
      console.log('third');
      return;
    }

    if (clientDetail && clientDetail.length === 0) {
      console.log('fourth')
      return;
    }

    if (validationClient(clientDetail, clientSetting)) {
      console.log('fifth')
      return;
    }
    setclientMessage(false);
    setDisable(true);

    let data = null;

    if (isUpdate) {
      data = {
        clientName: values.clientName,
        clientPhone: values.clientPhone,
        clientEmail: values.clientEmail,
        receptionist: values.receptionist,
        date: dte,
        typeId: values.typeId.value,
        tourId: values.tourId.value,
        clientDetails: (clientDetail &&
          clientDetail.length > 0 &&
          clientDetail) || [BOOKING_CLIENT_DETAILS],
        type: TOUR_TYPES.shared,
        description: values.description,
        lang: values.language.value,
        rooms: ClientRoom,
        platform:
          (tour && tour.platform === PLATFORM.app && PLATFORM.app) ||
          PLATFORM.web,
        uId: tour && tour.uId,
      };
      dispatch(bookUpdateSharedTour(data, bookId)).then((res) => {
        setDisable(false);
        navigate("/dashboard/shared-tours-booking-list");
      });
    } else {
      data = {
        clientName: values.clientName,
        clientPhone: values.clientPhone,
        clientEmail: values.clientEmail,
        receptionist: values.receptionist,
        date: dte,
        numOfAdults: noAdults,
        numOfChildren: noChild,
        numOfBabies: noBaby,
        extraCharges: values.extraCharges,
        paymentMethod: values.paymentMethod,
        typeId: values.typeId.value,
        tourId: values.tourId.value,
        clientDetails: (clientDetail &&
          clientDetail.length > 0 &&
          clientDetail) || [BOOKING_CLIENT_DETAILS],
        type: TOUR_TYPES.shared,
        description: values.description,
        lang: values.language.value,
        rooms: ClientRoom,
        platform:
          (tour && tour.platform === PLATFORM.app && PLATFORM.app) ||
          PLATFORM.web,
        uId: tour && tour.uId,
      };
      dispatch(bookSharedTour(data)).then((bookRes) => {
        if (values.paymentMethod === 4) {
          dispatch(getPaymentIntent(bookRes.bT.id)).then((res) => {
            setDisable(false);
            setClientSecret(res.paymentIntent);
            navigate(`/dashboard/payment-stripe/${res.paymentIntent}`, {
              state: {
                bookId: bookRes.bT.id,
                res,
                path: "shared-tours-booking-list",
              },
            });
          });
        } else {
          setDisable(false);
          navigate("/dashboard/shared-tours-booking-list");
        }
      });
    }
  };

  const tourList = useSelector((state) => {
    return state.Receptionist.tours;
  });

  useEffect(() => {
    dispatch(getSettingTime());
  }, []);

  const setting = useSelector((state) => {
    return state.App.time;
  });

  let privateTours = _.filter(tourList, function (o) {
    return o.type === TOUR_TYPES.shared;
  });

  let arr = [];

  privateTours.forEach((element) => {
    arr.push({
      value: element.id,
      label: element.destination,
      adultPerPrice: element.price.adultPerPrice,
      babiesPerPrice: element.price.babiesPerPrice,
      childrenPerPrice: element.price.childrenPerPrice,
      currencyCode: element.price.currencyCode,
      description: element.description,
      schedule: element.schedule[0],
      hotel: element.user.hotel[0],
    });
  });

  const singleTour =
    arr &&
    arr.length > 0 &&
    tour &&
    _.filter(arr, function (o) {
      return o.value === tour.tour.id;
    });

  const [priceSelected, setPriceSelected] = useState(
    (singleTour && singleTour[0]) || null
  );

  const schedCalculation = (singleTour) => {
    setPriceSelected((singleTour && singleTour[0]) || null);
  };

  useMemo(() => schedCalculation(singleTour), [tourList]);

  // priceSelected = (singleTour && singleTour[0]) || null;

  const tourID = (priceSelected && priceSelected.value) || null;
  const hotelID =
    (priceSelected && priceSelected.hotel && priceSelected.hotel.id) || null;

  useEffect(() => {
    if (tourID && hotelID) {
      dispatch(viewHotelPrice(tourID, hotelID)).then((res) => {
        setHotelPrice(res);
      });
      dispatch(viewDefaultPrice(tourID)).then((res) => {
        setTourPrice(res);
      });
      dispatch(tourClientSetting(tourID)).then((res) => {
        setClienSetting(fillTourSetting(res));
      });
    }
  }, [tourID]);

  let adultPerPrice = 0;
  let babiesPerPrice = 0;
  let childrenPerPrice = 0;

  if (hotelPrice) {
    adultPerPrice = hotelPrice && parseFloat(hotelPrice.adultPerPrice);
    babiesPerPrice =
      (hotelPrice &&
        hotelPrice.babiesPerPrice &&
        parseFloat(hotelPrice.babiesPerPrice)) ||
      0;
    childrenPerPrice = hotelPrice && parseFloat(hotelPrice.childrenPerPrice);
  } else {
    adultPerPrice = tourPrice && parseFloat(tourPrice.adultPerPrice);
    babiesPerPrice =
      (tourPrice &&
        tourPrice.babiesPerPrice &&
        parseFloat(tourPrice.babiesPerPrice)) ||
      0;
    childrenPerPrice = tourPrice && parseFloat(tourPrice.childrenPerPrice);
  }

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };
  const { rowStyle, colStyle, gutter } = basicStyle;

  const days = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const daysNotAllowed = [];
  const datesNotAllowed = [];
  const daysAllowed = [];
  const datesAllowed = [];

  priceSelected &&
    days.forEach((day, index) => {
      (priceSelected.schedule &&
        !priceSelected.schedule[day] &&
        daysNotAllowed.push(index)) ||
        daysAllowed.push({
          day: index,
          data: (priceSelected.schedule && priceSelected.schedule[day]) || null,
        });
    });

  availableExc &&
    availableExc.forEach((day) => {
      if (day && day.date) {
        // const dayIndex = moment(day.date).day();
        // const isDayAllowed = daysAllowed.some((allowedDay) => allowedDay.day === dayIndex);
        // if (isDayAllowed) {
        // If the day is allowed according to schedule , add it to datesAllowed
        datesAllowed.push(day.date);
        // }
      }
    });

  closeDay.forEach((day, index) => {
    if (datesAllowed.includes(moment(day.closeDate).format("YYYY-MM-DD")))
      datesNotAllowed.push(day.closeDate);
  });

  const currentDate = moment().format("YYYY-MM-DD");

  const closingTime = setting && setting.closingAt;

  if (closingTime < moment().format("HH:mm")) {
    moment().add(1, "days").format("YYYY-MM-DD");
    datesNotAllowed.push(moment().add(1, "days").format("YYYY-MM-DD"));
  }

  let tourDate = locParams.schdDate || null;

  const getScheduleDate = (date) => {
    if (!datesNotAllowed.includes(date)) {
      return date;
    }

    const newDate = moment(date).add(7, "days").format("YYYY-MM-DD");
    return getScheduleDate(newDate);
  };

  const scheduledDate = tourDate ? getScheduleDate(tourDate) : "";

  let createFormValues = {
    clientName: tour ? tour.clientName : "",
    clientPhone: tour ? tour.clientPhone : "",
    clientEmail: tour ? tour.clientEmail : "",
    receptionist: tour ? tour.receptionist : "",
    date: scheduledDate ? scheduledDate : tour ? tour.date : "",
    numOfAdults: tour ? tour.numOfAdults : "",
    numOfChildren: tour ? tour.numOfChildren : "",
    numOfBabies: tour ? tour.numOfBabies : "",
    extraCharges: tour ? tour.extraCharges : null,
    paymentMethod: (tour && tour.paymentMethod && tour.paymentMethod) || 1,
    typeId: typeList && typeList.length > 0 && typeList[0],
    tourId:
      (tour &&
        tour.tour && { label: tour.tour.destination, value: tour.tour.id }) ||
      {},
    description: tour ? tour.description : "",
    language: tour
      ? _.find(LANGUAGES, function (o) {
          return o.value === tour.lang;
        })
      : {},
  };

  const disabledDate = (current) => {

    if (moment(current).format("YYYY-MM-DD") <= currentDate) {
      return true;
    }
    if (daysNotAllowed.includes(moment(current).day())) {
      // if(datesAllowed.includes(moment(current).format("YYYY-MM-DD"))){
      //   return false;
      // }
      return true;
    } else if (datesNotAllowed.includes(moment(current).format("YYYY-MM-DD"))) {
      return true;
    } 
    else if (datesAllowed.length !== 0 && !datesAllowed.includes(moment(current).format("YYYY-MM-DD"))) {
      return true;
    }
  };

  const pickUpDataFunction = (day) => {
    if (day && daysAllowed.length !== 0) {
      const pickUp = _.find(daysAllowed, function (o) {
        return o.day === day;
      });
      setpickData(pickUp.data);
    } else {
      setpickData(null);
    }
  };

  useEffect(() => {
    if (tourList && setting) {
      setLoading(false);
    }
  }, [tourList, setting]);

  const diversionLoading = useSelector((state) => {
    return state.Common.loading;
  });

  return (
    <SignUpStyleWrapper className="isoSignUpPage">
      <div className="isoSignUpContentWrapper">
        <div className="isoSignUpContent">
          <div className="isoLogoWrapper">
            <Link to="/dashboard/private-tours-booking-list">
              Book Shared Tour
            </Link>
          </div>

          {loading ? (
            <Spin />
          ) : (
            <Formik
              initialValues={createFormValues}
              onSubmit={handleSubmit}
              validationSchema={sharedBookingValidation}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => {
                // const defaultDate = values.date
                //   ? moment(values.date).format("DD/MM/YYYY")
                //   : null;
                let defaultDate = null;

                if (values.date.includes("/")) {
                  defaultDate = moment(values.date, "DD/MM/YYYY");
                } else {
                  // The input is already in YYYY-MM-DD format
                  defaultDate = values.date
                    ? moment(values.date).format("DD/MM/YYYY")
                    : null;
                }

                return (
                  <form onSubmit={handleSubmit}>
                    <div className="isoSignUpForm">
                      <div className="isoInputWrapper">
                        <label className="label-style">Select a Tour</label>
                        <Dropdown
                          showSearch={!view}
                          options={arr}
                          disable={(isUpdate && true) || false}
                          onChange={(value) => {
                            setPriceSelected(value);
                            setTourId(value.value);
                            setFieldValue("tourId", value);
                            setpickData(null);
                            dispatch(fetchTourCloseDayByRec(value.value)).then(
                              (res) => {
                                setCloseDay(res);
                              }
                            );
                            //   setTypeList(res);
                            // });
                            // dispatch(fetchavailableExc(value.value)).then(
                            //   (res) => {
                            //     setAvailableExc(res);
                            //   }
                            // );
                          }}
                          value={values ? values.tourId : null}
                          placeholder="Select a Tour"
                        />
                        {errors.tourId && touched.tourId && (
                          <div className="invalid-input">
                            {errors.tourId.value}
                          </div>
                        )}
                      </div>
                      {/* <div className="isoInputWrapper">
                    <Dropdown
                      options={cityList}
                      onChange={(value) => {
                        setFieldValue("cityId", value);
                        setCity(value.value);
                        dispatch(getStopList(value.value, values.tourId)).then(
                          (res) => {
                            setStops(res);
                            setFieldValue("stopId", null);
                          }
                        );
                      }}
                      value={values.cityId ? values.cityId : null}
                      placeholder="Select a City"
                    />
                    {errors.cityId && touched.cityId && (
                      <div className="invalid-input">{errors.cityId.value}</div>
                    )}
                  </div> */}

                      {/* <div className="isoInputWrapper">
                    <Dropdown
                      options={stops}
                      onChange={(value) => {
                        setFieldValue("stopId", value);
                      }}
                      value={values.stopId ? values.stopId : null}
                      placeholder="Select a Stop"
                    />
                    {errors.stopId && touched.stopId && (
                      <div className="invalid-input">{errors.stopId.value}</div>
                    )}
                  </div> */}

                      <div className="isoInputWrapper">
                        <label className="label-style">Client Name</label>
                        <Input
                          size="large"
                          type="text"
                          name="clientName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.clientName}
                        />
                        {errors.clientName && touched.clientName && (
                          <div className="invalid-input">
                            {errors.clientName}
                          </div>
                        )}
                      </div>
                      <div className="isoInputWrapper">
                        <label className="label-style">Client Phone</label>
                        <Input
                          size="large"
                          type="text"
                          name="clientPhone"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.clientPhone}
                        />
                        {errors.clientPhone && touched.clientPhone && (
                          <div className="invalid-input">
                            {errors.clientPhone}
                          </div>
                        )}
                      </div>

                      <div className="isoInputWrapper">
                        <label className="label-style">Client Email</label>
                        <Input
                          size="large"
                          type="text"
                          name="clientEmail"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.clientEmail}
                        />
                        {errors.clientEmail && touched.clientEmail && (
                          <div className="invalid-input">
                            {errors.clientEmail}
                          </div>
                        )}
                      </div>

                      <div className="isoInputWrapper">
                        <label className="label-style">Receptionist Name</label>
                        <Input
                          size="large"
                          type="text"
                          name="receptionist"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.receptionist}
                        />
                        {errors.receptionist && touched.receptionist && (
                          <div className="invalid-input">
                            {errors.receptionist}
                          </div>
                        )}
                      </div>

                      <div className="RowDiv">
                        {/* <div
                      className="isoInputWrapper"
                      style={{ width: "50%", padding: "0 7px 0 7px" }}
                    >
                      <Input
                        size="large"
                        placeholder="Number of Adults"
                        type="number"
                        name="numOfAdults"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.numOfAdults}
                      />
                      {errors.numOfAdults && touched.numOfAdults && (
                        <div className="invalid-input">
                          {errors.numOfAdults}
                        </div>
                      )}
                    </div>

                    <div
                      className="isoInputWrapper"
                      style={{ width: "50%", padding: "0 7px 0 7px" }}
                    >
                      <Input
                        size="large"
                        placeholder="Number of Children"
                        type="number"
                        name="numOfChildren"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.numOfChildren}
                      />
                      {errors.numOfChildren && touched.numOfChildren && (
                        <div className="invalid-input">
                          {errors.numOfChildren}
                        </div>
                      )}
                    </div>

                    <div
                      className="isoInputWrapper"
                      style={{ width: "50%", padding: "0 7px 0 7px" }}
                    >
                      <Input
                        size="large"
                        placeholder="Number of Babies"
                        type="number"
                        name="numOfBabies"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.numOfBabies}
                      />
                      {errors.numOfBabies && touched.numOfBabies && (
                        <div className="invalid-input">
                          {errors.numOfBabies}
                        </div>
                      )}
                    </div> */}
                        <div className="isoInputWrapper">
                          <label className="label-style">
                            Select Passengers
                          </label>
                          <div style={{ marginBottom: "8px", display: "flex" }}>
                            <Dropdown
                              showSearch={view.isDesiredWidth}
                              options={nonPrivateUsers}
                              onChange={(value) => {
                                setClients(value);
                                setclientMessageFlag(true);
                              }}
                              value={client ? client : null}
                              placeholder="Passengers"
                              disable={
                                (isUpdate && true) ||
                                (tour_id === null && true) ||
                                false
                              }
                            />
                            {(!diversionLoading && (
                              <PlusOutlined
                                disabled={(isUpdate && true) || false}
                                onClick={() => addElement(setFieldValue)}
                                style={{
                                  backgroundColor: "#4482FF",
                                  color: "white",
                                  padding: "10px",
                                  borderRadius: "5px",
                                }}
                              />
                            )) || (
                              <div
                                style={{
                                  backgroundColor: "#4482FF",
                                  color: "white",
                                  borderRadius: "5px",
                                }}
                              >
                                <LoadingOutlined
                                  style={{
                                    padding: "10px",
                                  }}
                                />
                              </div>
                            )}
                          </div>
                          {clientDetail && clientDetail.length === 0 && (
                            <div className="invalid-input">Required</div>
                          )}
                        </div>
                      </div>
                      {clientDetail &&
                        clientDetail.length > 0 &&
                        clientDetail.map((data, index) => {
                          return (
                            <ClientDetailsForm
                              key={index}
                              index={index}
                              view={view}
                              data={data}
                              clientSetting={clientSetting}
                              handleBlur={handleBlur}
                              handleChangeF={handleChangeF}
                              handleNationality={handleNationality}
                              handleDocumentType={handleDocumentType}
                              removeFormFields={removeFormFields}
                              setFieldValue={setFieldValue}
                            />
                          );
                        })}

                      <div className="isoInputWrapper">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <label className="label-style">Date</label>
                          <DatePicker
                            inputReadOnly={view}
                            // size="large"
                            // type="text"
                            name="date"
                            disabled={
                              (clientDetail.length === 0 && true) || false
                            }
                            disabledDate={disabledDate}
                            onChange={(date, dateString) => {
                              pickUpDataFunction(
                                (dateString &&
                                  moment(dateString, "DD/MM/yyyy").day()) ||
                                  null
                              );
                              setFieldValue("date", dateString);
                            }}
                            value={
                              defaultDate
                                ? moment(defaultDate, "DD/MM/yyyy")
                                : null
                            }
                            // onBlur={handleBlur}
                            format="DD/MM/YYYY"
                            defaultValue={
                              defaultDate
                                ? moment(defaultDate, "DD/MM/yyyy")
                                : null
                            }
                          />
                          {errors.date && touched.date && (
                            <div className="invalid-input">{errors.date}</div>
                          )}
                         {clientDetail.length === 0 && <small class="form-text text-muted" style={{color:"darkgreen"}}>
                          *Select a passenger to enable date.
                        </small>}
                        </div>
                      </div>
                      {(pickData && (
                        <div className="isoInputWrapper">
                          <Tag color={"geekblue"} key={pickData}>
                            {pickData}
                          </Tag>
                        </div>
                      )) ||
                        null}
                      <div className="isoInputWrapper">
                        <label className="label-style">Extra Charges</label>
                        <Input
                          size="large"
                          type="number"
                          name="extraCharges"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.extraCharges}
                          disabled={(isUpdate && true) || false}
                        />
                        {errors.extraCharges && touched.extraCharges && (
                          <div className="invalid-input">
                            {errors.extraCharges}
                          </div>
                        )}
                      </div>
                      <div className="isoInputWrapper">
                        <label className="label-style">
                          Select an operator
                        </label>
                        <Dropdown
                          showSearch={view.isDesiredWidth}
                          options={typeList}
                          onChange={(value) => {
                            setFieldValue("typeId", value);
                          }}
                          value={values.typeId ? values.typeId : null}
                        />
                        {errors.typeId && touched.typeId && (
                          <div className="invalid-input">
                            {errors.typeId.value}
                          </div>
                        )}
                      </div>
                      <div className="isoInputWrapper">
                        <label className="label-style">Select a Language</label>
                        <Dropdown
                          showSearch={view.isDesiredWidth}
                          options={LANGUAGES}
                          onChange={(value) => {
                            setFieldValue("language", value);
                          }}
                          value={values.language ? values.language : null}
                        />
                        {errors.language && touched.language && (
                          <div className="invalid-input">
                            {errors.language.value}
                          </div>
                        )}
                      </div>
                      <div className="isoInputWrapper">
                        <label className="label-style">Add Room#</label>
                        <Select
                          mode="tags"
                          defaultValue={ClientRoom}
                          style={{ width: "100%" }}
                          onChange={handleTags}
                          // onBlur={handleBlur}
                          onDeselect={handleDeselect}
                          options={ClientRoom}
                        />
                      </div>
                      <div className="isoInputWrapper">
                        <label className="label-style">Comments</label>
                        <Textarea
                          rows={4}
                          size="large"
                          type="text"
                          name="description"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.description}
                        />
                        {errors.description && touched.description && (
                          <div className="invalid-input">
                            {errors.description}
                          </div>
                        )}
                        <small class="form-text text-muted">
                          *This does not appear on client voucher - only
                          intended for internal communication with BGT Travel
                        </small>
                      </div>
                      <div className="isoInputWrapper">
                        <Row style={rowStyle} gutter={gutter} justify="start">
                          <Col md={12} sm={12} xs={24} style={colStyle}>
                            <div>
                              <p style={{ fontWeight: "bold" }}>
                                Payment Method
                              </p>
                            </div>
                            <RadioGroup
                              onChange={handleChange}
                              name="paymentMethod"
                              value={values.paymentMethod}
                              disabled={(isUpdate && true) || false}
                            >
                              <Radio style={radioStyle} value={1}>
                                Cash
                              </Radio>
                              <Radio style={radioStyle} value={2}>
                                Credit or debit card
                              </Radio>
                              <Radio style={radioStyle} value={3}>
                                Monthly Invoicing
                              </Radio>
                              <Radio style={radioStyle} value={4}>
                                Pay Here With Card Now
                              </Radio>
                            </RadioGroup>
                          </Col>
                        </Row>
                        {errors.paymentMethod && touched.paymentMethod && (
                          <div className="invalid-input">
                            {errors.paymentMethod}
                          </div>
                        )}
                      </div>
                      {priceSelected ? (
                        <div className="isoInputWrapper">
                          <div>
                            <p>{`Total Price: ${
                              EURO.format(
                                noAdults * adultPerPrice +
                                  noBaby * babiesPerPrice +
                                  noChild * childrenPerPrice +
                                  parseFloat(
                                    values.extraCharges
                                      ? values.extraCharges
                                      : "0"
                                  )
                              )
                              // : values.numOfAdults && values.numOfChildren
                              // ? parseInt(values.numOfAdults) *
                              //     parseInt(priceSelected.adultPerPrice) +
                              //   parseInt(values.numOfChildren) *
                              //     parseInt(priceSelected.childrenPerPrice) +
                              //   parseFloat(
                              //     values.extraCharges ? values.extraCharges : "0"
                              //   )
                              // : values.numOfAdults
                              // ? parseInt(values.numOfAdults) *
                              //     parseInt(priceSelected.adultPerPrice) +
                              //   parseFloat(
                              //     values.extraCharges ? values.extraCharges : "0"
                              //   )
                            }`}</p>
                          </div>
                        </div>
                      ) : null}

                      <Button
                        type="primary"
                        onClick={handleSubmit}
                        disabled={disable}
                      >
                        Book
                      </Button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          )}
        </div>
        <div className="rightbar">
          <div className="rightbar-inner">
            <h3>Description</h3>
            <p>
              {(priceSelected && priceSelected.description) ||
                "description not available!"}
            </p>
          </div>
        </div>
      </div>
    </SignUpStyleWrapper>
  );
}
