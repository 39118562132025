import React, {useState} from 'react';
import isEmpty from 'lodash/isEmpty';
import {useSelector, useDispatch} from 'react-redux';
import {Spin, Modal} from 'antd';
// import {useNavigate} from 'react-router-dom';
// import _ from 'lodash';

import NoBoardFounds from '../BoardNotFound/BoardNotFound';
import BoardListCard from './BoardListCard/BoardListCard';
import AppLayout from '../../AppLayout/AppLayout';
import {Table} from './BoardList.style';
import {deleteAirportTransport} from '@iso/redux/receptionist/actions';
function BoardLists({history, match}) {
  const [search, setSearch] = useState(null);
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedModalBoard, setSelectedModalBoard] = useState(null);

  const showModal = (tour) => {
    setSelectedModalBoard(tour);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    dispatch(deleteAirportTransport(selectedModalBoard.id)).then((res) => {
      setIsModalVisible(false);
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  // const handleEdit = (tour) => {
  //   navigate(`/dashboard/createTours/${tour.id}`, {state: {tour}});
  // };

  let transportList = [];

  transportList = useSelector((state) => {
    return state.Receptionist.airportTransports;
  });

  if (search) {
    let searched = transportList.filter((i) => {
      return (
        i.clientName.toLowerCase().includes(search.toLowerCase()) ||
        i.clientEmail.toLowerCase().includes(search.toLowerCase())
      );
    });
    transportList = searched;
  }

  let Loading = useSelector((state) => {
    return state.Receptionist.loading;
  });

  if (transportList && transportList.length > 1) {
    transportList.sort((a, b) => parseFloat(b.id) - parseFloat(a.id));
  }

  return (
    <>
      <AppLayout
        history={history}
        match={match}
        setSearchText={(value) => {
          setSearch(value);
        }}>
        {Loading ? (
          <div className="spinner">
            <Spin />
          </div>
        ) : (
          <>
            {!isEmpty(transportList) ? (
              <Table>
                {transportList.map((board) => {
                  return (
                    <BoardListCard
                      key={board.id}
                      item={board}
                      onDelete={() => {
                        showModal(board);
                      }}
                    />
                  );
                })}
              </Table>
            ) : (
              <NoBoardFounds history={history} match={match} />
            )}
          </>
        )}
      </AppLayout>
      <Modal title="Delete Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>Are you sure you want to delete this Airport Transfer?</p>
      </Modal>
    </>
  );
}

export default BoardLists;
