/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Spin, Space, Table, Popconfirm } from "antd";

import NoBoardFounds from "../BoardNotFound/BoardNotFound";
import AppLayout from "../../AppLayout/AppLayout";

import {
  deleteCityPrice,
  deleteService,
  getServiceList,
} from "../../../../../../redux/airportTransfer/actions.js";
import CityPriceModal from "../../../../../../components/Modal/CityPriceModel";

function BoardLists({ history, match }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);

  const [cityPriceData, setCityPriceData] = useState({ id: null, data: null });
  const [services, setServices] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [cityId, setCityId] = useState(null);

  useEffect(() => {
    dispatch(getServiceList()).then((res) => {
      setServices(res);
    });
  }, []);

  const showModal = (cityId) => {
    setIsModalVisible(true);
    setCityId(cityId);
    setCityPriceData(null);
  };

  function showUpdateModal(id, data, city) {
    setIsUpdateModalVisible(!isUpdateModalVisible);
    setCityPriceData({ id, data });
    setCityId(data.cityId);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleUpCancel = () => {
    setIsUpdateModalVisible(false);
  };

  // const handleReceipt = (tour) => {
  //   dispatch(generateTourReceipt(tour.id));
  // };

  const onCancel = (id, cityId) => {
    dispatch(deleteCityPrice(id, cityId));
  };

  const cityPriceList = useSelector((state) => {
    return state.AirportTransfer.cityPrice;
  });

  let Loading = useSelector((state) => {
    return state.AirportTransfer.loading;
  });

  const columns = [
    {
      title: "City",
      dataIndex: "name",
      rowKey: "name",
    },
    {
      title: "Action",
      dataIndex: "id",
      rowKey: "id",
      render: (text, data) => (
        <Space size="middle">
          <a onClick={() => showModal(text)}>Add Service</a>
        </Space>
      ),
    },
  ];

  const expandedRowRender = (d, city) => {
    const columns = [
      {
        title: "Persons",
        dataIndex: "service",
        rowKey: "service",
        width: "22%",
        render: (text) => (
          <p>
            {text.min} - {text.max}
          </p>
        ),
      },
      {
        title: "Service",
        dataIndex: "service",
        rowKey: "service",
        width: "22%",
        render: (text) => <p>{text.title}</p>,
      },
      {
        title: "Price",
        dataIndex: "price",
        rowKey: "price",
      },
      {
        title: "Night Price",
        dataIndex: "nightPrice",
        rowKey: "nightPrice",
      },
      {
        title: "Action",
        dataIndex: "id",
        rowKey: "id",
        render: (text, data) => (
          <Space size="middle">
            <a onClick={() => showUpdateModal(text, data, city)}>Edit</a>
            {Loading ? (
              <Spin />
            ) : (
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => onCancel(text, data.cityId)}
              >
                <a style={{ color: "#DC3545" }}>Delete</a>
              </Popconfirm>
            )}
            {/* <Dropdown
              menu={{
                items
              }}
            >
            More
            </Dropdown> */}
          </Space>
        ),
      },
    ];
    return <Table columns={columns} dataSource={d} pagination={false} />;
  };

  return (
    <>
      <AppLayout history={history} match={match} title={"City Prices"}>
        {Loading ? (
          <div className="spinner">
            <Spin />
          </div>
        ) : (
          <>
            {!isEmpty(cityPriceList) ? (
              <Table
                columns={columns}
                rowKey="id"
                expandable={{
                  onExpandedRowsChange: (er) => {
                    setExpandedRowKeys(er);
                  },
                  expandedRowKeys,
                }}
                expandedRowRender={(r, i) =>
                  expandedRowRender(
                    cityPriceList[i].cityPrice,
                    cityPriceList[i]
                  )
                }
                dataSource={cityPriceList}
                pagination={false}
              />
            ) : (
              <NoBoardFounds history={history} match={match} />
            )}
          </>
        )}
      </AppLayout>
      {isModalVisible ? (
        <CityPriceModal
          isModalVisible={isModalVisible}
          handleCancel={handleCancel}
          title={"Add service"}
          buttonTitle="Add"
          cityPriceData={cityPriceData}
          cityId={cityId}
          services={services}
        />
      ) : null}
      {isUpdateModalVisible ? (
        <CityPriceModal
          isModalVisible={isUpdateModalVisible}
          cityPriceData={cityPriceData}
          handleCancel={handleUpCancel}
          title={"Edit a service"}
          buttonTitle="Edit"
          cityId={cityId}
          services={services}
          disable={true}
        />
      ) : null}
    </>
  );
}

export default BoardLists;
