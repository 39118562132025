import * as api from '../../constants/Api';
import {API} from '../../services';
import * as T from '../ActionTypes';

export function getView(width) {
  let newView = 'MobileView';
  if (width > 1220) {
    newView = 'DesktopView';
  } else if (width > 767) {
    newView = 'TabView';
  }
  return newView;
}
const actions = {
  COLLPSE_CHANGE: 'COLLPSE_CHANGE',
  COLLPSE_OPEN_DRAWER: 'COLLPSE_OPEN_DRAWER',
  CHANGE_OPEN_KEYS: 'CHANGE_OPEN_KEYS',
  TOGGLE_ALL: 'TOGGLE_ALL',
  CHANGE_CURRENT: 'CHANGE_CURRENT',
  CLEAR_MENU: 'CLEAR_MENU',
  GET_RECEPTIONIST_TIME_SETTING: 'GET_RECEPTIONIST_TIME_SETTING',

  toggleCollapsed: () => ({
    type: actions.COLLPSE_CHANGE,
  }),
  toggleAll: (width, height) => {
    const view = getView(width);
    const collapsed = view !== 'DesktopView';
    return {
      type: actions.TOGGLE_ALL,
      collapsed,
      view,
      height,
    };
  },
  toggleOpenDrawer: () => ({
    type: actions.COLLPSE_OPEN_DRAWER,
  }),
  changeOpenKeys: openKeys => ({
    type: actions.CHANGE_OPEN_KEYS,
    openKeys,
  }),
  changeCurrent: current => ({
    type: actions.CHANGE_CURRENT,
    current,
  }),
  clearMenu: () => ({ type: actions.CLEAR_MENU }),
  getSettingTime: () => {
    return (dispatch) => {
      return API.execute(api.GET_RECEPTIONIST_TIME_SETTING).then(
        (response) => {
          dispatch({type: actions.GET_RECEPTIONIST_TIME_SETTING, payload: response.data});
          return response.data;
        },
        (error) => {
          dispatch({type: T.RECEPTIONIST_ERROR, payload: error});
          return error;
        },
      );
    };
  }
  
};

// export const getSettingTime = () => {
//   return (dispatch) => {
//     return API.execute(api.GET_RECEPTIONIST_TIME_SETTING).then(
//       (response) => {
//         dispatch({type: T.GET_RECEPTIONIST_TIME_SETTING, payload: response.data});
//         console.log('response', response)
//         return response.data;
//       },
//       (error) => {
//         dispatch({type: T.RECEPTIONIST_ERROR, payload: error});
//         return error;
//       },
//     );
//   };
// };

export default actions;
