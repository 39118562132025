import React from "react";
import Map from "../../../services/MapView";
import { ContactCardWrapper } from './ContactCard.style';

const Invoice = React.forwardRef(({ currentHotel }, ref) => {
  return (
    <div className="PageContent" style={{ padding: "20" }} ref={ref}>
      <div className="OrderInfo">
        <div className="LeftSideContent">
          <h3 className="Title">Hotel</h3>
          <span className="InvoiceNumber">{currentHotel.displayName}</span>
        </div>
      </div>
      <div className="BillingInformation">
        <div className="LeftSideContent">
          <h3 className="Title">Details</h3>
          <ContactCardWrapper className="isoContactCard" style={{ padding: '0'}}>
          <div className="isoContactInfoWrapper">
            <div className="isoContactCardInfos" key={currentHotel.displayName}>
              <p className="isoInfoLabel"> Name </p>
              <p className="isoInfoDetails">{currentHotel.displayName}</p>
            </div>
            <div className="isoContactCardInfos" key={currentHotel.email}>
              <p className="isoInfoLabel"> Email </p>
              <p className="isoInfoDetails">{currentHotel.email}</p>
            </div>
            <div className="isoContactCardInfos" key={currentHotel.city}>
              <p className="isoInfoLabel"> City </p>
              <p className="isoInfoDetails">{currentHotel.city}</p>
            </div>
            <div className="isoContactCardInfos" key={currentHotel.state}>
              <p className="isoInfoLabel"> State </p>
              <p className="isoInfoDetails">{currentHotel.state}</p>
            </div>
            <div className="isoContactCardInfos" key={currentHotel.country}>
              <p className="isoInfoLabel"> Country </p>
              <p className="isoInfoDetails">{currentHotel.country}</p>
            </div>
            <div className="isoContactCardInfos" key={currentHotel.phone}>
              <p className="isoInfoLabel"> Phone </p>
              <p className="isoInfoDetails">{currentHotel.phone}</p>
            </div>
            <div className="isoContactCardInfos" key={currentHotel.radius}>
              <p className="isoInfoLabel"> Radius </p>
              <p className="isoInfoDetails">{currentHotel.radius}</p>
            </div>
            <div className="isoContactCardInfos" key={currentHotel.mainContactName}>
              <p className="isoInfoLabel"> Main Contact Name </p>
              <p className="isoInfoDetails">{currentHotel.mainContactName}</p>
            </div>
            <div className="isoContactCardInfos" key={currentHotel.mainContactPhone}>
              <p className="isoInfoLabel"> Main Contact Phone </p>
              <p className="isoInfoDetails">{currentHotel.mainContactPhone}</p>
            </div>
            <div className="isoContactCardInfos" key={currentHotel.addressLine1}>
              <p className="isoInfoLabel"> Address Line 1 </p>
              <p className="isoInfoDetails">{currentHotel.addressLine1}</p>
            </div>
            <div className="isoContactCardInfos" key={currentHotel.addressLine2}>
              <p className="isoInfoLabel"> Address Line 2 </p>
              <p className="isoInfoDetails">{currentHotel.addressLine2}</p>
            </div>
            <div className="isoContactCardInfos" key={currentHotel.addressLine3}>
              <p className="isoInfoLabel"> Address Line 3 </p>
              <p className="isoInfoDetails">{currentHotel.addressLine3}</p>
            </div>
            <div className="isoContactCardInfos" key={currentHotel.addressLine4}>
              <p className="isoInfoLabel"> Address Line 4 </p>
              <p className="isoInfoDetails">{currentHotel.addressLine4}</p>
            </div>
          </div>
          </ContactCardWrapper>
        </div>
      </div>
      <div>
        <div className="mapWrapper">
          <Map
            getLocation={(loc) => {
              // console.log("loc", loc);
            }}
            setLocation={{
              lat: currentHotel.latitude,
              lng: currentHotel.longitude,
            }}
            getQuery={() => {}}
          />
        </div>
      </div>
    </div>
  );
});
export default Invoice;
