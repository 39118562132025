import React, {useState} from 'react';
import {Layout, Button} from 'antd';
// import {Scrollbars} from 'react-custom-scrollbars';
import {useNavigate} from 'react-router';

import {Title, Header, HeaderSecondary} from './AppLayout.style';
import SearchInput from '@iso/components/ScrumBoard/SearchInput/SearchInput';
import { TOUR_TYPES, TOUR_NAMES } from '../../../../constants';

const {Content} = Layout;

const AppLayout = ({children, setSearchText}) => {
  const navigate = useNavigate();
  return (
    <Layout style={{backgroundColor: '#fff'}}>
      <Header>
        <Title>{TOUR_NAMES[TOUR_TYPES.ticket].name}</Title>

        <Button
          type="primary"
          onClick={() => {
            navigate('/dashboard/createTicketTours', {state: {selectedType: 'private'}});
          }}>
          Add new
        </Button>
      </Header>

      <HeaderSecondary>
        <SearchInput
          onChange={(value) => setSearchText(value)}
          onCross={(val) => {
            setSearchText(val);
          }}
        />
      </HeaderSecondary>

      <Content
        style={{
          padding: '0 24px',
        }}>
        {children}
      </Content>
    </Layout>
  );
};

export default AppLayout;
