import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Button, Layout } from "antd";
import DatePicker from "@iso/components/uielements/datePicker";
import Select, { SelectOption } from "@iso/components/uielements/select";
import { Scrollbars } from "react-custom-scrollbars";
import { Filters, HeaderSecondary } from "./AppLayout.style";
import moment from "moment";
import { StatusTag } from "../../../../../containers/Lists/Lists.styles";
import { tourList } from "../../../../../redux/superAdmin/actions";

const Option = SelectOption;

const { Content } = Layout;

const ALL_TYPES = [
  { label: "All", value: "" },
  { label: "Shared", value: 2 },
  { label: "Private", value: 4 },
  { label: "Ticket", value: 1 },
  { label: "Ticket + Guide", value: 3 },
  { label: "Special Excursion", value: 5 },
];


const AppLayout = ({ children, typeFilter, dateFilter, typeTours, history, match, handleReport, people }) => {

  const tours = useSelector((state) => {
    return state.SuperAdmin.tour;
  });

  const dispatch = useDispatch();

  const handleType = (value) => {
    typeFilter(value)
    dispatch(tourList(value));
  }
  return (
  <Layout style={{ backgroundColor: "#fff"}}>
    <HeaderSecondary>
      <Filters>
        <Select
          defaultValue=""
          onChange={(value) => handleType(value)}
          style={{ width: "120px" }}
        >
          {ALL_TYPES.map((data, key) => {
            return (
              <Option key={key} value={data.value}>
                {data.label}
              </Option>
            );
          })}
        </Select>
        <DatePicker
        style={{margin: '0 10px'}}
          placeholder="Date"
          onChange={(value) =>
            dateFilter((value && moment(value).format("YYYY-MM-DD")) || "")
          }
        />
        <Select
          placeholder="Tours"
          allowClear
          onChange={(value) => typeTours((value !== undefined && value) || "")}
          style={{ width: "120px" }}
        >
          {tours && tours.map((data, key) => {
            return (
              <Option key={key} value={data.id}>
                {data.destination}
              </Option>
            );
          })}
        </Select>
        <StatusTag
          style={{
            backgroundColor: "#00B16A",
            padding: "15px 8px",
            borderRadius: "3px",
            margin: "0 10px",
          }}
        >
          No. Of People {(people && people.noOfPeople) || 0}
        </StatusTag>
      </Filters>
      <div style={{marginRight: "30px"}}>
      <Button type="primary" onClick={() => handleReport()} >
          Report
        </Button>
      </div>
    </HeaderSecondary>

    <Content
      style={{
        padding: "0 24px",
      }}
    >
      <Scrollbars style={{ width: "100%", height: "calc(100vh - 201px)" }}>
        {children}
      </Scrollbars>
    </Content>
  </Layout>
)};

export default connect(null, {})(AppLayout);
