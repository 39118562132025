import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Button, Layout } from "antd";
import DatePicker from "@iso/components/uielements/datePicker";
import Select, { SelectOption } from "@iso/components/uielements/select";
import { Scrollbars } from "react-custom-scrollbars";
import { Filters, HeaderSecondary } from "./AppLayout.style";
import moment from "moment";
import { StatusTag } from "../../../../../containers/Lists/Lists.styles";
import { getToursList } from "../../../../../redux/tours/actions";
import { PAYMENT_METHOD } from "../../../../../constants";

const Option = SelectOption;
const { RangePicker } = DatePicker;

const { Content } = Layout;

const ALL_TYPES = [
  { label: "All", value: "" },
  { label: "Shared", value: 2 },
  { label: "Private", value: 4 },
  { label: "Ticket", value: 1 },
  { label: "Ticket + Guide", value: 3 },
  { label: "Special Excursion", value: 5 },
];

const AppLayout = ({
  children,
  typeFilter,
  hotelList,
  hotels,
  typeTours,
  history,
  match,
  handleReport,
  handleCSV,
  people,
  bookedTours,
  referenceNo,
  handleDateRange,
  handlePaymentMethod,
}) => {
  const dispatch = useDispatch();

  const tourList = useSelector((state) => {
    return state.Tours.tours;
  });

  const handleType = (value) => {
    typeFilter(value);
    dispatch(getToursList(value));
  };

  return (
    <Layout style={{ backgroundColor: "#fff" }}>
      <HeaderSecondary>
        <Filters>
          <Select
            showSearch
            defaultValue=""
            onChange={(value) => handleType(value)}
            style={{ width: "150px" }}
            filterOption={(input, option) =>
              (option?.children ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {ALL_TYPES.map((data, key) => {
              return (
                <Option key={key} value={data.value}>
                  {data.label}
                </Option>
              );
            })}
          </Select>
          {/* <DatePicker
            style={{ margin: "0 10px" }}
            placeholder="Date"
            onChange={(value) =>
              dateFilter((value && moment(value).format("YYYY-MM-DD")) || "")
            }
          /> */}
          <Select
            showSearch
            placeholder="Tours"
            allowClear
            onChange={(value) =>
              typeTours((value !== undefined && value) || "")
            }
            filterOption={(input, option) =>
              (option?.children ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            style={{ margin: "0 10px", width: "270px" }}
          >
            {tourList &&
              tourList.map((data, key) => {
                return (
                  <Option key={key} value={data.id}>
                    {data.destination}
                  </Option>
                );
              })}
          </Select>
          <Select
            showSearch
            placeholder="Hotels"
            allowClear
            onChange={(value) =>
              hotels((value !== undefined && value) || "")
            }
            filterOption={(input, option) =>
              (option?.children ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            style={{ margin: "0 10px", width: "150px" }}
          >
            {hotelList &&
              hotelList.map((data, key) => {
                return (
                  <Option key={key} value={data.id}>
                    {data.name}
                  </Option>
                );
              })}
          </Select>
          <Select
            showSearch
            placeholder="Reference No"
            allowClear
            onChange={(value) =>
              referenceNo((value !== undefined && value) || "")
            }
            style={{ margin: "0 10px", width: "150px" }}
            // filterOption={(input, option) =>
            //   (option?.children ?? "").includes(input)
            // }
          >
            {bookedTours &&
              bookedTours.map((data, key) => {
                return (
                  <Option key={key} value={data.id}>
                    {data.id}
                  </Option>
                );
              })}
          </Select>
          <RangePicker onChange={handleDateRange} format="DD/MM/yyyy" allowClear placeholder={["Tour Date - Start", "Tour Date - End"]} />
          <Select
            showSearch
            allowClear
            placeholder="Payment Method"
            // defaultValue=""
            onChange={(value) => handlePaymentMethod((value !== undefined && value) || "")
          }
            style={{ margin: "0 10px", width: "150px" }}
            filterOption={(input, option) =>
              (option?.children ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {PAYMENT_METHOD.map((data, key) => {
              return (
                <Option key={key} value={data.value}>
                  {data.label}
                </Option>
              );
            })}
          </Select>
        </Filters>
        <div style={{ marginRight: "10px" }}>
          <Button type="primary" onClick={() => handleReport()}>
            Report
          </Button>
        </div>
        <div style={{ marginRight: "10px" }}>
          <Button type="primary" onClick={() => handleCSV()}>
            Generate CSV
          </Button>
        </div>
      </HeaderSecondary>

      <Content
        style={{
          padding: "0 24px",
        }}
      >
        <div>
        <StatusTag
          style={{
            backgroundColor: "#00B16A",
            padding: "15px 8px",
            borderRadius: "3px",
            margin: "10px 0",
          }}
        >
          No. Of People {(people && people.noOfPeople) || 0}
        </StatusTag>
      </div>
        <Scrollbars style={{ width: "100%", height: "calc(100vh - 201px)" }}>
          {children}
        </Scrollbars>
      </Content>
    </Layout>
  );
};

export default connect(null, {})(AppLayout);
