import React from "react";
import { connect } from "react-redux";
import { Button, Layout, InputNumber } from "antd";
import DatePicker from "@iso/components/uielements/datePicker";
import Select, { SelectOption } from "@iso/components/uielements/select";
import { Scrollbars } from "react-custom-scrollbars";
import { Filters, HeaderSecondary } from "./AppLayout.style";
import moment from "moment";

const Option = SelectOption;

const { Content } = Layout;



const AppLayout = ({
  children,
  vPriceListFilter,
  vPriceList,
  hotelListFilter,
  hotelList,
  dateFilter,
  airportListFilter,
  airportsList,
  history,
  match,
  handleReport
}) => {



  
  return (
  <Layout style={{ backgroundColor: "#fff" }}>
    <HeaderSecondary>
      <Filters>
        <DatePicker
          style={{ margin: "0 10px" }}
          placeholder="Date"
          onChange={(value) =>
            dateFilter((value && moment(value).format("YYYY-MM-DD")) || "")
          }
        />
        <Select
          placeholder="Hotel"
          allowClear
          // defaultValue=""
          onChange={(value) =>
            hotelListFilter((value !== undefined && value) || "")
          }
          style={{ width: "120px", margin: "0 0 0 10px" }}
        >
          {hotelList &&
            hotelList.length !== 0 &&
            hotelList.map((data, key) => {
              return (
                <Option key={data.id} value={data.id}>
                  {data.displayName}
                </Option>
              );
            })}
        </Select>
        <Select
          placeholder="Airport"
          allowClear
          onChange={(value) =>
            airportListFilter((value !== undefined && value) || "")
          }
          style={{ width: "120px", margin: "0 10px" }}
        >
          {airportsList &&
            airportsList.length !== 0 &&
            airportsList.map((data, key) => {
              return (
                <Option key={data.id} value={data.id}>
                  {data.name}
                </Option>
              );
            })}
        </Select>
        <InputNumber 
            placeholder="Vehicle Price"
            min={0}
            style={{ width: '20%' }}
            onPressEnter={(e) =>
              vPriceListFilter((e.target !== undefined && e.target.value) || "")
            }
          />
      </Filters>
      <div style={{marginRight: "30px"}}>
      <Button type="primary" onClick={() => handleReport()} >
          Report
        </Button>
      </div>
    </HeaderSecondary>

    <Content
      style={{
        padding: "0 24px",
      }}
    >
      <Scrollbars style={{ width: "100%", height: "calc(100vh - 201px)" }}>
        {children}
      </Scrollbars>
    </Content>
  </Layout>
  )
};

export default connect(null, {})(AppLayout);
