import React, {useState} from 'react';
import isEmpty from 'lodash/isEmpty';
import {useDispatch, useSelector} from 'react-redux';
import {Modal, Spin} from 'antd';
import {useNavigate} from 'react-router-dom';

import NoBoardFounds from '../BoardNotFound/BoardNotFound';
import BoardListCard from './BoardListCard/BoardListCard';
import AppLayout from '../../AppLayout/AppLayout';
import {Table} from './BoardList.style';
import {activateReceptionist, deactivateReceptionist} from '@iso/redux/receptionists/actions';
function BoardLists({history, match}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedModalBoard, setSelectedModalBoard] = useState(null);
  const [search, setSearch] = useState(null);

  const showModal = (receptionist) => {
    setSelectedModalBoard(receptionist);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (selectedModalBoard.status === 1) {
      dispatch(deactivateReceptionist(selectedModalBoard.id)).then((res) => {
        setIsModalVisible(false);
      });
    } else {
      dispatch(activateReceptionist(selectedModalBoard.id)).then((res) => {
        setIsModalVisible(false);
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleEdit = (receptionist) => {
    navigate(`/dashboard/createReceptionists/${receptionist.userId}`, {state: {receptionist}});
  };

  let receptionistsList = useSelector((state) => {
    return state.Receptionists.receptionists;
  });

  if (search) {
    let searched = receptionistsList.filter((i) => {
      return i.user.email.toLowerCase().includes(search.toLowerCase());
    });
    receptionistsList = searched;
  }

  let Loading = useSelector((state) => {
    return state.Receptionists.loading;
  });

  return (
    <>
      <AppLayout
        history={history}
        match={match}
        setSearchText={(value) => {
          setSearch(value);
        }}>
        {Loading ? (
          <div className="spinner">
            <Spin />
          </div>
        ) : (
          <>
            {!isEmpty(receptionistsList) ? (
              <Table>
                {receptionistsList.map((board) => {
                  return (
                    <BoardListCard
                      key={board.id}
                      item={board}
                      onStatus={() => {
                        showModal(board);
                      }}
                      onEdit={() => handleEdit(board)}
                    />
                  );
                })}
              </Table>
            ) : (
              <NoBoardFounds history={history} match={match} />
            )}
          </>
        )}
      </AppLayout>
      <Modal title="Delete Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>
          {`Are you sure you want to ${
            selectedModalBoard && selectedModalBoard.status === 1 ? 'deactivate' : 'activate'
          } this receptionist`}
        </p>
      </Modal>
    </>
  );
}

export default BoardLists;
