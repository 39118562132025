import React from 'react';
import {Button} from 'antd';
import {Wrapper, Title, Text, Icon} from './NoBoardFounds.style';
import emptyProjectPlaceHolder from '@iso/assets/images/icon/12.svg';
export default function NoBoardFounds({history, match}) {
  return (
    <Wrapper>
      <Icon src={emptyProjectPlaceHolder} />
      <Title>You Currently have no Hotels</Title>
      <Text>Let's Create your first hotel</Text>
      <Button type="primary" onClick={() => history.push(`createHotels`)}>
        Create Hotel
      </Button>
    </Wrapper>
  );
}
