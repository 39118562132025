import React from "react";
import Box from "@iso/components/utility/box";
import { Button } from "antd";
import LayoutWrapper from "@iso/components/utility/layoutWrapper";
import InvoicePageWrapper from "./SingleHotel.styles";
import Hotel from "./Hotel";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { hotelCommisionView } from "../../../redux/superAdmin/actions";

const HotelView = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useLocation();
  const locParams = params && params.state ? params.state : { hotel: null };
  const handleView = (hotel) => {
    dispatch(hotelCommisionView(hotel.id)).then((res) => {
      navigate(`/dashboard/hotels-commision/${res.id}`, { state: res  });
    });
  };
  return (
    <LayoutWrapper>
      <Box style={{ padding: 20 }}>
        <InvoicePageWrapper className="InvoicePageWrapper">
          <div className="PageHeader viewMode">
            <Button color="secondary" onClick={() => handleView(locParams.res)}>
              <span>Update Commision</span>
            </Button>
          </div>
          <Hotel currentHotel={locParams.res} ref={(invoice) => invoice} />
        </InvoicePageWrapper>
      </Box>
    </LayoutWrapper>
  );
};

export default HotelView;
