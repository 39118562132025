import _ from 'lodash';

import * as T from '../ActionTypes';

const initState = {vehicles: null, vehiclesPrice: null, loading: false};

export default function vehicleReducer(state = initState, action) {
  switch (action.type) {
    case T.CREATE_VEHICLE: {
      let newVehiclesList = [...state.vehicles];
      newVehiclesList.push(action.payload);
      return {...state, vehicles: newVehiclesList, loading: false};
    }
    case T.UPDATE_VEHICLE: {
      const vehiclesArray = _.filter(state.vehicles, (o) => {
        return o.id !== action.id;
      });
      vehiclesArray.push(action.payload);
      return {...state, vehicles: vehiclesArray, loading: false};
    }

    case T.GET_VEHICLES_LIST: {
      return {...state, vehicles: action.payload, loading: false};
    }

    case T.DELETE_VEHICLE: {
      const vehiclesArray = _.filter(state.vehicles, (o) => {
        return o.id !== action.id;
      });
      return {...state, vehicles: vehiclesArray, loading: false};
    }

    case T.CREATE_VEHICLE_PRICE: {
      let newVehiclesPriceList = [...state.vehiclesPrice];
      newVehiclesPriceList.push(action.payload);
      return {...state, vehiclesPrice: newVehiclesPriceList, loading: false};
    }
    case T.UPDATE_VEHICLE_PRICE: {
      const vehiclesPriceArray = _.filter(state.vehiclesPrice, (o) => {
        return o.id !== action.id;
      });
      vehiclesPriceArray.push(action.payload);
      return {...state, vehiclesPrice: vehiclesPriceArray, loading: false};
    }

    case T.GET_VEHICLE_PRICE_LIST: {
      return {...state, vehiclesPrice: action.payload, loading: false};
    }

    case T.DELETE_VEHICLE_PRICE: {
      const vehiclesPriceArray = _.filter(state.vehiclesPrice, (o) => {
        return o.id !== action.id;
      });
      return {...state, vehiclesPrice: vehiclesPriceArray, loading: false};
    }

    case T.VEHICLE_LOADING: {
      return {...state, loading: true};
    }

    default:
      return state;
  }
}
