import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Radio, { RadioGroup } from "@iso/components/uielements/radio";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import _ from "lodash";
import Dropdown from "@iso/components/uielements/CustomDropdown";
import Input from "@iso/components/uielements/input";
import Button from "@iso/components/uielements/button";
import { monthlyExcBookingValidation } from "@iso/constants";
import SignUpStyleWrapper from "./BookSharedTours.styles";
import {
  getTourBookingTypeList,
  bookSharedTour,
  getToursList,
  tourClientSetting,
  viewHotelPrice,
  viewDefaultPrice,
  bookUpdateSharedTour,
  getPaymentIntent,
} from "../../../redux/receptionist/actions";
import basicStyle from "@iso/assets/styles/constants";
import { Col, Row, Tag } from "antd";
import moment from "moment";
import { PlusOutlined } from "@ant-design/icons";
import {
  EURO,
  LANGUAGES,
  nonPrivateUsers,
  PLATFORM,
  TOUR_TYPES,
} from "../../../constants";
import { Textarea } from "../../../components/uielements/input";
import "../../../assets/styles/bookTour.css";
import Select from "@iso/components/uielements/select";
import * as rdd from "react-device-detect";
import { fillTourSetting, validationClient } from "../../../services";
import { BOOKING_CLIENT_DETAILS } from "../../../constants/Tour";
import ClientDetailsForm from "../../../components/TourBooking/ClientDetailsForm";

export default function CreateTours() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useLocation();

  const locParams = params && params.state ? params.state : { tour: null };
  const tour = (locParams && locParams.tour) || null;

  const bookId = (locParams && locParams.tour && locParams.tour.id) || null;
  const isUpdate = (locParams && locParams.flag) || 0;

  const [typeList, setTypeList] = useState([]);
  const [disable, setDisable] = useState(false);

  const [tourPrice, setTourPrice] = useState(null);
  const [hotelPrice, setHotelPrice] = useState(null);

  const [client, setClients] = useState(null);

  const [noAdults, setAdults] = useState(tour ? tour.numOfAdults : 0);
  const [noChild, setChild] = useState(tour ? tour.numOfChildren : 0);
  const [noBaby, setBaby] = useState(tour ? tour.numOfBabies : 0);

  // The width and device detect below which view should be rendered
  const tablet = rdd.isTablet;
  const mobile = rdd.isMobile;
  const ipad13 = rdd.isIPad13;

  const [view, setView] = useState(tablet || mobile || ipad13);
  const [width, setWidth] = useState(window.innerWidth);

  useLayoutEffect(() => {
    setView(rdd.isTablet || rdd.isMobile || rdd.isIPad13);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      const currentWindowWidth = window.innerWidth;
      setWidth(currentWindowWidth);
      setView(rdd.isTablet || rdd.isMobile || rdd.isIPad13);
    });
    return () =>
      window.removeEventListener("resize", () => {
        const currentWindowWidth = window.innerWidth;
        setWidth(currentWindowWidth);
        setView(rdd.isTablet || rdd.isMobile || rdd.isIPad13);
      });
  }, [width]);

  const room =
    (tour && tour.room && tour.room[0] && tour.room[0].roomNumber) || [];

  let roomOption = [];

  const roomLength = (room && room.length) || 0;
  for (let i = 0; i < roomLength; i++) {
    roomOption.push({
      value: room[i].value,
      label: room[i].label,
    });
  }

  const [ClientRoom, setClientRoom] = useState(roomOption);

  const [clientDetail, setClientDetail] = useState(
    (tour && tour.bookingClient) || []
  );

  const [clientMessage, setclientMessage] = useState(false);

  const [clientMessageFlag, setclientMessageFlag] = useState(false);

  const [clientRemoveFlag, setclientRemoveFlag] = useState([]);

  const addElement = () => {
    let updatedClientDetails = null;
    if (!client) {
      return;
    } else if (client.value === 0) {
      setAdults(noAdults + 1);
      setclientRemoveFlag([...clientRemoveFlag, client.value]);
      setclientMessageFlag(false);
      updatedClientDetails = { ...BOOKING_CLIENT_DETAILS, type: "Adult" };
    } else if (client.value === 1) {
      setclientRemoveFlag([...clientRemoveFlag, client.value]);
      setChild(noChild + 1);
      setclientMessageFlag(false);
      updatedClientDetails = { ...BOOKING_CLIENT_DETAILS, type: "Child" };
    } else if (client.value === 2) {
      setclientRemoveFlag([...clientRemoveFlag, client.value]);
      setBaby(noBaby + 1);
      setclientMessageFlag(false);
      updatedClientDetails = { ...BOOKING_CLIENT_DETAILS, type: "Baby" };
    }
    setClientDetail([...clientDetail, updatedClientDetails]);
  };

  const removeFormFields = (i) => {
    if (isUpdate) {
      return;
    }
    if (clientRemoveFlag[i] === 0) {
      clientRemoveFlag.splice(i, 1);
      setclientRemoveFlag(clientRemoveFlag);
      setAdults(noAdults - 1);
    } else if (clientRemoveFlag[i] === 1) {
      clientRemoveFlag.splice(i, 1);
      setclientRemoveFlag(clientRemoveFlag);
      setChild(noChild - 1);
    } else if (clientRemoveFlag[i] === 2) {
      clientRemoveFlag.splice(i, 1);
      setclientRemoveFlag(clientRemoveFlag);
      setBaby(noBaby - 1);
    }
    let newFormValues = [...clientDetail];
    newFormValues.splice(i, 1);
    setClientDetail(newFormValues);
  };

  const handleChangeF = (i, name, value) => {

      setClientDetail((prevClientDetail) => {
        const newClientDetail = [...prevClientDetail];
        newClientDetail[i] = {
          ...newClientDetail[i],
          [name]: value,
        };
        return newClientDetail;
      });

  };

  const handleNationality = (i, e) => {
    setClientDetail((prevClientDetail) => {
      const newClientDetail = [...prevClientDetail];
      newClientDetail[i] = {
        ...newClientDetail[i],
        nationality: e.label,
      };
      return newClientDetail;
    });
  };

  const handleDocumentType = (i, e) => {
    setClientDetail((prevClientDetail) => {
      const newClientDetail = [...prevClientDetail];
      newClientDetail[i] = {
        ...newClientDetail[i],
        documentType: e.label,
      };
      return newClientDetail;
    });
  };

  useEffect(() => {
    dispatch(getToursList());
    // dispatch(getCityList()).then((res) => {
    //   setCityList(res);
    // });
    dispatch(getTourBookingTypeList()).then((res) => {
      setTypeList(res);
    });
  }, []);

  const handleTags = (value) => {
    for (let i = 0; i < value.length; i++) {
      setClientRoom([...ClientRoom, { value: value[i], label: value[i] }]);
    }
  };

  const handleDeselect = (value) => {
    roomOption = _.filter(ClientRoom, function (o) {
      return o.value !== value;
    });
    setClientRoom(roomOption);
  };

  const [clientSecret, setClientSecret] = useState("");

  const handleSubmit = (values) => {
    let dte = null;
    if (
      priceSelected &&
      priceSelected.date &&
      priceSelected.date.includes("/")
    ) {
      dte = moment(priceSelected.date, "DD/MM/YYYY").format("YYYY-MM-DD");
    } else {
      // The input is already in YYYY-MM-DD format
      dte = moment(priceSelected.date).format("YYYY-MM-DD");
    }

    if (clientDetail && clientDetail.length === 0) {
      return;
    }
    if (validationClient(clientDetail, clientSetting)) {
      return;
    }
    setclientMessage(false);
    setDisable(true);

    if (isUpdate) {
      let data = {
        clientName: values.clientName,
        clientPhone: values.clientPhone,
        clientEmail: values.clientEmail,
        receptionist: values.receptionist,
        date: dte,
        typeId: values.typeId.value,
        tourId: values.tourId.value,
        clientDetails: (clientDetail &&
          clientDetail.length > 0 &&
          clientDetail) || [BOOKING_CLIENT_DETAILS],
        type: TOUR_TYPES.monthly_excursion,
        description: values.description,
        lang: values.language.value,
        rooms: ClientRoom,
        platform:
          (tour && tour.platform === PLATFORM.app && PLATFORM.app) ||
          PLATFORM.web,
        uId: tour && tour.uId,
      };
      dispatch(bookUpdateSharedTour(data, bookId)).then((res) => {
        setDisable(false);
        navigate("/dashboard/monthly-excursion-booking-list");
      });
    } else {
      let data = {
        clientName: values.clientName,
        clientPhone: values.clientPhone,
        clientEmail: values.clientEmail,
        receptionist: values.receptionist,
        date: dte,
        numOfAdults: noAdults,
        numOfChildren: noChild,
        numOfBabies: noBaby,
        extraCharges: null,
        paymentMethod: values.paymentMethod,
        typeId: values.typeId.value,
        tourId: values.tourId.value,
        clientDetails: (clientDetail &&
          clientDetail.length > 0 &&
          clientDetail) || [BOOKING_CLIENT_DETAILS],
        type: TOUR_TYPES.monthly_excursion,
        description: values.description,
        lang: values.language.value,
        rooms: ClientRoom,
        platform:
          (tour && tour.platform === PLATFORM.app && PLATFORM.app) ||
          PLATFORM.web,
        uId: tour && tour.uId,
      };
      dispatch(bookSharedTour(data)).then((bookRes) => {
        if (values.paymentMethod === 4) {
          dispatch(getPaymentIntent(bookRes.bT.id)).then((res) => {
            setDisable(false);
            setClientSecret(res.paymentIntent);
            navigate(`/dashboard/payment-stripe/${res.paymentIntent}`, {
              state: {
                bookId: bookRes.bT.id,
                res,
                path: "monthly-excursion-booking-list",
              },
            });
          });
        } else {
          setDisable(false);
          navigate("/dashboard/monthly-excursion-booking-list");
        }
      });
    }
  };

  let createFormValues = {
    clientName: tour ? tour.clientName : "",
    clientPhone: tour ? tour.clientPhone : "",
    clientEmail: tour ? tour.clientEmail : "",
    receptionist: tour ? tour.receptionist : "",
    date: tour ? tour.date : "",
    numOfAdults: tour ? tour.numOfAdults : "",
    numOfChildren: tour ? tour.numOfChildren : "",
    numOfBabies: tour ? tour.numOfBabies : "",
    extraCharges: tour ? tour.extraCharges : null,
    paymentMethod: (tour && tour.paymentMethod && tour.paymentMethod) || 1,
    typeId: typeList && typeList.length > 0 && typeList[0],
    tourId:
      (tour &&
        tour.tour && { label: tour.tour.destination, value: tour.tour.id }) ||
      {},
    description: tour ? tour.description : "",
    language: tour
      ? _.find(LANGUAGES, function (o) {
          return o.value === tour.lang;
        })
      : "",
  };

  const tourList = useSelector((state) => {
    return state.Receptionist.tours;
  });

  const currentDate = moment().format("YYYY-MM-DD");

  let privateTours = _.filter(tourList, function (o) {
    return (
      o.type === TOUR_TYPES.monthly_excursion && o.pickUpDate >= currentDate
    );
  });

  let arr = [];
  privateTours.forEach((element) => {
    arr.push({
      value: element.id,
      label: element.destination,
      adultPerPrice: (element.price && element.price.adultPerPrice) || 0,
      babiesPerPrice: (element.price && element.price.babiesPerPrice) || 0,
      childrenPerPrice: (element.price && element.price.childrenPerPrice) || 0,
      currencyCode: (element.price && element.price.currencyCode) || "",
      date: element.pickUpDate,
      description: element.description,
      schedule: element.schedule[0],
      hotel: element.user.hotel[0],
    });
  });

  const singleTour =
    arr &&
    arr.length > 0 &&
    tour &&
    _.filter(arr, function (o) {
      return o.value === tour.tour.id;
    });

  const [priceSelected, setPriceSelected] = useState(
    (singleTour && singleTour[0]) || null
  );

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };
  const { rowStyle, colStyle, gutter } = basicStyle;

  // const days = [
  //   "sunday",
  //   "monday",
  //   "tuesday",
  //   "wednesday",
  //   "thursday",
  //   "friday",
  //   "saturday",
  // ];

  // const daysNotAllowed = [];
  // const datesNotAllowed = [];

  // priceSelected &&
  //   days.forEach((day, index) => {
  //     priceSelected.schedule &&
  //       !priceSelected.schedule[day] &&
  //       daysNotAllowed.push(index);
  //   });

  // closeDay.forEach((day, index) => {
  //   datesNotAllowed.push(day.closeDate);
  // });

  // const disabledDate = (current) => {
  //   if (daysNotAllowed.includes(moment(current).day())) {
  //     return true;
  //   } else {
  //     return datesNotAllowed.includes(moment(current).format("YYYY-MM-DD"));
  //   }
  // };

  const [clientSetting, setClienSetting] = useState(fillTourSetting());

  const tourID = (priceSelected && priceSelected.value) || null;
  const hotelID =
    (priceSelected && priceSelected.hotel && priceSelected.hotel.id) || null;

  let selectedTourDate = null;
  if (priceSelected && priceSelected.date && priceSelected.date.includes("/")) {
    selectedTourDate = moment(priceSelected.date, "DD/MM/YYYY").format(
      "YYYY-MM-DD"
    );
  } else {
    // The input is already in YYYY-MM-DD format
    selectedTourDate =
      (priceSelected && moment(priceSelected.date).format("YYYY-MM-DD")) ||
      null;
  }

  useEffect(() => {
    if (tourID) {
      dispatch(viewHotelPrice(tourID, hotelID)).then((res) => {
        setHotelPrice(res);
      });
      dispatch(viewDefaultPrice(tourID)).then((res) => {
        setTourPrice(res);
      });
      dispatch(tourClientSetting(tourID)).then((res) => {
        setClienSetting(fillTourSetting(res));
      });
    }
  }, [tourID]);

  let adultPerPrice = 0;
  let babiesPerPrice = 0;
  let childrenPerPrice = 0;

  if (hotelPrice) {
    adultPerPrice = hotelPrice && parseFloat(hotelPrice.adultPerPrice);
    babiesPerPrice =
      (hotelPrice &&
        hotelPrice.babiesPerPrice &&
        parseFloat(hotelPrice.babiesPerPrice)) ||
      0;
    childrenPerPrice = hotelPrice && parseFloat(hotelPrice.childrenPerPrice);
  } else {
    adultPerPrice = tourPrice && parseFloat(tourPrice.adultPerPrice);
    babiesPerPrice =
      (tourPrice &&
        tourPrice.babiesPerPrice &&
        parseFloat(tourPrice.babiesPerPrice)) ||
      0;
    childrenPerPrice = tourPrice && parseFloat(tourPrice.childrenPerPrice);
  }

  return (
    <SignUpStyleWrapper className="isoSignUpPage">
      <div className="isoSignUpContentWrapper">
        <div className="isoSignUpContent">
          <div className="isoLogoWrapper">
            <Link to="/dashboard/private-tours-booking-list">
              Book Special Excursion
            </Link>
          </div>

          <Formik
            initialValues={createFormValues}
            onSubmit={handleSubmit}
            validationSchema={monthlyExcBookingValidation}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="isoSignUpForm">
                  <div className="isoInputWrapper">
                    <label className="label-style">Select a Tour</label>
                    <Dropdown
                      showSearch={!view}
                      options={arr}
                      disable={(isUpdate && true) || false}
                      onChange={(value) => {
                        setPriceSelected(value);
                        setFieldValue("tourId", value);
                        // dispatch(fetchTourCloseDayByRec(value.value)).then(
                        //   (res) => {
                        //     setCloseDay(res);
                        //   }
                        // );
                        //   setTypeList(res);
                        // });
                        // dispatch(getStopList(city, value)).then(
                        //   (res) => {
                        //     setStops(res);
                        //     setFieldValue("stopId", null);
                        //   }
                        // );
                      }}
                      value={values.tourId ? values.tourId : null}
                      placeholder="Select a Tour"
                    />
                    {errors.tourId && touched.tourId && (
                      <div className="invalid-input">{errors.tourId.value}</div>
                    )}
                  </div>
                  {/* <div className="isoInputWrapper">
                    <Dropdown
                      options={cityList}
                      onChange={(value) => {
                        setFieldValue("cityId", value);
                        setCity(value.value);
                        dispatch(getStopList(value.value, values.tourId)).then(
                          (res) => {
                            setStops(res);
                            setFieldValue("stopId", null);
                          }
                        );
                      }}
                      value={values.cityId ? values.cityId : null}
                      placeholder="Select a City"
                    />
                    {errors.cityId && touched.cityId && (
                      <div className="invalid-input">{errors.cityId.value}</div>
                    )}
                  </div> */}

                  {/* <div className="isoInputWrapper">
                    <Dropdown
                      options={stops}
                      onChange={(value) => {
                        setFieldValue("stopId", value);
                      }}
                      value={values.stopId ? values.stopId : null}
                      placeholder="Select a Stop"
                    />
                    {errors.stopId && touched.stopId && (
                      <div className="invalid-input">{errors.stopId.value}</div>
                    )}
                  </div> */}

                  <div className="isoInputWrapper">
                    <label className="label-style">Client Name</label>
                    <Input
                      size="large"
                      type="text"
                      name="clientName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.clientName}
                    />
                    {errors.clientName && touched.clientName && (
                      <div className="invalid-input">{errors.clientName}</div>
                    )}
                  </div>
                  <div className="isoInputWrapper">
                    <label className="label-style">Client Phone</label>
                    <Input
                      size="large"
                      type="text"
                      name="clientPhone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.clientPhone}
                    />
                    {errors.clientPhone && touched.clientPhone && (
                      <div className="invalid-input">{errors.clientPhone}</div>
                    )}
                  </div>

                  <div className="isoInputWrapper">
                    <label className="label-style">Client Email</label>
                    <Input
                      size="large"
                      type="text"
                      name="clientEmail"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.clientEmail}
                    />
                    {errors.clientEmail && touched.clientEmail && (
                      <div className="invalid-input">{errors.clientEmail}</div>
                    )}
                  </div>

                  <div className="isoInputWrapper">
                    <label className="label-style">Receptionist Name</label>
                    <Input
                      size="large"
                      type="text"
                      name="receptionist"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.receptionist}
                    />
                    {errors.receptionist && touched.receptionist && (
                      <div className="invalid-input">{errors.receptionist}</div>
                    )}
                  </div>

                  {(selectedTourDate && (
                    <div className="isoInputWrapper">
                      <Tag color="blue">{selectedTourDate}</Tag>
                    </div>
                  )) ||
                    null}

                  <div className="RowDiv">
                    {/* <div
                      className="isoInputWrapper"
                      style={{ width: "50%", padding: "0 7px 0 7px" }}
                    >
                      <Input
                        size="large"
                        placeholder="Number of Adults"
                        type="number"
                        name="numOfAdults"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.numOfAdults}
                      />
                      {errors.numOfAdults && touched.numOfAdults && (
                        <div className="invalid-input">
                          {errors.numOfAdults}
                        </div>
                      )}
                    </div>

                    <div
                      className="isoInputWrapper"
                      style={{ width: "50%", padding: "0 7px 0 7px" }}
                    >
                      <Input
                        size="large"
                        placeholder="Number of Children"
                        type="number"
                        name="numOfChildren"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.numOfChildren}
                      />
                      {errors.numOfChildren && touched.numOfChildren && (
                        <div className="invalid-input">
                          {errors.numOfChildren}
                        </div>
                      )}
                    </div>

                    <div
                      className="isoInputWrapper"
                      style={{ width: "50%", padding: "0 7px 0 7px" }}
                    >
                      <Input
                        size="large"
                        placeholder="Number of Babies"
                        type="number"
                        name="numOfBabies"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.numOfBabies}
                      />
                      {errors.numOfBabies && touched.numOfBabies && (
                        <div className="invalid-input">
                          {errors.numOfBabies}
                        </div>
                      )}
                    </div> */}
                    <div className="isoInputWrapper">
                      <div style={{ marginBottom: "8px", display: "flex" }}>
                        <label className="label-style">Select Passengers</label>
                        <Dropdown
                          showSearch={view.isDesiredWidth}
                          options={nonPrivateUsers}
                          disable={(isUpdate && true) || false}
                          onChange={(value) => {
                            setClients(value);
                            setclientMessageFlag(true);
                          }}
                          value={client ? client : null}
                          placeholder="Passengers"
                        />
                        <PlusOutlined
                          onClick={addElement}
                          style={{
                            backgroundColor: "#4482FF",
                            color: "white",
                            padding: "10px",
                            borderRadius: "5px",
                          }}
                        />
                      </div>
                      {clientDetail && clientDetail.length === 0 && (
                        <div className="invalid-input">Required</div>
                      )}
                    </div>
                    {/* <div className="InvoiceTableBtn">
                      <Button
                        onClick={addElement}
                        type="primary"
                        icon={<PlusOutlined />}
                        size={10}
                      >
                        Add
                      </Button>
                    </div> */}
                  </div>
                  {clientDetail &&
                    clientDetail.length > 0 &&
                    clientDetail.map((data, index) => {
                      return (
                        <ClientDetailsForm
                          key={index}
                          index={index}
                          view={view}
                          data={data}
                          clientSetting={clientSetting}
                          handleBlur={handleBlur}
                          handleChangeF={handleChangeF}
                          handleNationality={handleNationality}
                          handleDocumentType={handleDocumentType}
                          removeFormFields={removeFormFields}
                        />
                      );
                    })}
                  {/* <div className="isoInputWrapper">
                    <Input
                      size="large"
                      placeholder="Extra Charges"
                      type="number"
                      name="extraCharges"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.extraCharges}
                    />
                    {errors.extraCharges && touched.extraCharges && (
                      <div className="invalid-input">{errors.extraCharges}</div>
                    )}
                  </div> */}
                  <div className="isoInputWrapper">
                    <label className="label-style">Select a Language</label>
                    <Dropdown
                      showSearch={view.isDesiredWidth}
                      options={LANGUAGES}
                      onChange={(value) => {
                        setFieldValue("language", value);
                      }}
                      value={values.language ? values.language : null}
                      placeholder="Select a Language"
                    />
                    {errors.language && touched.language && (
                      <div className="invalid-input">
                        {errors.language.value}
                      </div>
                    )}
                  </div>
                  <div className="isoInputWrapper">
                    <label className="label-style">Select an operator</label>
                    <Dropdown
                      showSearch={view.isDesiredWidth}
                      options={typeList}
                      onChange={(value) => {
                        setFieldValue("typeId", value);
                      }}
                      value={values.typeId ? values.typeId : null}
                      placeholder="Select an operator"
                    />
                    {errors.typeId && touched.typeId && (
                      <div className="invalid-input">{errors.typeId.value}</div>
                    )}
                  </div>
                  <div className="isoInputWrapper">
                    <label className="label-style">Add Room#</label>
                    <Select
                      mode="tags"
                      defaultValue={ClientRoom}
                      style={{ width: "100%" }}
                      onChange={handleTags}
                      // onBlur={handleBlur}
                      onDeselect={handleDeselect}
                      options={ClientRoom}
                    />
                  </div>
                  <div className="isoInputWrapper">
                    <label className="label-style">Comments</label>
                    <Textarea
                      rows={4}
                      size="large"
                      type="text"
                      name="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                    />
                    {errors.description && touched.description && (
                      <div className="invalid-input">{errors.description}</div>
                    )}
                    <small class="form-text text-muted">
                      *This does not appear on client voucher - only intended
                      for internal communication with BGT Travel
                    </small>
                  </div>
                  <div className="isoInputWrapper">
                    <Row style={rowStyle} gutter={gutter} justify="start">
                      <Col md={12} sm={12} xs={24} style={colStyle}>
                        <div>
                          <p style={{ fontWeight: "bold" }}>Payment Method</p>
                        </div>
                        <RadioGroup
                          onChange={handleChange}
                          name="paymentMethod"
                          value={values.paymentMethod}
                          disabled={(isUpdate && true) || false}
                        >
                          <Radio style={radioStyle} value={1}>
                            Cash
                          </Radio>
                          <Radio style={radioStyle} value={2}>
                            Credit or debit card
                          </Radio>
                          <Radio style={radioStyle} value={3}>
                            Monthly Invoicing
                          </Radio>
                          <Radio style={radioStyle} value={4}>
                            Pay Here With Card Now
                          </Radio>
                        </RadioGroup>
                      </Col>
                    </Row>
                    {errors.paymentMethod && touched.paymentMethod && (
                      <div className="invalid-input">
                        {errors.paymentMethod}
                      </div>
                    )}
                  </div>
                  {priceSelected ? (
                    <div className="isoInputWrapper">
                      <div>
                        <p>{`Total Price: ${
                          EURO.format(
                            noAdults * adultPerPrice +
                              noBaby * babiesPerPrice +
                              noChild * childrenPerPrice +
                              parseFloat(
                                values.extraCharges ? values.extraCharges : "0"
                              )
                          )
                          // : values.numOfAdults && values.numOfChildren
                          // ? parseInt(values.numOfAdults) *
                          //     parseInt(priceSelected.adultPerPrice) +
                          //   parseInt(values.numOfChildren) *
                          //     parseInt(priceSelected.childrenPerPrice) +
                          //   parseFloat(
                          //     values.extraCharges ? values.extraCharges : "0"
                          //   )
                          // : values.numOfAdults
                          // ? parseInt(values.numOfAdults) *
                          //     parseInt(priceSelected.adultPerPrice) +
                          //   parseFloat(
                          //     values.extraCharges ? values.extraCharges : "0"
                          //   )
                        }`}</p>
                      </div>
                    </div>
                  ) : null}

                  <Button
                    type="primary"
                    onClick={handleSubmit}
                    disabled={disable}
                  >
                    Book
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className="rightbar">
          <div className="rightbar-inner">
            <h3>Description</h3>
            <p>
              {(priceSelected && priceSelected.description) ||
                "description not available!"}
            </p>
          </div>
        </div>
      </div>
    </SignUpStyleWrapper>
  );
}
