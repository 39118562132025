import React, {useState} from 'react';
import isEmpty from 'lodash/isEmpty';
import {useDispatch, useSelector} from 'react-redux';
import {Modal, Spin} from 'antd';
import {useNavigate} from 'react-router-dom';

import NoBoardFounds from '../BoardNotFound/BoardNotFound';
import BoardListCard from './BoardListCard/BoardListCard';
import AppLayout from '../../AppLayout/AppLayout';
import {Table} from './BoardList.style';
import {deleteCity} from '@iso/redux/city/actions';
function BoardLists({history, match}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedModalBoard, setSelectedModalBoard] = useState(null);
  const [search, setSearch] = useState(null);

  const showModal = (city) => {
    setSelectedModalBoard(city);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    dispatch(deleteCity(selectedModalBoard.id)).then((res) => {
      setIsModalVisible(false);
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleEdit = (city) => {
    navigate(`/dashboard/createCity/${city.id}`, {state: {city}});
  };

  let cityList = useSelector((state) => {
    return state.City.city;
  });

  if (search) {
    let searched = cityList.filter((i) => {
      return i.name.toLowerCase().includes(search.toLowerCase());
    });
    cityList = searched;
  }

  let Loading = useSelector((state) => {
    return state.City.loading;
  });

  return (
    <>
      <AppLayout
        history={history}
        match={match}
        setSearchText={(value) => {
          setSearch(value);
        }}>
        {Loading ? (
          <div className="spinner">
            <Spin />
          </div>
        ) : (
          <>
            {!isEmpty(cityList) ? (
              <Table>
                {cityList.map((board) => {
                  return (
                    <BoardListCard
                      key={board.id}
                      item={board}
                      onDelete={() => {
                        showModal(board);
                      }}
                      onEdit={() => handleEdit(board)}
                    />
                  );
                })}
              </Table>
            ) : (
              <NoBoardFounds history={history} match={match} />
            )}
          </>
        )}
      </AppLayout>
      <Modal title="Delete Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>Are you sure you want to inactivate this user?</p>
      </Modal>
    </>
  );
}

export default BoardLists;
