import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import BoardLists from "./Board/BoardList/BoardList";
import { getHotelPriceList } from "@iso/redux/airportTransfer/actions";

export default function ToursList() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getHotelPriceList());
  }, []);

  return (
    <>
      <BoardLists />
    </>
  );
}
