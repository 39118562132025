import React from "react";
import { Route } from "react-router-dom";
import CreateAdmin from "@iso/pages/superAdmin/createAdmin/createAdmin";
import AdminList from "@iso/pages/superAdmin/adminList";
import ResetPassword from "@iso/pages/ResetPassword/ResetPassword";
import HotelList from "../pages/superAdmin/hotel/HotelList";
import HotelView from "../pages/superAdmin/hotel/HotelView";
import HotelCommision from "../pages/superAdmin/hotel/Commision";
import TourBooking from "../pages/superAdmin/booking/tour/List";
import AirportTransfers from "../pages/superAdmin/booking/airportTransfer/List";
import TourBookingView from "../pages/superAdmin/booking/tour/View";
import AirportTransfersView from "../pages/superAdmin/booking/airportTransfer/View";
import Dashboard from "@iso/pages/superAdmin/dashboard/Dashboard";
import Profile from "@iso/pages/profile/Profile";

export default function SuperAdminRoutes() {
  return (
    <>
      <Route exact path="" element={<Dashboard />} />
      <Route exact path="my-profile" element={<Profile />} />
      <Route exact path="createAdmin/:id" element={<CreateAdmin />} />
      <Route exact path="createAdmin" element={<CreateAdmin />} />
      <Route exact path="tourBooking-view/:id" element={<TourBookingView />} />
      <Route
        exact
        path="airportTransfers-view/:id"
        element={<AirportTransfersView />}
      />
      <Route exact path="admin-list" element={<AdminList />} />
      <Route exact path="hotels-commision/:id" element={<HotelCommision />} />
      <Route exact path="hotels-view/:id" element={<HotelView />} />
      <Route exact path="hotels-list" element={<HotelList />} />
      <Route path="resetPassword" element={<ResetPassword />} />
      <Route path="booking">
        <Route path="airport-reports" element={<AirportTransfers />} />
        <Route path="tour-reports" element={<TourBooking />} />
      </Route>
    </>
  );
}
