import React, {useState, useEffect} from 'react';
import {Link, useNavigate, useLocation, useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {Formik} from 'formik';
import _ from 'lodash';

import Dropdown from '@iso/components/uielements/CustomDropdown';
import Button from '@iso/components/uielements/button';
import Input from '@iso/components/uielements/input';
import {
  createVehiclePrice,
  updateVehiclePrice,
  airportList,
  hotelList,
} from '@iso/redux/vehicle/actions';
import IntlMessages from '@iso/components/utility/intlMessages';
import SignUpStyleWrapper from './CreateVehiclePrice.styles';
import {fetchCurrency} from '@iso/redux/common/actions';
import {vehiclePriceFormValidation, vehiclePrice2FormValidation} from '../../../constants';

export default function CreateVehiclesPrice() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useLocation();
  const locParams = params && params.state ? params.state : {vehiclePrice: null};
  const par = useParams();

  const [airports, setAirport] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [countryTypes, setCountryTypes] = useState([]);
  const [disable, setDisable] = useState(false);

  let dropdownData = [
    {value: 1, label: '1-4 Persons'},
    {value: 2, label: '5-7 Persons'},
    {value: 3, label: '8-12 Persons'},
    {value: 4, label: '12-19 Persons'},
    {value: 5, label: '20-24 Persons'},
  ];

  useEffect(() => {
    dispatch(hotelList()).then((res) => {
      setHotels(res);
    });
    dispatch(airportList()).then((res) => {
      setAirport(res);
    });
    dispatch(fetchCurrency()).then((res) => {
      let arr = [];
      res.forEach((item) => {
        arr.push({value: item.code, label: item.name});
      });
      setCountryTypes(arr);
    });
  }, []);

  const handleSubmit = (values) => {
    setDisable(true);
    if ((locParams.pricingType && locParams.pricingType === 2) && (par && !par.id)) {
      let data = {
        pricingType: locParams.pricingType,
        vehicleCapacity: values.vehicleCapacity.value,
        airportId: values.airportId.value,
        hotelId: values.hotelId.value,
        farePrice: values.farePrice,
        currency: values.countryId.value,
      };
      dispatch(createVehiclePrice(data)).then((res) => {
        setDisable(false);
        navigate('/dashboard/vehicle-price');
      });
    } else if ((locParams.pricingType && locParams.pricingType === 1) && (par && !par.id)) {
      let data = {
        pricingType: locParams.pricingType,
        airportId: values.airportId.value,
        hotelId: values.hotelId.value,
        farePrice: values.farePrice,
        currency: values.countryId.value,
      };
      dispatch(createVehiclePrice(data)).then((res) => {
        setDisable(false);
        navigate('/dashboard/vehicle-price');
      });
    } else if ((locParams.pricingType && locParams.pricingType === 1) && (par && par.id)) {
      let updatedVehiclePrice = {
        noOfPassengers: values.noOfPassengers,
        airportId: values.airportId.value,
        hotelId: values.hotelId.value,
        farePrice: values.farePrice,
        currency: values.countryId.value,
        id: par.id,
      };
      dispatch(updateVehiclePrice(updatedVehiclePrice)).then((res) => {
        setDisable(false);
        navigate('/dashboard/vehicle-price');
      });
    } else if ((locParams.pricingType && locParams.pricingType === 2) && (par && par.id)) {
      let updatedVehiclePrice = {
        pricingType: locParams.pricingType,
        vehicleCapacity: values.vehicleCapacity.value,
        airportId: values.airportId.value,
        hotelId: values.hotelId.value,
        farePrice: values.farePrice,
        currency: values.countryId.value,
        id: par.id,
      };
      dispatch(updateVehiclePrice(updatedVehiclePrice)).then((res) => {
        setDisable(false);
        navigate('/dashboard/vehicle-price');
      });
    }
  };

  let vehicleInitialValues = {
    vehicleCapacity:
      locParams.pricingType && locParams.vehiclePrice && locParams.pricingType === 2
        ? _.find(dropdownData, function (o) {
            return o.value === locParams.vehiclePrice.vehicleCapacity;
          })
        : {value: 1, label: '1-3 Persons'},
    airportId: locParams.vehiclePrice
      ? _.find(airports, function (o) {
          return o.value === locParams.vehiclePrice.airportId;
        })
      : {value: ''},
    hotelId: locParams.vehiclePrice
      ? _.find(hotels, function (o) {
          return o.value === locParams.vehiclePrice.hotelId;
        })
      : {value: ''},
    farePrice: locParams.vehiclePrice ? locParams.vehiclePrice.farePrice : '',
    countryId: locParams.vehiclePrice
      ? _.find(countryTypes, function (o) {
          return o.value === locParams.vehiclePrice.currency;
        })
      : {value: 'EUR', label: 'Euro'},
  };

  return (
    <SignUpStyleWrapper className="isoSignUpPage">
      <div className="isoSignUpContentWrapper">
        <div className="isoSignUpContent">
          <div className="isoLogoWrapper">
            <Link to="/dashboard">
              {locParams.vehiclePrice
                ? `update ${
                    locParams.pricingType === 2 ? 'per vehicle' : 'per person'
                  } airport transfer rates`
                : `Add a new ${
                    locParams.pricingType === 2 ? 'per vehicle' : 'per person'
                  } airport transfer rates`}
            </Link>
          </div>

          <Formik
            initialValues={vehicleInitialValues}
            onSubmit={handleSubmit}
            validationSchema={
              locParams.pricingType ? vehiclePrice2FormValidation : vehiclePriceFormValidation
            }
            enableReinitialize>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              /* and other goodies */
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="isoSignUpForm">
                    {locParams.pricingType && locParams.pricingType === 2 ? (
                      <div className="isoInputWrapper">
                        <Dropdown
                          options={dropdownData}
                          onChange={(value) => {
                            setFieldValue('vehicleCapacity', value);
                          }}
                          value={values.vehicleCapacity ? values.vehicleCapacity : null}
                          placeholder="Select a airport"
                        />
                        {errors.vehicleCapacity && touched.vehicleCapacity && (
                          <div className="invalid-input">{errors.vehicleCapacity.value}</div>
                        )}
                      </div>
                    ) : null}
                    <div className="isoInputWrapper">
                      <Dropdown
                        options={airports}
                        onChange={(value) => {
                          setFieldValue('airportId', value);
                        }}
                        value={values.airportId ? values.airportId : null}
                        placeholder="Select a airport"
                      />
                      {errors.airportId && touched.airportId && (
                        <div className="invalid-input">{errors.airportId.value}</div>
                      )}
                    </div>
                    <div className="isoInputWrapper">
                      <Dropdown
                        options={hotels}
                        onChange={(value) => {
                          setFieldValue('hotelId', value);
                        }}
                        value={values.hotelId ? values.hotelId : null}
                        placeholder="Select a hotel"
                      />
                      {errors.hotelId && touched.hotelId && (
                        <div className="invalid-input">{errors.hotelId.value}</div>
                      )}
                    </div>

                    <div className="isoInputWrapper">
                      <Input
                        size="large"
                        placeholder="Fare Price"
                        type="number"
                        name="farePrice"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.farePrice}
                      />
                      {errors.farePrice && touched.farePrice && (
                        <div className="invalid-input">{errors.farePrice}</div>
                      )}
                    </div>

                    <div className="isoInputWrapper">
                      <Dropdown
                        options={countryTypes}
                        onChange={(value) => {
                          setFieldValue('countryId', value);
                        }}
                        value={values.countryId ? values.countryId : null}
                        placeholder="Select a Currency"
                      />
                      {errors.countryId && touched.countryId && (
                        <div className="invalid-input">{errors.countryId.value}</div>
                      )}
                    </div>

                    <div className="isoInputWrapper">
                      <Button type="primary" onClick={handleSubmit} disabled={disable}>
                        <IntlMessages
                          id={locParams.vehiclePrice ? 'Update' : 'page.signUpButton'}
                        />
                      </Button>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </SignUpStyleWrapper>
  );
}
