import React from "react";
import { Layout, Button } from "antd";
import { Scrollbars } from "react-custom-scrollbars";

import { Header } from "./AppLayout.style";

const { Content } = Layout;

const AppLayout = ({ children, onCreate, disable }) => {
  return (
    <Layout style={{ backgroundColor: "#fff" }}>
      <Header>
        <div style={{ display: "flex" }}></div>
        <Button type="primary" onClick={onCreate} disabled={disable}>
          Create
        </Button>
      </Header>
      <Content
        style={{
          padding: "0 24px",
        }}
      >
        <Scrollbars style={{ width: "100%", height: "80vh" }}>
          {children}
        </Scrollbars>
      </Content>
    </Layout>
  );
};

export default AppLayout;
