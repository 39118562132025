import React, {useState} from 'react';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {Formik} from 'formik';

import Input from '@iso/components/uielements/input';
import Button from '@iso/components/uielements/button';
import {createCity, updateCity} from '@iso/redux/city/actions';
import IntlMessages from '@iso/components/utility/intlMessages';
import SignUpStyleWrapper from './CreateCity.styles';
import {cityValidation} from '../../../constants';

export default function CreateCity() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useLocation();
  const locParams = params && params.state ? params.state : {city: null};

  const [disable, setDisable] = useState(false);

  const handleSubmit = (values) => {
    setDisable(true);
    if (locParams.city) {
      let updatedCity = {...values, id: locParams.city.id, status: locParams.city.status};
      dispatch(updateCity(updatedCity)).then((res) => {
        setDisable(false);
        navigate('/dashboard/city-list');
      });
    } else {
      dispatch(createCity(values)).then((res) => {
        setDisable(false);
        navigate('/dashboard/city-list');
      });
    }
  };

  return (
    <SignUpStyleWrapper className="isoSignUpPage">
      <div className="isoSignUpContentWrapper">
        <div className="isoSignUpContent">
          <div className="isoLogoWrapper">
            <Link to="/dashboard">
              <IntlMessages id={locParams.city ? 'Update City' : 'Add a New City'} />
            </Link>
          </div>

          <Formik
            initialValues={{
              name: locParams.city ? locParams.city.name : '',
            }}
            onSubmit={handleSubmit}
            validationSchema={cityValidation}
            enableReinitialize>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="isoSignUpForm">
                  <div className="isoInputWrapper">
                  <label className="label-style">Name</label>
                    <Input
                      size="large"
                      type="text"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    {errors.name && touched.name && (
                      <div className="invalid-input">{errors.name}</div>
                    )}
                  </div>

                  <div className="isoInputWrapper">
                    <Button type="primary" onClick={handleSubmit} disabled={disable}>
                      <IntlMessages id={locParams.city ? 'Update' : 'page.signUpButton'} />
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </SignUpStyleWrapper>
  );
}
