import React, {useState, useRef} from 'react';
import {Modal} from 'antd';
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {Formik, Field} from 'formik';
import {PaperClipOutlined} from '@ant-design/icons';

import Input from '@iso/components/uielements/input';
import Button from '@iso/components/uielements/button';
import {updateAvatar, updateProfile} from '@iso/redux/common/actions';
import IntlMessages from '@iso/components/utility/intlMessages';
import SignUpStyleWrapper from './styles';
import {profileValidation} from '../../constants';

const MapModal = ({visible = false, setVisible = () => {}, user}) => {
  let func;
  const imageRef = useRef();
  const dispatch = useDispatch();

  const [photo, setPhoto] = useState(user.avatar ? user.avatar.url :null);

  let initialValues = {
    firstName: user ? user.firstName : '',
    lastName: user ? user.lastName : '',
    pic: user ? (user.avatar ? user.avatar.url : '') : '',
  };

  const handleSubmit = (values) => {
    if (values.pic) {
      dispatch(updateAvatar({avatar: values.pic})).then((res) => {
        setVisible(false);
      });
    }
    if (values.firstName && values.lastName) {
      dispatch(updateProfile({firstName: values.firstName, lastName: values.lastName})).then(
        (res) => {
          setVisible(false);
        },
      );
    }
  };

  const showOpenFileDialog = () => {
    imageRef.current.click();
  };

  return (
    <div>
      <Modal
        title="Edit your profile"
        centered
        visible={visible}
        onOk={() => func()}
        onCancel={() => {
          initialValues = {
            firstName: '',
            lastName: '',
            pic: '',
          };
          setVisible(false);
        }}
        width={700}
        bodyStyle={{height: 500}}>
        <SignUpStyleWrapper className="isoSignUpPage">
          <div className="isoSignUpContentWrapper">
            <div className="isoSignUpContent">
              <div className="isoLogoWrapper">
                <Link to="/dashboard">
                  <IntlMessages id={'edit profile'} />
                </Link>
              </div>

              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={profileValidation}
                enableReinitialize>
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  /* and other goodies */
                }) => {
                  func = handleSubmit;
                  const handleChanged = (e) => {
                    if (e.target.name === 'pic') {
                      setFieldValue(e.target.name, e.target.files[0]);
                      const blob = URL.createObjectURL(e.target.files[0]);
                      setPhoto(blob);
                    } else {
                      setFieldValue(e.target.name, e.target.value);
                    }
                  };
                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="isoSignUpForm">
                        <Field name={`pic`}>
                          {({field}) => {
                            if (field.value !== 0 && !field.value) {
                              field.value = '';
                            }
                            return (
                              <>
                                <input
                                  ref={imageRef}
                                  type="file"
                                  {...field}
                                  value={undefined}
                                  onChange={handleChanged}
                                  style={{display: 'none'}}
                                  accept="image/x-png,image/jpeg,image/jpg"
                                />
                              </>
                            );
                          }}
                        </Field>
                        <div className="isoInputWrapper">
                          <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div className="memo-upload-pic" onClick={showOpenFileDialog}>
                              {photo && (
                                <div className="Avatar">
                                  <img src={photo} alt={''} />
                                </div>
                              )}
                              <Button>
                                <PaperClipOutlined style={{fontSize: 20}} /> add photo
                              </Button>
                            </div>
                          </div>
                          {errors.firstName && touched.firstName && (
                            <div className="invalid-input">{errors.firstName}</div>
                          )}
                        </div>
                        <div className="isoInputWrapper">
                          <Input
                            size="large"
                            placeholder="First Name"
                            type="text"
                            name="firstName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.firstName}
                          />
                          {errors.firstName && touched.firstName && (
                            <div className="invalid-input">{errors.firstName}</div>
                          )}
                        </div>
                        <div className="isoInputWrapper">
                          <Input
                            size="large"
                            placeholder="Last Name"
                            type="text"
                            name="lastName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.lastName}
                          />
                          {errors.lastName && touched.lastName && (
                            <div className="invalid-input">{errors.lastName}</div>
                          )}
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </SignUpStyleWrapper>
      </Modal>
    </div>
  );
};

export default MapModal;
