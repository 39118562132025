import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import _ from "lodash";
import moment from "moment";
import "./CreateTours.css";

import Dropdown from "@iso/components/uielements/CustomDropdown";
import Input from "@iso/components/uielements/input";
import Button from "@iso/components/uielements/button";
import { createTour, updateTour } from "@iso/redux/tours/actions";
import IntlMessages from "@iso/components/utility/intlMessages";
import { fetchCurrency } from "@iso/redux/common/actions";
import { monthluExcursionValidation } from "@iso/constants";
import SignUpStyleWrapper from "./CreateTours.styles";
import { Avatar, DatePicker } from "antd";
import basicStyle from "@iso/assets/styles/constants";
import { TOUR_TYPES } from "../../../constants";
import { Map, fillTourSetting } from "../../../services";
import { Textarea } from "../../../components/uielements/input";
import TourSettingForm from "../../../components/TourSetting/Form";
import {
  view_Srd_Tkt_Tour,
  tourClientSetting,
} from "../../../redux/tours/actions";

export default function CreateMonthlyExcursion() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useLocation();
  const locParams = params && params.state ? params.state : { tour: null };

  const tourID = locParams.tour ? locParams.tour.id : null;

  const [tourData, setTourData] = useState(null);
  const [location, setLocation] = useState({});
  const [countryTypes, setCountryTypes] = useState([]);
  const [disable, setDisable] = useState(false);
  const [imageFile, setImageFile] = useState();
  const [radius, setRadius] = useState(
    (locParams &&
      locParams.tour &&
      locParams.tour.DeparturePlace &&
      locParams.tour.DeparturePlace.length > 0 &&
      locParams.tour.DeparturePlace[0].radius) ||
      0
  );

  const [client, setclient] = useState(fillTourSetting());

  useEffect(() => {
    dispatch(fetchCurrency()).then((res) => {
      let arr = [];
      res.forEach((item) => {
        arr.push({ value: item.code, label: item.name });
      });
      setCountryTypes(arr);
    });
  }, []);

  useEffect(() => {
    if (tourID) {
      dispatch(view_Srd_Tkt_Tour(tourID)).then((res) => {
        setTourData(res);
      });
      dispatch(tourClientSetting(tourID)).then((res) => {
        setclient(fillTourSetting(res));
      });
    }
  }, [tourID]);

  const onChangeRadius = (val) => {
    setRadius(val);
  };

  const handleSubmit = (values) => {
    console.log("values.pickUpDate", values.pickUpDate);
    // return;
    setDisable(true);
    values.location.lat = location.lat;
    values.location.lng = location.lng;
    if (locParams.tour) {
      let data = {
        id: locParams.tour.id,
        tour: {
          destination: values.destination,
          tagline: values.tagline,
          duration: values.duration,
          includes: values.includes,
          observations: values.observations,
          remarks: values.remarks,
          description: values.description,
          pickUpDate: values.pickUpDate,
        },
        price: {
          babiesPerPrice: values.babiesPerPrice,
          childrenPerPrice: values.childrenPerPrice,
          adultPerPrice: values.adultPerPrice,
          currencyCode: values.countryId.value,
        },
        departurePlace: {
          name: values.location.name,
          latitude: values.location.lat,
          longitude: values.location.lng,
          address: values.location.address,
          radius: 0,
        },
        clientSetting: {
          client,
        },
        photo: values.photo,
        type: TOUR_TYPES.monthly_excursion,
      };
      dispatch(updateTour(data)).then((res) => {
        setDisable(false);
        navigate("/dashboard/special-monthly-excursion");
      });
    } else {
      let data = {
        tour: {
          destination: values.destination,
          tagline: values.tagline,
          duration: values.duration,
          includes: values.includes,
          observations: values.observations,
          remarks: values.remarks,
          description: values.description,
          pickUpDate: values.pickUpDate,
        },
        price: {
          babiesPerPrice: values.babiesPerPrice,
          childrenPerPrice: values.childrenPerPrice,
          adultPerPrice: values.adultPerPrice,
          currencyCode: values.countryId.value,
        },
        departurePlace: {
          name: values.location.name,
          latitude: values.location.lat,
          longitude: values.location.lng,
          address: values.location.address,
          radius: 0,
        },
        clientSetting: {
          client,
        },
        photo: values.photo,
        type: TOUR_TYPES.monthly_excursion,
      };
      dispatch(createTour(data)).then((res) => {
        setDisable(false);
        navigate("/dashboard/special-monthly-excursion");
      });
    }
  };

  let createFormValues = {
    destination: "",
    tagline: "",
    duration: "",
    includes: "",
    observations: "",
    remarks: "",
    childrenPerPrice: "",
    adultPerPrice: "",
    babiesPerPrice: "",
    location: {},
    countryId: { value: "EUR", label: "Euro" },
    photo: null,
    description: "",
    pickUpDate: "",
  };

  let updateFormValues = {
    destination: locParams.tour ? locParams.tour.destination : "",
    photo:
      (locParams.tour && locParams.tour.photo && locParams.tour.photo.url) ||
      null,
    duration: locParams.tour ? locParams.tour.duration : "",
    includes: locParams.tour ? locParams.tour.includes : "",
    observations: locParams.tour ? locParams.tour.observations : "",
    description: locParams.tour ? locParams.tour.description : "",
    tagline: locParams.tour ? locParams.tour.tagline : "",
    remarks: locParams.tour ? locParams.tour.remarks : "",
    pickUpDate: locParams.tour ? locParams.tour.pickUpDate : "",
    childrenPerPrice: tourData ? tourData.price.childrenPerPrice : "",
    adultPerPrice: tourData ? tourData.price.adultPerPrice : "",
    babiesPerPrice: tourData ? tourData.price.babiesPerPrice : "",
    countryId: tourData
      ? _.find(countryTypes, function (o) {
          return o.value === tourData.price.currencyCode;
        })
      : null,
    location: tourData
      ? tourData.DeparturePlace
        ? tourData.DeparturePlace.length !== 0
          ? {
              lat: tourData.DeparturePlace[0].latitude,
              lng: tourData.DeparturePlace[0].longitude,
              name: tourData.DeparturePlace[0].name
                ? tourData.DeparturePlace[0].name
                : "name",
              location: tourData.DeparturePlace[0].location
                ? tourData.DeparturePlace[0].location
                : "location",
            }
          : {}
        : {}
      : {},
  };

  const { rowStyle, colStyle, gutter } = basicStyle;

  console.log("location", location);

  return (
    <SignUpStyleWrapper className="isoSignUpPage">
      <div className="isoSignUpContentWrapper">
        <div className="isoSignUpContent">
          <div className="isoLogoWrapper">
            <Link to="/dashboard">
              <IntlMessages
                id={
                  locParams.tour
                    ? "Edit Special Excursion"
                    : "Add a Special Excursion"
                }
              />
            </Link>
          </div>

          <Formik
            initialValues={locParams.tour ? updateFormValues : createFormValues}
            onSubmit={handleSubmit}
            validationSchema={monthluExcursionValidation}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              /* and other goodies */
            }) => {
              const defaultDate = values.pickUpDate
                ? moment(values.pickUpDate).format("DD/MM/yyyy")
                : null;
              console.log("defaultDate", defaultDate);
              return (
                <form onSubmit={handleSubmit}>
                  <div className="isoSignUpForm">
                    <div className="RowDiv">
                      <div className="isoInputWrapper">
                        <label className="label-style">Destination</label>
                        <Input
                          size="large"
                          type="text"
                          name="destination"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.destination}
                        />
                        {errors.destination && touched.destination && (
                          <div className="invalid-input">
                            {errors.destination}
                          </div>
                        )}
                      </div>

                      <div className="isoInputWrapper">
                        <label className="label-style">Duration (hours)</label>
                        <Input
                          size="large"
                          type="number"
                          name="duration"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.duration}
                        />
                        {errors.duration && touched.duration && (
                          <div className="invalid-input">{errors.duration}</div>
                        )}
                      </div>
                    </div>

                    <div className="RowDiv">
                      <div className="isoInputWrapper">
                        <label className="label-style">Tagline</label>
                        <Input
                          size="large"
                          type="text"
                          name="tagline"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.tagline}
                        />
                        {errors.tagline && touched.tagline && (
                          <div className="invalid-input">{errors.tagline}</div>
                        )}
                      </div>
                      <div className="isoInputWrapper">
                        <label className="label-style">Includes</label>
                        <Input
                          size="large"
                          type="text"
                          name="includes"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.includes}
                        />
                        {errors.includes && touched.includes && (
                          <div className="invalid-input">{errors.includes}</div>
                        )}
                      </div>
                    </div>

                    <div className="RowDiv">
                      <div className="isoInputWrapper">
                        <label className="label-style">Remarks</label>
                        <Input
                          size="large"
                          type="text"
                          name="remarks"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.remarks}
                        />
                        {errors.remarks && touched.remarks && (
                          <div className="invalid-input">{errors.remarks}</div>
                        )}
                      </div>

                      <div className="isoInputWrapper">
                        <label className="label-style">Adult Price</label>
                        <Input
                          size="large"
                          type="number"
                          name="adultPerPrice"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.adultPerPrice}
                        />
                        {errors.adultPerPrice && touched.adultPerPrice && (
                          <div className="invalid-input">
                            {errors.adultPerPrice}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="RowDiv">
                      <div className="isoInputWrapper">
                        <label className="label-style">Child Price</label>
                        <Input
                          size="large"
                          type="number"
                          name="childrenPerPrice"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.childrenPerPrice}
                        />
                        {errors.childrenPerPrice &&
                          touched.childrenPerPrice && (
                            <div className="invalid-input">
                              {errors.childrenPerPrice}
                            </div>
                          )}
                      </div>
                      <div className="isoInputWrapper">
                        <label className="label-style">Toddler Price</label>
                        <Input
                          size="large"
                          type="number"
                          name="babiesPerPrice"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.babiesPerPrice}
                        />
                        {errors.babiesPerPrice && touched.babiesPerPrice && (
                          <div className="invalid-input">
                            {errors.babiesPerPrice}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="isoInputWrapper">
                      <label className="label-style">Date</label>
                      <DatePicker
                        name="pickUpDate"
                        onChange={(date, dateString) => {
                          setFieldValue("pickUpDate", dateString);
                        }}
                        format="DD/MM/yyyy"
                        defaultValue={
                          defaultDate ? moment(defaultDate, "DD/MM/yyyy") : null
                        }
                      />
                      {errors.pickUpDate && touched.pickUpDate && (
                        <div className="invalid-input">{errors.pickUpDate}</div>
                      )}
                    </div>

                    <div className="RowDiv">
                      <div className="isoInputWrapper">
                        <label className="label-style">Select a Currency</label>
                        <Dropdown
                          options={countryTypes}
                          onChange={(value) => {
                            setFieldValue("countryId", value);
                          }}
                          value={values.countryId ? values.countryId : null}
                        />
                        {errors.countryId && touched.countryId && (
                          <div className="invalid-input">
                            {errors.countryId.value}
                          </div>
                        )}
                      </div>
                      <div
                        className="isoInputWrapper"
                        style={{ marginBottom: "12px" }}
                      >
                        <label className="label-style">Image</label>
                        <Input
                          type="file"
                          name="photo"
                          accept="image/x-png,image/jpeg,image/jpg"
                          onChange={(e) =>
                            handleChange(
                              setFieldValue("photo", e.target.files[0]),
                              setImageFile(
                                URL.createObjectURL(e.target.files[0])
                              )
                            )
                          }
                          onBlur={handleBlur}
                          value={undefined}
                        />
                        <Avatar
                          src={
                            (imageFile && imageFile) ||
                            (tourData && tourData.photo && tourData.photo.url)
                          }
                          style={{ marginTop: "10px" }}
                          shape="square"
                          size={100}
                        />

                        {/* {errors.countryId && touched.countryId && (
                        <div className="invalid-input">
                          {errors.countryId.value}
                        </div>
                      )} */}
                      </div>
                    </div>

                    <div className="RowDiv">
                      <div
                        className="isoInputWrapper"
                        style={{ marginBottom: "15px" }}
                      >
                        <label className="label-style">Observations</label>
                        <Textarea
                          rows={4}
                          size="large"
                          type="text"
                          name="observations"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.observations}
                        />
                        {errors.observations && touched.observations && (
                          <div className="invalid-input">
                            {errors.observations}
                          </div>
                        )}
                      </div>
                      <div className="isoInputWrapper">
                        <label className="label-style">Description</label>
                        <Textarea
                          rows={4}
                          size="large"
                          type="text"
                          name="description"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.description}
                        />
                        {errors.description && touched.description && (
                          <div className="invalid-input">
                            {errors.description}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="isoInputWrapper">
                      <TourSettingForm client={client} setclient={setclient} />
                      {errors.paymentMethod && touched.paymentMethod && (
                        <div className="invalid-input">
                          {errors.paymentMethod}
                        </div>
                      )}
                    </div>
                    <div style={{ padding: "0 7px" }}>
                      <p
                        style={{
                          fontWeight: 400,
                          paddingBottom: 10,
                          fontSize: "25px",
                          color: "black",
                        }}
                      >
                        Departure Place Location
                      </p>
                    </div>
                    {/* <div style={{ padding: "0 7px" }}>
                      <label style={{ paddingBottom: 10, color: "#788195" }}>
                        Radius
                      </label>
                      <Slider
                        defaultValue={radius}
                        style={{ width: "50%" }}
                        handleStyle={{
                          marginTop: "-10px",
                          width: "22px",
                          height: "22px",
                        }}
                        max={1000}
                        onChange={onChangeRadius}
                      />
                    </div> */}
                    <div className="mapWrapper">
                      {
                        <Map
                          radius={radius}
                          getLocation={(loc) => {
                            setLocation(loc);
                            setFieldValue("location", loc);
                          }}
                          setLocation={
                            tourData && tourData.DeparturePlace
                              ? tourData.DeparturePlace.length !== 0
                                ? {
                                    lat:
                                      (tourData &&
                                        tourData.DeparturePlace &&
                                        tourData.DeparturePlace.length !== 0 &&
                                        tourData.DeparturePlace[0] &&
                                        tourData.DeparturePlace[0].latitude) ||
                                      location.lat,
                                    lng:
                                      (tourData &&
                                        tourData.DeparturePlace &&
                                        tourData.DeparturePlace.length !== 0 &&
                                        tourData.DeparturePlace[0] &&
                                        tourData.DeparturePlace[0].longitude) ||
                                      location.lng,
                                    name:
                                      tourData &&
                                      tourData.DeparturePlace &&
                                      tourData.DeparturePlace.length !== 0 &&
                                      tourData.DeparturePlace[0] &&
                                      tourData.DeparturePlace[0].name,
                                  }
                                : null
                              : null
                          }
                          getQuery={() => {}}
                        />
                      }
                      {errors.location && touched.location && (
                        <div className="invalid-input">{errors.location}</div>
                      )}
                    </div>

                    <Button
                      type="primary"
                      onClick={handleSubmit}
                      disabled={disable}
                    >
                      <IntlMessages
                        id={locParams.tour ? "Update" : "page.signUpButton"}
                      />
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </SignUpStyleWrapper>
  );
}
