import React, {useState} from 'react';
import isEmpty from 'lodash/isEmpty';
import {useSelector, useDispatch} from 'react-redux';
import {Spin} from 'antd';
// import {useNavigate} from 'react-router-dom';
import _ from 'lodash';

import NoBoardFounds from '../BoardNotFound/BoardNotFound';
import BoardListCard from './BoardListCard/BoardListCard';
import AppLayout from '../../AppLayout/AppLayout';
import {Table} from './BoardList.style';
import {generateTourReceipt} from '@iso/redux/receptionist/actions';

function BoardLists({history, match}) {
  const dispatch = useDispatch();

  const [search, setSearch] = useState(null);

  const handleReceipt = (tour) => {
    dispatch(generateTourReceipt(tour.id));
  };

  const tourList = useSelector((state) => {
    return state.Receptionist.bookedTours;
  });

  let newTours = _.filter(tourList, function (o) {
    return o.tour.type === 'ticket_guide';
  });

  if (search) {
    let searched = newTours.filter((i) => {
      return (
        i.clientName.toLowerCase().includes(search.toLowerCase()) ||
        i.clientEmail.toLowerCase().includes(search.toLowerCase())
      );
    });
    newTours = searched;
  }

  let Loading = useSelector((state) => {
    return state.Receptionist.loading;
  });

  return (
    <>
      <AppLayout
        history={history}
        match={match}
        setSearchText={(value) => {
          setSearch(value);
        }}>
        {Loading ? (
          <div className="spinner">
            <Spin />
          </div>
        ) : (
          <>
            {!isEmpty(newTours) ? (
              <Table>
                {newTours.map((board) => {
                  return (
                    <BoardListCard
                      key={board.id}
                      item={board}
                      onGenerateReceipt={() => handleReceipt(board)}
                    />
                  );
                })}
              </Table>
            ) : (
              <NoBoardFounds history={history} match={match} />
            )}

            {/* <div style={{width: 600}}>
          <Document file="/example.pdf" onLoadSuccess={this.onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} width={600} />
          </Document>
        </div> */}
          </>
        )}
      </AppLayout>
      {/* <Modal title="Delete Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>Are you sure you want to delete this Tour?</p>
      </Modal> */}
    </>
  );
}

export default BoardLists;
