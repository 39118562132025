import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useSelector, useDispatch } from "react-redux";
import { Button, Popconfirm, Typography, Spin, Popover, Tag } from "antd";
import moment from "moment";
import { MoreActionWrapper } from "./BoardListCard/BoardListCard.style.js";
import { IconSvg } from "@iso/components/ScrumBoard/IconSvg/IconSvg";
import MoreIcon from "@iso/assets/images/icon/16.svg";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import NoBoardFounds from "../BoardNotFound/BoardNotFound";
import BoardListCard from "./BoardListCard/BoardListCard";
import AppLayout from "../../AppLayout/AppLayout";
import { Table } from "./BoardList.style";
import {
  generateTourReceipt,
  cancelRequestBooking,
} from "@iso/redux/receptionist/actions";
import { LANGUAGES, TOUR_TYPES } from "../../../../../constants";
import Lists from "../../../../../containers/Lists/Lists";
import { StatusTag } from "../../../../../containers/Lists/Lists.styles.js";
import { getPaymentIntent } from "../../../../../redux/receptionist/actions.js";
import BookingCancellation from "../../../../../components/Modal/BookingCancellation.js";

function BoardLists({ history, match }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [search, setSearch] = useState(null);
  const [filteredDate, setFilteredDate] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tourSelectedData, setTourSelectedData] = useState(null);

  const handleReceipt = (tour) => {
    dispatch(generateTourReceipt(tour.id));
  };

  const onCancel = (id) => {
    dispatch(cancelRequestBooking(id));
  };

  const onDuplicate = (tour) => {
    navigate(`/dashboard/ticket-guide-tours-booking/transport/`, {
      state: { tour },
    });
  };

  const onEdit = (tour) => {
    navigate(`/dashboard/ticket-guide-tours-booking/transport/`, {
      state: { tour, flag: 1 },
    });
  };

  const tourList = useSelector((state) => {
    return state.Receptionist.bookedTours;
  });

  let newTours = _.filter(tourList, function (o) {
    return o.tour.type === TOUR_TYPES.ticket_guide;
  });

  if (search) {
    let searched = newTours.filter((i) => {
      const clientNameMatch = i.clientName
        .toLowerCase()
        .includes(search.toLowerCase());
      const destinationMatch = i.tour.destination
        .toLowerCase()
        .includes(search.toLowerCase());

      return clientNameMatch || destinationMatch;
    });
    newTours = searched;
  }

  let Loading = useSelector((state) => {
    return state.Receptionist.loading;
  });

  const showModal = (data) => {
    setTourSelectedData(data);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    // dispatch(deleteVehicle(selectedModalBoard.id)).then((res) => {
    //   setIsModalVisible(false);
    // });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "Res#",
      dataIndex: "id",
      rowKey: "id",
      width: 10,
      fixed: "left",
      render: (text) => <Typography.Text ellipsis>{text}</Typography.Text>,
      sorter: (a, b) => moment(a.id) - moment(b.id),
    },
    {
      title: "Tour Date",
      dataIndex: "date",
      rowKey: "date",
      width: 10,
      render: (text) => (
        <Typography.Text ellipsis>
          {moment(text).format("DD/MM/YYYY")}
        </Typography.Text>
      ),
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
    },
    {
      title: "Tour Name",
      dataIndex: "tour",
      rowKey: "tour",
      width: 10,
      render: (text) => (
        <Typography.Text ellipsis>{text.destination}</Typography.Text>
      ),
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      rowKey: "clientName",
      width: 5,
      render: (text) => <Typography.Text ellipsis>{text}</Typography.Text>,
    },
    // {
    //   title: "Email",
    //   dataIndex: "clientEmail",
    //   rowKey: "clientEmail",
    //   width: 5,
    //   render: (text) => <Typography.Text ellipsis>{text}</Typography.Text>,
    // },
    {
      title: "Persons",
      dataIndex: "noOfPersons",
      rowKey: "noOfPersons",
      width: 10,
      render: (text) => <Typography.Text ellipsis>{text}</Typography.Text>,
    },
    {
      title: "Payment",
      dataIndex: "payment",
      rowKey: "payment",
      width: 5,
      render: (text, data) => (
        <span>
          {(data.platform === "web" && data && data.paymentMethod !== 4 && (
            <Tag color={"green"} key={text}>
              Paid
            </Tag>
          )) ||
            (text && (
              <Tag color={"green"} key={text}>
                Paid
              </Tag>
            )) || (
              <Tag color={"magenta"} key={text}>
                Unpaid
              </Tag>
            ) ||
            data.platform ===
              "app"(
                text && (
                  <Tag color={"green"} key={text}>
                    Paid
                  </Tag>
                )
              ) || (
              <Tag color={"magenta"} key={text}>
                Unpaid
              </Tag>
            )}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      rowKey: "status",
      width: 8,
      render: (text) => (
        <Typography.Text ellipsis>
          {(text === 0 && "-") || (
            <StatusTag
              style={{
                backgroundColor: "#FEAC01",
                padding: "10px 6px",
                borderRadius: "3px",
              }}
            >
              Cancelled
            </StatusTag>
          )}
        </Typography.Text>
      ),
    },
    {
      title: "Language",
      dataIndex: "lang",
      rowKey: "lang",
      width: 10,

      render: (text) => (
        <Typography.Text ellipsis>
          {_.result(
            _.find(LANGUAGES, function (o) {
              if (o.value === text) {
                return text;
              }
            }),
            "label"
          )}
        </Typography.Text>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      rowKey: "price",
      width: 10,
      render: (text) => <Typography.Text ellipsis>{text}</Typography.Text>,
    },
    {
      title: "Platform",
      dataIndex: "platform",
      rowKey: "platform",
      width: "10%",
      render: (text) => (
        <p>
          {(text === "web" && (
            <Tag color={"purple"} key={text}>
              WEB
            </Tag>
          )) || (
            <Tag color={"cyan"} key={text}>
              APP
            </Tag>
          )}
        </p>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      rowKey: "id",
      width: 5,
      fixed: "right",
      render: (text, data) => (
        <div className="isoInvoiceBtnView">
          <Popover
            placement="bottom"
            content={
              <MoreActionWrapper>
                {Loading ? (
                  <Spin />
                ) : (
                  <p onClick={() => handleReceipt(data)}>Generate Receipt</p>
                )}
                {<p onClick={() => onEdit(data)}>Edit</p>}

                {<p onClick={() => onDuplicate(data)}>Duplicate</p>}
                {data.paymentMethod === 4 && !data.payment && (
                  <p
                    onClick={() =>
                      dispatch(getPaymentIntent(data.id)).then((res) => {
                        navigate(
                          `/dashboard/payment-stripe/${res.paymentIntent}`,
                          {
                            state: {
                              bookId: data.id,
                              res,
                              path: "ticket-guide-tours-booking-list",
                            },
                          }
                        );
                      })
                    }
                  >
                    Pay Now
                  </p>
                )}
                {
                  <p
                    onClick={() => {
                      showModal(data);
                    }}
                  >
                    Cancel Request
                  </p>
                }
              </MoreActionWrapper>
            }
            trigger="click"
          >
            <IconSvg src={MoreIcon} border={"none"} padding={"0"} />
          </Popover>
        </div>
      ),
    },
  ];

  if (filteredDate && filteredDate.length > 1) {
    let filtered = newTours.filter((product) => {
      let productDate = moment(product["date"]).format("YYYY-MM-DD");
      return (
        productDate >= moment(filteredDate[0]).format("YYYY-MM-DD") &&
        productDate <= moment(filteredDate[1]).format("YYYY-MM-DD")
      );
    });
    newTours = filtered;
  }

  return (
    <>
      <AppLayout
        history={history}
        match={match}
        setSearchText={(value) => {
          setSearch(value);
        }}
        setFilteredData={(data) => {
          setFilteredDate(data);
        }}
      >
        {Loading ? (
          <div className="spinner">
            <Spin />
          </div>
        ) : (
          <>
            {!isEmpty(newTours) ? (
              <Lists
                title={"Ticket + Guide Tours"}
                Columns={columns}
                Rows={newTours}
                pagination={{ pageSize: "10" }}
              />
            ) : (
              <NoBoardFounds history={history} match={match} />
            )}

            {/* <div style={{width: 600}}>
          <Document file="/example.pdf" onLoadSuccess={this.onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} width={600} />
          </Document>
        </div> */}
          </>
        )}
      </AppLayout>
      {isModalVisible ? (
        <BookingCancellation
          isModalVisible={isModalVisible}
          handleCancel={handleCancel}
          title={"Cancellation Request"}
          buttonTitle="Save"
          cancellationData={tourSelectedData}
        />
      ) : null}
    </>
  );
}

export default BoardLists;
