import React, { Suspense, useEffect, useState } from "react";
import { Route, Navigate, BrowserRouter, Routes } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import ErrorBoundary from "./ErrorBoundary";
import Loader from "@iso/components/utility/loader";
import { getSession } from "./services";
import { signInSuccess, logout } from "./redux/auth/actions";
import SignIn from "@iso/pages/SignIn/SignIn";
import Privacy from "@iso/pages/privacy";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import ProtectedRoute from "./routes/ProtectedRoute";
import Dashboard from "./routes/dashboard/Dashboard";
import AdminRoutes from "./routes/AdminRoutes";
import SuperAdminRoutes from "./routes/SuperAdminRoutes";
import TCAdminRoutes from "./routes/TCAdmin";
import ReceptionistRoutes from "./routes/ReceptionistRoutes";

function RedirectComponent() {
  return <Navigate to="/signin" />;
}

export default function Routers() {
  const dispatch = useDispatch();

  const [logged, setlogged] = useState(null);
  const [role, setrole] = useState("");

  useEffect(() => {
    checkSession();
  }, []);

  const isLoggedIn = useSelector((state) => {
    return (
      state.Auth.user &&
      state.Auth.user.receptionist &&
      state.Auth.user.receptionist[0]
    );
  });

  const hotel = isLoggedIn ? (isLoggedIn.hotelId === 12 ? false : true) : true;

  const checkSession = async () => {
    getSession().then(async (data) => {
      if (!data) {
        return;
      }
      const s = await dispatch(signInSuccess(data));
      if (s) {
        setlogged(true);
        setrole(s.role);
      } else {
        setlogged(false);
      }
    });
  };

  const local =
    (localStorage.getItem("token") && localStorage.getItem("token")) || null;

  const isSign = (s) => {
    if (s) {
      setlogged(true);
      setrole(s.role);
    } else {
      setlogged(false);
    }
  };

  console.log("local", local);

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <Routes>
            {
              <Route
                path="/"
                element={
                  local ? (
                    <Navigate to="/dashboard" />
                  ) : (
                    <Navigate to="/signin" />
                  )
                }
              ></Route>
            }

            <Route path="/signin" element={<SignIn isSign={isSign} />} />
            <Route path="/resetPassword/:code" element={<ResetPassword />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/privacy" element={<Privacy />} />
           { local ?  <Route
              path="/dashboard"
              element={
                  <ProtectedRoute logged={local}>
                    {logged && <Dashboard /> }
                  </ProtectedRoute>
              }
            >
              {role === "SuperAdmin"
                ? SuperAdminRoutes()
                : role === "Admin"
                ? AdminRoutes()
                : role === "TransportCompanyAdmin"
                ? TCAdminRoutes()
                : role === "Receptionist"
                ? ReceptionistRoutes(hotel)
                : null}
            </Route>: (
              <Route path="/dashboard/*" element={<RedirectComponent />} />
            )
}
          </Routes>
        </BrowserRouter>
      </Suspense>
    </ErrorBoundary>
  );
}
