import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Form,
  Input,
  Popconfirm,
  Table,
  Typography,
  Button,
  Space,
} from "antd";
import { useDispatch } from "react-redux";
import _ from "lodash";
import {
  fetchTourCloseDay,
  createTourCloseDay,
} from "../../../redux/tours/actions";
import { SNACKBAR_MSG_TYPE } from "../../../constants";
import { showSnackbar } from "../../../redux/snackbar/actions";
import moment from 'moment';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "date" ? <Input type="date" /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const TourCloseDay = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const params = useParams();
  const [data, setData] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);

  const [newRecord, setNewRecord] = useState([]);

  const isEditing = (index) => index === editingIndex;

  useEffect(() => {
    dispatch(fetchTourCloseDay(params.id)).then((res) => {
      setData(res);
    });
  }, []);

  const edit = (record, index) => {
    // console.log("record:", record);
    // console.log("index:", index);
    form.setFieldsValue({
      reason: "",
      closeDate: "",
      ...record,
    });

    setEditingIndex(index);
  };

  const cancel = () => {
    setEditingIndex(null);
    // console.log("editingIndex:", editingIndex);
  };

  function hasDuplicates(a) {
    return _.uniqBy(a, obj => obj.closeDate);
  }

  function hasDuplicateNewRecord(a) {
    return _.reverse(_.uniqBy(_.reverse(a), 'index'))
  }

  const done = async (index, record) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];

      newData.splice(index, 1, {
        ...record,
        ...row,
      });

      const spaceFreeData = _.filter(newData, function(o) { return o.closeDate; });

      console.log('newRecordIndex', spaceFreeData)

      const namesSet = new Set(spaceFreeData.map(item => item.closeDate));

      console.log('[...namesSet.length]', [...namesSet].length);
      console.log('spaceFreeData', spaceFreeData.length);


      // const isDuplicate = hasDuplicates(spaceFreeData)

      if([...namesSet].length === spaceFreeData.length) {
        setData(newData);
        newRecord.push({index, ...row, id: record.id});
        setNewRecord(newRecord);
      } else {
        const message = "Duplicate date!"
        const {messageType} = SNACKBAR_MSG_TYPE.error;
        dispatch(showSnackbar(message, messageType));
      }

      setEditingIndex(null);

    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };


  const handleAdd = () => {
    const newData = {
      reason: "",
      closeDate: "",
    };

    setData([...data, newData]);
  };

  const save = () => {
    const uniqueRecords = hasDuplicateNewRecord(newRecord)
    dispatch(createTourCloseDay(params.id, uniqueRecords)).then((res)=>{
      setNewRecord([])
    });


    // const uniqueRecords = hasDuplicateNewRecord(newRecord)
    // let namesSet = new Set(newRecord.map(item => item.closeDate));
    // const namesSet = new Set(newRecord.map(elem => (
    //   {
    //     closeDate: elem.closeDate,
    //     index: elem.index,
    //     reason: elem.reason,
    //     id: elem.id
    //   } 
    // )));
    // console.log('namesSet', [...namesSet])
    // const a = [...namesSet].map(item => item)

  };

  const columns = [
    {
      title: "Reason",
      dataIndex: "reason",
      width: "25%",
      editable: true,
    },
    {
      title: "Date",
      dataIndex: "closeDate",
      width: "15%",
      editable: true,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => moment(a.closeDate).unix() - moment(b.closeDate).unix(),
      render: (text) => (
        <p> {(text && moment(text).format("DD/MM/yyyy")) || "N/A"}</p>)
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record, index) => {
        const editable = isEditing(index);

        // console.log(`editable - ${index}:`, editable);

        return editable ? (
          <span>
            <Typography.Link
              onClick={() => done(index, record)}
              style={{
                marginRight: 8,
              }}
            >
              Done
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingIndex}
            onClick={() => edit(record, index)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record, rowIndex) => ({
        record,
        inputType: col.dataIndex === "closeDate" ? "date" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(rowIndex),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Space
        style={{
          marginTop: 16,
          marginBottom: 16,
          marginLeft: 16,
        }}
      >
        <Button onClick={handleAdd} type="primary">
          Add
        </Button>
        <Button onClick={save} disabled={newRecord.length > 0 ? false : true}>
          Save
        </Button>
      </Space>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
        // pagination={{
        //   onChange: cancel,
        // }}
      />
    </Form>
  );
};

export default TourCloseDay;
