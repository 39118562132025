import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import "./Payment.css";
import { useLocation } from "react-router";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLICATION_KEY);
export default function Payment() {
  const params = useLocation();
  const bookId = params && params.state && params.state.bookId;
  const clientSecret =
    params &&
    params.state &&
    params.state.res &&
    params.state.res.paymentIntent;
  const path = params && params.state && params.state.path;
  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="App">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            clientSecret={clientSecret}
            bookId={bookId}
            path={path}
          />
        </Elements>
      )}
    </div>
  );
}
