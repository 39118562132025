import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Lists from "../../../../containers/Lists/Lists";
import moment from "moment";
import {
  airportTransfersView,
  getAirportTransfers,
} from "../../../../redux/airport/actions";
import './tour.css';
import ReportList from "./ReportList";
import * as html2pdf from "html2pdf.js";
import AppLayout from "./AppLayout/AppLayout";
import isEmpty from "lodash/isEmpty";
import { Button, Popconfirm, Spin, Tag, Tooltip, Typography } from "antd";
import NoBoardFounds from "./Board/BoardNotFound/BoardNotFound";
import _ from "lodash";
import { deleteBooking } from "../../../../redux/airportTransfer/actions";
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import { LANGUAGES } from "../../../../constants";

const List = ({ history, match }) => {
  const [d, setd] = React.useState("none");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [airportTransfer, setAT] = useState([]);
  const [dateTour, setDate] = useState("");
  const [dateFilter, setDateFilter] = React.useState("");
  
  const htmlContent = `
  <!DOCTYPE html>
  <html>
    <head>
      <title>Tour Booking</title>
    </head>
    <body>
      <table>
        <thead>
          <tr>
            <th>Res#</th>
            <th>Transfer Date</th>
            <th>Destination</th>
            <th>Airport</th>
            <th>Hotel</th>
            <th>Name</th>
            <th>Service</th>
            <th>Price</th>
            <th>Status</th>
            <th>Platform</th>
          </tr>
        </thead>
        <tbody>
        ${
          airportTransfer &&
          airportTransfer.map((d, k) => {
            return (
              "<tr>" +
              "<td>" +
              d.id +
              "</td>" +
              "<td>" +
              d.pickUpDate +
              "</td>" +
              "<td>" +
              // eslint-disable-next-line no-mixed-operators
              (d && d.direction === 1 && d.airport.name || d && d.direction === 2 && d.hotel) +
              "</td>" +
              "<td>" +
              d.airport.name +
              "</td>" +
              "<td>" +
              d.hotel +
              "</td>" +
              "<td>" +
              d.clientName +
              "</td>" +
              "<td>" +
              `${d.min}-${d.max}-${d.serviceTitle}` +
              "</td>" +
              "<td>" +
              d.totalPrice +
              "</td>" +
              "<td>" +
              (d.status === 0 ? "Active" : "Cancelled") +
              "</td>" +
              "<td>" +
              d.platform +
              "</td>" +
              "</tr>"
            );
          })
        }
        </tbody>
      </table>
    </body>
  </html>
`;

  const convertToCsv = async () => {
    try {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, "text/html");
      console.log("doc", doc);
      const table = doc.querySelector("table");

      if (!table) {
        throw new Error("Table element not found in HTML content.");
      }

      const rows = Array.from(table.querySelectorAll("tr"));
      const headers = Array.from(rows[0].querySelectorAll("th, td")).map(
        (cell) => cell.innerText
      );
      const rowData = rows
        .slice(1)
        .map((row) =>
          Array.from(row.querySelectorAll("td")).map((cell) => cell.innerText)
        );

      const csvRows = [headers, ...rowData];
      const csvContent = csvRows
        .map((row) => row.map((cell) => `"${cell}"`).join(","))
        .join("\n");

      // Save the CSV content as a file
      const downloadLink = document.createElement("a");
      downloadLink.href = `data:text/csv;charset=utf-8,${encodeURIComponent(
        csvContent
      )}`;
      const currentDate = moment().format("YYMMDD");
      downloadLink.download = `booking${currentDate}.csv`;
      downloadLink.click();

      console.log("CSV file generated successfully!");
    } catch (error) {
      console.error("Error converting HTML to CSV:", error.message);
    }
  };

  const handleReport = () => {
    var p2 = new Promise(function (resolve, reject) {
      setd("inline-block");
      var element = document.getElementById("element-print");

      html2pdf().from(element).save();
      resolve(false);
    });

    p2.then(function (value) {
      setd("none");
      return value;
    }).then(function (value) {
      setd("none");
      window.close();
    });
  };

  useEffect(() => {
    dispatch(getAirportTransfers(dateTour, dateFilter)).then((res) => {
      setAT(res);
    });
  }, [dateTour, dateFilter]);

  const handleView = (tour) => {
    dispatch(airportTransfersView(tour)).then((res) => {
      navigate(`/dashboard/airportTransfer/view/${res.id}`, { state: { res } });
    });
  };

  const onDeleteBooking = (e, id) => {
    e.stopPropagation();
    dispatch(deleteBooking(id)).then((res) => {
      const tourArray = _.filter(airportTransfer, (o) => {
        return o.id !== id;
      });
      setAT(tourArray);
    });
  };

  let Loading = useSelector((state) => {
    return state.Airport.loading;
  });

  const getRowClassName = (record) => {
    return record.status === 1 ? "pink-row" : "";
  };

  const columns = [
    {
      title: "Res#",
      dataIndex: "id",
      rowKey: "id",
      width: 6,
      fixed: 'left',
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => moment(a.id) - moment(b.id),
    },
    {
      title: "Transfer Date",
      dataIndex: "pickUpDate",
      rowKey: "pickUpDate",
      width: 6,
      render: (text) => <span>{moment(text).format("DD/MM/yyyy")}</span>,
    },
    {
      title: "Destination",
      dataIndex: "direction",
      rowKey: "direction",
      width: 6,
      render: (text, data) => (
        <span title={`${(text === 1 && data.airport.name) || (text === 2 && data.hotel)}`}>
          {(text === 1 && data.airport.name) || (text === 2 && data.hotel)}
        </span>
      ),
    },
    {
      title: "Airport",
      dataIndex: "airportId",
      rowKey: "airportId",
      width: 7.69,
      render: (text, data) => (
        <span title={`${data.airport && data.airport.name}`}>
          {(data.airport && data.airport.name)}
        </span>
      ),
    },
    {
      title: "Hotel",
      dataIndex: "hotelId",
      rowKey: "hotelId",
      width: 7.69,
      render: (text, data) => (
        <span title={`${data.hotel}`}>
          {(data.hotel)}
        </span>
      ),
    },
    {
      title: "Name",
      dataIndex: "clientName",
      rowKey: "clientName",
      width: 6,
      render: (text) => <span title={`${text}`}>{text}</span>,
    },
    {
      title: "Service",
      dataIndex: "id",
      rowKey: "id",
      width: 6,
      render: (text, data) => (
        <span  title={`${data.min}-${data.max}-${data.serviceTitle}`}>{`${data.min}-${data.max}-${data.serviceTitle}`}</span>
      ),
    },
    {
      title: "Price",
      dataIndex: "totalPrice",
      rowKey: "totalPrice",
      width: 6,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Platform",
      dataIndex: "platform",
      rowKey: "platform",
      width: 6,
      render: (text) => <Typography.Text ellipsis>
      {(text === 'web' && (
        <Tag color={"purple"} key={text}>
          WEB
        </Tag>
      )) || (
        <Tag color={"#7cb305"} key={text}>
          APP
        </Tag>
      )}
    </Typography.Text>,
    },
    {
      title: "Status",
      dataIndex: "status",
      rowKey: "status",
      width: 6,
      render: (text) => (
        <Typography.Text ellipsis>
          {(text === 0 && (
            <Tag color={"geekblue"} key={text}>
              Active
            </Tag>
          )) || (
            <Tag color={"volcano"} key={text}>
              Cancelled
            </Tag>
          )}
        </Typography.Text>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      rowKey: "id",
      width: 4,
      fixed: 'right',
      render: (text, data) => (
        <div className="actionBtnView">
          <Tooltip placement="rightBottom" title="Delete">
            <Popconfirm
              title="Sure to delete?"
              onConfirm={(e) => onDeleteBooking(e, text)}
              onCancel={(e) => e.stopPropagation()}
              onClick={(e) => e.stopPropagation()}
            >
              <Button
                style={{ backgroundColor: "#ff0e0e", color: "#ffffff" }}
                icon={<DeleteOutlined />}
              ></Button>
              {/* <DeleteOutlined style={{ fontSize: "20px", color: "red" }} /> */}
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleDateRange = (data) => {
    setDateFilter(data);
  };

  return (
    <>
      {/* <div style={{ display: "flex", justifyContent:"end", padding: "25px 25px 0 25px"}}>
        <Button type="primary" onClick={() => handleReport()}>
          Report
        </Button>
      </div> */}
      <AppLayout
        dateFilter={(value) => {
          setDate(value);
        }}
        handleDateRange={handleDateRange}
        handleReport={handleReport}
        handleCSV={convertToCsv}
      >
        {Loading ? (
          <div className="spinner">
            <Spin />
          </div>
        ) : !isEmpty(airportTransfer) ? (
          <Lists
            title={"Airport Transfers"}
            Columns={columns}
            Rows={airportTransfer}
            // rowClassName='cursor'
            rowClassName={getRowClassName}

            onRow={(record, rowIndex) => {
              return {
                onClick: () => {handleView(record.id)}, // click row
              };
            }}
          />
        ) : (
          <NoBoardFounds history={history} match={match} />
        )}
      </AppLayout>
      <div id="element-print" style={{ display: d }}>
        <ReportList data={airportTransfer} />
      </div>
    </>
  );
};

export default List;
