import React from "react";
import { StickerWidgetWrapper } from "./StickerWidget.styles";

const StickerWidget = ({ fontColor, bgColor, width, number, text, fontSize }) => {
  const textColor = {
    color: fontColor,
    fontSize:fontSize
  };
  const widgetStyle = {
    backgroundColor: bgColor,
    width: width,
  };
  const iconStyle = {
    color: fontColor,
  };

  return (
    <StickerWidgetWrapper className="isoStickerWidget" style={widgetStyle}>
      <div className="isoContentWrapper">
        <h3 className="isoStatNumber" style={textColor}>
          {number}
        </h3>
        <span className="isoLabel" style={textColor}>
          {text}
        </span>
      </div>
    </StickerWidgetWrapper>
  );
}

export default StickerWidget
