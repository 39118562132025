import React from 'react';

import BoardLists from './Board/BoardList/BoardList';

export default function ToursList() {


  return (
    <>
      <BoardLists />
    </>
  );
}
