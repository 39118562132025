import React from 'react';
import {Layout, Button} from 'antd';
import {Scrollbars} from 'react-custom-scrollbars';
import {useNavigate} from 'react-router';

import SearchInput from '@iso/components/ScrumBoard/SearchInput/SearchInput';
import {Title, Header, HeaderSecondary} from './AppLayout.style';

const {Content} = Layout;

const AppLayout = ({children, setSearchText, history, match}) => {
  const navigate = useNavigate();
  return (
    <Layout style={{backgroundColor: '#fff'}}>
      <Header>
        <Title>Ticket + Guide Tours List</Title>

        <Button
          type="primary"
          onClick={() => {
            navigate(`/dashboard/ticket-guide-tours-booking/guide`, {state: {}});
          }}>
          Book Ticket + Guide Tours
        </Button>
      </Header>

      <HeaderSecondary>
        <SearchInput
          onChange={(value) => setSearchText(value)}
          onCross={(value) => {
            setSearchText(value);
          }}
        />
      </HeaderSecondary>

      <Content
        style={{
          padding: '0 24px',
        }}>
        <Scrollbars style={{width: '100%', height: '500px'}}>{children}</Scrollbars>
      </Content>
    </Layout>
  );
};

export default AppLayout;
