import _ from 'lodash';

import * as T from '../ActionTypes';

const initState = {busStop: null, loading: false};

export default function busStopReducer(state = initState, action) {
  switch (action.type) {
    case T.CREATE_BUS_STOP: {
      let newBusStopList = [...state.busStop];
      newBusStopList.push(action.payload);
      return {...state, busStop: newBusStopList, loading: false};
    }
    case T.UPDATE_BUS_STOP: {
      const busStopArray = _.filter(state.busStop, (o) => {
        return o.id !== action.id;
      });
      busStopArray.push(action.payload);
      return {...state, busStop: busStopArray, loading: false};
    }

    case T.GET_BUS_STOP_LIST: {
      return {...state, busStop: action.payload, loading: false};
    }

    case T.DELETE_BUS_STOP: {
      const busStopArray = _.filter(state.busStop, (o) => {
        return o.id !== action.id;
      });
      return {...state, busStop: busStopArray, loading: false};
    }

    case T.BUS_STOP_LOADING: {
      return {...state, loading: true};
    }

    default:
      return state;
  }
}
