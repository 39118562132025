import React, { useState, useEffect, useRef } from "react";
import { Button, Popconfirm, Tag, Tooltip, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Lists from "../../../../containers/Lists/Lists";
import {
  deleteBooking,
  generateTourAdminReceipt,
  getTourBooking,
  getToursList,
  tourBookingView,
} from "../../../../redux/tours/actions";
import { getHotelsList } from "@iso/redux/hotels/actions";
import { LANGUAGES } from "../../../../constants";
import * as html2pdf from "html2pdf.js";
import AppLayout from "./AppLayout/AppLayout";
import ReportList from "./ReportList";
import isEmpty from "lodash/isEmpty";
import { Spin } from "antd";
import "./tour.css";
import NoBoardFounds from "./Board/BoardNotFound/BoardNotFound";
import Papa from "papaparse";
import _ from "lodash";
import moment from "moment";
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import Csv from "./Csv";
import { getPeopleLifeTime } from "../../../../redux/dashboard/actions";

const List = ({ history, match }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [bData, setBt] = useState([]);
  const [tours, setTour] = useState([]);
  const [bookedTours, setBookedTours] = useState([]);
  const [reference, setReference] = useState([]);
  const [typeTour, setType] = useState("");
  const [dateTour, setDate] = useState("");
  const [tourId, setTourId] = useState("");
  const [people, setPeople] = React.useState(null);
  const [dateFilter, setDateFilter] = React.useState("");
  const [hotel, setHotel] = React.useState([]);
  const [hotelId, setHotelId] = React.useState("");
  const [paymentMethod, setPaymentMethod] = useState("");

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
      dispatch(
        getTourBooking(
          typeTour,
          dateTour,
          tourId,
          reference,
          dateFilter,
          hotelId,
          paymentMethod,
          pagination
        )
      ).then((res) => {
        setBt(res.booking.data);
        setBookedTours(res.booking.data);
        setPagination({
          ...pagination,
          total: (res.booking && res.booking.pagination && res.booking.pagination.total) || 0,
        });
      });
  }, [
    typeTour,
    dateTour,
    tourId,
    reference,
    dateFilter,
    hotelId,
    paymentMethod,
    pagination.current,
  ]);

  const handlePaginationChange = (page) => {
    setPagination({
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
    });
  };

  useEffect(() => {
    dispatch(getToursList()).then((res) => {
      setTour(res);
    });
    dispatch(getHotelsList()).then((res) => {
      setHotel(res);
    });
    dispatch(getPeopleLifeTime()).then((res) => {
      setPeople(res);
    });

  }, []);

  const [d, setd] = React.useState("none");

  const handleReceipt = (tour) => {
    dispatch(generateTourAdminReceipt(tour));
  };

  const htmlContent = `
  <!DOCTYPE html>
  <html>
    <head>
      <title>Tour Booking</title>
    </head>
    <body>
      <table>
        <thead>
          <tr>
            <th>Res#</th>
            <th>Tour Date</th>
            <th>Resv Date</th>
            <th>Tour</th>
            <th>Name</th>
            <th>Email</th>
            <th>Persons</th>
            <th>Adults</th>
            <th>Children</th>
            <th>Hotel</th>
            <th>Payment</th>
            <th>Language</th>
            <th>Price</th>
            <th>Status</th>
            <th>Platform</th>
          </tr>
        </thead>
        <tbody>
        ${
          bData &&
          bData.map((d, k) => {
            return (
              "<tr>" +
              "<td>" +
              d.id +
              "</td>" +
              "<td>" +
              d.date +
              "</td>" +
              "<td>" +
              moment(d.createdAt).format("DD-MMM-YYYY") +
              "</td>" +
              "<td>" +
              (d.tour && d.tour.destination) +
              "</td>" +
              "<td>" +
              d.clientName +
              "</td>" +
              "<td>" +
              d.clientEmail +
              "</td>" +
              "<td>" +
              d.noOfPersons +
              "</td>" +
              "<td>" +
              d.numOfAdults +
              "</td>" +
              "<td>" +
              d.numOfChildren +
              "</td>" +
              "<td>" +
              (d.hotel && d.hotel.name) +
              "</td>" +
              "<td>" +
              ((d.platform === "web" && "paid") ||
                (d.payment && "paid") ||
                "unpaid") +
              "</td>" +
              "<td>" +
              _.result(
                _.find(LANGUAGES, function (o) {
                  if (o.value === d.lang) {
                    return d.lang;
                  }
                }),
                "label"
              ) +
              "</td>" +
              "<td>" +
              d.price +
              "</td>" +
              "<td>" +
              (d.status === 0 ? "Active" : "Cancelled") +
              "</td>" +
              "<td>" +
              d.platform +
              "</td>" +
              "</tr>"
            );
          })
        }
        </tbody>
      </table>
    </body>
  </html>
`;

  const convertToCsv = async () => {
    try {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, "text/html");
      console.log("doc", doc);
      const table = doc.querySelector("table");

      if (!table) {
        throw new Error("Table element not found in HTML content.");
      }

      const rows = Array.from(table.querySelectorAll("tr"));
      const headers = Array.from(rows[0].querySelectorAll("th, td")).map(
        (cell) => cell.innerText
      );
      const rowData = rows
        .slice(1)
        .map((row) =>
          Array.from(row.querySelectorAll("td")).map((cell) => cell.innerText)
        );

      const csvRows = [headers, ...rowData];
      const csvContent = csvRows
        .map((row) => row.map((cell) => `"${cell}"`).join(","))
        .join("\n");

      // Save the CSV content as a file
      const downloadLink = document.createElement("a");
      downloadLink.href = `data:text/csv;charset=utf-8,${encodeURIComponent(
        csvContent
      )}`;
      const currentDate = moment().format("YYMMDD");
      downloadLink.download = `booking${currentDate}.csv`;
      downloadLink.click();

      console.log("CSV file generated successfully!");
    } catch (error) {
      console.error("Error converting HTML to CSV:", error.message);
    }
  };

  const handleReportList = async () => {
    var p2 = new Promise(function (resolve, reject) {
      setd("inline-block");
      var element = document.getElementById("element-print-list");

      html2pdf().from(element).save();
      resolve(false);
    });

    p2.then(function (value) {
      setd("none");
      return value;
    }).then(function (value) {
      setd("none");
      window.close();
    });
  };

  let Loading = useSelector((state) => {
    return state.Tours.bookingLoading;
  });

  const handleView = (tour) => {
    dispatch(tourBookingView(tour)).then((res) => {
      navigate(`/dashboard/tourBooking-view/${res.id}`, { state: { res } });
    });
  };

  const onDeleteBooking = (e, id) => {
    e.stopPropagation();
    dispatch(deleteBooking(id)).then((res) => {
      const tourArray = _.filter(bData, (o) => {
        return o.id !== id;
      });
      setBt(tourArray);
      setBookedTours(tourArray);
    });
  };

  const columns = [
    {
      title: "Res#",
      dataIndex: "id",
      rowKey: "id",
      width: 3,
      fixed: "left",
      render: (text) => <Typography.Text ellipsis>{text}</Typography.Text>,
      sorter: (a, b) => moment(a.id) - moment(b.id),
    },
    {
      title: "Tour Date",
      dataIndex: "date",
      rowKey: "date",
      width: 5,
      render: (text) => (
        <Typography.Text ellipsis>
          {moment(text).format("DD/MM/yyyy")}
        </Typography.Text>
      ),
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
    },
    {
      title: "Resv Date",
      dataIndex: "createdAt",
      rowKey: "createdAt",
      width: 5,
      render: (text) => (
        <Typography.Text ellipsis>
          {moment(text).format("DD/MM/yyyy")}
        </Typography.Text>
      ),
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
    },
    {
      title: "Tour",
      dataIndex: "tour",
      rowKey: "tour",
      width: 6,
      render: (text) => (
        <Typography.Text ellipsis title={`${text.destination}`}>
          {text.destination}
        </Typography.Text>
      ),
    },
    {
      title: "Name",
      dataIndex: "clientName",
      rowKey: "clientName",
      width: 6,
      render: (text) => (
        <Typography.Text ellipsis title={`${text}`}>
          {text}
        </Typography.Text>
      ),
    },
    {
      title: "Email",
      dataIndex: "clientEmail",
      rowKey: "clientEmail",
      width: 6.69,
      render: (text) => (
        <Typography.Text ellipsis title={`${text}`}>
          {text}
        </Typography.Text>
      ),
    },
    {
      title: "Persons",
      dataIndex: "noOfPersons",
      rowKey: "noOfPersons",
      width: 6,
      // ellipsis: true,
      render: (text, record) => {
        return <Typography.Text ellipsis>{text}</Typography.Text>;
      },
    },
    {
      title: "Adults",
      dataIndex: "numOfAdults",
      rowKey: "numOfAdults",
      width: 5,
      // ellipsis: true,
      render: (text) => {
        return <p>{(text && text) || 0}</p>;
      },
    },
    {
      title: "Children",
      dataIndex: "numOfChildren",
      rowKey: "numOfChildren",
      width: 5,
      render: (text) => (
        <Typography.Text ellipsis>{(text && text) || 0}</Typography.Text>
      ),
    },
    // {
    //   title: "Receptionist",
    //   dataIndex: "agent",
    //   rowKey: "agent",
    //   width: "10%",
    //   render: (text) =>
    //     (text && (
    //       <span>
    //         {text.firstName} {text.lastName}
    //       </span>
    //     )) || <span>-</span>,
    // },
    {
      title: "Hotel",
      dataIndex: "hotelId",
      rowKey: "hotelId",
      width: 7.69,
      render: (text, data) => (
        <Typography.Text ellipsis>
          {(data && data.hotel && data.hotel.displayName) || "-"}
        </Typography.Text>
      ),
    },
    {
      title: "Payment",
      dataIndex: "payment",
      rowKey: "payment",
      width: 5,
      render: (text, data) => (
        <span>
          {(data.platform === "web" && data && data.paymentMethod !== 4 && (
            <Tag color={"green"} key={text}>
              Paid
            </Tag>
          )) ||
            (text && (
              <Tag color={"green"} key={text}>
                Paid
              </Tag>
            )) || (
              <Tag color={"magenta"} key={text}>
                Unpaid
              </Tag>
            ) ||
            data.platform ===
              "app"(
                text && (
                  <Tag color={"green"} key={text}>
                    Paid
                  </Tag>
                )
              ) || (
              <Tag color={"magenta"} key={text}>
                Unpaid
              </Tag>
            )}
        </span>
      ),
    },
    {
      title: "Language",
      dataIndex: "lang",
      rowKey: "lang",
      width: 4,

      render: (text) => (
        <Typography.Text ellipsis>
          {_.result(
            _.find(LANGUAGES, function (o) {
              if (o.value === text) {
                return text;
              }
            }),
            "label"
          )}
        </Typography.Text>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      rowKey: "price",
      width: 4,
      render: (text) => <Typography.Text ellipsis>{text}</Typography.Text>,
    },
    {
      title: "Status",
      dataIndex: "status",
      rowKey: "status",
      width: 4,
      render: (text) => (
        <Typography.Text ellipsis>
          {(text === 0 && (
            <Tag color={"geekblue"} key={text}>
              Active
            </Tag>
          )) || (
            <Tag color={"volcano"} key={text}>
              Cancelled
            </Tag>
          )}
        </Typography.Text>
      ),
    },
    {
      title: "DT Id",
      dataIndex: "diversion",
      rowKey: "diversion",
      width: 5,
      render: (text, data) => (
        <>
          {(data && data.diversion && data.diversion.localizador && (
            <p style={{ textAlign: "center", color: "#000000D9" }}>
              {(data && data.diversion && data.diversion.localizador) || "-"}
            </p>
          )) || <p style={{ textAlign: "center", color: "red" }}>n/a</p>}
        </>
      ),
      sorter: (a, b) => moment(a.id) - moment(b.id),
    },
    {
      title: "Platform",
      dataIndex: "platform",
      rowKey: "platform",
      width: 3,
      render: (text) => (
        <Typography.Text ellipsis>
          {(text === "web" && (
            <Tag color={"purple"} key={text}>
              WEB
            </Tag>
          )) || (
            <Tag color={"cyan"} key={text}>
              APP
            </Tag>
          )}
        </Typography.Text>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      rowKey: "id",
      width: 3,
      fixed: "right",
      render: (text, data) => (
        <div className="actionBtnView">
          <Tooltip placement="leftBottom" title="Delete">
            <Popconfirm
              title="Sure to delete?"
              onConfirm={(event) => onDeleteBooking(event, text)}
              onCancel={(e) => e.stopPropagation()}
              onClick={(e) => e.stopPropagation()}
            >
              <Button
                style={{ backgroundColor: "#ff0e0e", color: "#ffffff" }}
                icon={<DeleteOutlined />}
              ></Button>
              {/* <DeleteOutlined style={{ fontSize: "20px", color: "red" }} /> */}
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleDateRange = (data) => {
    setDateFilter(data);
    handlePaginationChange({current: 1, pageSize: 10})
  };

  return (
    <>
      <AppLayout
        typeFilter={(value) => {
          setType(value);
          handlePaginationChange({current: 1, pageSize: 10})
        }}
        handlePaymentMethod={(value) => {
          setPaymentMethod(value);
          handlePaginationChange({current: 1, pageSize: 10})
        }}
        dateFilter={(value) => {
          setDate(value);
          handlePaginationChange({current: 1, pageSize: 10})
        }}
        tours={(tours && tours) || null}
        bookedTours={(bookedTours && bookedTours) || null}
        typeTours={(value) => {
          setTourId(value);
          handlePaginationChange({current: 1, pageSize: 10})
        }}
        referenceNo={(value) => {
          setReference(value);
          handlePaginationChange({current: 1, pageSize: 10})
        }}
        hotels={(value) => {
          setHotelId(value);
          handlePaginationChange({current: 1, pageSize: 10})

        }}
        hotelList={hotel}
        handleReport={handleReportList}
        handleCSV={convertToCsv}
        handleDateRange={handleDateRange}
        people={people}
      >
        {Loading ? (
          <div className="spinner">
            <Spin />
          </div>
        ) : !isEmpty(bData) ? (
          <Lists
            title={"Tour Bookings"}
            Columns={columns}
            Rows={bData}
            scroll={{
              x: "calc(1300px + 50%)",
              y: "auto",
            }}
            // pagination={true}
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              total: pagination.total,
            }}
            handleTableChange={handlePaginationChange}
            rowClassName="cursor"
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  handleView(record.id);
                }, // click row
              };
            }}
          />
        ) : (
          <NoBoardFounds history={history} match={match} />
        )}
      </AppLayout>

      <div id="element-print-list" style={{ display: d }}>
        <ReportList data={bData} />
      </div>
    </>
  );
};

export default List;
