import React from "react";

import { GoogleMap, Marker } from "@react-google-maps/api";

let coords = [];

class Map extends React.Component {
  constructor(props) {
    super(props);

    this.autocomplete = null;

    this.onLoad = this.onLoad.bind(this);
    this.state = {
      center:
        this.props.setLocation && !Array.isArray(this.props.setLocation)
          ? { ...this.props.setLocation }
          : this.props.setLocation,
      coordsResult: [],
      search: "",
      map: {},
    };
  }
  onLoad(autocomplete) {
    this.autocomplete = autocomplete;
  }

  onMapLoad = (map) => {
    let request = {
      query: this.state.search,
      fields: ["name", "geometry"],
    };

    if (this.state.search === "" && !Array.isArray(this.props.setLocation) && this.props.setLocation ) {
      this.props.getLocation({
        lat: (this.props.setLocation && this.props.setLocation.lat) || null,
        lng: (this.props.setLocation && this.props.setLocation.lng) || null,
        name: "location",
        address: "address",
      });
      this.setState({
        center: {
          lat: (this.props.setLocation && this.props.setLocation.lat) || null,
        lng: (this.props.setLocation && this.props.setLocation.lng) || null,
        },
        coordsResult: [],
      });
    } else if (
      this.state.search === "" &&
      navigator &&
      navigator.geolocation &&
      !Array.isArray(this.props.setLocation)
    ) {
      navigator.geolocation.getCurrentPosition((pos) => {
        const coord = pos.coords;
        this.props.getLocation({
          lat: coord.latitude,
          lng: coord.longitude,
          name: "location",
          address: "address",
        });
        this.setState({
          center: {
            lat: parseFloat(coord.latitude),
            lng: parseFloat(coord.longitude),
          },
          coordsResult: [],
        });
      });
    } else {
      coords = [];
      for (var i = 0; i < this.props.setLocation.length; i++) {
        coords.push(this.props.setLocation[i]);
        this.props.getLocation({
          lat: this.props.setLocation[i].lat,
          lng: this.props.setLocation[i].lng,
          name: this.props.setLocation[i].name,
          address: this.state.search,
        });

        this.setState({
          center: {
            lat: this.props.setLocation[i].lat,
            lng: this.props.setLocation[i].lng,
          },
        });
      }
      this.setState({
        coordsResult: coords,
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.state.search !== "") {
      this.onMapLoad(this.state.map);
      this.props.getQuery(this.state.search);
    }
  }

  render() {
    return (
      <div>
        <GoogleMap
          center={this.state.center}
          zoom={13}
          onLoad={(map) => {
            this.setState({ map });
            this.onMapLoad(map, this.props.setLocation);
          }}
          mapContainerStyle={{ height: "400px", width: "100%" }}
        >
          {this.state.coordsResult.length !== 0 ? (
            this.state.coordsResult.map(function (results, i) {
              return (
                <Marker
                  key={i}
                  position={{
                    lat: results.lat,
                    lng: results.lng,
                  }}
                  title={results.name}
                  draggable={false}
                />
              );
            })
          ) : (
            <>
              {this.state.center && this.state.center.lat ? (
                <Marker
                  position={{ ...this.state.center }}
                  title={"Your Location"}
                  draggable={false}
                />
              ) : null}
            </>
          )}
        </GoogleMap>
      </div>
    );
  }
}

export default Map;
