import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Popconfirm,
  Table,
  Typography,
  Modal,
  Button,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "@iso/components/uielements/CustomDropdown";
import TimeKeeper from "react-timekeeper";
import moment from "moment";
import _ from "lodash";
import {
  fetchTourSchedule,
  updateTourSchedule,
  createTourSchedule,
  deleteSchedule,
  getToursList,
  duplicateTourSchedule,
} from "../../../redux/tours/actions";
import { getHotelsList } from "../../../redux/hotels/actions";
import { TOUR_TYPES } from "../../../constants";

const TourSchedule = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [form] = Form.useForm();
  const tourData = useLocation();
  const tour = tourData && tourData.state ? tourData.state : { data: null };
  const activePickupTime = tour && tour.data && tour.data.pickUpDate;

  const dayAllowed = moment(activePickupTime).format("dddd").toLowerCase();

  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [editingRowItem, setEditingRowItem] = useState("");
  const [visible, setVisible] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);

  const [hotelModel, sethotelModel] = useState(false);
  const [hotelList, setHotelList] = useState(null);

  const [day, setday] = useState(null);

  useEffect(() => {
    dispatch(fetchTourSchedule(params.id)).then((res) => {
      setOriginalData(res);
      let arr = [];
      res.forEach((item) => {
        arr.push({ ...item, name: item.hotel.name });
      });
      setData(arr);
    });
  }, [day]);

  useEffect(() => {
    dispatch(getToursList(TOUR_TYPES.monthly_excursion));
  }, []);

  const tourList = useSelector((state) => {
    return state.Tours.tours;
  });

  const currentDate = moment().format("YYYY-MM-DD");
  const currentTourDate =
    (tour.data && tour.data.createdAt && moment(tour.data.createdAt)) || null;

  let tours = _.filter(tourList, function (o) {
    return (
      o.type === TOUR_TYPES.monthly_excursion &&
      o.pickUpDate >= currentDate &&
      moment(o.createdAt) < currentTourDate
    );
  });

  let arr = [];

  tours.forEach((element) => {
    arr.push({
      value: element.id,
      label: element.destination,
      schedule: element.schedule,
    });
  });

  const addDay = (hotel) => {
    // setData([
    //   ...data,
    //   {
    //     monday: null,
    //     tuesday: null,
    //     wednesday: null,
    //     thursday: null,
    //     friday: null,
    //     saturday: null,
    //     sunday: null,
    //     ...hotel
    //   },
    // ]);
    // console.log('data', data);
    // return;
    const isHotel = _.find(data, function (o) {
      return o.hotel.name === hotel.name;
    });
    if (isHotel === undefined) {
      dispatch(createTourSchedule(hotel.id, params.id)).then((res) => {
        // navigate("/dashboard/transport-tours-list");
        setday(res);
      });
      sethotelModel(false);
    }
  };

  const openHotelModel = () => {
    dispatch(getHotelsList()).then((res) => {
      setHotelList(res);
    });
    sethotelModel(true);
  };

  const isEditing = (record) => record.id === editingKey;

  const filteredHotel =
    (data &&
      data.length > 0 &&
      hotelList &&
      hotelList.length > 0 &&
      hotelList.filter((d) => data.every((item) => item.hotelId !== d.id))) ||
    hotelList;

  const filterHotelLength = (filteredHotel && filteredHotel.length) || 0;

  const getDayValue = (index, objKey) => {
    let newObj = { ...data[index] };
    if (newObj[`${objKey}`]) {
      return moment(newObj[`${objKey}`], "HH:mm:ss").format("HH:mm");
    } else {
      return null;
    }
  };

  const save = () => {
    dispatch(
      updateTourSchedule(data[editingIndex].id, data[editingIndex])
    ).then((res) => {
      setEditingKey("");
    });
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
          >
            <div
              onClick={() => {
                setVisible(true);
                setEditingRowItem(dataIndex);
              }}
            >
              <Input value={getDayValue(editingIndex, `${dataIndex}`)} />
            </div>
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const edit = (record) => {
    form.setFieldsValue({
      name: "",
      monday: "",
      tuesday: "",
      wednesday: "",
      thursday: "",
      friday: "",
      saturday: "",
      sunday: "",
      ...record,
    });
    const index = originalData.findIndex((object) => {
      return object.id === record.id;
    });
    setEditingIndex(index);
    setEditingKey(record.id);
  };

  const cancel = () => {
    let arr = [];
    originalData.forEach((item) => {
      arr.push({ ...item, name: item.hotel.name });
    });
    setData(arr);
    setEditingKey("");
  };

  const ondeleteSchedule = (id) => {
    dispatch(deleteSchedule(id)).then((res) => {
      setday(res);
    });
  };

  const columns = [
    {
      title: "Hotel Name",
      dataIndex: "name",
      indexType: "text",
      // editable: true,
    },
    {
      title: "Monday",
      dataIndex: "monday",
      indexType: "time",
      editable: true,
    },
    {
      title: "Tuesday",
      dataIndex: "tuesday",
      indexType: "time",
      editable: true,
    },
    {
      title: "Wednesday",
      dataIndex: "wednesday",
      indexType: "time",
      editable: true,
    },
    {
      title: "Thursday",
      dataIndex: "thursday",
      indexType: "time",
      editable: true,
    },
    {
      title: "Friday",
      dataIndex: "friday",
      indexType: "time",
      editable: true,
    },
    {
      title: "Saturday",
      dataIndex: "saturday",
      indexType: "time",
      editable: true,
    },
    {
      title: "Sunday",
      dataIndex: "sunday",
      indexType: "time",
      editable: true,
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={save}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <>
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
              style={{ margin: "0 8px" }}
            >
              Edit
            </Typography.Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => ondeleteSchedule(record.id)}
            >
              <a style={{ margin: "0 8px", color: "red" }}>Delete</a>
            </Popconfirm>
          </>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  const tourType = tour && tour.data && tour.data.type;

  return (
    <div>
      <Form form={form} component={false}>
        <div
          style={{
            float: "right",
            padding: "10px 10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {(TOUR_TYPES.monthly_excursion === tourType && (
            <Dropdown
              options={arr}
              onChange={(data) => {
                // Show a prompt to confirm the selection
                const confirmed = window.confirm(
                  `Are you sure you want to duplicate schedules for ${data.label}?`
                );

                if (confirmed) {
                  dispatch(
                    duplicateTourSchedule({ data, tourId: params.id })
                  ).then((res) => {
                    setday(res);
                  });
                }
              }}
              placeholder="Select Tours to Duplicate Schedules"
            />
          )) ||
            null}
          <Button
            onClick={openHotelModel}
            type="primary"
            style={{
              marginLeft: 16,
            }}
          >
            Add Hotel
          </Button>
        </div>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
        />
      </Form>
      <Modal
        title="Select your Time"
        centered
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width={300}
        bodyStyle={{ height: 500 }}
      >
        <TimeKeeper
          time={getDayValue(editingIndex, `${editingRowItem}`)}
          onDoneClick={(value) => {
            let newData = [...data];
            let newObj = { ...data[editingIndex] };
            newObj[`${editingRowItem}`] = value.formatted24;
            newData[editingIndex] = newObj;
            setData(newData);
            setVisible(false);
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#F4F4F4",
            border: "none",
            color: "#686868",
            cursor: "pointer",
            margin: "10px 0 5px 5px",
            padding: "10px",
            fontSize: "14px",
            fontWeight: "500",
          }}
          onClick={() => {
            let newData = [...data];
            let newObj = { ...data[editingIndex] };
            newObj[`${editingRowItem}`] = null;
            newData[editingIndex] = newObj;
            setData(newData);
            setVisible(false);
          }}
        >
          Clear
        </div>
      </Modal>
      <Modal
        title="Select Hotel"
        centered
        visible={hotelModel}
        onCancel={() => sethotelModel(false)}
        footer={null}
        width={300}
        bodyStyle={{ height: 500, overflowY: "auto" }}
      >
        <div>
          {(filterHotelLength > 0 &&
            filteredHotel.map((data, key) => {
              return (
                <div
                  style={{
                    padding: "5px 10px",
                    fontSize: "15px",
                    cursor: "pointer",
                  }}
                  key={key}
                  onClick={() => addDay(data)}
                >
                  <span>
                    {key + 1}
                    {". "}
                    {data.displayName}
                  </span>
                </div>
              );
            })) || (
            <span
              style={{
                padding: "5px 10px",
                fontSize: "13px",
                color: "green",
                fontStyle: "italic",
              }}
            >
              Schedule for hotels already exists!
            </span>
          )}
        </div>
      </Modal>
      {/* <Button >Add</Button> */}
    </div>
  );
};

export default TourSchedule;
