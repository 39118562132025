import React from "react";
import { Form } from "formik";
import Input from "@iso/components/uielements/input";
import { Button } from "antd";
import SignUpStyleWrapper from "../../pages/admin/airportTransfer/service/Board/BoardList/BoardList.style";
import Dropdown from "@iso/components/uielements/CustomDropdown";

const CityPriceForm = ({
  values,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  handleChange,
  submitForm,
  buttonTitle,
  services,
  disable,
  ...props
}) => {
  handleChange = (e) => {
    setFieldValue(e.target.name, e.target.value);
  };

  return (
    <SignUpStyleWrapper className="isoSignUpPage">
      <div className="isoSignUpContentWrapper">
        <div className="isoSignUpContent">
          <Form className="form-fields">
            <div className="isoSignUpForm">
              <div className="isoInputWrapper">
              <div className="isoInputWrapper">
                <Dropdown
                  options={services}
                  disable={disable}
                  onChange={(value) => {
                    setFieldValue("serviceId", value);
                  }}
                  value={values.serviceId ? values.serviceId : null}
                  placeholder="Select a service"
                />
                {errors.serviceId && touched.serviceId && (
                  <div className="invalid-input">{errors.serviceId.value}</div>
                )}
              </div>
                <Input
                  size="large"
                  placeholder="Price"
                  type="number"
                  name="price"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.price}
                />
                {errors.price && touched.price && (
                  <div className="invalid-input">{errors.price}</div>
                )}
              </div>
              <div className="isoInputWrapper">
                <Input
                  size="large"
                  placeholder="Night Price"
                  type="number"
                  name="nightPrice"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.nightPrice}
                />
                {errors.nightPrice && touched.nightPrice && (
                  <div className="invalid-input">{errors.nightPrice}</div>
                )}
              </div>
              <div className="isoInputWrapper">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    margin: "10px",
                  }}
                >
                  <Button type="primary" onClick={submitForm}>
                    {buttonTitle}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </SignUpStyleWrapper>
  );
};

export default (CityPriceForm);
