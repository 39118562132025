import * as Yup from "yup";

export const API_URL_PREFIX = process.env.REACT_APP_API_URL_PREFIX;

const required = "Required";

export const PAYMENT_METHODS = {
  cash: 1,
  card: 2,
};

export const logInValidation = Yup.object().shape({
  email: Yup.string()
    .required(required)
    .email()
    .matches(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    ),
  password: Yup.string().required(required),
});

export const registerValidation = Yup.object().shape({
  firstName: Yup.string().required(required),
  lastName: Yup.string().required(required),
  email: Yup.string().required().email(),
  password: Yup.string().required(required),
  // .matches(/(?=.*[0-9])/, 'New Password must contain one number')
  // .matches(/(?=.*[A-Z])/, 'New Password must contain one capital letter')
  // .matches(
  //   /(?=.*[!@#%*$&^()<>+=._-])/,
  //   'New Password must contain one special character i.e !, @, #, $, %, ^, &, *, (, ), -, _, =, +, <, >, .',
  // )
  // .matches(/(?=.{8,})/, 'New Password must be 8 character long'),
  // confirmPassword: Yup.string()
  //   .required(required)
  //   .oneOf([Yup.ref('password')], 'Passwords must match'),
});




export const hotelFormValidation = Yup.object().shape({
  displayName: Yup.string().required(required),
  name: Yup.string().required(required),
  typeId: Yup.object().shape({
    value: Yup.string().required(required),
  }),
  country: Yup.string().required(required),
  state: Yup.string().required(required),
  addressLine1: Yup.string().required(required),
  cityId:  Yup.object().shape({
    value: Yup.string().required(required),
  }),
  email: Yup.string().required().email(),
  location: Yup.object().required(required),
  facebook: Yup.string().matches(
    // eslint-disable-next-line max-len
    /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-.]*\/)*([\w\-.]*)/gi,
    "Enter correct facebook url!"
  ),
  instagram: Yup.string().matches(
    // eslint-disable-next-line max-len
    /(?:https?:\/\/)?(?:www\.)?(instagram|instagr|instagr)\.(com|am)(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-.]*\/)*([\w\-.]*)/gi,
    "Enter correct instagram url!"
  ),
  website: Yup.string().matches(
    // eslint-disable-next-line max-len
    /(?:https?:\/\/)?(?:www\.)?([-a-zA-Z0-9])\.(com|me|[a-zA-Z])(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-.]*\/)*([\w\-.]*)/gi,
    "Enter correct website url!"
  ),
});

export const nonPrivateUsers = [
  { label: "Adults", value: 0 },
  { label: "Children", value: 1 },
  { label: "Babies", value: 2 },
];

export const airportFormValidation = Yup.object().shape({
  name: Yup.string().required(required),
  country: Yup.string().required(required),
  state: Yup.string().required(required),
  address: Yup.string().required(required),
  city: Yup.string().required(required),
  location: Yup.object().required(required),
});

export const privateTourValidation = Yup.object().shape({
  destination: Yup.string().required(required),
  tagline: Yup.string().required(required),
  duration: Yup.number()
    .required(required)
    .test(
      "Is positive?",
      "The value must be greater than 0",
      (value) => value > 0
    ),
  includes: Yup.string().required(required),
  observations: Yup.string().required(required),
  privatePrice: Yup.number()
    .required(required)
    .test(
      "Is positive?",
      "The value must be greater than 0",
      (value) => value >= 0
    ),
  countryId: Yup.object().shape({
    value: Yup.string().required(required),
  }),
  description: Yup.string().nullable(),
});

export const monthluExcursionValidation = Yup.object().shape({
  destination: Yup.string().required(required),
  tagline: Yup.string().required(required),
  duration: Yup.number()
    .required(required)
    .test(
      "Is positive?",
      "The value must be greater than 0",
      (value) => value > 0
    ),
  includes: Yup.string().required(required),
  observations: Yup.string().required(required),
  remarks: Yup.string().required(required),
  childrenPerPrice: Yup.number()
    .required(required)
    .test(
      "Is positive?",
      "The value must be greater than 0",
      (value) => value >= 0
    ),
  adultPerPrice: Yup.number()
    .required(required)
    .test(
      "Is positive?",
      "The value must be greater than 0",
      (value) => value >= 0
    ),
  babiesPerPrice: Yup.number()
    .required(required)
    .test(
      "Is positive?",
      "The value must be greater than 0",
      (value) => value >= 0
    ),
  countryId: Yup.object().shape({
    value: Yup.string().required(required),
  }),
  pickUpDate: Yup.string().required(required),
  description: Yup.string(),
});

export const sharedTourValidation = Yup.object().shape({
  destination: Yup.string().required(required),
  tagline: Yup.string().required(required),
  duration: Yup.number()
    .required(required)
    .test(
      "Is positive?",
      "The value must be greater than 0",
      (value) => value > 0
    ),
  includes: Yup.string().required(required),
  observations: Yup.string().required(required),
  remarks: Yup.string().required(required),
  childrenPerPrice: Yup.number()
    .required(required)
    .test(
      "Is positive?",
      "The value must be greater than 0",
      (value) => value >= 0
    ),
  adultPerPrice: Yup.number()
    .required(required)
    .test(
      "Is positive?",
      "The value must be greater than 0",
      (value) => value >= 0
    ),
  babiesPerPrice: Yup.number()
    .required(required)
    .test(
      "Is positive?",
      "The value must be greater than 0",
      (value) => value >= 0
    ),
  countryId: Yup.object().shape({
    value: Yup.string().required(required),
  }),
  description: Yup.string(),
});

export const receptionistValidation = Yup.object().shape({
  firstName: Yup.string().required(required),
  lastName: Yup.string().required(required),
  email: Yup.string().required().email(),
  password: Yup.string().required(required),
  confirmPassword: Yup.string()
    .required("required")
    .oneOf([Yup.ref("password")], "Passwords must match"),
  hotelId: Yup.object().shape({
    value: Yup.string().required(required),
  }),
  // .matches(/(?=.*[0-9])/, 'Password must contain one number')
  // .matches(/(?=.*[A-Z])/, 'Password must contain one capital letter')
  // .matches(
  //   /(?=.*[!@#%*$&^()<>+=._-])/,
  //   'Password must contain one special character i.e !, @, #, $, %, ^, &, *, (, ), -, _, =, +, <, >, .',
  // )
  // .matches(/(?=.{8,})/, 'Password must be 8 character long'),
});

export const receptionistUpdateValidation = Yup.object().shape({
  firstName: Yup.string().required(required),
  lastName: Yup.string().required(required),
});

export const cityValidation = Yup.object().shape({
  name: Yup.string().required(required),
});

export const busStopValidation = Yup.object().shape({
  // busStopPlace: Yup.object().shape({
  //   latitude: Yup.string().required(required),
  // }),
  location: Yup.object().required(required),
  name: Yup.string().required(required),
});

export const tCRegisterFormValidation = Yup.object().shape({
  firstName: Yup.string().required(required),
  lastName: Yup.string().required(required),
  email: Yup.string().required().email(),
  password: Yup.string().required(required),
  companyAddress: Yup.string().required(required),
  companyName: Yup.string().required(required),
  companyEmail: Yup.string().required(required).email(),
  // .matches(/(?=.*[0-9])/, 'Password must contain one number')
  // .matches(/(?=.*[A-Z])/, 'Password must contain one capital letter')
  // .matches(
  //   /(?=.*[!@#%*$&^()<>+=._-])/,
  //   'Password must contain one special character i.e !, @, #, $, %, ^, &, *, (, ), -, _, =, +, <, >, .',
  // )
  // .matches(/(?=.{8,})/, 'Password must be 8 character long'),
});

export const tCUpdateFormValidation = Yup.object().shape({
  companyAddress: Yup.string().required(required),
  companyName: Yup.string().required(required),
});

export const vehicleFormValidation = Yup.object().shape({
  typeId: Yup.object().shape({
    value: Yup.string().required(required),
  }),
});

export const vehiclePriceFormValidation = Yup.object().shape({
  noOfPassengers: Yup.number()
    .required(required)
    .test(
      "Is positive?",
      "The value must be greater than 0",
      (value) => value > 0
    ),
  airportId: Yup.object().shape({
    value: Yup.string().required(required),
  }),
  hotelId: Yup.object().shape({
    value: Yup.string().required(required),
  }),
  farePrice: Yup.number()
    .required(required)
    .test(
      "Is positive?",
      "The value must be greater than 0",
      (value) => value >= 0
    ),
  countryId: Yup.object().shape({
    value: Yup.string().required(required),
  }),
});

export const vehiclePrice2FormValidation = Yup.object().shape({
  airportId: Yup.object().shape({
    value: Yup.string().required(required),
  }),
  hotelId: Yup.object().shape({
    value: Yup.string().required(required),
  }),
  farePrice: Yup.number()
    .required(required)
    .test(
      "Is positive?",
      "The value must be greater than 0",
      (value) => value >= 0
    ),
  countryId: Yup.object().shape({
    value: Yup.string().required(required),
  }),
});

export const privateBookingValidation = Yup.object().shape({
  clientName: Yup.string().required(required),
  clientPhone: Yup.string().required(required),
  clientEmail: Yup.string().required(required).email(),
  date: Yup.string().required(required),
  pickUpTime: Yup.string().required(required),
  extraCharges: Yup.number().nullable(),
  typeId: Yup.object().shape({
    value: Yup.string().required(required),
  }),

  location: Yup.object().shape({
    name: Yup.string().required(required),
  }),
  countryId: Yup.object().shape({
    value: Yup.string().required(required),
  }),
  destination: Yup.string().required(required),
  tagline: Yup.string().required(required),
  duration: Yup.string().required(required),
  includes: Yup.string().required(required),
  observations: Yup.string().required(required),
  privatePrice: Yup.string().required(required),
  remarks: Yup.string().required(required),
  noOfPersons: Yup.string().required(required),
  description: Yup.string().nullable(),
});

export const privateBookingValidation2 = Yup.object().shape({
  clientName: Yup.string().required(required),
  clientPhone: Yup.string().required(required),
  clientEmail: Yup.string().required(required).email(),
  date: Yup.string().required(required),
  pickUpTime: Yup.string().required(required),
  extraCharges: Yup.number().nullable(),
  typeId: Yup.object().shape({
    value: Yup.string().required(required),
  }),
  countryId: Yup.object().shape({
    value: Yup.string().required(required),
  }),
  // noOfPersons: Yup.string().required(required),
  tourId: Yup.object().shape({
    value: Yup.string().required(required),
  }),
  description: Yup.string().nullable(),
  receptionist: Yup.string().nullable(),
});

export const sharedBookingValidation = Yup.object().shape({
  clientName: Yup.string().required(required),
  clientPhone: Yup.string().required(required),
  clientEmail: Yup.string().required(required).email(),
  date: Yup.string().required(required),
  // numOfAdults: Yup.number()
  //   .required(required)
  //   .test(
  //     "Is positive?",
  //     "The value must be greater than or equal to 0",
  //     (value) => value >= 0
  //   ),
  // numOfChildren: Yup.number()
  //   .required(required)
  //   .test(
  //     "Is positive?",
  //     "The value must be greater than or equal to 0",
  //     (value) => value >= 0
  //   ),
  // numOfBabies: Yup.number()
  //   .required(required)
  //   .test(
  //     "Is positive?",
  //     "The value must be greater than or equal to 0",
  //     (value) => value >= 0
  //   ),
  extraCharges: Yup.number().nullable(),
  description: Yup.string().nullable(),
  // cityId: Yup.object().shape({
  //   value: Yup.string().required(required),
  // }),
  // stopId: Yup.object().shape({
  //   value: Yup.string().required(required),
  // }),
  typeId: Yup.object().shape({
    value: Yup.string().required(required),
  }),
  tourId: Yup.object().shape({
    value: Yup.string().required(required),
  }),
  language: Yup.object().shape({
    value: Yup.string().required(required),
  }),
  receptionist: Yup.string().nullable(),
  // room: Yup.array()
  //   .min(1, "Pick at least 1 tag")
  //   .of(
  //     Yup.object().shape({
  //       value: Yup.string().required(),
  //       label: Yup.string().required(),
  //     })
  //   ),
  // clientDetailValid: Yup.array().of(
  //   Yup.object().shape({
  //     firstName: Yup.string().required("Name required"),
  //     surName: Yup.string().required("Name required"),
  //   dob: Yup.string().required("Name required"),
  //   nationality: Yup.string().required("Name required"),
  //   documentType: Yup.string().required("Name required"),
  //   documentExpiry: Yup.string().required("Name required"),
  //   document: Yup.string().required("Name required"),
  //   })
  // )
});

export const monthlyExcBookingValidation = Yup.object().shape({
  clientName: Yup.string().required(required),
  clientPhone: Yup.string().required(required),
  clientEmail: Yup.string().required(required).email(),
  // numOfAdults: Yup.number()
  //   .required(required)
  //   .test(
  //     "Is positive?",
  //     "The value must be greater than or equal to 0",
  //     (value) => value >= 0
  //   ),
  // numOfChildren: Yup.number()
  //   .required(required)
  //   .test(
  //     "Is positive?",
  //     "The value must be greater than or equal to 0",
  //     (value) => value >= 0
  //   ),
  // numOfBabies: Yup.number()
  //   .required(required)
  //   .test(
  //     "Is positive?",
  //     "The value must be greater than or equal to 0",
  //     (value) => value >= 0
  //   ),
  extraCharges: Yup.number().nullable(),
  description: Yup.string().nullable(),
  // cityId: Yup.object().shape({
  //   value: Yup.string().required(required),
  // }),
  // stopId: Yup.object().shape({
  //   value: Yup.string().required(required),
  // }),
  typeId: Yup.object().shape({
    value: Yup.string().required(required),
  }),
  tourId: Yup.object().shape({
    value: Yup.string().required(required),
  }),
  language: Yup.object().shape({
    value: Yup.string().required(required),
  }),
  receptionist: Yup.string().nullable(),
});

export const transportVehicleValidation = Yup.object().shape({
  clientName: Yup.string().required(required),
  clientPhone: Yup.string().required(required),
  clientEmail: Yup.string().required(required).email(),
  date: Yup.string().required(required),
  pickUpTime: Yup.string().required(required),
  vehicleId: Yup.object().shape({
    value: Yup.string().required(required),
  }),
  airportId: Yup.object().shape({
    value: Yup.string().required(required),
  }),
  direction: Yup.object().shape({
    value: Yup.string().required(required),
  }),
  extraCharges: Yup.number().nullable(),
});

export const resetPassValidation = Yup.object().shape({
  token: Yup.string().required(required),
  password: Yup.string().required(required),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

export const profileValidation = Yup.object().shape({
  firstName: Yup.string().required(required),
  lastName: Yup.string().required(required),
});

export const airportTransferValidation = (dir) => 
  Yup.object().shape({
    clientName: Yup.string().required(required),
    clientPhone: Yup.string().required(required),
    clientEmail: Yup.string().required(required).email(),
    pickUpDate: Yup.string().required(required),
    pickUpTime: Yup.string().required(required),
    receptionist: Yup.string().required(required),
    airportId: Yup.object().shape({
      value: Yup.string().required(required),
    }),
    service: Yup.object().shape({
      value: Yup.string().required(required),
    }),
    direction: Yup.object().shape({
      value: Yup.string().required(required),
    }),
    flightNo: Yup.string().required(required),
    noOfLuggage: Yup.number().required(required).test(
      "Is positive?",
      "The value must be greater than or equal to 0",
      (value) => value >= 0
    ),
    babySeats: Yup.number()
      .required(required)
      .test(
        "Is positive?",
        "The value must be greater than or equal to 0",
        (value) => value >= 0
      ),
    childBSeats: Yup.number()
      .required(required)
      .test(
        "Is positive?",
        "The value must be greater than or equal to 0",
        (value) => value >= 0
      ),
    golfEquipments: Yup.number()
      .required(required)
      .test(
        "Is positive?",
        "The value must be greater than or equal to 0",
        (value) => value >= 0
      ),
    specialEquipments: Yup.number()
      .required(required)
      .test(
        "Is positive?",
        "The value must be greater than or equal to 0",
        (value) => value >= 0
      ),
      paymentMethod: Yup.number(),
      comment: Yup.string().nullable(),
      noOfPeople: Yup.string().nullable(),
  });

export const INDUSTRY_TYPE = [
  { id: "", name: "Select a Value" },
  { id: "employee", name: "Employee" },
  { id: "company", name: "Company" },
];

export const SNACKBAR_MSG = {
  createExperience: "User Experience created successfully.",
  createEducation: "User Education created successfully.",
  updateExperience: "User Experience updated successfully.",
  updateEducation: "User Education updated successfully.",
  createProject: "User Project created successfully.",
  updateProject: "User Project updated successfully.",
  emptyEmailPassword: "Email and Password is required!",
  emptyEmail: "Email is required!",
  incorrectEmail: "Invalid Email",
  emptyPassword: "Password is required!",
  emptyFirstName: "Firstname is required!",
  emptyLastName: "Lastname is required!",
  emptyUserName: "Username is required!",
  emptyAllFields: "All Fields Required!",
  passwordMatch: "Password and Confirm Password does not matched!",
  networkError: "Couldn't response this request!",
  SaveNewsMessage: "Saved Successfully!",
  addressRequired: "Address is required!",
  phoneRequired: "Phone # is required!",
  companyNameRequired: "Company name is required!",
};

export const SNACKBAR_MSG_TYPE = {
  error: "error",
  success: "success",
};

export const GUESTS = [
  { value: 1, label: "Guest 1" },
  { value: 2, label: "Guest 2" },
  { value: 3, label: "Guest 3" },
  { value: 4, label: "Guest 4" },
  { value: 5, label: "Guest 5" },
  { value: 6, label: "Guest 6" },
  { value: 7, label: "Guest 7" },
];

export const TOUR_TYPES = {
  ticket: 1,
  shared: 2,
  ticket_guide: 3,
  tpt_tkt_lcl_acc_guide: 4,
  monthly_excursion: 5,
};

export const PLATFORM = {
  app: 'app',
  web: 'web',
};

export const TOUR_NAMES = {
  // private: 'private',
  1: { name: "Ticket Tour" },
  2: { name: "Shared Tour" },
  3: { name: "Ticket + Guide Tour" },
  4: { name: "Private Tour" },
  5: { name: "Special Excursion" },
};

export const transportTourValidation = Yup.object().shape({
  destination: Yup.string().required(required),
  tagline: Yup.string().required(required),
  duration: Yup.number()
    .required(required)
    .test(
      "Is positive?",
      "The value must be greater than 0",
      (value) => value > 0
    ),
  includes: Yup.string().required(required),
  observations: Yup.string().required(required),
  remarks: Yup.string().required(required),
  description: Yup.string().nullable(),
});

export const transportBookingValidation = Yup.object().shape({
  clientName: Yup.string().required(required),
  clientPhone: Yup.string().required(required),
  clientEmail: Yup.string().required(required).email(),
  date: Yup.string().required(required),
  description: Yup.string().nullable(),
  receptionist: Yup.string().nullable(),
  pickUpTime: Yup.string().required(required),
  extraCharges: Yup.number().nullable(),
  typeId: Yup.object().shape({
    value: Yup.string().required(required),
  }),
  tourId: Yup.object().shape({
    value: Yup.string().required(required),
  }),
  language: Yup.object().shape({
    value: Yup.string().required(required),
  }),
});

export const serviceValidation = Yup.object().shape({
  min: Yup.number().required(required),
  max: Yup.number().required(required),
  title: Yup.string().required(required),
  email: Yup.string().required(required).email(),
  phoneNumber: Yup.string().nullable(),
});

export const cityPriceValidation = Yup.object().shape({
  price: Yup.number().required(required),
  nightPrice: Yup.number(),
  serviceId: Yup.object().shape({
    value: Yup.string().required(required),
  }),
});

export const hotelPriceValidation = Yup.object().shape({
  price: Yup.number().required(required),
  nightPrice: Yup.number(),
  serviceId: Yup.object().shape({
    value: Yup.string().required(required),
  }),
  email: Yup.string().nullable().email(),
  phoneNumber: Yup.string().nullable(),
});

export const extraServiceValidation = Yup.object().shape({
  babySeatPrice: Yup.number().required(required),
  childBoosterSeatPrice: Yup.number().required(required),
  golfEquipmentPrice: Yup.number().required(required),
  specialEquipmentPrice: Yup.number().required(required),
});

export const tourBookingRequestValidation = Yup.object().shape({
  reason: Yup.string().nullable(),
  babySeatPrice: Yup.number(),
  request: Yup.string().nullable(),
  approve: Yup.string().nullable(),
});

//Euro currency converter
export const EURO = Intl.NumberFormat("en-DE", {
  style: "currency",
  currency: "EUR",
});

export const LANGUAGES = [
  {
    value: "en",
    label: "English",
  },
  {
    value: "fr",
    label: "French",
  },
  {
    value: "gr",
    label: "German",
  },
  {
    value: "sp",
    label: "Spanish",
  },
];

export const DOCUMENT_TYPE = [
  {
    value: "Passport",
    label: "Passport",
  },
  {
    value: "ID Card",
    label: "ID Card",
  },
  {
    value: "Driving License",
    label: "Driving License",
  },
];


export const PAYMENT_METHOD = [
  // { value: "", label: "" },
  { value: 1, label: "Cash" },
  { value: 2, label: "Credit or Debit Card" },
  { value: 3, label: "Monthly Invoice" },
];