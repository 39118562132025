import _ from "lodash";

import * as T from "../ActionTypes";

const initState = {
  tours: null,
  privateTours: null,
  ticketTours: null,
  ticketGuideTours: null,
  loading: false,
  bookingLoading: false
};

export default function ToursReducer(state = initState, action) {
  switch (action.type) {
    case T.CREATE_TOUR: {
      let newToursList = state.tours ? [...state.tours] : [];
      newToursList.push(action.payload);
      return { ...state, tours: newToursList, loading: false };
    }
    case T.UPDATE_TOUR: {
      const toursArray = _.filter(state.tours, (o) => {
        return o.id !== action.id;
      });
      toursArray.push(action.payload);
      return { ...state, tours: toursArray, loading: false };
    }

    case T.GET_TOURS_LIST: {
      return { ...state, tours: action.payload, loading: false };
    }

    case T.DELETE_TOUR: {
      const tourArray = _.filter(state.tours, (o) => {
        return o.id !== action.id;
      });
      return { ...state, tours: tourArray, loading: false };
    }

    case T.DELETE_T_TOUR: {
      const tourTArray = _.filter(state.ticketTours, (o) => {
        return o.id !== action.id;
      });
      return { ...state, ticketTours: tourTArray, loading: false };
    }

    case T.GET_TICKET_TOURS_LIST: {
      return { ...state, ticketTours: action.payload, loading: false };
    }

    case T.GET_TICKET_GUIDE_TOURS_LIST: {
      return { ...state, ticketGuideTours: action.payload, loading: false };
    }

    case T.DELETE_TG_TOUR: {
      const tourTGArray = _.filter(state.ticketGuideTours, (o) => {
        return o.id !== action.id;
      });
      return { ...state, ticketGuideTours: tourTGArray, loading: false };
    }

    case T.SEARCH_PRIVATE_TOURS: {
      return {
        ...state,
        tours: action.payload,
        privateTours: action.payload,
        loading: false,
      };
    }

    case T.GENERATE_TOUR_ADMIN_RECEIPT: {
      return {...state, loading: false};
    }

    case T.GENERATE_TOUR_S_ADMIN_RECEIPT: {
      return {...state, loading: false};
    }

    case T.TOUR_LOADING: {
      return { ...state, loading: true,  };
    }
    case T.TOURS_ERROR: {
      return { ...state, loading: false };
    }
    case T.TOUR_BOOKING_LOADING: {
      return { ...state, bookingLoading: true,  };
    }
    case T.TOUR_BOOKING_ERR: {
      return { ...state, bookingLoading: false };
    }
    default:
      return state;
  }
}
