import React, {useEffect, useState} from 'react';
import {Layout, Button, Popover, Checkbox} from 'antd';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import {Title, Filters, Header, HeaderSecondary} from './AppLayout.style';
import SearchInput from '@iso/components/ScrumBoard/SearchInput/SearchInput';
import {CaretDownOutlined} from '@ant-design/icons';
import {airportList, hotelList} from '@iso/redux/vehicle/actions';

const {Content} = Layout;

const AppLayout = ({children, airportFilter, hotelFilter, setSearchText}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [airports, setAirport] = useState([]);
  const [hotels, setHotels] = useState([]);
  useEffect(() => {
    dispatch(hotelList()).then((res) => {
      setHotels(res);
    });
    dispatch(airportList()).then((res) => {
      setAirport(res);
    });
  }, []);

  return (
    <Layout style={{backgroundColor: '#fff'}}>
      <Header>
        <Title>Airport Transfers List</Title>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <Button
            type="primary"
            style={{marginRight: 10}}
            onClick={() => navigate('/dashboard/createVehiclePrice', {state: {pricingType: 1}})}>
            Add new per person
          </Button>
          <Button
            type="submit"
            onClick={() => navigate('/dashboard/createVehiclePrice', {state: {pricingType: 2}})}>
            Add new per vehicle
          </Button>
        </div>
      </Header>

      <HeaderSecondary>
        <SearchInput
          onChange={(value) => setSearchText(value)}
          onCross={(value) => {
            setSearchText(value);
          }}
        />
        <Filters>
          <Popover
            placement="bottom"
            overlayClassName=""
            content={
              <Checkbox.Group options={airports} onChange={(value) => airportFilter(value)} />
            }
            trigger="click">
            <span>
              Airports <CaretDownOutlined />
            </span>
          </Popover>
          <Popover
            placement="bottom"
            content={<Checkbox.Group options={hotels} onChange={(value) => hotelFilter(value)} />}
            trigger="click">
            <span>
              Hotels
              <CaretDownOutlined />
            </span>
          </Popover>
        </Filters>
      </HeaderSecondary>

      <Content
        style={{
          padding: '0 24px',
        }}>
        {children}
        {/* <Scrollbars style={{width: '100%', height: '500px'}}>{children}</Scrollbars> */}
      </Content>
    </Layout>
  );
};

export default AppLayout;
