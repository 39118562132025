import {
  Button,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
} from "antd";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  fetchTourPricing,
  saveTourPricing,
} from "../../../redux/tours/actions";
import SignUpStyleWrapper from "../../receptionist/bookPrivateTours/BookPrivateTours.styles";

const TourPricing = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [inputFields, setInputFields] = useState([
    { guests: 1, price: null },
    { guests: 2, price: null },
    { guests: 3, price: null },
    { guests: 4, price: null },
    { guests: 5, price: null },
    { guests: 6, price: null },
    { guests: 7, price: null },
  ]);
  const [inps, setInp] = useState([
  ]);

  useEffect(() => {
    dispatch(fetchTourPricing(params.id)).then((res) => {
      res.forEach((rating) => {
        inputFields.splice(rating.guests - 1, 1, rating);
      });
      setInputFields(inputFields);
      setInp(inputFields)
    });
  }, []);


  const handleSubmit = (values) => {
    const data = { tourPricing: [...inputFields] };
    dispatch(saveTourPricing(params.id, data)).then((res) => {
      setInputFields(res);
    });
  };
  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] =
      (event.target.value && event.target.value) || 0;
    data[index]["guests"] = index + 1;
    setInputFields(data);
  };
  return (
    <SignUpStyleWrapper className="isoSignUpPage">
      <div className="isoSignUpContentWrapper">
        <div className="isoSignUpContent">
          <div className="isoLogoWrapper">
            <Link to="/dashboard">Pricing</Link>
          </div>
          <form>
            <div className="isoSignUpForm">
              <div className="RowDiv">
                {inps.map((input, index) => {
                  return (
                    <div className="isoInputWrapper" key={index}>
                      <label style={{fontSize: '12px', color: 'grey'}} >
                      {`${index + 1} Guests`}
                      <Input
                        name="price"
                        type="number"
                        value={input.price}
                        onChange={(event) => handleFormChange(index, event)}
                      />
                      </label>
                    </div>
                  );
                })}
              </div>
              <div>
                <Button type="primary" onClick={handleSubmit}>
                  Save
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </SignUpStyleWrapper>
  );
};

export default TourPricing;
