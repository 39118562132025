import React from 'react';
import {Popover} from 'antd';
import _ from 'lodash';
import {
  Avatar,
  InfoWrapper,
  Title,
  CreatedAt,
  ProjectInfo,
  MoreActionWrapper,
} from './BoardListCard.style';

import {IconSvg} from '@iso/components/ScrumBoard/IconSvg/IconSvg';
import MoreIcon from '@iso/assets/images/icon/16.svg';
import AvatarIcon from '@iso/assets/airporttransfer.png';

export default function BoardListCard({item, onDelete, onEdit}) {
  let perVehicleData = [
    {value: 1, label: '1-3 Persons'},
    {value: 2, label: '4-7 Persons'},
  ];

  let arr = _.filter(perVehicleData, function (o) {
    return o.value === item.vehicleCapacity;
  });

  const MoreActions = (
    <MoreActionWrapper>
      <p onClick={onEdit}>Edit Airport Transfer</p>
      <p style={{color:'#DC3545'}} onClick={onDelete}>Delete Airport Transfer</p>
    </MoreActionWrapper>
  );
  return (
    <tbody>
      <tr>
        <td>
          <ProjectInfo>
            <Avatar src={AvatarIcon} style={{backgroundColor: 'white'}} />
            <InfoWrapper>
              <Title>{item.airport.name}</Title>
              <CreatedAt>{item.hotel.name}</CreatedAt>
            </InfoWrapper>
          </ProjectInfo>
        </td>
        <td>
          {item.pricingType === 1
            ? 'Per Person'
            : `Per Vehicle (${arr.length !== 0 ? arr[0].label : ''})`}
        </td>
        <td>
          <div style={{width: 180}}>
            {item.farePrice} {item.currency}
          </div>
        </td>
        <td>
          <Popover placement="bottom" content={MoreActions} trigger="click">
            <IconSvg src={MoreIcon} border={'none'} padding={'0'} />
          </Popover>
        </td>
      </tr>
    </tbody>
  );
}
