import React from "react";
import { Table, Typography } from "antd";
import { useNavigate } from "react-router";

const List = ({ schedule }) => {
  const navigate = useNavigate();

  const getNextOccurrenceDate = (selectedDay) => {
    const today = new Date();
    const selectedDayIndex = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ].indexOf(selectedDay);

    let daysUntilSelectedDay = selectedDayIndex - today.getDay();
    if (daysUntilSelectedDay <= 0) {
      daysUntilSelectedDay += 7;
    }

    const nextOccurrenceDate = new Date(today);
    nextOccurrenceDate.setDate(today.getDate() + daysUntilSelectedDay);

    const formattedDate = nextOccurrenceDate.toISOString().split("T")[0];
    return formattedDate;
  };

  const onEdit = (data, day) => {
    const nextOccurrenceDate = getNextOccurrenceDate(day);
    const tour = {
      tour: {
        destination: data.tourStopwise.destination,
        id: data.tourStopwise.id,
      },
    };
    navigate(`/dashboard/shared-tours-booking/shared/`, {
      state: {
        tour,
        schdDate: nextOccurrenceDate,
        closeDate: data.tourStopwise.closeDay,
      },
    });
  };

  const columns = [
    {
      title: "Monday",
      dataIndex: "monday",
      rowKey: "monday",
      width: 3,

      onCell: (data) => ({
        onClick: () => {
          if (data.monday) {
            onEdit(data, "Monday");
          }
        },
      }),
      render: (text, data) => ({
        children: (
          <Typography.Text ellipsis>
            {text && data && data.tourStopwise && data.tourStopwise.destination}
          </Typography.Text>
        ),
        props: {
          style: {
            backgroundColor: data.monday ? `rgb(247,93,129, 0.3)` : "white",
            fontSize: "12px",
            cursor: "pointer",
          },
        },
      }),
    },
    {
      title: "Tuesday",
      dataIndex: "tuesday",
      rowKey: "tuesday",
      width: 3,

      onCell: (data) => ({
        onClick: () => {
          if (data.tuesday) {
            onEdit(data, "Tuesday");
          }
        },
      }),
      render: (text, data) => ({
        children: (
          <Typography.Text ellipsis>
            {text && data && data.tourStopwise && data.tourStopwise.destination}
          </Typography.Text>
        ),
        props: {
          style: {
            backgroundColor: data.tuesday ? `rgb(0, 179, 162, 0.18)` : "white",
            fontSize: "12px",
            cursor: "pointer",
            color: "white",
          },
        },
      }),
    },
    {
      title: "Wednesday",
      dataIndex: "wednesday",
      rowKey: "wednesday",
      width: 3,

      onCell: (data) => ({
        onClick: () => {
          if (data.wednesday) {
            onEdit(data, "Wednesday");
          }
        },
      }),
      render: (text, data, index) => ({
        children: (
          <Typography.Text ellipsis>
            {text && data && data.tourStopwise && data.tourStopwise.destination}
          </Typography.Text>
        ),
        props: {
          style: {
            backgroundColor: data.wednesday ? `rgb(150,126,200, 0.3)` : "white",
            fontSize: "12px",
            cursor: "pointer",
            color: "white",
          },
        },
      }),
    },
    {
      title: "Thursday",
      dataIndex: "thursday",
      rowKey: "thursday",
      width: 3,

      onCell: (data) => ({
        onClick: () => {
          if (data.thursday) {
            onEdit(data, "Thursday");
          }
        },
      }),
      render: (text, data, index) => ({
        children: (
          <Typography.Text ellipsis>
            {text && data && data.tourStopwise && data.tourStopwise.destination}
          </Typography.Text>
        ),
        props: {
          style: {
            backgroundColor: data.thursday
              ? `rgba(240, 255, 162, 0.18)`
              : "white",
            fontSize: "12px",
            cursor: "pointer",
            color: "white",
          },
        },
      }),
    },
    {
      title: "Friday",
      dataIndex: "friday",
      rowKey: "friday",
      width: 3,

      onCell: (data) => ({
        onClick: () => {
          if (data.friday) {
            onEdit(data, "Friday");
          }
        },
      }),
      render: (text, data, index) => ({
        children: (
          <Typography.Text ellipsis>
            {text && data && data.tourStopwise && data.tourStopwise.destination}
          </Typography.Text>
        ),
        props: {
          style: {
            backgroundColor: data.friday ? `rgb(210,100, 41, 0.18)` : "white",
            fontSize: "12px",
            cursor: "pointer",
            color: "white",
          },
        },
      }),
    },
    {
      title: "Saturday",
      dataIndex: "saturday",
      rowKey: "saturday",
      width: 3,

      onCell: (data) => ({
        onClick: () => {
          if (data.saturday) {
            onEdit(data, "Saturday");
          }
        },
      }),
      render: (text, data, index) => ({
        children: (
          <Typography.Text ellipsis>
            {text && data && data.tourStopwise && data.tourStopwise.destination}
          </Typography.Text>
        ),
        props: {
          style: {
            backgroundColor: data.saturday ? `rgb(66,165,246,0.3)` : "white",
            fontSize: "12px",
            cursor: "pointer",
            color: "white",
          },
        },
      }),
    },
    {
      title: "Sunday",
      dataIndex: "sunday",
      rowKey: "sunday",
      width: 3,

      onCell: (data) => ({
        onClick: () => {
          if (data.sunday) {
            onEdit(data, "Sunday");
          }
        },
      }),
      render: (text, data, index) => ({
        children: (
          <Typography.Text ellipsis>
            {text && data && data.tourStopwise && data.tourStopwise.destination}
          </Typography.Text>
        ),
        props: {
          style: {
            backgroundColor: data.sunday ? `rgb(0, 120, 120, 0.18)` : "white",
            fontSize: "12px",
            cursor: "pointer",
            color: "white",
          },
        },
      }),
    },
  ];

  if (schedule) {
    return (
      <Table
        columns={columns}
        dataSource={schedule}
        bordered
        size="small"
        pagination={false}
      />
    );
  }
};

export default List;
