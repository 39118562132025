import React from 'react';
import {connect, useDispatch} from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

import {clearSnackbar} from '@iso/redux/snackbar/actions';

const SnackbarMessage = (props) => {
  const dispatch = useDispatch();
  const snackbarOpen =
    (props && props.snackbarMessage) || props.successSnackbarMessage;
  const snackbarColor = props.snackbarMessage ? '#dc3545' : '#28a745';
  const snackbarMessage =
    (props.snackbarMessage && props.snackbarMessage.message) ||
    (props.successSnackbarMessage && props.successSnackbarMessage.message);

  const handleClose = () => {
    dispatch(clearSnackbar());
  };
  return (
    <div>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <SnackbarContent
          style={{
            backgroundColor: snackbarColor,
            height: 60,
            fontSize: 14,
            fontWeight: 500,
          }}
          message={<span>{snackbarMessage}</span>}
          action={[
            <IconButton
              key="close"
              arial-label="Close"
              color="inherit"
              onClick={handleClose}
            >
              <FontAwesomeIcon icon={faTimes} style={{cursor: 'pointer'}} />
            </IconButton>,
          ]}
        ></SnackbarContent>
      </Snackbar>
    </div>
  );
};

const mapStatetoProps = (state) => {
  return {
    snackbarMessage: state.Snackbar.snackbarMessage,
    successSnackbarMessage: state.Snackbar.successSnackbarMessage,
  };
};

export default connect(mapStatetoProps, {clearSnackbar})(SnackbarMessage);
