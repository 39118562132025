import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Layout } from "antd";
import { Outlet } from "react-router";

import useWindowSize from "@iso/lib/hooks/useWindowSize";
import appActions from "@iso/redux/app/actions";
import siteConfig from "@iso/config/site.config";
import Sidebar from "@iso/containers/Sidebar/Sidebar";
import Topbar from "@iso/containers/Topbar/Topbar";

import { DashboardContainer, DashboardGlobalStyles } from "./Dashboard.styles";

const { Content, Footer } = Layout;
const { toggleAll } = appActions;
const styles = {
  layout: { flexDirection: "row", overflowX: "hidden" },
  content: {
    padding: "70px 0 0",
    flexShrink: "0",
    background: "#fff",
    position: "relative",
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    background: "#ffffff",
    textAlign: "center",
    borderTop: "1px solid #ededed",
  },
};

export default function Dashboard() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { appHeight } = state.App.height;
  const { role } = state.Auth.user;
  const { width, height } = useWindowSize();
  const isLoggedIn = useSelector((state) => {
    return (
      state.Auth.user &&
      state.Auth.user.receptionist &&
      state.Auth.user.receptionist[0]
    );
  });

  const hotel = isLoggedIn ? isLoggedIn.hotelId === 12 ? false : true : true;

  React.useEffect(() => {
    dispatch(toggleAll(width, height));
  }, [width, height, dispatch]);
  return (
    <DashboardContainer>
      <DashboardGlobalStyles />
      <Layout style={{ height: height }}>
        <Topbar role={role} />
        <Layout style={styles.layout}>
          <Sidebar role={role} hotel={hotel} />
          <Layout
            className="isoContentMainLayout"
            style={{
              height: appHeight,
            }}
          >
            <Content className="isomorphicContent" style={styles.content}>
              <Outlet />
            </Content>
            <Footer style={styles.footer}>{siteConfig.footerText}{siteConfig.developers}</Footer>
          </Layout>
        </Layout>
      </Layout>
    </DashboardContainer>
  );
}
