import React, { useEffect, useState } from "react";
import ImageUploader from "../../../components/uielements/ImageUploader";
import { useDispatch } from "react-redux";
import { Formik } from "formik";

import Button from "@iso/components/uielements/button";
import { getHotelBanner, hotelBannerAdd } from "../../../redux/setting/actions";

const HotelBanner = () => {
  const dispatch = useDispatch();

  const [disable, setDisable] = useState(true);
  const [bannerImg, setBannerImg] = useState(null);
  const [bannerFile, setBannerFile] = useState();
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    dispatch(getHotelBanner()).then((res) => {
      setBanner(res);
    });
  }, []);

  const handleSubmit = (values) => {
    setDisable(true);
    dispatch(hotelBannerAdd(values.banner)).then((res) => {
      setDisable(true);
    }).catch((err)=>{
        setDisable(false);
    });
  };

  return (
    <div>
      <Formik
        initialValues={{
          banner: (banner && banner.url) || null,
        }}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => {
          //   func = handleSubmit;
          const onBannerChange = (e) => {
            if ((e.target.files[0].size / 1048576).toFixed(2) > 2) {
              setBannerImg("Image size must be smaller than 2mb");
              setBannerFile(null);
            } else {
              setBannerImg(null);
              setFieldValue("banner", e.target.files[0]);
              setBannerFile(URL.createObjectURL(e.target.files[0]));
              setDisable(false);
            }
          };
          return (
            <form onSubmit={handleSubmit} style={{margin:'10px'}}>
              <div className="isoInputWrapper">
                <label className="label-style">Banner</label>
                <ImageUploader
                  handleImageChange={(e) => onBannerChange(e)}
                  value={undefined}
                  src={(bannerFile && bannerFile) || (banner && banner.url)}
                />

                {bannerImg && <div className="invalid-input">{bannerImg}</div>}
              </div>
              <Button style={{margin:'10px 0'}} type="primary" onClick={handleSubmit} disabled={disable}>
                Submit
              </Button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default HotelBanner;
