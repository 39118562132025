import _ from 'lodash';

import * as T from '../ActionTypes';

const initState = {companies: null, loading: false};

export default function hotelReducer(state = initState, action) {
  switch (action.type) {
    case T.CREATE_TRANSPORT_COMPANY: {
      let newCompaniesList = [...state.companies];
      newCompaniesList.push(action.payload);
      return {...state, companies: newCompaniesList, loading: false};
    }
    case T.UPDATE_TRANSPORT_COMPANY: {
      const companiesArray = _.filter(state.companies, (o) => {
        return o.id !== action.id;
      });
      companiesArray.push(action.payload);
      return {...state, companies: companiesArray, loading: false};
    }

    case T.GET_TRANSPORT_COMPANY_LIST: {
      return {...state, companies: action.payload, loading: false};
    }

    case T.DELETE_TRANSPORT_COMPANY: {
      const companiesArray = _.filter(state.companies, (o) => {
        return o.id !== action.id;
      });
      return {...state, companies: companiesArray, loading: false};
    }
    case T.TRANSPORT_COMPANY_LOADING: {
      return {...state, loading: true};
    }

    default:
      return state;
  }
}
