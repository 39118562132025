import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useSelector, useDispatch } from "react-redux";
import { Button, Popconfirm, Typography, Spin, Popover, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import NoBoardFounds from "../BoardNotFound/BoardNotFound";
import BoardListCard from "./BoardListCard/BoardListCard";
import { MoreActionWrapper } from "./BoardListCard/BoardListCard.style.js";
import AppLayout from "../../AppLayout/AppLayout";
import {
  generateTourReceipt,
  cancelRequestBooking,
} from "@iso/redux/receptionist/actions";
import { LANGUAGES, TOUR_TYPES } from "../../../../../constants";
import Lists from "../../../../../containers/Lists/Lists";
import { IconSvg } from "@iso/components/ScrumBoard/IconSvg/IconSvg";
import MoreIcon from "@iso/assets/images/icon/16.svg";
import { StatusTag } from "../../../../../containers/Lists/Lists.styles";
import { getPaymentIntent } from "../../../../../redux/receptionist/actions";
import BookingCancellation from "../../../../../components/Modal/BookingCancellation.js";

function BoardLists({ history, match }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [search, setSearch] = useState(null);
  const [filteredDate, setFilteredDate] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tourSelectedData, setTourSelectedData] = useState(null);

  const handleReceipt = (tour) => {
    dispatch(generateTourReceipt(tour.id));
  };

  const onCancel = (id) => {
    dispatch(cancelRequestBooking(id));
  };

  const onDuplicate = (tour) => {
    navigate(`/dashboard/ticket-tours-booking/private/`, { state: { tour } });
  };

  const onEdit = (tour) => {
    navigate(`/dashboard/ticket-tours-booking/private/`, {
      state: { tour, flag: 1 },
    });
  };

  const tourList = useSelector((state) => {
    return state.Receptionist.bookedTours;
  });

  let newTours = _.filter(tourList, function (o) {
    return o.tour.type === TOUR_TYPES.ticket;
  });

  if (search) {
    let searched = newTours.filter((i) => {
      return (
        i.clientName.toLowerCase().includes(search.toLowerCase()) ||
        i.clientEmail.toLowerCase().includes(search.toLowerCase()) ||
        i.tour.destination.toLowerCase().includes(search.toLowerCase())
      );
    });
    newTours = searched;
  }

  let Loading = useSelector((state) => {
    return state.Receptionist.loading;
  });

  const showModal = (data) => {
    setTourSelectedData(data);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    // dispatch(deleteVehicle(selectedModalBoard.id)).then((res) => {
    //   setIsModalVisible(false);
    // });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const MoreActions = (text, data) => (
    <MoreActionWrapper>
      {/* {Loading ? <Spin /> : <p onClick={onGenerateReceipt}>Generate Receipt</p>} */}
      {<p onClick={onDuplicate(data)}>Duplicate</p>}
      {Loading ? (
        <Spin />
      ) : (
        <Popconfirm title="Sure to cancel?" onConfirm={onCancel}>
          <p>Cancel Request</p>
        </Popconfirm>
      )}
    </MoreActionWrapper>
  );

  const columns = [
    {
      title: "Res#",
      dataIndex: "id",
      rowKey: "id",
      width: 10,
      fixed: "left",
      render: (text) => <Typography.Text ellipsis>{text}</Typography.Text>,
      sorter: (a, b) => moment(a.id) - moment(b.id),
    },
    {
      title: "Tour Date",
      dataIndex: "date",
      rowKey: "date",
      width: 10,
      render: (text) => (
        <Typography.Text ellipsis>
          {moment(text).format("DD/MM/yyyy")}
        </Typography.Text>
      ),
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
    },
    {
      title: "Tour Name",
      dataIndex: "tour",
      rowKey: "tour",
      width: 10,
      render: (text) => (
        <Typography.Text ellipsis>{text.destination}</Typography.Text>
      ),
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      rowKey: "clientName",
      width: 5,
      render: (text) => <Typography.Text ellipsis>{text}</Typography.Text>,
    },
    // {
    //   title: "Email",
    //   dataIndex: "clientEmail",
    //   rowKey: "clientEmail",
    //   width: 5,
    //   render: (text) => <Typography.Text ellipsis>{text}</Typography.Text>,
    // },
    {
      title: "Persons",
      dataIndex: "noOfPersons",
      rowKey: "noOfPersons",
      width: 10,
      render: (text) => <Typography.Text ellipsis>{text}</Typography.Text>,
    },
    {
      title: "Payment",
      dataIndex: "payment",
      rowKey: "payment",
      width: 5,
      render: (text, data) => (
        <span>
          {(data.platform === "web" && data && data.paymentMethod !== 4 && (
            <Tag color={"green"} key={text}>
              Paid
            </Tag>
          )) ||
            (text && (
              <Tag color={"green"} key={text}>
                Paid
              </Tag>
            )) || (
              <Tag color={"magenta"} key={text}>
                Unpaid
              </Tag>
            ) ||
            data.platform ===
              "app"(
                text && (
                  <Tag color={"green"} key={text}>
                    Paid
                  </Tag>
                )
              ) || (
              <Tag color={"magenta"} key={text}>
                Unpaid
              </Tag>
            )}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      rowKey: "status",
      width: 8,
      render: (text) => (
        <Typography.Text ellipsis>
          {(text === 0 && "-") || (
            <StatusTag
              style={{
                backgroundColor: "#FEAC01",
                padding: "10px 6px",
                borderRadius: "3px",
              }}
            >
              Cancelled
            </StatusTag>
          )}
        </Typography.Text>
      ),
    },
    {
      title: "Language",
      dataIndex: "lang",
      rowKey: "lang",
      width: 10,

      render: (text) => (
        <Typography.Text ellipsis>
          {_.result(
            _.find(LANGUAGES, function (o) {
              if (o.value === text) {
                return text;
              }
            }),
            "label"
          )}
        </Typography.Text>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      rowKey: "price",
      width: 10,
      render: (text) => <Typography.Text ellipsis>{text}</Typography.Text>,
    },
    {
      title: "Platform",
      dataIndex: "platform",
      rowKey: "platform",
      width: "10%",
      render: (text) => (
        <p>
          {(text === "web" && (
            <Tag color={"purple"} key={text}>
              WEB
            </Tag>
          )) || (
            <Tag color={"cyan"} key={text}>
              APP
            </Tag>
          )}
        </p>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      rowKey: "id",
      width: 5,
      fixed: "right",
      render: (text, data) => (
        <div className="isoInvoiceBtnView">
          <Popover
            placement="bottom"
            content={
              <MoreActionWrapper>
                {Loading ? (
                  <Spin />
                ) : (
                  <p onClick={() => handleReceipt(data)}>Generate Receipt</p>
                )}
                {<p onClick={() => onEdit(data)}>Edit</p>}
                {<p onClick={() => onDuplicate(data)}>Duplicate</p>}
                {data.paymentMethod === 4 && !data.payment && (
                  <p
                    onClick={() =>
                      dispatch(getPaymentIntent(data.id)).then((res) => {
                        navigate(
                          `/dashboard/payment-stripe/${res.paymentIntent}`,
                          {
                            state: {
                              bookId: data.id,
                              res,
                              path: "ticket-tours-booking-list",
                            },
                          }
                        );
                      })
                    }
                  >
                    Pay Now
                  </p>
                )}
                {
                  <p
                    onClick={() => {
                      showModal(data);
                    }}
                  >
                    Cancel Request
                  </p>
                }
              </MoreActionWrapper>
            }
            trigger="click"
          >
            <IconSvg src={MoreIcon} border={"none"} padding={"0"} />
          </Popover>
        </div>
      ),
    },
  ];

  if (filteredDate && filteredDate.length > 1) {
    let filtered = newTours.filter((product) => {
      let productDate = moment(product["date"]).format("YYYY-MM-DD");
      return (
        productDate >= moment(filteredDate[0]).format("YYYY-MM-DD") &&
        productDate <= moment(filteredDate[1]).format("YYYY-MM-DD")
      );
    });
    newTours = filtered;
  }

  return (
    <>
      <AppLayout
        history={history}
        match={match}
        setSearchText={(value) => {
          setSearch(value);
        }}
        setFilteredData={(data) => {
          setFilteredDate(data);
        }}
      >
        {Loading ? (
          <div className="spinner">
            <Spin />
          </div>
        ) : (
          <>
            {!isEmpty(newTours) ? (
              // <Table>
              //   {newTours.map((board) => {
              //     console.log('board', board)
              //     return (
              //       <BoardListCard
              //         key={board.id}
              //         item={board}
              //         onGenerateReceipt={() => handleReceipt(board)}
              //         onCancelBooking={()=> onCancel(board.id)}
              //         onDuplicateBooking={()=> onDuplicate(board)}
              //       />
              //     );
              //   })}
              // </Table>
              <Lists
                title={"Ticket Tours"}
                Columns={columns}
                Rows={newTours}
                pagination={{ pageSize: "10" }}
                // scroll={{
                //   // x: "calc(1300px + 50%)",
                //   y: "auto",
                // }}
                // pagination={true}
              />
            ) : (
              <NoBoardFounds history={history} match={match} />
            )}
          </>
        )}
      </AppLayout>
      {isModalVisible ? (
        <BookingCancellation
          isModalVisible={isModalVisible}
          handleCancel={handleCancel}
          title={"Cancellation Request"}
          buttonTitle="Save"
          cancellationData={tourSelectedData}
        />
      ) : null}
    </>
  );
}

export default BoardLists;
