import React from "react";
import { Layout, Button, DatePicker } from "antd";
import { Scrollbars } from "react-custom-scrollbars";
import { useNavigate } from "react-router";

import SearchInput from "@iso/components/ScrumBoard/SearchInput/SearchInput";
import { Header } from "./AppLayout.style";
const { RangePicker } = DatePicker;

const { Content } = Layout;

const AppLayout = ({
  children,
  setSearchText,
  history,
  match,
  setFilteredData,
}) => {
  const navigate = useNavigate();
  return (
    <Layout style={{ backgroundColor: "#fff" }}>
      <Header>
        <div style={{display: 'flex'}}>
          <SearchInput
            onChange={(value) => setSearchText(value)}
            onCross={(value) => {
              setSearchText(value);
            }}
          />
          <RangePicker style={{ backgroundColor: "#fff", border: 'none' }} format="DD/MM/yyyy" onChange={(data) => setFilteredData(data)} allowClear />
        </div>

        <Button
          type="primary"
          onClick={() => {
            navigate(`/dashboard/ticket-guide-tours-booking/transport`, {
              state: {},
            });
          }}
        >
          Book Tour
        </Button>
      </Header>

      <Content
        style={{
          padding: "0 24px",
        }}
      >
        <Scrollbars style={{ width: "100%", height: "80vh" }}>
          {children}
        </Scrollbars>
      </Content>
    </Layout>
  );
};

export default AppLayout;
