import API from "./API";
import Map from "./Map";
import { DEFAULT_TOUR_SETTING } from "../constants/Tour";
import _ from "lodash";
// import {PROMPTS} from '../constants';

const storeSession = async (data) => {
  try {
    const jsonValue = JSON.stringify(data);
    const token = data.accessToken;
    localStorage.setItem("token", token);
    localStorage.setItem("user", jsonValue);
  } catch (e) {
    throw e;
  }
};

const getSession = async () => {
  try {
    const jsonValue = await localStorage.getItem("user");
    return JSON.parse(jsonValue);
  } catch (e) {
    throw e;
  }
};

const removeSession = async () => {
  try {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  } catch (e) {
    throw e;
  }
};

const validateEmail = (email) => {
  let pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  return pattern.test(email);
};

const validatePassword = (password) => {
  let pattern = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,32}$/);

  return pattern.test(password);
};

// const generateRequiredFieldErrors = (fields, data) => {
//   let errObj = {};

//   fields.forEach((field) => {
//     if (!data[field]) {
//       errObj = {...errObj, [field]: PROMPTS.errors.empty};
//     }
//   });

//   return errObj;
// };

// const getImageFileObject = (image) => {
//   let obj = {uri: image.path, type: image.mime};

//   const ext = image.path.split('/');

//   obj.name = ext[ext.length - 1];
//   obj.donotConvertToJSON = true;

//   return obj;
// };

export const getStaticMap = (lat, lng, zoom) => {
  const staticMap = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&markers=color:red%7C${lat},${lng}&format=png&zoom=${zoom}&size=400x400&key=AIzaSyCIen1q5qlBeRbLejD17UiNp15AtqbXZUw`;
  return staticMap;
};

const fillTourSetting = (res = null) => {
  let setting = DEFAULT_TOUR_SETTING;
  if (res) {
    setting = {
      firstName: res.firstName || setting.firstName,
      surName: res.surName || setting.surName,
      dob: res.dob || setting.dob,
      nationality: res.nationality || setting.nationality,
      documentType: res.documentType || setting.documentType,
      documentExpiry: res.documentExpiry || setting.documentExpiry,
      document: res.document || setting.document,
    };
  } else {
    setting = DEFAULT_TOUR_SETTING;
  }
  return setting;
};

export const validationClient = (client, setting) => {
  const isClientDetail = _.find(client, function (o) {
    return (
      (setting.firstName && o.firstName === "") ||
      (setting.surName && o.surName === "") ||
      (setting.dob && o.dob === null) ||
      (setting.nationality && o.nationality === "") ||
      (setting.documentType && o.documentType === "") ||
      (setting.documentExpiry && o.documentExpiry === null) ||
      (setting.document && o.document === "")
    );
  });
  return (isClientDetail === undefined ? false : true);
};

export {
  API,
  Map,
  storeSession,
  getSession,
  removeSession,
  validateEmail,
  validatePassword,
  fillTourSetting,
  // generateRequiredFieldErrors,
  // getImageFileObject,
};
