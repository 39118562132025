import * as api from '../../constants/Api';
import {API, storeSession, removeSession} from '../../services';
import * as T from '../ActionTypes';
import {SNACKBAR_MSG_TYPE, SNACKBAR_MSG} from '../../constants';
import { faSkating } from '@fortawesome/free-solid-svg-icons';

export const signIn = ({email, password}) => {
  return (dispatch) => {
    return API.execute(api.SIGN_IN, {
      data: {email, password},
    })
      .then((response) => {
        const token = response.data.accessToken;
        localStorage.setItem('token', token);
        storeSession(response.data);
        dispatch(signInSuccess(response.data));
        return response.data;
      })
      .catch((error) => {
        let {message} = error;
        if (message === 'Network Error') {
          message = SNACKBAR_MSG.networkError;
        }
        const {messageType} = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: {message, messageType},
        });
        dispatch({
          type: T.LOGIN_ERROR,
          payload: error,
        });
      });
  };
};

export const forgotPassword = (data) => {
  return (dispatch) => {
    return API.execute(api.FORGOT_PASSWORD, {data}).then(
      (response) => {
        const message = 'Check your mail inbox to change password';
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        return true;
      },
      (error) => {
        dispatch({type: T.LOGIN_ERROR, payload: error});
        return false;
      },
    );
  };
};

export const resetPassword = (data) => {
  return (dispatch) => {
    return API.execute(api.RESET_PASSWORD, {data}).then(
      (response) => {
        const message = 'Password updated successfully';
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        return true;
      },
      (error) => {
        dispatch({type: T.LOGIN_ERROR, payload: error});
        return false;
      },
    );
  };
};

// export const signInSuccess = (data) => ({
//   type: T.LOGIN_SUCCESS,
//   payload: data,
// });

export const s = (data) => {
  return (dispatch) => {
    return API.execute(api.GET_USER_VIEW(data.id)).then(
      (response) => {
        // if(!response.data){
        //   localStorage.removeItem('token');
        //   localStorage.removeItem('user');
        // }
        dispatch({type: T.LOGIN_SUCCESS, payload: response.data});
        return response.data;
      }
      // ,
      // (error) => {
      //   // localStorage.removeItem('token');
      //   // localStorage.removeItem('user');
      //   return error;
      // },
    ).catch((err)=>{
      return err
    });
  };
};

// export const signInSuccess = (data) => {
//   return (dispatch) => {
//     return API.execute(api.GET_USER_VIEW(data.id))
//       .then((response) => {
//         dispatch({type: T.LOGIN_SUCCESS, payload: true});
//         return true;
//       })
//       .catch((error) => {
//         let {message} = error;
//         if (message === 'Network Error') {
//           message = SNACKBAR_MSG.networkError;
//         }
//         const {messageType} = SNACKBAR_MSG_TYPE.error;
//         dispatch({
//           type: T.SNACKBAR_ERROR,
//           payload: {message, messageType},
//         });

//         return false
//       });
//   };
// };

export const signInSuccess = (data) => {
  return (dispatch) => {
  return API
      .execute(api.GET_USER_VIEW(data.id))
      .then((response) => {
        if(!response.data){
          localStorage.removeItem('token');
          localStorage.removeItem('user');
        }
        dispatch({type: T.LOGIN_SUCCESS, payload: response.data});
        return response.data;
      })
      .catch((error) => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        return error;
      });
    }
};

export const logout = () => {
  return (dispatch) => {
    removeSession();
    dispatch({type: T.LOGOUT});
    return true;
  };
};
