import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppLayout from "./AppLayout/AppLayout";
import Lists from "../../../../containers/Lists/Lists";
import {
  getTourBooking,
  tourList,
  tourBookingView,
} from "../../../../redux/superAdmin/actions";
import { PAYMENT_METHODS } from "../../../../constants";
import ReportList from "./ReportList";
import * as html2pdf from "html2pdf.js";
import isEmpty from "lodash/isEmpty";
import { Spin } from "antd";
import NoBoardFounds from "./Board/BoardNotFound/BoardNotFound";

const List = ({ history, match }) => {
  const [d, setd] = React.useState("none");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [hotels, setHotels] = useState([]);
  const [tours, setTour] = useState([]);
  const [typeTour, setType] = useState("");
  const [dateTour, setDate] = useState("");
  const [tourId, setTourId] = useState("");
  const [people, setPeople] = React.useState(null);

  const handleReport = () => {
    var p2 = new Promise(function (resolve, reject) {
      setd("inline-block");
      var element = document.getElementById("element-print");

      html2pdf().from(element).save();
      resolve(false);
    });

    p2.then(function (value) {
      setd("none");
      return value;
    }).then(function (value) {
      setd("none");
      window.close();
    });
  };

  useEffect(() => {
    dispatch(getTourBooking(typeTour, dateTour, tourId)).then((res) => {
      setHotels(res.data);
      setPeople(res.people);

    });
  }, [typeTour, dateTour, tourId]);

  useEffect(() => {
    dispatch(tourList()).then((res) => {
      setTour(res);
    });
  }, []);

  const handleView = (tour) => {
    dispatch(tourBookingView(tour)).then((res) => {
      navigate(`/dashboard/tourBooking-view/${res.id}`, { state: { res } });
    });
  };

  let Loading = useSelector((state) => {
    return state.SuperAdmin.loadTour;
  });
  
  const columns = [
    {
      title: "Name",
      dataIndex: "clientName",
      rowKey: "clientName",
      width: "15%",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Email",
      dataIndex: "clientEmail",
      rowKey: "clientEmail",
      width: "25%",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Receptionist",
      dataIndex: "agent",
      rowKey: "agent",
      width: "15%",
      render: (text) =>
        (text && (
          <span>
            {text.firstName}
            {' '}
            {text.lastName}
          </span>
        )) || <span>-</span>,
    },
    {
      title: "Hotel",
      dataIndex: "agent",
      rowKey: "agent",
      width: "15%",
      render: (text) => (
        <span>
          {(text &&
            text.receptionist &&
            text.receptionist[0] &&
            text.receptionist[0].hotel.displayName) ||
            "-"}
        </span>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      rowKey: "date",
      width: "22%",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Payment",
      dataIndex: "paymentMethod",
      rowKey: "paymentMethod",
      width: "15%",
      render: (text) => (
        <span>
          {(text === PAYMENT_METHODS.cash && "Cash") ||
            (text === PAYMENT_METHODS.card && "Card")}
        </span>
      ),
    },
    {
      title: "Tour",
      dataIndex: "tour",
      rowKey: "tour",
      width: "15%",
      render: (text) => (
        <span>
          {text.destination}
        </span>
      ),
    },
    {
      title: "Pickup Time",
      dataIndex: "pickUpTime",
      rowKey: "pickUpTime",
      width: "13%",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Price",
      dataIndex: "price",
      rowKey: "price",
      width: "13%",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Platform",
      dataIndex: "platform",
      rowKey: "platform",
      width: "13%",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "",
      dataIndex: "id",
      rowKey: "id",
      width: "10%",
      render: (text, data) => (
        <div className="isoInvoiceBtnView">
          <Button
            color="primary"
            className="invoiceViewBtn"
            onClick={() => handleView(text)}
          >
            View
          </Button>
        </div>
      ),
    },
  ];
  if (tours) {
    return (
      <>
        <AppLayout
          typeFilter={(value) => {
            setType(value);
          }}
          dateFilter={(value) => {
            setDate(value);
          }}
          tours={(tours && tours) || null}
          typeTours={(value) => {
            setTourId(value);
          }}
          handleReport={handleReport}
          people={people}
        >
          {Loading ? (
            <div className="spinner">
              <Spin />
            </div>
          ) : !isEmpty(hotels) ? (
            <Lists title={"Tour Bookings"} Columns={columns} Rows={hotels} />
          ) : (
            <NoBoardFounds history={history} match={match} />
          )}
        </AppLayout>
        <div id="element-print" style={{ display: d }}>
          <ReportList data={hotels} />
        </div>
      </>
    );
  }
};

export default List;
