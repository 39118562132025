import React from 'react';
import {EditOutlined} from '@ant-design/icons';

import CardWrapper, {Avatar, Info} from './AvatarCard.style';
import userPic from '@iso/assets/images/Dummypicture.png';

const AvatarCard = ({className, avatar, name, username, onEdit}) => {
  return (
    <CardWrapper className={`avatar-card ${className ? className : ''}`}>
        <Avatar className="avatar">
          <img src={avatar ? avatar.url : userPic} alt={name} />
        </Avatar>
      {name || username ? (
        <Info className="info">
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            {name && <h3 className="name">{name}</h3>}
            <div onClick={() => onEdit()} style={{paddingLeft: 10}}>
              <EditOutlined style={{color: 'white', fontSize: 20}} />
            </div>
          </div>
          {username && <p className="username">{username}</p>}
        </Info>
      ) : (
        ''
      )}
    </CardWrapper>
  );
};

export default AvatarCard;
