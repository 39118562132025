// import _ from 'lodash';

import _ from 'lodash';
import * as T from '../ActionTypes';

const initState = {tours: null, bookedTours: null, airportTransports: null, loading: false};

export default function ReceptionistReducer(state = initState, action) {
  switch (action.type) {
    case T.GET_RECEPTIONIST_TOURS_LIST: {
      return {...state, tours: action.payload, loading: false};
    }
    case T.GET_RECEPTIONIST_BOOKED_TOURS_LIST: {
      return {...state, bookedTours: action.payload, loading: false};
    }
    case T.GET_RECEPTIONIST_BOOKED_AIRPORT_TRANSPORT_LIST: {
      return {...state, airportTransports: action.payload, loading: false};
    }
    case T.GENERATE_TOUR_RECEIPT: {
      return {...state, loading: false};
    }

    case T.RECEPTIONIST_LOADING: {
      return {...state, loading: true};
    }
    case T.RECEPTIONIST_ERROR: {
      return {...state, loading: false};
    }

    default:
      return state;
  }
}
