import React, { useState, useRef } from 'react';
import './styles/imageUploader.css';
import Input from "@iso/components/uielements/input";
import { CameraOutlined } from "@ant-design/icons";

function ImageUploader({handleImageChange, value, src}) {
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef();

  

//   const handleImageChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onload = () => {
//         setSelectedImage(reader.result);
//       };
//       reader.readAsDataURL(file);
//     }
//   };

  const handleCapture = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      <div className="image-container">
        {src ? (
          <img src={src} alt="Selected" className="selected-image" onClick={handleCapture} />
        ) : (
          <div className="camera-icon" onClick={handleCapture}>
            <CameraOutlined/>
          </div>
        )}
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: 'none' }}
          ref={fileInputRef}
        />
      </div>
    </div>
  );
}

export default ImageUploader;