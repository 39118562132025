import * as actionTypes from '../ActionTypes';

export const showSnackbarSuccess = (message, messageType) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SNACKBAR_SUCCESS,
      payload: {message, messageType},
    });
  };
};
export const showSnackbar = (message, messageType) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SNACKBAR_ERROR,
      payload: {message, messageType},
    });
  };
};

export const clearSnackbar = () => {
  return (dispatch) => {
    dispatch({type: actionTypes.SNACKBAR_CLEAR});
  };
};
