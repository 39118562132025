import React, {useState, useEffect} from 'react';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {Formik} from 'formik';
import _ from 'lodash';

import Dropdown from '@iso/components/uielements/CustomDropdown';
import Button from '@iso/components/uielements/button';
import {createVehicle, vehicleTypeList, updateVehicle} from '@iso/redux/vehicle/actions';
import IntlMessages from '@iso/components/utility/intlMessages';
import SignUpStyleWrapper from './CreateVehicle.styles';
import {vehicleFormValidation} from '../../../constants';

export default function CreateVehicles() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useLocation();
  const locParams = params && params.state ? params.state : {vehicle: null};

  const [vehiclesType, setVehiclesType] = useState([]);

  useEffect(() => {
    dispatch(vehicleTypeList()).then((res) => {
      setVehiclesType(res);
    });
  }, []);

  const handleSubmit = (values) => {
    if (locParams.vehicle) {
      let updatedVehicle = {typeId: values.typeId.value, id: locParams.vehicle.id};
      dispatch(updateVehicle(updatedVehicle)).then((res) => {
        navigate('/dashboard/vehicle-list');
      });
    } else {
      let data = {typeId: values.typeId.value};

      dispatch(createVehicle(data)).then((res) => {
        navigate('/dashboard/vehicle-list');
      });
    }
  };

  return (
    <SignUpStyleWrapper className="isoSignUpPage">
      <div className="isoSignUpContentWrapper">
        <div className="isoSignUpContent">
          <div className="isoLogoWrapper">
            <Link to="/dashboard">
              <IntlMessages id="Add a new vehicles" />
            </Link>
          </div>

          <Formik
            initialValues={{
              typeId: locParams.vehicle
                ? _.find(vehiclesType, function (o) {
                    return o.value === locParams.vehicle.typeId;
                  })
                : {value: ''},
            }}
            onSubmit={handleSubmit}
            validationSchema={vehicleFormValidation}
            enableReinitialize>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="isoSignUpForm">
                  <div className="isoInputWrapper">
                    <Dropdown
                      options={vehiclesType}
                      onChange={(value) => {
                        setFieldValue('typeId', value);
                      }}
                      value={values.typeId ? values.typeId : null}
                      placeholder="Select a vehicle type"
                    />
                    {errors.typeId && touched.typeId && (
                      <div className="invalid-input">{errors.typeId.value}</div>
                    )}
                  </div>

                  <div className="isoInputWrapper">
                    <Button type="primary" onClick={handleSubmit}>
                      <IntlMessages id={locParams.vehicle ? 'Update' : 'page.signUpButton'} />
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </SignUpStyleWrapper>
  );
}
