import React from 'react';
// import {Button} from 'antd';
import {Wrapper, Title, Icon} from './NoBoardFounds.style';
import emptyProjectPlaceHolder from '@iso/assets/images/icon/12.svg';
export default function NoBoardFounds({history, match}) {
  return (
    <Wrapper>
      <Icon src={emptyProjectPlaceHolder} />
      <Title>General Transfer In Development</Title>
    </Wrapper>
  );
}
