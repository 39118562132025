import React from 'react';
import { Col, Row, Checkbox } from "antd";
import basicStyle from "@iso/assets/styles/constants";

const ToutSettingForm = (props) => {
    const {client, setclient} = props;
    const { rowStyle, colStyle, gutter } = basicStyle;
  return (

    <Row style={rowStyle} gutter={gutter} justify="start">
        <Col md={12} sm={12} xs={24} style={colStyle}>
            <div>
            <p style={{ fontWeight: "bold" }}>
                Client Detail Required Settings
            </p>
            </div>
            <div
            style={{ display: "flex", flexDirection: "column" }}
            >
            <Checkbox
                style={{ margin: "0" }}
                name="firstName"
                checked={client.firstName}
                onChange={(e) => {
                setclient({
                    ...client,
                    firstName: (e.target.checked && 1) || 0,
                });
                }}
            >
                Name
            </Checkbox>
            <Checkbox
                style={{ margin: "0" }}
                name="surName"
                checked={client.surName}
                onChange={(e) => {
                setclient({
                    ...client,
                    surName: (e.target.checked && 1) || 0,
                });
                }}
            >
                Surname
            </Checkbox>
            <Checkbox
                style={{ margin: "0" }}
                name="dob"
                checked={client.dob}
                onChange={(e) => {
                setclient({
                    ...client,
                    dob: (e.target.checked && 1) || 0,
                });
                }}
            >
                Date of Birth
            </Checkbox>
            <Checkbox
                style={{ margin: "0" }}
                name="nationality"
                checked={client.nationality}
                onChange={(e) => {
                setclient({
                    ...client,
                    nationality: (e.target.checked && 1) || 0,
                });
                }}
            >
                Nationality
            </Checkbox>
            <Checkbox
                style={{ margin: "0" }}
                name="documentType"
                checked={client.documentType}
                onChange={(e) => {
                setclient({
                    ...client,
                    documentType: (e.target.checked && 1) || 0,
                });
                }}
            >
                    Document Type
                </Checkbox>
            <Checkbox
                style={{ margin: "0" }}
                name="document"
                checked={client.document}
                onChange={(e) => {
                setclient({
                    ...client,
                    document: (e.target.checked && 1) || 0,
                });
                }}
            >
                Document Number
            </Checkbox>
            <Checkbox
                style={{ margin: "0" }}
                name="documentExpiry"
                checked={client.documentExpiry}
                onChange={(e) => {
                setclient({
                    ...client,
                    documentExpiry: (e.target.checked && 1) || 0,
                });
                }}
            >
                Document Expiry
            </Checkbox>
            </div>
        </Col>
    </Row>
  );
}

export default ToutSettingForm;
