import React, {useState} from 'react';
import Spin from '@iso/ui/Antd/Spin/Spin';
import Modal from '@iso/ui/Antd/Modal/Modal';
import Container from '@iso/ui/UI/Container/Container';
import AvatarCard from '@iso/components/AvatarCard/AvatarCard';
import Posts from './Posts/Posts';
import Followers from './Followers/Followers';
import Following from './Following/Following';
import Wrapper, {Banner, Navigation, ContentWrapper} from './Profile.styles';
import {useSelector} from 'react-redux';
import bgImage from '../../assets/images/cover.svg';
import EditProfileModal from '../../containers/EditProfileModal';

const MyProfile = () => {
  const data = {
    avatar: '/static/media/user1.56a1f25e.png',
    followers: [],
    following: [],
    id: 1,
    name: 'Samwell Martina',
    post: [],
    profile_bg: '/static/media/profile-bg.f57b6453.jpg',
    username: '@samwell_martina',
  };
  const loading = false;

  const [active, setActive] = useState('post');
  const [visible, setVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const user = useSelector((state) => {
    return state.Auth.user;
  });

  const {avatar, firstName, lastName, email} = user;

  const handleMenu = (type) => {
    if (type === 'post') {
      setActive(type);
    }
    if (type === 'followers') {
      setActive(type);
      setVisible(true);
    }
    if (type === 'following') {
      setActive(type);
      setVisible(true);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setActive('post');
  };

  const handleEdit = () => {
    setModalVisible(true);
  };

  return (
    <Wrapper>
      {loading !== true ? (
        <>
          <Banner className="profile-banner" style={{backgroundImage: `url(${bgImage})`}}>
            <Container className="container">
              <AvatarCard
                avatar={avatar}
                name={`${firstName} ${lastName}`}
                username={`${email}`}
                onEdit={handleEdit}
              />
            </Container>
          </Banner>

          {/* <Navigation className="navigation">
            <Container className="container">
              <ul className="menu">
                <li
                  className={active === 'post' ? 'active' : ''}
                  onClick={() => handleMenu('post')}>
                  <strong>{data.post.length}</strong> Posts
                </li>
                <li
                  className={active === 'followers' ? 'active' : ''}
                  onClick={() => handleMenu('followers')}>
                  <strong>{data.followers.length}</strong> Followers
                </li>
                <li
                  className={active === 'following' ? 'active' : ''}
                  onClick={() => handleMenu('following')}>
                  <strong>{data.following.length}</strong> Following
                </li>
              </ul>
            </Container>
          </Navigation> */}

          <ContentWrapper>
            <Container className="container">
              <Posts avatar={data.avatar} username={data.username} data={data.post} />
              <Modal
                wrapClassName="follow-modal"
                visible={visible}
                onCancel={handleCancel}
                footer={null}>
                {active === 'followers' && <Followers data={data.followers} />}
                {active === 'following' && <Following data={data.following} />}
              </Modal>
              <EditProfileModal visible={modalVisible} setVisible={setModalVisible} user={user} />
            </Container>
          </ContentWrapper>
        </>
      ) : (
        <div
          style={{
            minHeight: '150px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Spin />
        </div>
      )}
    </Wrapper>
  );
};

export default MyProfile;
