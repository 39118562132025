import React from "react";
import { Provider } from "react-redux";
import GlobalStyles from "@iso/assets/styles/globalStyle";
import { store } from "./redux/store";
import Routes from "./router";
import AppProvider from "./AppProvider";
import SnackbarMessage from "@iso/components/snackbar/SnackbarMessage";

import "./App.css";

const App = () => {
  return (
    <Provider store={store}>
      <AppProvider>
        <>
          <GlobalStyles />
          <SnackbarMessage />
          <Routes />
        </>
      </AppProvider>
    </Provider>
  );
};

export default App;
