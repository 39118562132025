import React, {useState} from 'react';
import isEmpty from 'lodash/isEmpty';
import {useDispatch, useSelector} from 'react-redux';
import {Modal, Spin} from 'antd';
import {useNavigate} from 'react-router-dom';
import _ from 'lodash';

import NoBoardFounds from '../BoardNotFound/BoardNotFound';
import BoardListCard from './BoardListCard/BoardListCard';
import AppLayout from '../../AppLayout/AppLayout';
import {Table} from './BoardList.style';
import {deleteTGTour} from '@iso/redux/tours/actions';
function BoardLists({history, match}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedModalBoard, setSelectedModalBoard] = useState(null);
  const [search, setSearch] = useState(null);

  const showModal = (tour) => {
    setSelectedModalBoard(tour);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    dispatch(deleteTGTour(selectedModalBoard.id)).then((res) => {
      setIsModalVisible(false);
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleEdit = (tour) => {
    navigate(`/dashboard/createTicketGuideTours/${tour.id}`, {state: {tour}});
  };

  const tourList = useSelector((state) => {
    return state.Tours.ticketGuideTours;
  });

  let ticketTours = _.filter(tourList, function (o) {
    return o.type === 'ticket_guide';
  });

  if (search) {
    let searched = ticketTours.filter((i) => {
      return i.destination.toLowerCase().includes(search.toLowerCase());
    });
    ticketTours = searched;
  }

  let Loading = useSelector((state) => {
    return state.Tours.loading;
  });

  return (
    <>
      <AppLayout
        history={history}
        match={match}
        setSearchText={(value) => {
          setSearch(value);
        }}>
        {Loading ? (
          <div className="spinner">
            <Spin />
          </div>
        ) : (
          <>
            {!isEmpty(ticketTours) ? (
              <Table>
                {ticketTours.map((board) => {
                  return (
                    <BoardListCard
                      key={board.id}
                      item={board}
                      onDelete={() => {
                        showModal(board);
                      }}
                      onEdit={() => handleEdit(board)}
                      onEditSchedule={() => {
                        navigate(`/dashboard/tours-schedule/${board.id}`);
                      }}
                    />
                  );
                })}
              </Table>
            ) : (
              <NoBoardFounds history={history} match={match} />
            )}
          </>
        )}
      </AppLayout>
      <Modal title="Delete Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>Are you sure you want to delete this Tour?</p>
      </Modal>
    </>
  );
}

export default BoardLists;
