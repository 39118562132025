import React from 'react';
import {Route} from 'react-router-dom';
import CreateVehicle from '@iso/pages/transportCompanyAdmin/createVehicle/CreateVehicle';
import VehicleList from '@iso/pages/transportCompanyAdmin/vehicleList';
import ResetPassword from '@iso/pages/ResetPassword/ResetPassword';

export default function TransportCompanyAdminRoutes() {
  return (
    <>
      <Route exact path="createVehicle/:id" element={<CreateVehicle />} />
      <Route exact path="createVehicle" element={<CreateVehicle />} />
      <Route exact path="vehicle-list" element={<VehicleList />} />
      <Route path="resetPassword" element={<ResetPassword />} />
    </>
  );
}
