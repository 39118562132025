import React from 'react';

import Select from 'react-select';

const Dropdown = ({options = [], onChange = () => {}, value = '', placeholder = '', showSearch, disable=false}) => {

  return (
    <>
      <Select
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        value={value && value.label ? value : null}
        isSearchable={showSearch}
        isDisabled={disable}
      />
    </>
  );
};

export default Dropdown;
