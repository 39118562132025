export const DEFAULT_TOUR_SETTING = {
    firstName: 0,
    surName: 0,
    dob: 0,
    nationality: 0,
    documentType: 0,
    documentExpiry: 0,
    document: 0
}

export const BOOKING_CLIENT_DETAILS = {
    firstName: "",
    surName: "",
    dob: null,
    nationality: "",
    documentType: "",
    documentExpiry: null,
    document: "",
    type: null
  }