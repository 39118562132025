import * as actionTypes from '../ActionTypes';

const initialState = {
  successSnackbarMessage: null,
  snackbarMessage: null,
};

const Snackbar = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_ALL:
      return {
        ...initialState,
      };
    case actionTypes.SNACKBAR_SUCCESS:
      return {
        ...state,
        successSnackbarMessage: action.payload,
      };
    case actionTypes.SNACKBAR_ERROR:
      return {
        ...state,
        snackbarMessage: action.payload,
      };

    case actionTypes.SNACKBAR_CLEAR:
      return {
        ...state,
        snackbarMessage: null,
        successSnackbarMessage: null,
      };
    // case actionTypes.SIGNOUT_ACCOUNT:
    //   return {
    //     ...state,
    //     successSnackbarMessage: null,
    //     snackbarMessage: null,
    //   };

    default:
      return state;
  }
};

export default Snackbar;
