import { Modal } from "antd";
import { Formik } from "formik";
import React from "react";
import { cityPriceValidation } from "../../constants";
import { useDispatch } from "react-redux";
import {
  createCityPrice,
  updateCityPrice,
} from "../../redux/airportTransfer/actions";
import CityPriceForm from "../AirportTransfer/CityPriceForm";

const CityPriceModal = ({
  isModalVisible,
  cityPriceData,
  handleCancel,
  title,
  buttonTitle,
  cityId,
  services,
  disable
}) => {
  const dispatch = useDispatch();

  const initialValues = {
    price: (cityPriceData && cityPriceData.data && cityPriceData.data.price) || "",
    nightPrice: (cityPriceData && cityPriceData.data && cityPriceData.data.nightPrice) || "",
    serviceId:
      (cityPriceData &&
        cityPriceData.data &&
        cityPriceData.data.service && {
          label: `${cityPriceData.data.service.min} - ${cityPriceData.data.service.max} / ${cityPriceData.data.service.title}`,
          value: cityPriceData.data.service.id,
        }) ||
      "",
  };

  const onSubmit = (values) => {

    if (cityPriceData && cityPriceData.id) {
        const dataUpdated = {
            id: cityPriceData.id,
            price: values.price,
            nightPrice: values.nightPrice,
            serviceId: values.serviceId.value,
            cityId,
          };
        dispatch(updateCityPrice(dataUpdated))
    }
    else {
        const data = {
            price: values.price,
            nightPrice: values.nightPrice,
            serviceId: values.serviceId.value,
            cityId,
          };
          dispatch(createCityPrice(data));
    }

    handleCancel();
  };

  return (
    <Modal
      title={title}
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose={true}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={cityPriceValidation}
        onSubmit={onSubmit}
        component={(props) => (
          <CityPriceForm
            {...props}
            serviceData={cityPriceData}
            buttonTitle={buttonTitle}
            services={services}
            disable={disable}
          />
        )}
      ></Formik>
    </Modal>
  );
};

export default CityPriceModal;
