// import _ from 'lodash';

import * as T from '../ActionTypes';

const initState = {currency: null, loading: false};

export default function CommonReducer(state = initState, action) {
  switch (action.type) {
    case T.FETCH_CURRENCY: {
      return {...state, currency: action.payload, loading: false};
    }
    case T.COMMON_LOADING: {
      return {...state, loading: true};
    }
    case T.COMMON_ERROR: {
      return {...state, loading: false};
    }
    

    default:
      return state;
  }
}
