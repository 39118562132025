import * as api from '../../constants/Api';
import {API} from '../../services';
import * as T from '../ActionTypes';
import {SNACKBAR_MSG_TYPE, SNACKBAR_MSG} from '../../constants';

export const vehicleTypeList = () => {
  return (dispatch) => {
    const arr = [];
    return API.execute(api.VEHICLE_TYPE_LIST).then(
      (response) => {
        response.data.forEach((item) => {
          arr.push({value: item.id, label: item.name});
        });
        return arr;
      },
      (error) => {
        dispatch({type: T.VEHICLES_ERROR, payload: error});
        return arr;
      },
    );
  };
};

export const airportList = () => {
  return (dispatch) => {
    const arr = [];
    return API.execute(api.AIRPORTS_TYPE_LIST).then(
      (response) => {
        response.data.forEach((item) => {
          arr.push({value: item.id, label: item.name});
        });
        return arr;
      },
      (error) => {
        dispatch({type: T.VEHICLES_ERROR, payload: error});
        return arr;
      },
    );
  };
};

export const hotelList = () => {
  return (dispatch) => {
    const arr = [];
    return API.execute(api.HOTELS_TYPE_LIST).then(
      (response) => {
        response.data.forEach((item) => {
          arr.push({value: item.id, label: item.name});
        });
        return arr;
      },
      (error) => {
        dispatch({type: T.VEHICLES_ERROR, payload: error});
        return arr;
      },
    );
  };
};

export const createVehicle = (data) => {
  return (dispatch) => {
    dispatch({type: T.VEHICLE_LOADING});
    return API.execute(api.CREATE_VEHICLE, {data})
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        dispatch({type: T.CREATE_VEHICLE, payload: response.data});
        return true;
      })
      .catch((error) => {
        let {message} = error;
        if (message === 'Network Error') {
          message = SNACKBAR_MSG.networkError;
        }
        const {messageType} = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: {message, messageType},
        });
        dispatch({type: T.VEHICLES_ERROR, payload: error});
      });
  };
};

export const getVehiclesList = () => {
  return (dispatch) => {
    dispatch({type: T.VEHICLE_LOADING});
    return API.execute(api.GET_VEHICLES_LIST).then(
      (response) => {
        dispatch({type: T.GET_VEHICLES_LIST, payload: response.data});
        return response.data;
      },
      (error) => {
        dispatch({type: T.VEHICLES_ERROR, payload: error});
        return error;
      },
    );
  };
};

export const updateVehicle = (data) => {
  let newData = {...data};
  let id = newData.id;
  delete data.id;

  return (dispatch) => {
    dispatch({type: T.VEHICLE_LOADING});
    return API.execute(api.UPDATE_VEHICLE(id), {data})
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        dispatch({type: T.UPDATE_VEHICLE, payload: response.data, id});
        return true;
      })
      .catch((error) => {
        let {message} = error;
        if (message === 'Network Error') {
          message = SNACKBAR_MSG.networkError;
        }
        const {messageType} = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: {message, messageType},
        });

        dispatch({type: T.VEHICLES_ERROR, payload: error});
      });
  };
};

export const deleteVehicle = (id) => {
  return (dispatch) => {
    dispatch({type: T.VEHICLE_LOADING});
    return API.execute(api.DELETE_VEHICLE(id))
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        dispatch({type: T.DELETE_VEHICLE, id});
        return true;
      })
      .catch((error) => {
        let {message} = error;
        if (message === 'Network Error') {
          message = SNACKBAR_MSG.networkError;
        }
        const {messageType} = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: {message, messageType},
        });

        dispatch({type: T.VEHICLES_ERROR, payload: error});
      });
  };
};

export const createVehiclePrice = (data) => {
  return (dispatch) => {
    dispatch({type: T.VEHICLE_LOADING});
    return API.execute(api.CREATE_VEHICLE_PRICE, {data})
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        dispatch({type: T.CREATE_VEHICLE_PRICE, payload: response.data});
        return true;
      })
      .catch((error) => {
        let {message} = error;
        if (message === 'Network Error') {
          message = SNACKBAR_MSG.networkError;
        }
        const {messageType} = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: {message, messageType},
        });
        dispatch({type: T.VEHICLES_ERROR, payload: error});
      });
  };
};

export const getVehiclesPriceList = () => {
  return (dispatch) => {
    dispatch({type: T.VEHICLE_LOADING});
    return API.execute(api.GET_VEHICLE_PRICE_LIST).then(
      (response) => {
        dispatch({type: T.GET_VEHICLE_PRICE_LIST, payload: response.data});
        return response.data;
      },
      (error) => {
        dispatch({type: T.VEHICLES_ERROR, payload: error});
        return error;
      },
    );
  };
};

export const updateVehiclePrice = (data) => {
  let newData = {...data};
  let id = newData.id;
  delete data.id;

  return (dispatch) => {
    dispatch({type: T.VEHICLE_LOADING});
    return API.execute(api.UPDATE_VEHICLE_PRICE(id), {data})
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        dispatch({type: T.UPDATE_VEHICLE_PRICE, payload: response.data, id});
        return true;
      })
      .catch((error) => {
        let {message} = error;
        if (message === 'Network Error') {
          message = SNACKBAR_MSG.networkError;
        }
        const {messageType} = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: {message, messageType},
        });

        dispatch({type: T.VEHICLES_ERROR, payload: error});
      });
  };
};

export const deleteVehiclePrice = (id) => {
  return (dispatch) => {
    dispatch({type: T.VEHICLE_LOADING});
    return API.execute(api.DELETE_VEHICLE_PRICE(id))
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        dispatch({type: T.DELETE_VEHICLE_PRICE, id});
        return true;
      })
      .catch((error) => {
        let {message} = error;
        if (message === 'Network Error') {
          message = SNACKBAR_MSG.networkError;
        }
        const {messageType} = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: {message, messageType},
        });

        dispatch({type: T.VEHICLES_ERROR, payload: error});
      });
  };
};

export const vehiclePriceList = (hotelId, airportId) => {
  return (dispatch) => {
    dispatch({ type: T.ADMIN_LOADING });
    return API.execute(api.GET_VEHICLE_PRICE_LIST_ADMIN(hotelId, airportId)).then(
      (response) => {
        return response.data;
      },
      (error) => {
        dispatch({ type: T.ADMIN_ERROR, payload: error });
        return error;
      }
    );
  };
};

