import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import Popover from '@iso/components/uielements/popover';
import IntlMessages from '@iso/components/utility/intlMessages';
import userPic from '@iso/assets/images/user0.png';
import TopbarDropdownWrapper from './TopbarDropdown.styles';
import {logout} from '@iso/redux/auth/actions';

export default function TopbarUser() {
  const [visible, setVisibility] = React.useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  function handleVisibleChange() {
    setVisibility((visible) => !visible);
  }

  const user = useSelector((state) => {
    return state.Auth.user;
  });

  const content = (
    <TopbarDropdownWrapper className="isoUserDropdown">
      <Link className="isoDropdownLink" to={'/dashboard/my-profile'}>
        <IntlMessages id="topbar.myprofile" />
      </Link>
      <div
        className="isoDropdownLink"
        onClick={() => {
          navigate('/dashboard/resetPassword');
        }}>
        <IntlMessages id="Change Password" />
      </div>
      <div
        className="isoDropdownLink"
        onClick={() => {
          let check = dispatch(logout());
          if (check) {
            navigate('/signin');
          }
        }}>
        <IntlMessages id="topbar.logout" />
      </div>
    </TopbarDropdownWrapper>
  );

  return (
    <Popover
      content={content}
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
      arrowPointAtCenter={true}
      placement="bottomLeft">
      <div className="isoImgWrapper">
        <img
          alt="user"
          src={user.avatar ? (user.avatar.url ? user.avatar.url : userPic) : userPic}
          style={{borderRadius: '20px', height: '40px', width: '40px'}}
        />
        <span className="userActivity online" />
      </div>
    </Popover>
  );
}
