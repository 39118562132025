import * as api from '../../constants/Api';
import {API} from '../../services';
import * as T from '../ActionTypes';
import {SNACKBAR_MSG_TYPE, SNACKBAR_MSG} from '../../constants';

export const createReceptionist = (data) => {
  let newData = {...data};
  let id = newData.hotelId.value;
  delete data.hotelId;

  return (dispatch) => {
    dispatch({type: T.RECEPTIONISTS_LOADING});
    return API.execute(api.CREATE_RECEPTIONIST(id), {data})
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        // dispatch({type: T.CREATE_RECEPTIONIST, payload: response.data});
        dispatch(getReceptionistsList());
        return true;
      })
      .catch((error) => {
        let {message} = error;
        if (message === 'Network Error') {
          message = SNACKBAR_MSG.networkError;
        }
        const {messageType} = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: {message, messageType},
        });
        dispatch({type: T.RECEPTIONISTS_ERROR, payload: error});
      });
  };
};

export const getReceptionistsList = () => {
  return (dispatch) => {
    dispatch({type: T.RECEPTIONISTS_LOADING});
    return API.execute(api.GET_RECEPTIONISTS_LIST).then(
      (response) => {
        dispatch({type: T.GET_RECEPTIONISTS_LIST, payload: response.data});
        return response.data;
      },
      (error) => {
        dispatch({type: T.RECEPTIONISTS_ERROR, payload: error});
        return error;
      },
    );
  };
};

export const activateReceptionist = (id) => {
  return (dispatch) => {
    // dispatch({type: T.RECEPTIONISTS_LOADING});
    return API.execute(api.ACTIVATE_RECEPTIONIST(id))
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        dispatch({type: T.ACTIVATE_RECEPTIONIST, id});
        return true;
      })
      .catch((error) => {
        let {message} = error;
        if (message === 'Network Error') {
          message = SNACKBAR_MSG.networkError;
        }
        const {messageType} = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: {message, messageType},
        });
        dispatch({type: T.RECEPTIONISTS_ERROR, payload: error});
      });
  };
};

export const deactivateReceptionist = (id) => {
  return (dispatch) => {
    // dispatch({type: T.RECEPTIONISTS_LOADING});
    return API.execute(api.DEACTIVATE_RECEPTIONIST(id))
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        dispatch({type: T.DEACTIVATE_RECEPTIONIST, id});
        return true;
      })
      .catch((error) => {
        let {message} = error;
        if (message === 'Network Error') {
          message = SNACKBAR_MSG.networkError;
        }
        const {messageType} = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: {message, messageType},
        });
        dispatch({type: T.RECEPTIONISTS_ERROR, payload: error});
      });
  };
};

export const updateReceptionist = (data) => {
  let newData = {...data};
  let id = newData.id;
  delete data.id;

  return (dispatch) => {
    dispatch({type: T.RECEPTIONISTS_LOADING});
    return API.execute(api.UPDATE_RECEPTIONIST(id), {data})
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        dispatch(getReceptionistsList);
        return true;
      })
      .catch((error) => {
        let {message} = error;
        if (message === 'Network Error') {
          message = SNACKBAR_MSG.networkError;
        }
        const {messageType} = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: {message, messageType},
        });
        dispatch({type: T.RECEPTIONISTS_ERROR, payload: error});
      });
  };
};
