import React from "react";
import { connect } from "react-redux";
import { Layout } from "antd";

import { Scrollbars } from "react-custom-scrollbars";



const { Content } = Layout;

const AppLayout = ({
  children,
}) => (
  <Layout style={{ backgroundColor: "#fff" }}>


    <Content
      style={{
        padding: "0 24px",
      }}
    >
      <Scrollbars style={{ width: "100%", height: "calc(100vh - 201px)" }}>
        {children}
      </Scrollbars>
    </Content>
  </Layout>
);

export default connect(null, {})(AppLayout);
