import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import basicStyle from "@iso/assets/styles/constants";
import { Table } from "antd";
import {
  getSAdminHotelListCount,
  getSAdminTourBookingListCount,
  getSAdminAirportTransferBookingListCount
} from "@iso/redux/dashboard/actions";

const styles = {
  wisgetPageStyle: {
    display: "flex",
    flexFlow: "row wrap",
    alignItems: "flex-start",
    overflow: "hidden",
    margin: "10px 0",
  },
};
const Dashboard = () => {
  const { rowStyle, colStyle } = basicStyle;
  const dispatch = useDispatch();

  const [tourBookingCount, setTourBookingCount] = useState(null);
  const [hotelListCount, setHotelListCount] = useState(null);
  const [AirportBookingCount, setAirportBookingCount] = useState(null);

  useEffect(() => {
    dispatch(getSAdminHotelListCount()).then((res) => {
      setHotelListCount(res);
    });
    dispatch(getSAdminTourBookingListCount()).then((res) => {
      setTourBookingCount(res);
    });
    dispatch(getSAdminAirportTransferBookingListCount()).then((res) => {
      setAirportBookingCount(res);
    });

  }, []);

  // console.log('tableInfo', hotelBookingListCount)

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Hotels",
      dataIndex: "hotelsCount",
    },
  ];

  const columns2 = [
    {
      title: "First Name",
      dataIndex: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Bookings",
      dataIndex: "booking",
    },
  ];

  const columns3 = [
    {
      title: "First Name",
      dataIndex: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Bookings",
      dataIndex: "airportTransferCount",
    },
  ];
  return (
    <div style={styles.wisgetPageStyle}>
      <div style={{ margin: "5px 15px" }}>
        <div>
          <h2
            style={{
              fontSize: "21px",
              fontFamily: "Roboto",
              color: "#323332",
              fontWeight: "400",
            }}
          >
            Hotels List
          </h2>
        </div>

        <Table
          columns={columns}
          dataSource={
            hotelListCount &&
            hotelListCount.length > 0 &&
            hotelListCount
          }
          bordered
        />
      </div>
      <div style={{ margin: "5px 15px" }}>
        <div>
          <h2
            style={{
              fontSize: "21px",
              fontFamily: "Roboto",
              color: "#323332",
              fontWeight: "400",
            }}
          >
            Tour Bookings
          </h2>
        </div>

        <Table
          columns={columns2}
          dataSource={
            tourBookingCount &&
            tourBookingCount.length > 0 &&
            tourBookingCount
          }
          bordered
        />
      </div>
      <div style={{ margin: "5px 15px" }}>
        <div>
          <h2
            style={{
              fontSize: "21px",
              fontFamily: "Roboto",
              color: "#323332",
              fontWeight: "400",
            }}
          >
            Airport Transfer Bookings
          </h2>
        </div>

        <Table
          columns={columns3}
          dataSource={
            AirportBookingCount &&
            AirportBookingCount.length > 0 &&
            AirportBookingCount
          }
          bordered
        />
      </div>
    </div>
  );
};

export default Dashboard;
