import React from "react";
import { ContactCardWrapper } from "./ContactCard.style";
import { PAYMENT_METHODS } from "../../../../constants";
import { Button } from "antd";
import * as html2pdf from "html2pdf.js";
import Report from "./Report";
import MapView from "../../../../services/MapView";

const Invoice = React.forwardRef(({ currentAirportTranfer }, ref) => {
  const [loading, setLoading] = React.useState(false)
  const [d, setd] = React.useState('none')
  const handleReport = (id) => {
    setLoading(true)
    var p2 = new Promise(function (resolve, reject) {
      setd('inline-block')
      var element = document.getElementById("element-to-print");

      html2pdf().from(element).save();
      resolve(false);
    });

    p2.then(function (value) {
      setd('none')
      setLoading(value)
      return value;
    }).then(function (value) {
      setd('none')
      setLoading(value)
      window.close();
    });
  };

  return (
    <>
      <div className="PageContent" style={{ padding: "20" }} ref={ref}>
        <div className="OrderInfo">
          <div className="LeftSideContent">
            <h3 className="Title">Airport Transfers</h3>
          </div>
          <div className="RightSideContent">
            <Button
              onClick={() => handleReport(currentAirportTranfer.id)}
              type="primary"
              className="mateInvoPrint"
              disabled={loading}
            >
              <span>Report</span>
            </Button>
          </div>
        </div>
        <div className="BillingInformation">
          <div className="LeftSideContent">
            <h3 className="Title">Details</h3>
            <ContactCardWrapper
              className="isoContactCard"
              style={{ padding: "0" }}
            >
              <div className="isoContactInfoWrapper">
                <div
                  className="isoContactCardInfos"
                  key={currentAirportTranfer.clientName}
                >
                  <p className="isoInfoLabel"> Name </p>
                  <p className="isoInfoDetails">
                    {currentAirportTranfer.clientName}
                  </p>
                </div>
                <div
                  className="isoContactCardInfos"
                  key={currentAirportTranfer.clientEmail}
                >
                  <p className="isoInfoLabel"> Email </p>
                  <p className="isoInfoDetails">
                    {currentAirportTranfer.clientEmail}
                  </p>
                </div>
                <div
                  className="isoContactCardInfos"
                  key={currentAirportTranfer.date}
                >
                  <p className="isoInfoLabel"> Date </p>
                  <p className="isoInfoDetails">{currentAirportTranfer.date}</p>
                </div>
                <div
                  className="isoContactCardInfos"
                  key={currentAirportTranfer.paymentMethod}
                >
                  <p className="isoInfoLabel"> Payment Method </p>
                  <p className="isoInfoDetails">
                    {(currentAirportTranfer.paymentMethod ===
                      PAYMENT_METHODS.cash &&
                      "Cash") ||
                      (currentAirportTranfer.paymentMethod ===
                        PAYMENT_METHODS.card &&
                        "Card")}
                  </p>
                </div>
                <div
                  className="isoContactCardInfos"
                  key={currentAirportTranfer.hotel.displayName}
                >
                  <p className="isoInfoLabel"> Hotel </p>
                  <p className="isoInfoDetails">
                    {currentAirportTranfer.hotel.displayName}
                  </p>
                </div>
                <div
                  className="isoContactCardInfos"
                  key={currentAirportTranfer.airport.name}
                >
                  <p className="isoInfoLabel"> Airport </p>
                  <p className="isoInfoDetails">
                    {currentAirportTranfer.airport.name}
                  </p>
                </div>
                <div
                  className="isoContactCardInfos"
                  key={currentAirportTranfer.pickUpTime}
                >
                  <p className="isoInfoLabel"> PickUp Time </p>
                  <p className="isoInfoDetails">
                    {currentAirportTranfer.pickUpTime}
                  </p>
                </div>
                <div
                  className="isoContactCardInfos"
                  key={currentAirportTranfer.farePrice}
                >
                  <p className="isoInfoLabel"> Price </p>
                  <p className="isoInfoDetails">
                    {currentAirportTranfer.farePrice}
                  </p>
                </div>
                <div
                  className="isoContactCardInfos"
                  key={currentAirportTranfer.extraCharges}
                >
                  <p className="isoInfoLabel"> Extra Charges </p>
                  <p className="isoInfoDetails">
                    {currentAirportTranfer.extraCharges}
                  </p>
                </div>
                <div
                  className="isoContactCardInfos"
                  key={currentAirportTranfer.extraCharges}
                ></div>
              </div>
            </ContactCardWrapper>
          </div>
        </div>
        <div>
          <div className="mapWrapper">
            <MapView
              getLocation={(loc) => {
                // console.log("loc", loc);
              }}
              setLocation={[
                {
                  lat: currentAirportTranfer.hotel.latitude,
                  lng: currentAirportTranfer.hotel.longitude,
                  name: currentAirportTranfer.hotel.displayName,
                  address: currentAirportTranfer.hotel.displayName,
                },
                {
                  lat: currentAirportTranfer.airport.locationLat,
                  lng: currentAirportTranfer.airport.locationLon,
                  name: currentAirportTranfer.airport.name,
                  address: currentAirportTranfer.airport.name,
                },
              ]}
              getQuery={() => {}}
            />
          </div>
        </div>
      </div>
      
      <div id="element-to-print" style={{display: d}}>
        <Report data={currentAirportTranfer} />
      </div>
    </>
  );
});
export default Invoice;
