import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import BoardLists from "./Board/BoardList/BoardList";
import { getCityPriceList } from "@iso/redux/airportTransfer/actions";

export default function ToursList() {
  const dispatch = useDispatch();
  const cityPriceList = useSelector((state) => {
    return state.AirportTransfer.createCityPrice;
  });
  useEffect(() => {
    dispatch(getCityPriceList());
  }, []);

  return (
    <>
      <BoardLists />
    </>
  );
}
