import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {Formik} from 'formik';
import {Modal} from 'antd';
import TimeKeeper from 'react-timekeeper';
// import _ from 'lodash';

import Dropdown from '@iso/components/uielements/CustomDropdown';
import Radio, { RadioGroup } from "@iso/components/uielements/radio";
import Input from '@iso/components/uielements/input';
import Button from '@iso/components/uielements/button';
import DatePicker from '@iso/components/uielements/datePicker';
import {transportVehicleValidation} from '@iso/constants';
import SignUpStyleWrapper from './BookVehicleTransport.styles';
import {
  getAirportList,
  getVehicleList,
  bookTourTransport,
} from '../../../redux/receptionist/actions';
import basicStyle from "@iso/assets/styles/constants";
import { Col, Row } from "antd";

export default function CreateTours() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const params = useLocation();
  // const locParams = params && params.state ? params.state : {tour: null};

  const [airportList, setAirportList] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [pickUpTime, setPickUpTime] = useState(null);
  const [visible, setVisible] = useState(false);
  const [checkNoOfPersons, setCheckNoOfPersons] = useState(false);
  const [disable, setDisable] = useState(false);
  const [priceSelected, setPriceSelected] = useState(null);

  useEffect(() => {
    dispatch(getAirportList()).then((res) => {
      setAirportList(res);
    });
  }, []);

  const handleSubmit = (values) => {
    setDisable(true);
    if (values.vehicleId.label === 'Per Person') {
      if (values.noOfPersons) {
        let data = {
          clientName: values.clientName,
          clientPhone: values.clientPhone,
          clientEmail: values.clientEmail,
          date: values.date,
          pickUpTime: values.pickUpTime,
          extraCharges: values.extraCharges,
          paymentMethod: values.paymentMethod,
          vehicleId: values.vehicleId.value,
          airportId: values.airportId.value,
          direction: values.direction.value,
          noOfPersons: values.noOfPersons,
        };
        dispatch(bookTourTransport(data)).then((res) => {
          setDisable(false);
          navigate('/dashboard/tours-transport-list');
        });
      } else {
        setCheckNoOfPersons(true);
      }
    } else {
      let data = {
        clientName: values.clientName,
        clientPhone: values.clientPhone,
        clientEmail: values.clientEmail,
        date: values.date,
        pickUpTime: values.pickUpTime,
        extraCharges: values.extraCharges,
        paymentMethod: values.paymentMethod,
        vehicleId: values.vehicleId.value,
        airportId: values.airportId.value,
        direction: values.direction.value,
      };
      dispatch(bookTourTransport(data)).then((res) => {
        setDisable(false);
        navigate('/dashboard/tours-transport-list');
      });
    }
  };

  let createFormValues = {
    clientName: '',
    clientPhone: '',
    clientEmail: '',
    date: '',
    extraCharges: '',
    paymentMethod: 1,
    pickUpTime: '',
    vehicleId: {},
    airportId: {},
    direction: {},
    noOfPersons: '',
  };

  let dropdownData = [
    {value: 1, label: 'Hotel to Airport'},
    {value: 2, label: ' Airport to Hotel'},
  ];

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };
  const { rowStyle, colStyle, gutter } = basicStyle;

  return (
    <SignUpStyleWrapper className="isoSignUpPage">
      <div className="isoSignUpContentWrapper">
        <div className="isoSignUpContent">
          <div className="isoLogoWrapper">
            <Link to="/dashboard">Book Transport Vehicle</Link>
          </div>

          <Formik
            initialValues={createFormValues}
            onSubmit={handleSubmit}
            validationSchema={transportVehicleValidation}
            enableReinitialize>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              /* and other goodies */
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="isoSignUpForm">
                    <div className="isoInputWrapper">
                      <Dropdown
                        options={airportList}
                        onChange={(value) => {
                          setFieldValue('airportId', value);
                          setFieldValue('vehicleId', {});
                          dispatch(getVehicleList(value.value)).then((res) => {
                            setVehicleList(res);
                          });
                        }}
                        value={values.airportId ? values.airportId : null}
                        placeholder="Select a airport"
                      />
                      {errors.airportId && touched.airportId && (
                        <div className="invalid-input">{errors.airportId.value}</div>
                      )}
                    </div>

                    <div className="isoInputWrapper">
                      <Dropdown
                        options={vehicleList}
                        onChange={(value) => {
                          setPriceSelected(value);
                          setFieldValue('vehicleId', value);
                        }}
                        value={values.vehicleId ? values.vehicleId : null}
                        placeholder="Select vehicle price type"
                      />
                      {errors.vehicleId && touched.vehicleId && (
                        <div className="invalid-input">{errors.vehicleId.value}</div>
                      )}
                    </div>

                    <div className="isoInputWrapper">
                      <Input
                        size="large"
                        placeholder="Client Name"
                        type="text"
                        name="clientName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.clientName}
                      />
                      {errors.clientName && touched.clientName && (
                        <div className="invalid-input">{errors.clientName}</div>
                      )}
                    </div>

                    <div className="isoInputWrapper">
                      <Input
                        size="large"
                        placeholder="Client Phone"
                        type="number"
                        name="clientPhone"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.clientPhone}
                      />
                      {errors.clientPhone && touched.clientPhone && (
                        <div className="invalid-input">{errors.clientPhone}</div>
                      )}
                    </div>

                    <div className="isoInputWrapper">
                      <Input
                        size="large"
                        placeholder="Client Email"
                        type="text"
                        name="clientEmail"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.clientEmail}
                      />
                      {errors.clientEmail && touched.clientEmail && (
                        <div className="invalid-input">{errors.clientEmail}</div>
                      )}
                    </div>

                    <div className="isoInputWrapper">
                      <DatePicker
                        placeholder="Date"
                        name="date"
                        onChange={(date, dateString) => {
                          setFieldValue('date', dateString);
                        }}
                      />
                      {errors.date && touched.date && (
                        <div className="invalid-input">{errors.date}</div>
                      )}
                    </div>

                    <div
                      onClick={() => {
                        setVisible(true);
                      }}
                      className="isoInputWrapper">
                      <Input
                        size="large"
                        placeholder="Pick Up Time"
                        type="text"
                        name="pickUpTime"
                        // onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.pickUpTime}
                      />
                      {errors.pickUpTime && touched.pickUpTime && (
                        <div className="invalid-input">{errors.pickUpTime}</div>
                      )}
                    </div>

                    <div className="isoInputWrapper">
                      <Input
                        size="large"
                        placeholder="Extra Charges"
                        type="text"
                        name="extraCharges"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.extraCharges}
                      />
                      {errors.extraCharges && touched.extraCharges && (
                        <div className="invalid-input">{errors.extraCharges}</div>
                      )}
                    </div>
                    <div className="isoInputWrapper">
                    <Row style={rowStyle} gutter={gutter} justify="start">
                      <Col md={12} sm={12} xs={24} style={colStyle}>
                        <div>
                          <p style={{ fontWeight: "bold" }}>Payment Method</p>
                        </div>
                        <RadioGroup
                          onChange={handleChange}
                          name="paymentMethod"
                          value={values.paymentMethod}
                        >
                          <Radio style={radioStyle} value={1}>
                            Cash
                          </Radio>
                          <Radio style={radioStyle} value={2}>
                            Credit or debit card
                          </Radio>
                        </RadioGroup>
                      </Col>
                    </Row>
                    {errors.paymentMethod && touched.paymentMethod && (
                      <div className="invalid-input">
                        {errors.paymentMethod}
                      </div>
                    )}
                  </div>
                    {values.vehicleId.label === 'Per Person' ? (
                      <div className="isoInputWrapper">
                        <Input
                          size="large"
                          placeholder="No Of Persons"
                          type="number"
                          name="noOfPersons"
                          onChange={(e) => {
                            setCheckNoOfPersons(false);
                            setFieldValue('noOfPersons', e.target.value);
                          }}
                          onBlur={handleBlur}
                          value={values.noOfPersons}
                        />
                        {checkNoOfPersons && values.noOfPersons === '' && (
                          <div className="invalid-input">{'Required'}</div>
                        )}
                      </div>
                    ) : null}

                    <div className="isoInputWrapper">
                      <Dropdown
                        options={dropdownData}
                        onChange={(value) => {
                          setFieldValue('direction', value);
                        }}
                        value={values.direction ? values.direction : null}
                        placeholder="Select a direction"
                      />
                      {errors.direction && touched.direction && (
                        <div className="invalid-input">{errors.direction.value}</div>
                      )}
                    </div>

                    {priceSelected ? (
                      <div className="isoInputWrapper">
                        <div>
                          <p>{`Total Price: ${
                            values.noOfPersons && values.noOfPersons !== ''
                              ? parseInt(priceSelected.farePrice) * parseInt(values.noOfPersons) +
                                parseFloat(values.extraCharges ? values.extraCharges : '0')
                              : parseInt(priceSelected.farePrice) +
                                parseFloat(values.extraCharges ? values.extraCharges : '0')
                          } ${priceSelected.currency}`}</p>
                        </div>
                      </div>
                    ) : null}

                    <div className="isoInputWrapper">
                      <Button type="primary" onClick={handleSubmit} disabled={disable}>
                        Book
                      </Button>
                    </div>
                    <Modal
                      title="Select your Time"
                      centered
                      visible={visible}
                      onCancel={() => setVisible(false)}
                      footer={null}
                      width={300}
                      bodyStyle={{height: 500}}>
                      <TimeKeeper
                        time={pickUpTime}
                        onDoneClick={(value) => {
                          setPickUpTime(value.formatted24);
                          setFieldValue('pickUpTime', value.formatted24);
                          setVisible(false);
                        }}
                      />
                    </Modal>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </SignUpStyleWrapper>
  );
}
