import React from 'react';
import {Layout, Button} from 'antd';
import {useNavigate} from 'react-router';

import SearchInput from '@iso/components/ScrumBoard/SearchInput/SearchInput';
import {Title, Header, HeaderSecondary} from './AppLayout.style';
import { TOUR_TYPES, TOUR_NAMES } from '../../../../constants';

const {Content} = Layout;

const AppLayout = ({children, setSearchText, history, match}) => {
  const navigate = useNavigate();
  return (
    <Layout style={{backgroundColor: '#fff'}}>
      <Header>
      <Title>{TOUR_NAMES[TOUR_TYPES.monthly_excursion].name}</Title>

        <Button
          type="primary"
          onClick={() => {
            navigate('/dashboard/create-monthly-excursion', {state: {selectedType: 'public'}});
          }}>
          Add new
        </Button>
      </Header>
      <HeaderSecondary>
        <SearchInput
          onChange={(value) => setSearchText(value)}
          onCross={(value) => {
            setSearchText(value);
          }}
        />
      </HeaderSecondary>

      <Content
        style={{
          padding: '0 24px',
        }}>
        {children}
      </Content>
    </Layout>
  );
};

export default AppLayout;
