import React from "react";
import {
  GoogleMap,
  Marker,
  Autocomplete,
  Circle,
} from "@react-google-maps/api";

let coords = [];

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.autocomplete = null;
    this.onLoad = this.onLoad.bind(this);
    this.onPlaceChanged = this.onPlaceChanged.bind(this);
    this.circle = React.createRef();
    this.state = {
      center:
        props.setLocation && !Array.isArray(props.setLocation)
          ? { ...props.setLocation }
          : props.setLocation,
      coordsResult: [],
      search: "",
      radius: 100,
      map: {},
      place_id: null,
      mapObj: null,
      zoom: 13,
    };
  }

  onLoad(autocomplete) {
    this.autocomplete = autocomplete;
  }

  onPlaceChanged() {
    if (this.autocomplete !== null) {
      this.setState({
        search: this.autocomplete.getPlace().formatted_address,
        place_id: this.autocomplete.getPlace().place_id,
      });
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  }

  placeMap = (id, map) => {
    const requestPlace = {
      placeId: id,
      fields: ["name", "formatted_address", "place_id", "geometry", "photos"],
    };
    const service = new window.google.maps.places.PlacesService(map);
    try {
      service.getDetails(requestPlace, async (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          this.props.callBackMapImage(
            place &&
              place.photos &&
              place.photos[0] &&
              place.photos[0].getUrl(),
            place.formatted_address
          );
        }
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  geoCodeLocation = (latlng, map) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder
      .geocode({ location: latlng })
      .then((response) => {
        if (response) {
          this.setState({
            place_id: response.results[1].place_id,
          });
          this.placeMap(response.results[1].place_id, map);
        } else {
          console.log("No results found");
        }
      })
      .catch((e) => console.log("Geocoder failed due to: " + e));
  };

  onMapLoad = (map) => {
    let request = {
      query: this.state.search,
      fields: ["name", "geometry"],
    };

    const service = new window.google.maps.places.PlacesService(map);

    if (
      this.state.search === "" &&
      !Array.isArray(this.props.setLocation) &&
      this.props.setLocation
    ) {
      this.props.getLocation({
        lat: (this.props.setLocation && this.props.setLocation.lat) || null,
        lng: (this.props.setLocation && this.props.setLocation.lng) || null,
        name: "location",
        address: "address",
      });
      this.setState({
        center: {
          lat: (this.props.setLocation && this.props.setLocation.lat) || null,
          lng: (this.props.setLocation && this.props.setLocation.lng) || null,
        },
        coordsResult: [],
      });
      this.geoCodeLocation(
        {
          lat: (this.props.setLocation && this.props.setLocation.lat) || null,
          lng: (this.props.setLocation && this.props.setLocation.lng) || null,
        },
        map
      );
    } else if (
      this.state.search === "" &&
      navigator &&
      navigator.geolocation &&
      !Array.isArray(this.props.setLocation)
    ) {
      navigator.geolocation.getCurrentPosition((pos) => {
        const coord = pos.coords;
        this.props.getLocation({
          lat: coord.latitude,
          lng: coord.longitude,
          name: "location",
          address: "address",
        });
        this.setState({
          center: {
            lat: parseFloat(coord.latitude),
            lng: parseFloat(coord.longitude),
          },
          coordsResult: [],
        });
        this.geoCodeLocation(
          {
            lat: parseFloat(coord.latitude),
            lng: parseFloat(coord.longitude),
          },
          map
        );
      });
    } else {
      coords = [];
      this.placeMap(this.state ? this.state.place_id : "", map);

      service.findPlaceFromQuery(request, (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          for (var i = 0; i < results.length; i++) {
            coords.push(results[i]);
          }
          this.props.getLocation({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
            name: results[0].name,
            address: this.state.search,
          });
          this.setState({
            center: {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            },
            coordsResult: coords,
          });
        }
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.state.search !== "") {
      this.onMapLoad(this.state.map);
      this.props.getQuery(this.state.search);
      this.setState({
        search: "",
      });
    }

    else if (prevProps.setLocation !== this.props.setLocation) {
    //   console.log('prevProps.setLocation', prevProps.setLocation)
    //   console.log('this.props.setLocation', this.props.setLocation)
      this.setState({
        center:
          this.props.setLocation && !Array.isArray(this.props.setLocation)
            ? { ...this.props.setLocation }
            : this.props.setLocation,
      });
    }
  }

  render() {
    // console.log('this.state.center', this.state.center);
    // console.log('this.props.setLocation', this.props.setLocation)

    return (
      <div id="mapG">
        <GoogleMap
          center={this.state.center}
          zoom={this.state.zoom}
          onZoomChanged={() => {
            // console.log("mapObj:", this.state.mapObj ? this.state.mapObj.getZoom() : this.state.zoom);
            this.setState({
              zoom: this.state.mapObj
                ? this.state.mapObj.getZoom()
                : this.state.zoom,
            });
            // eslint-disable-next-line no-unused-expressions
            (this.props &&
              this.props.setZoom &&
              this.props.setZoom(
                this.state.mapObj
                  ? this.state.mapObj.getZoom()
                  : this.state.zoom
              )) ||
              null;
          }}
          onLoad={(map) => {
            this.setState({ mapObj: map });
            // this.setState({ zoom: this.state.mapObj });
            // eslint-disable-next-line no-unused-expressions
            (this.props &&
              this.props.setZoom &&
              this.props.setZoom(this.state.zoom)) ||
              null;
            this.setState({ map });
            this.onMapLoad(map, this.props.setLocation);
          }}
          mapContainerStyle={{ height: "400px", width: "100%" }}
        >
          <Autocomplete
            onLoad={this.onLoad}
            onPlaceChanged={this.onPlaceChanged}
          >
            <input
              type="text"
              placeholder="Search Location"
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `240px`,
                height: `32px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: "absolute",
                left: "50%",
                marginLeft: "-120px",
                top: "2%",
              }}
            />
          </Autocomplete>
          {this.state.coordsResult.length !== 0 ? (
            this.state.coordsResult.map(function (results, i) {
              return (
                <div key={i}>
                  <Marker
                    position={{
                      lat: results.geometry.location.lat(),
                      lng: results.geometry.location.lng(),
                    }}
                    title={results.name}
                    draggable={true}
                    onDragEnd={(val) => {
                      this.props.getLocation({
                        lat: val.latLng.lat(),
                        lng: val.latLng.lng(),
                        name: "location",
                        address: "address",
                      });
                      this.setState({
                        center: {
                          lat: val.latLng.lat(),
                          lng: val.latLng.lng(),
                        },
                        search: "",
                        coordsResult: [],
                      });
                      this.geoCodeLocation(
                        {
                          lat: val.latLng.lat(),
                          lng: val.latLng.lng(),
                        },
                        this.state.map
                      );
                      coords = [];
                    }}
                  />
                  <Circle
                    options={{
                      strokeColor: "#FF0000",
                      strokeOpacity: 0.8,
                      strokeWeight: 1,
                      fillOpacity: 0.35,
                      clickable: false,
                      draggable: false,
                      editable: true,
                      visible: true,
                      radius: console.log(this), //Calculation in Meter]
                    }}
                    center={{
                      lat: results.geometry.location.lat(),
                      lng: results.geometry.location.lng(),
                    }}
                  />
                </div>
              );
            })
          ) : (
            <>
              {this.state.center && this.state.center.lat ? (
                <>
                  <Marker
                    position={{ ...this.state.center }}
                    title={"Your Location"}
                    draggable={true}
                    onDragEnd={(val) => {
                      this.props.getLocation({
                        lat: val.latLng.lat(),
                        lng: val.latLng.lng(),
                        name: "location",
                        address: "address",
                      });
                      this.setState({
                        center: {
                          lat: val.latLng.lat(),
                          lng: val.latLng.lng(),
                        },
                        search: "",
                        coordsResult: [],
                      });
                      this.geoCodeLocation(
                        {
                          lat: val.latLng.lat(),
                          lng: val.latLng.lng(),
                        },
                        this.state.map
                      );
                      coords = [];
                    }}
                  />
                  <Circle
                    ref={this.circle}
                    options={{
                      strokeColor: "#FF0000",
                      strokeOpacity: 0.8,
                      strokeWeight: 1,
                      fillOpacity: 0.35,
                      clickable: false,
                      draggable: false,
                      // editable: true,
                      visible: true,
                      radius: this && this.props.radius, //Calculation in Meter]
                    }}
                    center={{
                      lat: this.state.center.lat,
                      lng: this.state.center.lng,
                    }}
                  />
                </>
              ) : null}
            </>
          )}
        </GoogleMap>
      </div>
    );
  }
}

export default Map;
