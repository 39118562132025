import { Button, Table } from "antd";
import React from "react";
import moment from "moment";
import Map from "../../../../services/MapView";
import { ContactCardWrapper } from "./ContactCard.style";
import { useDispatch, useSelector } from "react-redux";
import { generateTourAdminReceipt } from "@iso/redux/tours/actions";
import { Spin } from "antd";
import _ from "lodash";
import { LANGUAGES } from "../../../../constants";

const Invoice = React.forwardRef(({ currentTour }, ref) => {
  const dispatch = useDispatch();

  // const [loading, setLoading] = React.useState(false);
  // const [lat, setLat] = React.useState(null);

  // const [d, setd] = React.useState("none");
  // const [qrCode, setQR] = React.useState(null);
  // const handleReport = async (id) => {
  //   setLoading(true);
  //   setLat(currentTour.pickupLatitude)
  //   const qr = await QRCode.toDataURL(
  //     `https://maps.google.com/?q=${currentTour.pickupLatitude},${currentTour.pickupLongitude}`
  //   );
  //   setQR(qr);
  //   var p2 = new Promise(function (resolve, reject) {
  //     setd("inline-block");
  //     var element = document.getElementById("element-print");

  //     html2pdf().from(element).save();
  //     resolve(false);
  //   });

  //   p2.then(function (value) {
  //     setd("none");
  //     setLoading(value);
  //     return value;
  //   }).then(function (value) {
  //     setd("none");
  //     setLoading(value);
  //     window.close();
  //   });
  // };

  const handleReceipt = (tour) => {
    dispatch(generateTourAdminReceipt(tour));
  };

  let Loading = useSelector((state) => {
    return state.Tours.loading;
  });

  const adults = (currentTour && currentTour.numOfAdults) || 0;
  const babies = (currentTour && currentTour.numOfBabies) || 0;
  const children = (currentTour && currentTour.numOfChildren) || 0;
  const people = adults + babies + children;

  console.log('currentTour', currentTour)

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      width: "20%",
    },
    {
      title: "SurName",
      dataIndex: "surName",
      key: "surName",
      width: "20%",
    },
    {
      title: "DOB",
      dataIndex: "dob",
      key: "dob",
      width: "15%",
      render: (text) => (
        <p> {(text && moment(text).format("DD/MM/yyyy")) || "N/A"}</p>
      ),
    },
    {
      title: "Nationality",
      dataIndex: "nationality",
      key: "nationality",
      width: "10%",
    },
    {
      title: "Document Type",
      dataIndex: "documentType",
      key: "documentType",
      width: "15%",
    },
    {
      title: "Document Expiry",
      dataIndex: "documentExpiry",
      key: "documentExpiry",
      width: "15%",
      render: (text) => (
        <p> {(text && moment(text).format("DD/MM/yyyy")) || "N/A"}</p>
      ),
    },
    {
      title: "Document",
      dataIndex: "document",
      key: "document",
      width: "15%",
    },
  ];

  return (
    <>
      <div className="PageContent" style={{ padding: "20" }} ref={ref}>
        <div className="OrderInfo">
          <div className="LeftSideContent">
            <h3 className="Title">Tour Booking</h3>
            <span className="InvoiceNumber">
              {currentTour && currentTour.tour && currentTour.tour.destination}
            </span>
          </div>
          <div className="RightSideContent">
            <Button
              onClick={() => handleReceipt(currentTour.id)}
              type="primary"
              className="mateInvoPrint"
            >
              {Loading ? <Spin /> : <span>Invoice</span>}
            </Button>
          </div>
        </div>
        <div className="BillingInformation">
          <div className="LeftSideContent">
            <h3 className="Title">Details</h3>
            <ContactCardWrapper
              className="isoContactCard"
              style={{ padding: "0" }}
            >
              <div className="isoContactInfoWrapper">
                <div
                  className="isoContactCardInfos"
                  key={currentTour.clientName}
                >
                  <p className="isoInfoLabel"> Name </p>
                  <p className="isoInfoDetails">{currentTour.clientName}</p>
                </div>
                <div
                  className="isoContactCardInfos"
                  key={currentTour.clientEmail}
                >
                  <p className="isoInfoLabel"> Email </p>
                  <p className="isoInfoDetails">{currentTour.clientEmail}</p>
                </div>
                <div
                  className="isoContactCardInfos"
                  key={currentTour.clientPhone}
                >
                  <p className="isoInfoLabel"> Phone Number </p>
                  <p className="isoInfoDetails">{currentTour.clientPhone}</p>
                </div>
                <div
                  className="isoContactCardInfos"
                  key={currentTour.receptionist}
                >
                  <p className="isoInfoLabel"> Receptionist Name </p>
                  <p className="isoInfoDetails">{currentTour.receptionist}</p>
                </div>
                <div className="isoContactCardInfos" key={currentTour.date}>
                  <p className="isoInfoLabel"> Date </p>
                  <p className="isoInfoDetails">
                    {moment(currentTour.date).format("DD/MM/yyyy")}
                  </p>
                </div>
                <div
                  className="isoContactCardInfos"
                  key={currentTour.paymentMethod}
                >
                  <p className="isoInfoLabel"> Payment Method </p>
                  <p className="isoInfoDetails">{currentTour.paymentMethod}</p>
                </div>
                <div
                  className="isoContactCardInfos"
                  key={currentTour.pickUpTime}
                >
                  <p className="isoInfoLabel"> pickUp Time </p>
                  <p className="isoInfoDetails">{currentTour.pickUpTime}</p>
                </div>
                <div className="isoContactCardInfos" key={currentTour.price}>
                  <p className="isoInfoLabel"> Price </p>
                  <p className="isoInfoDetails">{currentTour.price}</p>
                </div>
                <div
                  className="isoContactCardInfos"
                  key={currentTour.extraCharges}
                >
                  <p className="isoInfoLabel"> Extra Charges </p>
                  <p className="isoInfoDetails">{currentTour.extraCharges}</p>
                </div>
                <div className="isoContactCardInfos" key={currentTour.remarks}>
                  <p className="isoInfoLabel"> Remarks </p>
                  <p className="isoInfoDetails">{currentTour.remarks}</p>
                </div>
                <div className="isoContactCardInfos" key={people}>
                  <p className="isoInfoLabel"> No. Of People </p>
                  <p className="isoInfoDetails">{currentTour.noOfPersons}</p>
                </div>
                <div className="isoContactCardInfos" key={people}>
                  <p className="isoInfoLabel"> Rooms </p>
                  <p className="isoInfoDetails">
                    {(currentTour.room &&
                      currentTour.room[0] &&
                      currentTour.room[0].roomNumber &&
                      currentTour.room[0].roomNumber.length &&
                      currentTour.room[0].roomNumber.map((data) => {
                        return <div>{data.value}</div>;
                      })) ||
                      0}
                  </p>
                </div>
                <div className="isoContactCardInfos" key={currentTour.lang}>
                  <p className="isoInfoLabel"> Language </p>
                  <p className="isoInfoDetails">
                    {_.result(
                      _.find(LANGUAGES, function (o) {
                        if (o.value === currentTour.lang) {
                          return currentTour.lang;
                        }
                      }),
                      "label"
                    )}
                  </p>
                </div>
                <div className="isoContactCardInfos" key={currentTour.diversion && currentTour.diversion.id}>
                  <p className="isoInfoLabel"> DT Id </p>
                  <p className="isoInfoDetails">{currentTour.diversion && currentTour.diversion.localizador}</p>
                </div>
                <div className="isoContactCardInfos" key={currentTour.id}>
                  <p className="isoInfoLabel"> Description </p>
                  <p className="isoInfoDetails">{currentTour.description}</p>
                </div>
              </div>
            </ContactCardWrapper>
          </div>
        </div>
        <Table
          dataSource={currentTour && currentTour.bookingClient}
          columns={columns}
          bordered
          title={() => "Client Details"}
        />
        <div>
          <div className="mapWrapper">
            <Map
              getLocation={(loc) => {}}
              setLocation={{
                lat: currentTour.pickupLatitude,
                lng: currentTour.pickupLongitude,
              }}
              getQuery={() => {}}
            />
          </div>
        </div>
      </div>
      {/* <div id="element-print" style={{ display: d }}>
        <Report data={currentTour} qrCode={qrCode} lat={lat} />
      </div> */}
    </>
  );
});
export default Invoice;
