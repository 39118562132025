import React from "react";
import { Button, DatePicker, Layout } from "antd";
import { Scrollbars } from "react-custom-scrollbars";
import { useNavigate } from "react-router";
import moment from "moment";
import SearchInput from "@iso/components/ScrumBoard/SearchInput/SearchInput";

import { Filters, Header, Title } from "./AppLayout.style";

const { Content } = Layout;
const { RangePicker } = DatePicker;

const AppLayout = ({ dateFilter,setSearchText, handleDateRange, children, title }) => {
  const navigate = useNavigate();

  return (
    <Layout style={{ backgroundColor: "#fff" }}>
      <Header>
        <Title>{title}</Title>
        <Filters>
        <SearchInput
            onChange={(value) => setSearchText(value)}
            onCross={(value) => {
              setSearchText(value);
            }}
          />
          <DatePicker
            style={{ margin: "0 10px" }}
            placeholder="Date"
            format="DD/MM/yyyy"
            onChange={(value) =>
              dateFilter((value && moment(value).format("YYYY-MM-DD")) || "")
            }
          />
          <RangePicker
            style={{ width: "350px" }}
            onChange={handleDateRange}
            allowClear
            format="DD/MM/yyyy"
            placeholder={["Transfer Date - Start", "Transfer Date - End"]}
          />
        </Filters>
        <Button
          type="primary"
          onClick={() => {
            navigate(`/dashboard/airport-transfer/booking`, { state: {} });
          }}
        >
          Book
        </Button>
      </Header>
      <Content
        style={{
          padding: "0 24px",
        }}
      >
        <Scrollbars style={{ width: "100%", height: "80vh" }}>
          {children}
        </Scrollbars>
      </Content>
    </Layout>
  );
};

export default AppLayout;
