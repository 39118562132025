export const SIGN_IN_SUPER_ADMIN = "SIGN_IN_SUPER_ADMIN";
export const SIGN_IN_ADMIN = "SIGN_IN_ADMIN";
export const CHECK_AUTHORIZATION = "CHECK_AUTHORIZATION";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGOUT = "LOGOUT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const AIRPORTS_ERROR = "AIRPORTS_ERROR";
export const CREATE_AIRPORT = "CREATE_AIRPORT";
export const GET_AIRPORTS_LIST = "GET_AIRPORTS_LIST";
export const UPDATE_AIRPORT = "UPDATE_AIRPORT";
export const DELETE_AIRPORT = "DELETE_AIRPORT";
export const AIRPORT_LOADING = "AIRPORT_LOADING";

export const AIRPORT_TRANSFER_ERROR = "AIRPORT_TRANSFER_ERROR";
export const AIRPORT_TRANSFER_LOADING = "AIRPORT_TRANSFER_LOADING";

export const GET_SERVICE_LIST = "GET_SERVICE_LIST";
export const CREATE_SERVICE = "CREATE_SERVICE";
export const UPDATE_SERVICE = "UPDATE_SERVICE";
export const DELETE_SERVICE = "DELETE_SERVICE";

export const GET_EXTRA_SERVICE_LIST = "GET_EXTRA_SERVICE_LIST";
export const CREATE_EXTRA_SERVICE = "CREATE_EXTRA_SERVICE";
export const UPDATE_EXTRA_SERVICE = "UPDATE_EXTRA_SERVICE";
export const DELETE_EXTRA_SERVICE = "DELETE_EXTRA_SERVICE";

export const GET_AIRPORT_TRANSFER_LIST = "GET_AIRPORT_TRANSFER_LIST";
export const BOOK_AIRPORT_TRANSFER = "BOOK_AIRPORT_TRANSFER";
export const GENERATE_AIRPORT_TRANSFER_RECEIPT =
  "GENERATE_AIRPORT_TRANSFER_RECEIPT";
export const GENERATE_AIRPORT_TRANSFERS_ADMIN_RECEIPT =
  "GENERATE_AIRPORT_TRANSFERS_ADMIN_RECEIPT";

export const GET_CITY_PRICE_LIST = "GET_CITY_PRICE_LIST";
export const CREATE_CITY_PRICE = "CREATE_CITY_PRICE";
export const UPDATE_CITY_PRICE = "UPDATE_CITY_PRICE";
export const DELETE_CITY_PRICE = "DELETE_CITY_PRICE";

export const GET_HOTEL_PRICE_LIST = "GET_HOTEL_PRICE_LIST";
export const CREATE_HOTEL_PRICE = "CREATE_HOTEL_PRICE";
export const UPDATE_HOTEL_PRICE = "UPDATE_HOTEL_PRICE";
export const DELETE_HOTEL_PRICE = "DELETE_HOTEL_PRICE";

export const HOTELS_ERROR = "HOTELS_ERROR";
export const CREATE_HOTEL = "CREATE_HOTEL";
export const GET_HOTELS_LIST = "GET_HOTELS_LIST";
export const UPDATE_HOTEL = "UPDATE_HOTEL";
export const DELETE_HOTEL = "DELETE_HOTEL";
export const UPDATE_COMMISSION = "UPDATE_COMMISSION";
export const HOTEL_LOADING = "HOTEL_LOADING";

export const GET_RECEPTIONISTS_LIST = "GET_RECEPTIONISTS_LIST";
export const RECEPTIONISTS_ERROR = "RECEPTIONISTS_ERROR";
export const CREATE_RECEPTIONIST = "CREATE_RECEPTIONIST";
export const ACTIVATE_RECEPTIONIST = "ACTIVATE_RECEPTIONIST";
export const DEACTIVATE_RECEPTIONIST = "DEACTIVATE_RECEPTIONIST";
export const UPDATE_RECEPTIONIST = "UPDATE_RECEPTIONIST";
export const RECEPTIONISTS_LOADING = "RECEPTIONISTS_LOADING";

export const TOURS_ERROR = "TOURS_ERROR";
export const CREATE_TOUR = "CREATE_TOUR";
export const DELETE_TOUR = "DELETE_TOUR";
export const DELETE_T_TOUR = "DELETE_T_TOUR";
export const DELETE_TG_TOUR = "DELETE_TG_TOUR";

export const UPDATE_TOUR = "UPDATE_TOUR";
export const GET_TOURS_LIST = "GET_TOURS_LIST";
export const CREATE_PRIVATE_TOUR = "CREATE_PRIVATE_TOUR";
export const UPDATE_PRIVATE_TOUR = "UPDATE_PRIVATE_TOUR";
export const SEARCH_PRIVATE_TOURS = "SEARCH_PRIVATE_TOURS";
export const GET_PRIVATE_TOURS_LIST = "GET_PRIVATE_TOURS_LIST";
export const FETCH_TOUR_SCHEDULE = "FETCH_TOUR_SCHEDULE";
export const TOUR_LOADING = "TOUR_LOADING";

export const GET_TICKET_TOURS_LIST = "GET_TICKET_TOURS_LIST";
export const GET_TICKET_GUIDE_TOURS_LIST = "GET_TICKET_GUIDE_TOURS_LIST";

export const CITY_ERROR = "CITY_ERROR";
export const CREATE_CITY = "CREATE_CITY";
export const GET_CITY_LIST = "GET_CITY_LIST";
export const UPDATE_CITY = "UPDATE_CITY";
export const DELETE_CITY = "DELETE_CITY";
export const CITY_LOADING = "CITY_LOADING";

export const BUS_STOP_ERROR = "BUS_STOP_ERROR";
export const CREATE_BUS_STOP = "CREATE_BUS_STOP";
export const GET_BUS_STOP_LIST = "GET_BUS_STOP_LIST";
export const UPDATE_BUS_STOP = "UPDATE_BUS_STOP";
export const DELETE_BUS_STOP = "DELETE_BUS_STOP";
export const BUS_STOP_LOADING = "BUS_STOP_LOADING";

export const SNACKBAR_SUCCESS = "SNACKBAR_SUCCESS";
export const SNACKBAR_ERROR = "SNACKBAR_ERROR";
export const SNACKBAR_CLEAR = "SNACKBAR_CLEAR";

export const GET_ADMIN_LIST = "GET_ADMIN_LIST";
export const ADMIN_ERROR = "ADMIN_ERROR";
export const CREATE_ADMIN = "CREATE_ADMIN";
export const ACTIVATE_ADMIN = "ACTIVATE_ADMIN";
export const DEACTIVATE_ADMIN = "DEACTIVATE_ADMIN";
export const ADMIN_LOADING = "ADMIN_LOADING";

export const TRANSPORT_COMPANY_ERROR = "TRANSPORT_COMPANY_ERROR";
export const CREATE_TRANSPORT_COMPANY = "CREATE_TRANSPORT_COMPANY";
export const GET_TRANSPORT_COMPANY_LIST = "GET_TRANSPORT_COMPANY_LIST";
export const UPDATE_TRANSPORT_COMPANY = "UPDATE_TRANSPORT_COMPANY";
export const DELETE_TRANSPORT_COMPANY = "DELETE_TRANSPORT_COMPANY";
export const UPDATE_TRANSPORT_COMPANY_COMMISSION =
  "UPDATE_TRANSPORT_COMPANY_COMMISSION";
export const TRANSPORT_COMPANY_LOADING = "TRANSPORT_COMPANY_LOADING";

export const VEHICLES_ERROR = "VEHICLES_ERROR";
export const CREATE_VEHICLE = "CREATE_VEHICLE";
export const GET_VEHICLES_LIST = "GET_VEHICLES_LIST";
export const UPDATE_VEHICLE = "UPDATE_VEHICLE";
export const DELETE_VEHICLE = "DELETE_VEHICLE";
export const CREATE_VEHICLE_PRICE = "CREATE_VEHICLE_PRICE";
export const GET_VEHICLE_PRICE_LIST = "GET_VEHICLE_PRICE_LIST";
export const UPDATE_VEHICLE_PRICE = "UPDATE_VEHICLE_PRICE";
export const DELETE_VEHICLE_PRICE = "DELETE_VEHICLE_PRICE";
export const VEHICLE_LOADING = "VEHICLE_LOADING";

export const GET_RECEPTIONIST_TOURS_LIST = "GET_RECEPTIONIST_TOURS_LIST";
export const RECEPTIONIST_ERROR = "RECEPTIONIST_ERROR";
export const BOOK_PRIVATE_TOUR = "BOOK_PRIVATE_TOUR";
export const GET_RECEPTIONIST_BOOKED_TOURS_LIST =
  "GET_RECEPTIONIST_BOOKED_TOURS_LIST";
export const GET_RECEPTIONIST_BOOKED_AIRPORT_TRANSPORT_LIST =
  "GET_RECEPTIONIST_BOOKED_AIRPORT_TRANSPORT_LIST";
export const RECEPTIONIST_LOADING = "RECEPTIONIST_LOADING";
export const GENERATE_TOUR_RECEIPT = "GENERATE_TOUR_RECEIPT";
export const GENERATE_TOUR_ADMIN_RECEIPT = "GENERATE_TOUR_ADMIN_RECEIPT";
export const GENERATE_TOUR_S_ADMIN_RECEIPT = "GENERATE_TOUR_S_ADMIN_RECEIPT";
export const CLEAR_ALL = "CLEAR_ALL";

export const COMMON_ERROR = "COMMON_ERROR";
export const COMMON_LOADING = "COMMON_LOADING";
export const FETCH_CURRENCY = "FETCH_CURRENCY";

export const GET_TOUR_BOOKING = "GET_TOUR_BOOKING";
export const SUPER_ADMIN_TOUR_LOADING = "SUPER_ADMIN_TOUR_LOADING";

export const GET_ADMIN_HOTEL_BOOKING_LIST_COUNT =
  "GET_ADMIN_HOTEL_BOOKING_LIST_COUNT";
export const GET_ADMIN_HOTEL_BOOKING_COUNT = "GET_ADMIN_HOTEL_BOOKING_COUNT";
export const GET_ADMIN_HOTEL_BOOKING_LIST_COUNT_ERROR =
  "GET_ADMIN_HOTEL_BOOKING_LIST_COUNT_ERROR";
export const GET_ADMIN_AIRPORT_TRANSFER_BOOKING_COUNT_ERROR =
  "GET_ADMIN_AIRPORT_TRANSFER_BOOKING_COUNT_ERROR";
export const GET_ADMIN_HOTEL_BOOKING_COUNT_ERROR =
  "GET_ADMIN_HOTEL_BOOKING_COUNT_ERROR";
export const GET_ADMIN_HOTEL_BOOKING_CANCEL_COUNT_ERROR =
  "GET_ADMIN_HOTEL_BOOKING_CANCEL_COUNT_ERROR";
export const GET_REC_HOTEL_BOOKING_COUNT_ERROR =
  "GET_REC_HOTEL_BOOKING_COUNT_ERROR";
export const GET_REC_AIRPORT_TRANSFER_BOOKING_COUNT_ERROR =
  "GET_REC_AIRPORT_TRANSFER_BOOKING_COUNT_ERROR";
export const GET_SA_HOTEL_COUNT_ERROR = "GET_SA_HOTEL_COUNT_ERROR";
export const GET_ADMIN_BOOKING_SUM = "GET_ADMIN_BOOKING_SUM";
export const GET_ADMIN_PEOPLE_LIFETIME = "GET_ADMIN_PEOPLE_LIFETIME";
export const GET_ADMIN_TOUR_BY_HOTEL = "GET_ADMIN_TOUR_BY_HOTEL";
export const GET_ADMIN_WEEKLY_ACCUMULATED_SALES_REVENUE = "GET_ADMIN_WEEKLY_ACCUMULATED_SALES_REVENUE";
export const GET_SA_TOUR_BOOKING_COUNT_ERROR =
  "GET_SA_TOUR_BOOKING_COUNT_ERROR";
export const GET_SA_AIRPORT_TRANSFER_BOOKING_COUNT_ERROR =
  "GET_SA_AIRPORT_TRANSFER_BOOKING_COUNT_ERROR";

export const GET_TOUR_LIST_ADMIN = "GET_TOUR_LIST_ADMIN";

export const AIRPORT_ERROR = 'AIRPORT_ERROR';
export const TOUR_BOOKING_LOADING = 'TOUR_BOOKING_LOADING';
export const TOUR_BOOKING_ERR = 'TOUR_BOOKING_ERR';

export const GET_REC_HOTEL_VIEW_ERR = 'GET_REC_HOTEL_VIEW_ERR';