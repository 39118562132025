import * as api from '../../constants/Api';
import {API} from '../../services';
import * as T from '../ActionTypes';
import {SNACKBAR_MSG_TYPE, SNACKBAR_MSG} from '../../constants';

export const getToursList = () => {
  return (dispatch) => {
    dispatch({type: T.RECEPTIONIST_LOADING});
    return API.execute(api.GET_RECEPTIONIST_TOURS_LIST).then(
      (response) => {
        dispatch({type: T.GET_RECEPTIONIST_TOURS_LIST, payload: response.data});
        return response.data;
      },
      (error) => {
        dispatch({type: T.RECEPTIONIST_ERROR, payload: error});
        return error;
      },
    );
  };
};

export const getPrivateToursList = () => {
  return (dispatch) => {
    dispatch({type: T.RECEPTIONIST_LOADING});
    return API.execute(api.GET_RECEPTIONIST_PRIVATE_TOURS_LIST).then(
      (response) => {
        let arr = []
        // let arr = [{value: response && response.data && response.data.length > 0 && response.data[0].id, label: response && response.data && response.data.length > 0 && response.data[0].destination}];
        response.data.forEach((item) => {
          arr.push({
            value: item.id,
            id:item.id,
            label: item.destination,
            description: item.description,
            noOfPersons: item.noOfPersons,
            privatePrice: item.price.privatePrice,
          });
        });
        return arr;
      },
      (error) => {
        dispatch({type: T.RECEPTIONIST_ERROR, payload: error});
        return error;
      },
    );
  };
};

export const getCityList = () => {
  return (dispatch) => {
    return API.execute(api.GET_RECEPTIONIST_CITY_LIST).then(
      (response) => {
        let arr = [];
        response.data.forEach((item) => {
          arr.push({value: item.id, label: item.name});
        });
        return arr;
        // dispatch({type: T.GET_RECEPTIONIST_CITY_LIST, payload: response.data});
        // return response.data;
      },
      (error) => {
        dispatch({type: T.RECEPTIONIST_ERROR, payload: error});
        return error;
      },
    );
  };
};

export const getStopList = (id, tourId) => {
  return (dispatch) => {
    return API.execute(api.GET_RECEPTIONIST_STOP_LIST(id, tourId.value)).then(
      (response) => {
        let arr = [];
        response.data.forEach((item) => {
          arr.push({value: item.id, label: item.name});
        });
        return arr;
        // dispatch({type: T.GET_RECEPTIONIST_BUS_STOP_LIST, payload: response.data});
        // return response.data;
      },
      (error) => {
        dispatch({type: T.RECEPTIONIST_ERROR, payload: error});
        return error;
      },
    );
  };
};

export const getTourBookingTypeList = () => {
  return (dispatch) => {
    return API.execute(api.TOUR_BOOKING_TYPE).then(
      (response) => {
        let arr = [];
        response.data.forEach((item) => {
          arr.push({value: item.id, label: item.name});
        });
        return arr;
        // dispatch({type: T.GET_RECEPTIONIST_BUS_STOP_LIST, payload: response.data});
        // return response.data;
      },
      (error) => {
        dispatch({type: T.RECEPTIONIST_ERROR, payload: error});
        return error;
      },
    );
  };
};

export const getAirportList = () => {
  return (dispatch) => {
    return API.execute(api.GET_RECEPTIONIST_AIRPORT_LIST).then(
      (response) => {
        let arr = [];
        response.data.forEach((item) => {
          arr.push({value: item.id, label: item.name});
        });
        return arr;
      },
      (error) => {
        dispatch({type: T.RECEPTIONIST_ERROR, payload: error});
        return error;
      },
    );
  };
};

export const getVehicleList = (id) => {
  return (dispatch) => {
    return API.execute(api.GET_RECEPTIONIST_VEHICLE_LIST(id)).then(
      (response) => {
        let arr = [];
        const capacityArray = [
          {value: 1, label: '1-3 Persons'},
          {value: 2, label: '4-7 Persons'},
        ];
        response.data.forEach((item) => {
          if (item.pricingType && item.pricingType === 1) {
            arr.push({
              value: item.id,
              label: 'Per Person',
              farePrice: item.farePrice,
              currency: item.currency,
            });
          } else if (item.pricingType && item.pricingType === 2) {
            capacityArray.forEach((i) => {
              if (item.vehicleCapacity === i.value) {
                arr.push({
                  value: item.id,
                  label: i.label,
                  farePrice: item.farePrice,
                  currency: item.currency,
                });
              }
            });
          }
        });
        return arr;
      },
      (error) => {
        dispatch({type: T.RECEPTIONIST_ERROR, payload: error});
        return error;
      },
    );
  };
};

export const cancelRequestBooking = (id) => {
  return (dispatch) => {
    // dispatch({type: T.RECEPTIONIST_LOADING});
    return API.execute(api.CANCEL_REQUEST_BOOKING_TOUR(id), {})
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        // dispatch(getBookedToursList());
        // dispatch({type: T.UPDATE_TOUR, payload: response.data, id});
        return true;
      })
      .catch((error) => {
        let {message} = error;
        if (message === 'Network Error') {
          message = SNACKBAR_MSG.networkError;
        }
        const {messageType} = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: {message, messageType},
        });
        dispatch({type: T.RECEPTIONIST_ERROR, payload: error});
      });
  };
};

export const bookPrivateTour = (data) => {
  return (dispatch) => {
    return API.execute(api.BOOK_PRIVATE_TOUR(), {
      data,
    })
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        // dispatch({type: T.BOOK_PRIVATE_TOUR, payload: response.data});
        return response.data;
      })
      .catch((error) => {
        let {message} = error;
        if (message === 'Network Error') {
          message = SNACKBAR_MSG.networkError;
        }
        const {messageType} = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: {message, messageType},
        });
        dispatch({type: T.RECEPTIONIST_ERROR, payload: error});
      });
  };
};

export const bookUpdatePrivateTour = (data, bId) => {
  return (dispatch) => {
    return API.execute(api.BOOK_UPDATE_PRIVATE_TOUR(bId), {
      data,
    })
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        // dispatch({type: T.BOOK_PRIVATE_TOUR, payload: response.data});
        return true;
      })
      .catch((error) => {
        let {message} = error;
        if (message === 'Network Error') {
          message = SNACKBAR_MSG.networkError;
        }
        const {messageType} = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: {message, messageType},
        });
        dispatch({type: T.RECEPTIONIST_ERROR, payload: error});
      });
  };
};

export const getPaymentIntent = (id) => {
  return (dispatch) => {
    return API.execute(api.PAYMENT_INTENT(id)).then(
      (response) => {
        return response.data;
      },
      (error) => {
        dispatch({type: T.RECEPTIONIST_ERROR, payload: error});
      },
    );
  };
};

export const paymentConfirmed = (bId, intentId, status) => {
  const uid = null;
  const data = {
      intentId: intentId,
      status: status,
  };
  return (dispatch) => {
    return API.execute(api.PAYMENT_CONFIRM(bId, uid), {data}).then(
      (response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        // dispatch({type: T.BOOK_PRIVATE_TOUR, payload: response.data});
        return true;
      },
      (error) => {
        dispatch({type: T.RECEPTIONIST_ERROR, payload: error});
      },
    );
  };
};

export const bookSharedTour = (data) => {
  return (dispatch) => {
    return API.execute(api.BOOK_SHARED_TOUR(data.tourId), {data})
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        return response.data;
      })
      .catch((error) => {
        let {message} = error;
        if (message === 'Network Error') {
          message = SNACKBAR_MSG.networkError;
        }
        const {messageType} = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: {message, messageType},
        });
        dispatch({type: T.RECEPTIONIST_ERROR, payload: error});
      });
  };
};

export const bookUpdateSharedTour = (data, bookId) => {
  return (dispatch) => {
    return API.execute(api.BOOK_UPDATE_SHARED_TOUR(data.tourId, bookId), {data})
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        return true;
      })
      .catch((error) => {
        let {message} = error;
        if (message === 'Network Error') {
          message = SNACKBAR_MSG.networkError;
        }
        const {messageType} = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: {message, messageType},
        });
        dispatch({type: T.RECEPTIONIST_ERROR, payload: error});
      });
  };
};

export const getBookedToursList = () => {
  return (dispatch) => {
    dispatch({type: T.RECEPTIONIST_LOADING});
    return API.execute(api.GET_RECEPTIONIST_BOOKED_TOURS_LIST).then(
      (response) => {
        dispatch({type: T.GET_RECEPTIONIST_BOOKED_TOURS_LIST, payload: response.data});
        return response.data;
      },
      (error) => {
        dispatch({type: T.RECEPTIONIST_ERROR, payload: error});
        return error;
      },
    );
  };
};

export const tourClientSetting = (id) => {
  return (dispatch) => {
    dispatch({ type: T.TOUR_LOADING });
    return API.execute(api.VIEW_TOUR_SETTING_REC(id))
      .then((response) => {
        // console.log('response', response);
        // const message = response.message ? response.message : 'fetched successfully';
        // const messageType = SNACKBAR_MSG_TYPE.success;
        // dispatch({
        //   type: T.SNACKBAR_SUCCESS,
        //   payload: {message, messageType},
        // });
        // dispatch(getToursList());
        return response.data;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.TOURS_ERROR, payload: error });
      });
  };
};

export const bookTourTransport = (data) => {
  let newData = {...data};

  delete data.vehicleId;
  delete data.airportId;

  return (dispatch) => {
    return API.execute(api.BOOK_TOUR_TRANSPORT(newData.airportId, newData.vehicleId), {data})
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        // dispatch({type: T.BOOK_PRIVATE_TOUR, payload: response.data});
        return true;
      })
      .catch((error) => {
        let {message} = error;
        if (message === 'Network Error') {
          message = SNACKBAR_MSG.networkError;
        }
        const {messageType} = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: {message, messageType},
        });
        dispatch({type: T.RECEPTIONIST_ERROR, payload: error});
      });
  };
};

export const deleteAirportTransport = (id) => {
  return (dispatch) => {
    dispatch({type: T.VEHICLE_LOADING});
    return API.execute(api.DELETE_AIRPORT_TRANSFER(id))
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        dispatch(getBookedAirportTransportList());
        return true;
      })
      .catch((error) => {
        let {message} = error;
        if (message === 'Network Error') {
          message = SNACKBAR_MSG.networkError;
        }
        const {messageType} = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: {message, messageType},
        });

        dispatch({type: T.RECEPTIONIST_ERROR, payload: error});
      });
  };
};

export const getBookedAirportTransportList = () => {
  return (dispatch) => {
    dispatch({type: T.RECEPTIONIST_LOADING});
    return API.execute(api.GET_RECEPTIONIST_BOOKED_AIRPORT_TRANSPORT_LIST).then(
      (response) => {
        dispatch({type: T.GET_RECEPTIONIST_BOOKED_AIRPORT_TRANSPORT_LIST, payload: response.data});
        return response.data;
      },
      (error) => {
        dispatch({type: T.RECEPTIONIST_ERROR, payload: error});
        return error;
      },
    );
  };
};

export const generateTourReceipt = (id) => {
  return (dispatch) => {
    dispatch({type: T.RECEPTIONIST_LOADING});
    return API.execute(api.GENERATE_TOUR_RECEIPT(id))
      .then((response) => {
        // var bufferArray = base64ToArrayBuffer(response.data);
        // var blobStore = new Blob([bufferArray], {type: 'application/pdf'});
        // const fileURL = URL.createObjectURL(blobStore);
        window.open(response.url, '_blank', 'noreferrer');
        // pdfWindow.location.href = response.url;
        dispatch({type: T.GENERATE_TOUR_RECEIPT});
        return response.url;
      })
      .catch((error) => {
        let {message} = error;
        if (message === 'Network Error') {
          message = SNACKBAR_MSG.networkError;
        }
        const {messageType} = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: {message, messageType},
        });
        dispatch({type: T.RECEPTIONIST_ERROR, payload: error});
      });
  };
};

function base64ToArrayBuffer(data) {
  var bString = window.atob(data);
  var bLength = bString.length;
  var bytes = new Uint8Array(bLength);
  for (var i = 0; i < bLength; i++) {
    var ascii = bString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

export const bookTicketTour = (data) => {
  let newData = {...data};

  delete data.cityId;
  delete data.stopId;
  delete data.tourId;

  return (dispatch) => {
    return API.execute(api.BOOK_TICKET_TOUR(newData.tourId, newData.cityId, newData.stopId), {data})
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        // dispatch({type: T.BOOK_PRIVATE_TOUR, payload: response.data});
        return true;
      })
      .catch((error) => {
        let {message} = error;
        if (message === 'Network Error') {
          message = SNACKBAR_MSG.networkError;
        }
        const {messageType} = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: {message, messageType},
        });
        dispatch({type: T.RECEPTIONIST_ERROR, payload: error});
      });
  };
};

export const bookTicketGuideTour = (data) => {
  let newData = {...data};

  delete data.cityId;
  delete data.stopId;
  delete data.tourId;

  return (dispatch) => {
    return API.execute(api.BOOK_TICKET_GUIDE_TOUR(newData.tourId, newData.cityId, newData.stopId), {data})
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        // dispatch({type: T.BOOK_PRIVATE_TOUR, payload: response.data});
        return true;
      })
      .catch((error) => {
        let {message} = error;
        if (message === 'Network Error') {
          message = SNACKBAR_MSG.networkError;
        }
        const {messageType} = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: {message, messageType},
        });
        dispatch({type: T.RECEPTIONIST_ERROR, payload: error});
      });
  };
};

export const viewDefaultPrice = (id) => {
  return (dispatch) => {
    dispatch({type: T.RECEPTIONIST_LOADING});
    return API.execute(api.VIEW_DEFAULT_PRICE(id)).then(
      (response) => {
        return response.data;
      },
      (error) => {
        dispatch({type: T.RECEPTIONIST_ERROR, payload: error});
        return error;
      },
    );
  };
};

export const viewHotelPrice = (id, hotelId) => {
  return (dispatch) => {
    dispatch({type: T.RECEPTIONIST_LOADING});
    return API.execute(api.VIEW_HOTEL_PRICE(id, hotelId)).then(
      (response) => {
        return response.data;
      },
      (error) => {
        dispatch({type: T.RECEPTIONIST_ERROR, payload: error});
        return error;
      },
    );
  };
};

export const cancelRequestTourBooking = (data) => {
  return (dispatch) => {
    return API.execute(api.CANCEL_BOOKING_TOUR_BOOKING, { data })
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        return true;
      })
      .catch((error) => {
        let { message } = error;
        if (message === "Network Error") {
          message = SNACKBAR_MSG.networkError;
        }
        const { messageType } = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: { message, messageType },
        });
        dispatch({ type: T.RECEPTIONIST_ERROR, payload: error });
      });
  };
};
