import React from "react";
import { connect } from "react-redux";
import { Button, InputNumber, Layout } from "antd";
import DatePicker from "@iso/components/uielements/datePicker";
import Select, { SelectOption } from "@iso/components/uielements/select";
import { Scrollbars } from "react-custom-scrollbars";
import { Filters, HeaderSecondary } from "./AppLayout.style";
import moment from "moment";

const Option = SelectOption;
const { RangePicker } = DatePicker;

const { Content } = Layout;

const AppLayout = ({
  children,
  dateFilter,
  handleReport,
  handleDateRange,
  handleCSV,
}) => {
  return (
    <Layout style={{ backgroundColor: "#fff" }}>
      <HeaderSecondary>
        <Filters>
          <DatePicker
            style={{ margin: "0 10px" }}
            placeholder="Date"
            format="DD/MM/yyyy"
            onChange={(value) =>
              dateFilter((value && moment(value).format("YYYY-MM-DD")) || "")
            }
          />
          <RangePicker
            style={{ width: "350px" }}
            onChange={handleDateRange}
            allowClear
            format="DD/MM/yyyy"
            placeholder={["Transfer Date - Start", "Transfer Date - End"]}
          />
        </Filters>
        <div style={{ display:'flex' }}>
          <div style={{ marginRight: "10px" }}>
            <Button type="primary" onClick={() => handleReport()}>
              Report
            </Button>
          </div>
          <div style={{ marginRight: "10px" }}>
            <Button type="primary" onClick={() => handleCSV()}>
              Generate CSV
            </Button>
          </div>
        </div>
      </HeaderSecondary>

      <Content
        style={{
          padding: "0 24px",
        }}
      >
        <Scrollbars style={{ width: "100%", height: "calc(100vh - 201px)" }}>
          {children}
        </Scrollbars>
      </Content>
    </Layout>
  );
};

export default connect(null, {})(AppLayout);
