import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import StickerWidget from "@iso/containers/Widgets/Sticker/StickerWidget";
import IsoWidgetsWrapper from "@iso/containers/Widgets/WidgetsWrapper";
import basicStyle from "@iso/assets/styles/constants";
import { Row, Col, Table, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
  getAdminHotelBookingCount,
  getAirportTransferBookingCount,
  // getAdminHotelBookingListCount,
  getAdminHotelBookingCancelCount,
  getAdminBookingSum,
  getPeopleLifeTime,
  getTourByHotel,
  weeklyAccumulatedSalesRevenue,
} from "@iso/redux/dashboard/actions";
import { EURO } from "../../../constants";
import SalesChart from "../../../components/Chart/SalesChart";

const styles = {
  wisgetPageStyle: {
    display: "flex",
    flexFlow: "row wrap",
    alignItems: "flex-start",
    overflow: "hidden",
    margin: "10px 0",
  },
};
const Dashboard = () => {
  function hndlr(response) {
    for (var i = 0; i < response.items.length; i++) {
      var item = response.items[i];
      // Make sure HTML in item.htmlTitle is escaped.
      document
        .getElementById("content")
        .append(
          document.createElement("br"),
          document.createTextNode(item.htmlTitle)
        );
    }
  }
  const { rowStyle, colStyle } = basicStyle;
  const dispatch = useDispatch();

  const [hotelBookingCount, setHotelBookingCount] = useState(null);
  // const [hotelBookingListCount, setHotelBookingListCount] = useState(null);
  const [AirportBookingCount, setAirportBookingCount] = useState(null);
  const [hotelBookingCancelCount, setHotelBookingCancelCount] = useState(null);
  const [bookingSum, setBookingSum] = useState(null);
  const [peopleLifetime, setPeopleLifetime] = useState(null);
  const [tourByHotel, setTourByHotel] = useState([]);
  const [accumulatedData, setAccumulatedData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    Promise.all([
      dispatch(weeklyAccumulatedSalesRevenue()),
      dispatch(getAdminHotelBookingCancelCount()),
      dispatch(getAdminHotelBookingCount()),
      // dispatch(getAdminHotelBookingListCount()),
      dispatch(getAirportTransferBookingCount()),
      dispatch(getAdminBookingSum()),
      dispatch(getPeopleLifeTime()),
      dispatch(getTourByHotel()),
    ])
      .then(
        ([
          accumulatedSalesRevenue,
          hotelBookingCancelCount,
          hotelBookingCount,
          // hotelBookingListCount,
          airportBookingCount,
          bookingSum,
          peopleLifetime,
          tourByHotel,
        ]) => {
          setAccumulatedData(accumulatedSalesRevenue);
          setHotelBookingCancelCount(hotelBookingCancelCount);
          setHotelBookingCount(hotelBookingCount);
          // setHotelBookingListCount(hotelBookingListCount);
          setAirportBookingCount(airportBookingCount);
          setBookingSum(bookingSum);
          setPeopleLifetime(peopleLifetime);
          setTourByHotel(tourByHotel);
          setLoading(false);
        }
      )
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  }, []);

  const people = (peopleLifetime && peopleLifetime.noOfPeople) || 0;

  const totalSum =
    (bookingSum &&
      bookingSum.totalBookings &&
      bookingSum.totalBookings.total_amount &&
      (Math.round(bookingSum.totalBookings.total_amount * 100) / 100).toFixed(
        2
      )) ||
    0;
  const totalMonthSum =
    (bookingSum &&
      bookingSum.totalBookingsMonth &&
      bookingSum.totalBookingsMonth.combined_total &&
      (
        Math.round(bookingSum.totalBookingsMonth.combined_total * 100) / 100
      ).toFixed(2)) ||
    0;
  const totalMonthSharedSum = bookingSum && bookingSum.totalSharedBookingsMonth;

  const tourTypeLength =
    (totalMonthSharedSum && totalMonthSharedSum.length) || 0;

  // console.log('tableInfo', hotelBookingListCount)

  const columns = [
    {
      title: "Hotel",
      dataIndex: "Hotel",
    },
    {
      title: "Bookings",
      dataIndex: "Bookings",
    },
    {
      title: "Total",
      dataIndex: "Total",
      render: (text) => (
        <p>
          {" "}
          {(text && EURO.format((Math.round(text * 100) / 100).toFixed(2))) ||
            0}
        </p>
      ),
    },
    {
      title: "Share",
      dataIndex: "Share",
    },
  ];

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 100,
      }}
      spin
    />
  );

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height:'100%'
        }}
      >
        <Spin indicator={antIcon} />
      </div>
    );
  } else {
    return (
      <>
        <div style={styles.wisgetPageStyle}>
          {/* <div id="content"></div> */}
          {/* <body><div class="gcse-search"></div></body> */}
          <div style={{ margin: "5px 15px" }}>
            <h2
              style={{
                fontSize: "16px",
                fontFamily: "Roboto",
                color: "#323332",
                fontWeight: "500",
              }}
            >
              Tours
            </h2>
          </div>
          <Row style={rowStyle} gutter={0} justify="start">
            <Col lg={4} md={12} sm={12} xs={24} style={colStyle}>
              <IsoWidgetsWrapper>
                <StickerWidget
                  number={(hotelBookingCount && hotelBookingCount.today) || 0}
                  text="Bookings Today"
                  fontColor={"white"}
                  fontSize={"14px"}
                  bgColor={"#7266BA"}
                />
              </IsoWidgetsWrapper>
            </Col>
            <Col lg={4} md={12} sm={12} xs={24} style={colStyle}>
              <IsoWidgetsWrapper>
                <StickerWidget
                  number={(hotelBookingCount && hotelBookingCount.past) || 0}
                  text="Past Bookings"
                  fontColor={"white"}
                  fontSize={"14px"}
                  bgColor={"#42A5F6"}
                />
              </IsoWidgetsWrapper>
            </Col>
            <Col lg={4} md={12} sm={12} xs={24} style={colStyle}>
              <IsoWidgetsWrapper>
                <StickerWidget
                  number={people}
                  text="Total People (Lifetime)"
                  fontColor={"white"}
                  fontSize={"14px"}
                  bgColor={"#525FE1"}
                />
              </IsoWidgetsWrapper>
            </Col>
            <Col lg={4} md={12} sm={12} xs={24} style={colStyle}>
              <IsoWidgetsWrapper>
                <StickerWidget
                  number={
                    (hotelBookingCount && hotelBookingCount.upcoming) || 0
                  }
                  text="Upcoming Bookings"
                  fontColor={"white"}
                  fontSize={"14px"}
                  bgColor={"#7ED320"}
                />
              </IsoWidgetsWrapper>
            </Col>
            <Col lg={4} md={12} sm={12} xs={24} style={colStyle}>
              <IsoWidgetsWrapper>
                <StickerWidget
                  number={
                    (hotelBookingCancelCount &&
                      hotelBookingCancelCount.cancel) ||
                    0
                  }
                  text="Cancelled Bookings"
                  fontColor={"white"}
                  fontSize={"14px"}
                  bgColor={"#2D3446"}
                />
              </IsoWidgetsWrapper>
            </Col>
          </Row>
          <div style={{ margin: "5px 15px" }}>
            <h2
              style={{
                fontSize: "16px",
                fontFamily: "Roboto",
                color: "#323332",
                fontWeight: "500",
              }}
            >
              Bookings Amount
            </h2>
          </div>
          <Row style={rowStyle} gutter={0} justify="start">
            <Col lg={7} md={12} sm={12} xs={24} style={colStyle}>
              <IsoWidgetsWrapper>
                <StickerWidget
                  number={EURO.format(totalSum) || 0}
                  text="Lifetime Total"
                  fontColor={"white"}
                  fontSize={"14px"}
                  // style={{fontSize:'25px'}}
                  bgColor={"black"}
                />
              </IsoWidgetsWrapper>
            </Col>
            <Col lg={7} md={12} sm={12} xs={24} style={colStyle}>
              <IsoWidgetsWrapper>
                <StickerWidget
                  number={EURO.format(totalMonthSum) || 0}
                  text="Total Bookings This Month"
                  fontColor={"white"}
                  fontSize={"14px"}
                  bgColor={"black"}
                />
              </IsoWidgetsWrapper>
            </Col>
            {tourTypeLength > 0 &&
              totalMonthSharedSum.map((data, key) => {
                const tourLabel = data && data.TypeLabel;
                const tourAmount =
                  (data &&
                    data.TotalPrice &&
                    (Math.round(data.TotalPrice * 100) / 100).toFixed(2)) ||
                  0;
                return (
                  <Col
                    lg={7}
                    md={12}
                    sm={12}
                    xs={24}
                    style={colStyle}
                    key={key}
                  >
                    <IsoWidgetsWrapper>
                      <StickerWidget
                        number={EURO.format(tourAmount) || 0}
                        text={`Total Bookings This Month - ${tourLabel}`}
                        fontColor={"white"}
                        fontSize={"14px"}
                        bgColor={"black"}
                      />
                    </IsoWidgetsWrapper>
                  </Col>
                );
              })}
          </Row>
          <div style={{ margin: "5px 15px" }}>
            <h2
              style={{
                fontSize: "16px",
                fontFamily: "Roboto",
                color: "#323332",
                fontWeight: "500",
              }}
            >
              Airport Transfers
            </h2>
          </div>
          <Row style={rowStyle} gutter={0} justify="start">
            <Col lg={4} md={12} sm={12} xs={24} style={colStyle}>
              <IsoWidgetsWrapper>
                <StickerWidget
                  number={
                    (AirportBookingCount && AirportBookingCount.today) || 0
                  }
                  text="Bookings Today"
                  fontColor={"white"}
                  fontSize={"14px"}
                  bgColor={"#42A5F6"}
                />
              </IsoWidgetsWrapper>
            </Col>
            <Col lg={4} md={12} sm={12} xs={24} style={colStyle}>
              <IsoWidgetsWrapper>
                <StickerWidget
                  number={
                    (AirportBookingCount && AirportBookingCount.past) || 0
                  }
                  text="Past Bookings"
                  fontColor={"white"}
                  fontSize={"14px"}
                  bgColor={"#FEAC01"}
                />
              </IsoWidgetsWrapper>
            </Col>
            <Col lg={4} md={12} sm={12} xs={24} style={colStyle}>
              <IsoWidgetsWrapper>
                <StickerWidget
                  number={
                    (AirportBookingCount && AirportBookingCount.upcoming) || 0
                  }
                  text="Upcoming Bookings"
                  fontColor={"white"}
                  fontSize={"14px"}
                  bgColor={"rgb(247, 93, 129)"}
                />
              </IsoWidgetsWrapper>
            </Col>
          </Row>
          <div style={{ margin: "5px 15px" }}>
            <div>
              <h2
                style={{
                  fontSize: "16px",
                  fontFamily: "Roboto",
                  color: "#323332",
                  fontWeight: "500",
                }}
              >
                Breakdown of Tours by Hotel
              </h2>
            </div>

            <Table
              columns={columns}
              dataSource={tourByHotel}
              bordered
              pagination={{ pageSize: "10" }}
            />
          </div>
          <div style={{ margin: "5px 15px", width: "50%" }}>
          <div>
            <h2
              style={{
                fontSize: "16px",
                fontFamily: "Roboto",
                color: "#323332",
                fontWeight: "500",
              }}
            >
              Weekly accumulated sales and revenue
            </h2>
          </div>
          <SalesChart data={accumulatedData} />
        </div>
        </div>
        
      </>
    );
  }
};

export default Dashboard;
