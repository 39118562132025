import * as actions from '../ActionTypes';

const initState = {user: null, idToken: null};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS: {
      return {
        idToken: action.payload,
        user: action.payload,
      };
    }
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
