import React from 'react';
// import {Link} from 'react-router-dom';
import {Popover} from 'antd';
import {
  Avatar,
  InfoWrapper,
  Title,
  ProjectInfo,
  MoreActionWrapper,
} from './BoardListCard.style';

import {IconSvg} from '@iso/components/ScrumBoard/IconSvg/IconSvg';
import MoreIcon from '@iso/assets/images/icon/16.svg';
import AvatarIcon from '@iso/assets/tour.jpeg';

export default function BoardListCard({item, onDelete, onEdit, onEditSchedule}) {
  const MoreActions = (
    <MoreActionWrapper>
      <p onClick={onEdit}>Edit Tour</p>
      <p style={{color:'#DC3545'}} onClick={onDelete}>Delete Tour</p>
    </MoreActionWrapper>
  );

  return (
    <tbody>
      <tr>
        <td>
          {/* <Link to={`/dashboard/scrum-board/project/${item.id}`}> */}
            <ProjectInfo>
              <Avatar src={AvatarIcon} alt={item.name} style={{backgroundColor: 'white'}}/>
              <InfoWrapper>
                <Title>{`${item.destination}`}</Title>
              </InfoWrapper>
            </ProjectInfo>
          {/* </Link> */}
        </td>
        <td>
          <div style={{width: 180}}>{item.email}</div>
        </td>
        <td>
          {/* <IconSvg src={SoftwareIcon} border={'none'} />
          {item.addressLine1} */}
        </td>
        <td>
          {/* <StarFilled
            style={{
              color: '#ED8A19',
              fontSize: 19,
              verticalAlign: 'middle',
              marginRight: 10,
            }}
          />
          {item.city} */}
        </td>
        <td>
          <>
            {/* <IconSvg src={PrivateIcon} border={'none'} />
            {item.country} */}
          </>
        </td>
        <td>
          {/* <IconSvg src={PlusIcon} radius={'10% 30% 25%'} padding={8} /> */}
        </td>
        <td>
          <Popover placement="bottom" content={MoreActions} trigger="click">
            <IconSvg src={MoreIcon} border={'none'} padding={'0'} />
          </Popover>
        </td>
      </tr>
    </tbody>
  );
}
