import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import BoardLists from './Board/BoardList/BoardList';
import {getToursList} from '@iso/redux/tours/actions';
import { TOUR_TYPES } from '../../../constants';

export default function HotelsList() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getToursList(TOUR_TYPES.tpt_tkt_lcl_acc_guide));
  }, []);

  return (
    <>
      <BoardLists />
    </>
  );
}
