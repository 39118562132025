import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import _ from "lodash";

import Dropdown from "@iso/components/uielements/CustomDropdown";
import Input from "@iso/components/uielements/input";
import Button from "@iso/components/uielements/button";
import Slider from "@iso/components/uielements/slider";

import {
  createHotel,
  hotelTypeList,
  updateHotel,
} from "@iso/redux/hotels/actions";
import IntlMessages from "@iso/components/utility/intlMessages";
import SignUpStyleWrapper from "./CreateHotels.styles";
import Map from "../../../services/Map";
import { hotelFormValidation } from "../../../constants";
import { Avatar } from "antd";
import { getCityList } from "../../../redux/city/actions";
import ImageUploader from "../../../components/uielements/ImageUploader";
import { getHotelBanner } from "../../../redux/setting/actions";

export default function CreateHotels() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useLocation();
  const locParams = params && params.state ? params.state : { hotel: null };
  const [hotelsType, setHotelsType] = useState([]);
  const [imageFile, setImageFile] = useState();
  const [bannerFile, setBannerFile] = useState();
  const [cities, setCities] = useState([]);
  const [zoom, setZoom] = useState(13);

  const [location, setLocation] = useState({});
  const [disable, setDisable] = useState(false);
  const [radius, setRadius] = useState(
    locParams.hotel ? locParams.hotel.radius : 0
  );

  const [avatarImg, setAvatarImg] = useState(null);
  const [bannerImg, setBannerImg] = useState(null);
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    dispatch(hotelTypeList()).then((res) => {
      setHotelsType(res);
    });
    dispatch(getCityList()).then((res) => {
      setCities(res);
    });
    dispatch(getHotelBanner()).then((res) => {
      setBanner(res);
    });
  }, []);

  const bannerUrl = (banner && banner.url) || null;

  const handleSubmit = (values) => {
    setDisable(true);
    values.latitude = location.lat;
    values.longitude = location.lng;
    if (locParams.hotel) {
      let updatedHotel = {
        ...values,
        radius,
        typeId: values.typeId.value,
        id: locParams.hotel.id,
        avatar: values.avatar,
        banner: values.banner,
        cityId: values.cityId.value,
        zoom,
      };
      dispatch(updateHotel(updatedHotel)).then((res) => {
        setDisable(false);
        navigate("/dashboard/hotels-list");
      });
    } else {
      let data = {
        ...values,
        radius,
        typeId: values.typeId.value,
        avatar: values.avatar,
        banner: values.banner,
        cityId: values.cityId.value,
        zoom,
      };

      dispatch(createHotel(data)).then((res) => {
        setDisable(false);
        navigate("/dashboard/hotels-list");
      });
    }
  };

  const onChangeRadius = (val) => {
    setRadius(val);
  };

  return (
    <SignUpStyleWrapper className="isoSignUpPage">
      <div className="isoSignUpContentWrapper">
        <div className="isoSignUpContent">
          <div className="isoLogoWrapper">
            <Link to="/dashboard">
              <IntlMessages
                id={(locParams.hotel && "Edit Hotel") || "Add a New Hotel"}
              />
            </Link>
          </div>

          <Formik
            initialValues={{
              email: locParams.hotel ? locParams.hotel.email : "",
              name: locParams.hotel ? locParams.hotel.name : "",
              displayName: locParams.hotel ? locParams.hotel.displayName : "",
              typeId: locParams.hotel
                ? _.find(hotelsType, function (o) {
                    return o.value === locParams.hotel.typeId;
                  })
                : { value: "" },
              country: locParams.hotel ? locParams.hotel.country : "",
              state: locParams.hotel ? locParams.hotel.state : "",
              addressLine1: locParams.hotel ? locParams.hotel.addressLine1 : "",
              cityId:
                (locParams.hotel &&
                  locParams.hotel.city && {
                    label: locParams.hotel.city.name,
                    value: locParams.hotel.city.id,
                  }) ||
                "",
              location: {},
              facebook: locParams.hotel ? locParams.hotel.facebook : "",
              instagram: locParams.hotel ? locParams.hotel.instagram : "",
              website: locParams.hotel ? locParams.hotel.website : "",
              avatar:
                (locParams.hotel &&
                  locParams.hotel.avatar &&
                  locParams.hotel.avatar.url) ||
                null,
              banner:
                (locParams.hotel &&
                  locParams.hotel.banner &&
                  locParams.hotel.banner.url) ||
                (bannerUrl && bannerUrl) ||
                null,
            }}
            onSubmit={handleSubmit}
            validationSchema={hotelFormValidation}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              /* and other goodies */
            }) => {
              const onFileChange = (e) => {
                console.log("e", e);
                if ((e.target.files[0].size / 1048576).toFixed(2) > 2) {
                  setAvatarImg("Image size must be smaller than 2mb");
                  setImageFile(null);
                } else {
                  setAvatarImg(null);
                  setFieldValue("avatar", e.target.files[0]);
                  setImageFile(URL.createObjectURL(e.target.files[0]));
                }
                // console.log('e', e.target.files[0])
                // setFieldValue("photo", e.target.files[0]);
              };
              const onBannerChange = (e) => {
                console.log("e", e);
                if ((e.target.files[0].size / 1048576).toFixed(2) > 2) {
                  setBannerImg("Image size must be smaller than 2mb");
                  setBannerFile(null);
                } else {
                  setBannerImg(null);
                  setFieldValue("banner", e.target.files[0]);
                  setBannerFile(URL.createObjectURL(e.target.files[0]));
                }
              };
              return (
                <form onSubmit={handleSubmit}>
                  <div className="isoSignUpForm">
                    <div className="RowDiv">
                      <div className="isoInputWrapper">
                        <label className="label-style">Name</label>
                        <Input
                          size="large"
                          type="text"
                          name="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                        />
                        {errors.name && touched.name && (
                          <div className="invalid-input">{errors.name}</div>
                        )}
                      </div>

                      <div className="isoInputWrapper">
                        <label className="label-style">Display Name</label>
                        <Input
                          size="large"
                          type="text"
                          name="displayName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.displayName}
                        />
                        {errors.displayName && touched.displayName && (
                          <div className="invalid-input">
                            {errors.displayName}
                          </div>
                        )}
                      </div>
                      <div className="isoInputWrapper">
                        <label className="label-style">Email</label>
                        <Input
                          size="large"
                          type="email"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                        {errors.email && touched.email && (
                          <div className="invalid-input">{errors.email}</div>
                        )}
                      </div>
                    </div>

                    <div className="RowDiv">
                      <div className="isoInputWrapper">
                        <label className="label-style">Image</label>
                        <ImageUploader
                          handleImageChange={(e) => onFileChange(e)}
                          value={undefined}
                          src={
                            (imageFile && imageFile) ||
                            (locParams &&
                              locParams.hotel &&
                              locParams.hotel.avatar &&
                              locParams.hotel.avatar.url)
                          }
                        />

                        {avatarImg && (
                          <div className="invalid-input">{avatarImg}</div>
                        )}
                      </div>

                      <div className="isoInputWrapper">
                        <label className="label-style">Banner</label>
                        <ImageUploader
                          handleImageChange={(e) => onBannerChange(e)}
                          value={undefined}
                          src={
                            (bannerFile && bannerFile) ||
                            (locParams &&
                              locParams.hotel &&
                              locParams.hotel.banner &&
                              locParams.hotel.banner.url) || (bannerUrl && bannerUrl)
                          }
                        />

                        {bannerImg && (
                          <div className="invalid-input">{bannerImg}</div>
                        )}
                      </div>
                    </div>

                    <div className="RowDiv">
                      <div className="isoInputWrapper">
                        <label className="label-style">Country</label>
                        <Input
                          size="large"
                          type="text"
                          name="country"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.country}
                        />
                        {errors.country && touched.country && (
                          <div className="invalid-input">{errors.country}</div>
                        )}
                      </div>

                      <div className="isoInputWrapper">
                        <label className="label-style">State</label>
                        <Input
                          size="large"
                          type="text"
                          name="state"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.state}
                        />
                        {errors.state && touched.state && (
                          <div className="invalid-input">{errors.state}</div>
                        )}
                      </div>
                    </div>

                    <div className="RowDiv">
                      <div className="isoInputWrapper">
                        <label className="label-style">Address</label>
                        <Input
                          size="large"
                          type="text"
                          name="addressLine1"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.addressLine1}
                        />
                        {errors.addressLine1 && touched.addressLine1 && (
                          <div className="invalid-input">
                            {errors.addressLine1}
                          </div>
                        )}
                      </div>

                      <div className="isoInputWrapper">
                        <label className="label-style">Select a city</label>
                        <Dropdown
                          options={cities}
                          placeholder="Select a city"
                          onChange={(value) => {
                            setFieldValue("cityId", value);
                          }}
                          value={values.cityId ? values.cityId : null}
                        />
                        {errors.cityId && touched.cityId && (
                          <div className="invalid-input">
                            {errors.cityId.value}
                          </div>
                        )}
                      </div>

                      <div className="isoInputWrapper">
                        <label className="label-style">
                          Select a hotel type
                        </label>
                        <Dropdown
                          options={hotelsType}
                          placeholder="Select a hotel type"
                          onChange={(value) => {
                            setFieldValue("typeId", value);
                          }}
                          value={values.typeId ? values.typeId : null}
                        />
                        {errors.typeId && touched.typeId && (
                          <div className="invalid-input">
                            {errors.typeId.value}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="RowDiv">
                      <div className="isoInputWrapper">
                        <label className="label-style">Facebook</label>
                        <Input
                          size="large"
                          type="text"
                          name="facebook"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.facebook}
                        />
                        {errors.facebook && touched.facebook && (
                          <div className="invalid-input">{errors.facebook}</div>
                        )}
                      </div>

                      <div className="isoInputWrapper">
                        <label className="label-style">Instagram</label>
                        <Input
                          size="large"
                          type="text"
                          name="instagram"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.instagram}
                        />
                        {errors.instagram && touched.instagram && (
                          <div className="invalid-input">
                            {errors.instagram}
                          </div>
                        )}
                      </div>
                      <div className="isoInputWrapper">
                        <label className="label-style">Website</label>
                        <Input
                          size="large"
                          type="text"
                          name="website"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.website}
                        />
                        {errors.website && touched.website && (
                          <div className="invalid-input">{errors.website}</div>
                        )}
                      </div>
                    </div>
                    <div style={{ padding: "0 7px" }}>
                      <p
                        style={{
                          fontWeight: 400,
                          paddingBottom: 10,
                          fontSize: "25px",
                          color: "black",
                        }}
                      >
                        Hotel Location
                      </p>
                    </div>
                    <div style={{ padding: "0 7px" }}>
                      <label style={{ paddingBottom: 10, color: "#788195" }}>
                        Radius
                      </label>
                      <Slider
                        defaultValue={radius}
                        style={{ width: "50%" }}
                        handleStyle={{
                          marginTop: "-10px",
                          width: "22px",
                          height: "22px",
                        }}
                        max={1000}
                        onChange={onChangeRadius}
                      />
                    </div>
                    <div className="mapWrapper">
                      <Map
                        radius={radius}
                        getLocation={(loc) => {
                          setLocation(loc);
                          setFieldValue("location", loc);
                        }}
                        setRadius={setRadius}
                        setZoom={setZoom}
                        setLocation={
                          locParams.hotel
                            ? {
                                lat:
                                  (locParams &&
                                    locParams.hotel &&
                                    locParams.hotel.latitude) ||
                                  location.lat,
                                lng:
                                  (locParams &&
                                    locParams.hotel &&
                                    locParams.hotel.longitude) ||
                                  location.lng,
                              }
                            : null
                        }
                        getQuery={() => {}}
                      />
                      {errors.location && touched.location && (
                        <div className="invalid-input">{errors.location}</div>
                      )}
                    </div>

                    <Button
                      type="primary"
                      onClick={handleSubmit}
                      disabled={disable}
                    >
                      <IntlMessages
                        id={locParams.hotel ? "Update" : "page.signUpButton"}
                      />
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </SignUpStyleWrapper>
  );
}
