import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import BoardLists from './Board/BoardList/BoardList';
import {getReceptionistsList} from '@iso/redux/receptionists/actions';
import {getHotelsList} from '@iso/redux/hotels/actions';

export default function HotelsList() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getReceptionistsList());
    dispatch(getHotelsList());
  }, []);

  return (
    <>
      <BoardLists />
    </>
  );
}
