import React from "react";
import logoPic from "@iso/assets/Be-App-Logo-shadow-small-white.png";
import "../airportTransfer/airportTransfer.css";

const ReportList = ({ data }) => {
  return (
    <body className="airportTransfer" style={{ padding: "10px 10px" }}>
      <div>
        <img class="imgLogo" alt="logo" src={logoPic} />
      </div>
      <div class="heading">
        <h1>Tour Bookings</h1>
      </div>
      <table>
        <tr>
          <th>Client Name</th>
          <th>Client Phone</th>
          <th>Date</th>
          <th>Pick Up Time</th>
          <th>Payment Method</th>
          <th>Tour</th>
          <th>Stop</th>
          <th>Price</th>
        </tr>
        {data &&
          data.map((d, k) => {
            return (
              <tr key={k}>
                <td>{d.clientName}</td>
                <td>{d.clientPhone}</td>
                <td>{d.date}</td>
                <td>{d.pickUpTime}</td>
                <td>{d.paymentMethod}</td>
                <td>{d.tour && d.tour.destination}</td>
                <td>{d.busStop && d.busStop.name}</td>
                <td>{d.price}</td>
              </tr>
            );
          })}
      </table>
    </body>
  );
};

export default ReportList;
