import React, { useState, useEffect } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

import Input from "@iso/components/uielements/input";
import Button from "@iso/components/uielements/button";
import IntlMessages from "@iso/components/utility/intlMessages";
import { signIn } from "@iso/redux/auth/actions";
import SignInStyleWrapper from "./SignIn.styles";

export default function SignIn(props) {
  let location = useLocation();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => {
    return state.Auth.user;
  });

  const [redirectToReferrer, setRedirectToReferrer] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      setRedirectToReferrer(true);
    }
  }, [isLoggedIn]);

  const handleLogin = (values) => {
    dispatch(signIn(values)).then((res) => {
      props.isSign(res);
    });
  }

  let { from } = location.state || { from: { pathname: "/dashboard" } };

  if (redirectToReferrer) {
    return <Navigate to={from} />;
  }

  const yupValidation = Yup.object().shape({
    email: Yup.string()
      .required("required")
      .email()
      .matches(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i, "Enter correct email!"
      ),
    password: Yup.string().required("required"),
  });

  return (
    <SignInStyleWrapper className="isoSignInPage">
      <div className="isoLoginContentWrapper">
        <div className="isoLoginContent">
          <div className="isoLogoWrapper">
            <Link to="/dashboard">BGT Travel</Link>
          </div>
          <div className="isoSignInForm">
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={yupValidation}
              onSubmit={handleLogin}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="isoInputWrapper">
                    <Input
                      size="large"
                      placeholder="Username or Email"
                      onChange={handleChange}
                      name="email"
                      value={values.email}
                    />
                    {errors.email && touched.email && (
                      <div className="invalid-input">{errors.email}</div>
                    )}
                  </div>

                  <div className="isoInputWrapper">
                    <Input
                      size="large"
                      type="password"
                      name="password"
                      placeholder="Password"
                      onChange={handleChange}
                      value={values.password}
                    />

                    {errors.password && touched.password && (
                      <div className="invalid-input">{errors.password}</div>
                    )}
                  </div>

                  <div className="isoInputWrapper isoLeftRightComponent">
                    <Button type="submit" htmlType="submit">
                      <IntlMessages id="page.signInButton" />
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
            <div className="isoCenterComponent isoHelperWrapper">
              <Link to="/forgotpassword" className="isoForgotPass">
                <IntlMessages id="page.signInForgotPass" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </SignInStyleWrapper>
  );
}
