import * as api from "../../constants/Api";
import { API } from "../../services";
import * as T from "../ActionTypes";
import { SNACKBAR_MSG_TYPE } from "../../constants";

export const closingTimeView = (id) => {
  return (dispatch) => {
    return API.execute(api.CLOSING_TIME_ADMIN).then(
      (response) => {
        // dispatch({type: T.GET_HOTEL_LIST, payload: response.data});
        return response.data;
      },
      (error) => {
        dispatch({ type: T.ADMIN_ERROR, payload: error });
        return error;
      }
    );
  };
};

export const closingTimeRecView = (id) => {
  return (dispatch) => {
    return API.execute(api.CLOSING_TIME_REC).then(
      (response) => {
        console.log('response', response)
        // dispatch({type: T.GET_HOTEL_LIST, payload: response.data});
        return response.data;
      },
      (error) => {
        dispatch({ type: T.RECEPTIONIST_ERROR, payload: error });
        return error;
      }
    );
  };
};

export const closingTimeAdd = (closingAt, zone) => {
    const data = {
        closingAt,
        zone
    }
  return (dispatch) => {
    return API.execute(api.CLOSING_TIME_ADD_ADMIN, {data}).then(
      (response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: { message, messageType },
        });
        return response.data;
      },
      (error) => {
        dispatch({ type: T.ADMIN_ERROR, payload: error });
        return error;
      }
    );
  };
};


export const getHotelBanner = () => {
  return (dispatch) => {
    return API.execute(api.GET_HOTEL_BANNER_ADMIN).then(
      (response) => {
        return response.data;
      },
      (error) => {
        dispatch({ type: T.ADMIN_ERROR, payload: error });
        return error;
      }
    );
  };
};

export const hotelBannerAdd = (banner) => {
  const data = {
      banner
  }
return (dispatch) => {
  return API.execute(api.HOTEL_BANNER_ADD_ADMIN, {data}).then(
    (response) => {
      const message = response.message;
      const messageType = SNACKBAR_MSG_TYPE.success;
      dispatch({
        type: T.SNACKBAR_SUCCESS,
        payload: { message, messageType },
      });
      return response.data;
    },
    (error) => {
      dispatch({ type: T.ADMIN_ERROR, payload: error });
      return error;
    }
  );
};
};

export const voucherPriceATAdd = (priceStatus) => {
  const data = {
    priceStatus
  }
return (dispatch) => {
  return API.execute(api.ADD_AT_VOUCHER_PRICE_STATUS_BY_ADMIN, {data}).then(
    (response) => {
      const message = response.message;
      const messageType = SNACKBAR_MSG_TYPE.success;
      dispatch({
        type: T.SNACKBAR_SUCCESS,
        payload: { message, messageType },
      });
      return response.data;
    },
    (error) => {
      dispatch({ type: T.ADMIN_ERROR, payload: error });
      return error;
    }
  );
};
};

export const getvoucherPriceAT = () => {
  return (dispatch) => {
    return API.execute(api.GET_AT_VOUCHER_PRICE_STATUS_BY_ADMIN).then(
      (response) => {
        return response.data;
      },
      (error) => {
        dispatch({ type: T.ADMIN_ERROR, payload: error });
        return error;
      }
    );
  };
};