import React, { useEffect, useState } from "react";
import TimeKeeper from "react-timekeeper";
import {
  closingTimeAdd,
  closingTimeView,
} from "../../../../redux/setting/actions";
import { useDispatch } from "react-redux";
import moment from "moment-timezone";
import Select, { SelectOption } from "@iso/components/uielements/select";
const Option = SelectOption;

const Time = () => {
  const dispatch = useDispatch();

  const [time, setTime] = useState(null);

  useEffect(() => {
    dispatch(closingTimeView()).then((res) => {
      setTime(res);
    });
  }, []);

  const handleTime = () => {

    if (!time.zone) {
      const timeZone = moment.tz.guess();
      dispatch(closingTimeAdd(time.closingAt, timeZone));
    } else {
      dispatch(closingTimeAdd(time.closingAt, time.zone));
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          flexDirection: "column",
        }}
      >
        <Select
          showSearch
          placeholder="Select Time Zone"
          allowClear
          onChange={(value) => setTime({ ...time, zone: value } || "")}
          filterOption={(input, option) =>
            (option?.children ?? "").toLowerCase().includes(input.toLowerCase())
          }
          value={(time && time.zone) || ""}
          style={{ width: "200px" }}
        >
          {moment.tz.names() &&
            moment.tz.names().map((data, key) => {
              return (
                <Option key={key} value={data}>
                  {data}
                </Option>
              );
            })}
        </Select>
        <h2
          style={{
            fontSize: "24px",
            fontWeight: "300",
            lineHeight: "4",
            textTransform: "uppercase",
            color: "#788195",
          }}
        >
          Select Closing Time
        </h2>
        <TimeKeeper
          time={
            time && time.closingAt
              ? moment(time.closingAt, "HH:mm:ss").format("HH:mm")
              : null
          }
          onChange={(data) => {
            setTime({ ...time, closingAt: moment(data.formatted24, "HH:mm:ss").format("HH:mm") });
          }}
          onDoneClick={()=>handleTime()}
        />
      </div>
    </>
  );
};

export default Time;
