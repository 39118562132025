import React, { useState, useEffect } from "react";
import { Button, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppLayout from "./AppLayout/AppLayout";
import Lists from "../../../../containers/Lists/Lists";
import {
  getAirportTransfers,
  airportList,
  hotelList,
  airportTransfersView,
  vehiclePriceList
} from "../../../../redux/superAdmin/actions";
import { PAYMENT_METHODS } from "../../../../constants";
import ReportList from "./ReportList";
import * as html2pdf from "html2pdf.js";
import NoBoardFounds from "./Board/BoardNotFound/BoardNotFound";
import isEmpty from "lodash/isEmpty";

const List = ({ history, match }) => {
  const [d, setd] = React.useState('none')
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [hotels, setHotels] = useState([]);
  // const [tours, setTour] = useState([]);
  const [hotelsList, setHotelList] = useState("");
  const [hotelData, setHotelData] = useState("");
  const [vPriceList, setVPriceList] = useState("");
  const [VPriceData, setVPriceData] = useState("");
  const [dateTour, setDate] = useState("");
  const [airportsList, setAirportList] = useState("");
  const [airportData, setAirportData] = useState("");

  const handleReport = () => {
    var p2 = new Promise(function (resolve, reject) {
      setd('inline-block')
      var element = document.getElementById("element-print");

      html2pdf().from(element).save();
      resolve(false);
    });

    p2.then(function (value) {
      setd('none')
      return value;
    }).then(function (value) {
      setd('none')
      window.close();
    });
  };

  useEffect(() => {
    dispatch(getAirportTransfers(dateTour, hotelData, airportData, VPriceData)).then((res) => {
      setHotels(res);
    });
  }, [dateTour, hotelData, airportData, VPriceData]);

  useEffect(() => {
    dispatch(hotelList()).then((res) => {
      setHotelList(res);
    });
    dispatch(airportList()).then((res) => {
      setAirportList(res);
    });
  }, []);

  useEffect(() => {
    dispatch(vehiclePriceList(hotelData, airportData)).then((res) => {
      setVPriceList(res);
    });
  }, [hotelData, airportData]);

  const handleView = (tour) => {
    dispatch(airportTransfersView(tour)).then((res) => {
      navigate(`/dashboard/airportTransfers-view/${res.id}`, {
        state: { res },
      });
    });
  };

  let Loading = useSelector((state) => {
    return state.SuperAdmin.loadTour;
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "clientName",
      rowKey: "clientName",
      width: "15%",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Email",
      dataIndex: "clientEmail",
      rowKey: "clientEmail",
      width: "25%",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Date",
      dataIndex: "date",
      rowKey: "date",
      width: "22%",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Payment",
      dataIndex: "paymentMethod",
      rowKey: "paymentMethod",
      width: "15%",
      render: (text) => (
        <span>
          {(text === PAYMENT_METHODS.cash && "Cash") ||
            (text === PAYMENT_METHODS.card && "Card")}
        </span>
      ),
    },
    {
      title: "Hotel",
      dataIndex: "hotel",
      rowKey: "hotel",
      width: "13%",
      render: (text) => <span>{text.displayName}</span>,
    },
    {
      title: "Airport",
      dataIndex: "airport",
      rowKey: "airport",
      width: "13%",
      render: (text) => <span>{text.name}</span>,
    },
    {
      title: "Pickup Time",
      dataIndex: "pickUpTime",
      rowKey: "pickUpTime",
      width: "13%",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Price",
      dataIndex: "farePrice",
      rowKey: "farePrice",
      width: "13%",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Platform",
      dataIndex: "platform",
      rowKey: "platform",
      width: "13%",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "",
      dataIndex: "id",
      rowKey: "id",
      width: "10%",
      render: (text, data) => (
        <div className="isoInvoiceBtnView">
          <Button
            color="primary"
            className="invoiceViewBtn"
            onClick={() => handleView(text)}
          >
            View
          </Button>
        </div>
      ),
    }
  ];
  return (
    <>
    <AppLayout
      hotelListFilter={(value) => {
        setHotelData(value);
      }}
      hotelList = {hotelsList}
      dateFilter={(value) => {
        setDate(value);
      }}
      airportsList = {airportsList}
      airportListFilter={(value) => {
        setAirportData(value);
      }}
      vPriceListFilter={(value) => {
        setVPriceData(value);
      }}
      vPriceList= {vPriceList}

      handleReport = {handleReport}
    >
      {Loading ? (
          <div className="spinner">
            <Spin />
          </div>
        ) : !isEmpty(hotels) ? (
          <Lists title={"Airport Transfers"} Columns={columns} Rows={hotels} />
        ) : (
          <NoBoardFounds history={history} match={match} />
        )}
    </AppLayout>
    <div id="element-print" style={{ display: d }}>
        <ReportList data={hotels} />
      </div>
    </>
  );
};

export default List;
