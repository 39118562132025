import _ from 'lodash';

import * as T from '../ActionTypes';

const initState = {hotels: null, loading: false};

export default function hotelReducer(state = initState, action) {
  switch (action.type) {
    case T.CREATE_HOTEL: {
      let newHotelsList = [...state.hotels];
      newHotelsList.push(action.payload);
      return {...state, hotels: newHotelsList, loading: false};
    }
    case T.UPDATE_HOTEL: {
      const hotelsArray = _.filter(state.hotels, (o) => {
        return o.id !== action.id;
      });
      hotelsArray.push(action.payload);
      return {...state, hotels: hotelsArray, loading: false};
    }

    case T.GET_HOTELS_LIST: {
      return {...state, hotels: action.payload, loading: false};
    }

    case T.DELETE_HOTEL: {
      const hotelsArray = _.filter(state.hotels, (o) => {
        return o.id !== action.id;
      });
      return {...state, hotels: hotelsArray, loading: false};
    }

    case T.HOTEL_LOADING: {
      return {...state, loading: true};
    }

    default:
      return state;
  }
}
