import * as api from '../../constants/Api';
import {API} from '../../services';
import * as T from '../ActionTypes';
import {SNACKBAR_MSG_TYPE, SNACKBAR_MSG} from '../../constants';

export const hotelTypeList = () => {
  return (dispatch) => {
    const arr = [];
    return API.execute(api.HOTEL_TYPE_LIST).then(
      (response) => {
        response.data.forEach((item) => {
          arr.push({value: item.id, label: item.name});
        });
        return arr;
      },
      (error) => {
        dispatch({type: T.HOTELS_ERROR, payload: error});
        return arr;
      },
    );
  };
};

export const createHotel = (data) => {
  return (dispatch) => {
    dispatch({type: T.HOTEL_LOADING});
    return API.execute(api.CREATE_HOTEL, {data})
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        dispatch({type: T.CREATE_HOTEL, payload: response.data});
        return true;
      })
      .catch((error) => {
        let {message} = error;
        if (message === 'Network Error') {
          message = SNACKBAR_MSG.networkError;
        }
        const {messageType} = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: {message, messageType},
        });
        dispatch({type: T.HOTELS_ERROR, payload: error});
      });
  };
};

export const getHotelsList = () => {
  return (dispatch) => {
    dispatch({type: T.HOTEL_LOADING});
    return API.execute(api.GET_HOTELS_LIST).then(
      (response) => {
        dispatch({type: T.GET_HOTELS_LIST, payload: response.data});
        return response.data;
      },
      // (error) => {
      //   dispatch({type: T.HOTELS_ERROR, payload: error});
      //   return error;
      // },
    ).catch((error)=>{
      dispatch({type: T.HOTELS_ERROR, payload: error});

    })
  };
};

export const updateHotel = (data) => {
  let newData = {...data};
  let id = newData.id;
  delete data.id;

  return (dispatch) => {
    dispatch({type: T.HOTEL_LOADING});
    return API.execute(api.UPDATE_HOTEL(id), {data})
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        dispatch({type: T.UPDATE_HOTEL, payload: response.data, id});
        return true;
      })
      .catch((error) => {
        let {message} = error;
        if (message === 'Network Error') {
          message = SNACKBAR_MSG.networkError;
        }
        const {messageType} = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: {message, messageType},
        });

        dispatch({type: T.HOTELS_ERROR, payload: error});
      });
  };
};

export const deleteHotel = (id) => {
  return (dispatch) => {
    dispatch({type: T.HOTEL_LOADING});
    return API.execute(api.DELETE_HOTEL(id))
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        dispatch({type: T.DELETE_HOTEL, id});
        return true;
      })
      .catch((error) => {
        let {message} = error;
        if (message === 'Network Error') {
          message = SNACKBAR_MSG.networkError;
        }
        const {messageType} = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: {message, messageType},
        });

        dispatch({type: T.HOTELS_ERROR, payload: error});
      });
  };
};

export const updateCommission = (id, data) => {
  return (dispatch) => {
    dispatch({type: T.HOTEL_LOADING});
    return API.execute(api.UPDATE_COMMISSION(id), {data})
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        dispatch(getHotelsList());
        return true;
      })
      .catch((error) => {
        let {message} = error;
        if (message === 'Network Error') {
          message = SNACKBAR_MSG.networkError;
        }
        const {messageType} = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: {message, messageType},
        });

        dispatch({type: T.HOTELS_ERROR, payload: error});
      });
  };
};
