import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import Hotels from '@iso/redux/hotels/reducer';
import Receptionists from '@iso/redux/receptionists/reducer';
import Tours from '@iso/redux/tours/reducer';
import City from '@iso/redux/city/reducer'
import BusStop from '@iso/redux/busStop/reducer'
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import Snackbar from '@iso/redux/snackbar/reducer';
import SuperAdmin from '@iso/redux/superAdmin/reducer';
import TransportCompany from '@iso/redux/transportCompany/reducer';
import Vehicle from '@iso/redux/vehicle/reducer';
import Common from '@iso/redux/common/reducer';
import Receptionist from '@iso/redux/receptionist/reducer';
import Airport from '@iso/redux/airport/reducer';
import AirportTransfer from '@iso/redux/airportTransfer/reducer';

export default combineReducers({
  Auth,
  Airport,
  SuperAdmin,
  App,
  Hotels,
  Receptionists,
  Tours,
  City,
  BusStop,
  TransportCompany,
  Snackbar,
  ThemeSwitcher,
  LanguageSwitcher,
  Vehicle,
  Receptionist,
  Common,
  AirportTransfer
});
