import React from "react";
import { Form } from "formik";
import Input from "@iso/components/uielements/input";
import { Button } from "antd";
import SignUpStyleWrapper from "../../pages/admin/airportTransfer/service/Board/BoardList/BoardList.style";
import { Textarea } from "../uielements/input";

const CancelRequestForm = ({
  values,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  handleChange,
  submitForm,
  buttonTitle,
  ...props
}) => {
  handleChange = (e) => {
    setFieldValue(e.target.name, e.target.value);
  };

  return (
    <SignUpStyleWrapper className="isoSignUpPage">
      <div className="isoSignUpContentWrapper">
        <div className="isoSignUpContent">
          <Form className="form-fields">
            <div className="isoSignUpForm">
              <div className="isoInputWrapper">
                <label style={{ fontSize: "12px", color: "#191D26" }}>
                  Reason
                </label>
                <Textarea
                  rows={4}
                  size="large"
                  type="test"
                  name="reason"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.reason}
                />
                {errors.reason && touched.reason && (
                  <div className="invalid-input">{errors.reason}</div>
                )}
              </div>

              <div className="isoInputWrapper">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    margin: "10px",
                  }}
                >
                  <Button type="primary" onClick={submitForm}>
                    {buttonTitle}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </SignUpStyleWrapper>
  );
};

export default CancelRequestForm;
