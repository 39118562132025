import * as api from '../../constants/Api';
import {API} from '../../services';
import * as T from '../ActionTypes';
import {SNACKBAR_MSG_TYPE, SNACKBAR_MSG} from '../../constants';

export const createCity = (data) => {
  return (dispatch) => {
    dispatch({type: T.CITY_LOADING});
    return API.execute(api.CREATE_CITY, {data})
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        dispatch({type: T.CREATE_CITY, payload: response.data});
        return true;
      })
      .catch((error) => {
        let {message} = error;
        if (message === 'Network Error') {
          message = SNACKBAR_MSG.networkError;
        }
        const {messageType} = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: {message, messageType},
        });

        dispatch({type: T.CITY_ERROR, payload: error});
      });
  };
};

export const getCityList = () => {
  return (dispatch) => {
    dispatch({type: T.CITY_LOADING});
    return API.execute(api.GET_CITY_LIST).then(
      (response) => {
        dispatch({type: T.GET_CITY_LIST, payload: response.data});
        let arr = []
        response.data.forEach((item) => {
          arr.push({
            value: item.id,
            label: item.name,
          });
        });
        return arr;
      },
      (error) => {
        dispatch({type: T.CITY_ERROR, payload: error});
        return error;
      },
    );
  };
};

export const updateCity = (data) => {
  let newData = {...data};
  let id = newData.id;
  delete data.id;

  return (dispatch) => {
    dispatch({type: T.CITY_LOADING});
    return API.execute(api.UPDATE_CITY(id), {data})
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        dispatch({type: T.UPDATE_CITY, payload: response.data, id});
        return true;
      })
      .catch((error) => {
        let {message} = error;
        if (message === 'Network Error') {
          message = SNACKBAR_MSG.networkError;
        }
        const {messageType} = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: {message, messageType},
        });

        dispatch({type: T.CITY_ERROR, payload: error});
      });
  };
};

export const deleteCity = (id) => {
  return (dispatch) => {
    dispatch({type: T.CITY_LOADING});
    return API.execute(api.DELETE_CITY(id))
      .then((response) => {
        const message = response.message;
        const messageType = SNACKBAR_MSG_TYPE.success;
        dispatch({
          type: T.SNACKBAR_SUCCESS,
          payload: {message, messageType},
        });
        dispatch({type: T.DELETE_CITY, id});
        return true;
      })
      .catch((error) => {
        let {message} = error;
        if (message === 'Network Error') {
          message = SNACKBAR_MSG.networkError;
        }
        const {messageType} = SNACKBAR_MSG_TYPE.error;
        dispatch({
          type: T.SNACKBAR_ERROR,
          payload: {message, messageType},
        });

        dispatch({type: T.CITY_ERROR, payload: error});
      });
  };
};
