import React from 'react';
// import {Button} from 'antd';
// import {useNavigate} from 'react-router-dom';

import {Wrapper, Title, Text, Icon} from './NoBoardFounds.style';
import emptyProjectPlaceHolder from '@iso/assets/images/icon/12.svg';
export default function NoBoardFounds({history, match}) {
  // const navigate = useNavigate();
  return (
    <Wrapper>
      <Icon src={emptyProjectPlaceHolder} />
      <Title>You Currently have no Vehicles</Title>
      <Text>Let's Create your first Vehicle</Text>
      {/* <Button type="primary" onClick={() => navigate('/dashboard/createVehicle')}>
        Create Vehicle
      </Button> */}
    </Wrapper>
  );
}
