import React from "react";
import { Route } from "react-router-dom";

import HotelsList from "@iso/pages/admin/hotelsList";
import ToursList from "@iso/pages/admin/toursList";
import CityList from "@iso/pages/admin/cityList";
import CreateHotels from "@iso/pages/admin/createHotels/CreateHotels";
import SetCommission from "@iso/pages/admin/setCommission/SetCommission";
import CreateReceptionist from "@iso/pages/admin/createReceptionists/CreateReceptionists";
import ReceptionistList from "@iso/pages/admin/receptionistsList";
import CreateTours from "@iso/pages/admin/createTours/CreateTours";
import CreateCity from "@iso/pages/admin/createCity/CreateCity";
import CreateBusStop from "@iso/pages/admin/createBusStop/CreateBusStop";
import BusStopList from "@iso/pages/admin/busStopList";
import ToursSchedule from "@iso/pages/admin/toursSchedule";
import TourHotelPrice from "@iso/pages/admin/hotelPrice";
import ToursPricing from "@iso/pages/admin/toursPricing";
import CreateVehiclePrice from "@iso/pages/admin/createVehiclePrice/CreateVehiclePrice";
import VehiclePriceList from "@iso/pages/admin/vehiclePriceList";
import CreateAirports from "@iso/pages/admin/createAirport/CreateAirport";
import AirportsList from "@iso/pages/admin/airportList";
import Profile from "@iso/pages/profile/Profile";
import ResetPassword from "@iso/pages/ResetPassword/ResetPassword";
import PrivateToursList from "@iso/pages/admin/privateToursList";
import CreatePrivateTours from "@iso/pages/admin/createPrivateTours/CreatePrivateTours";
import AirportTransfer from "@iso/pages/admin/booking/airportTransfer/List";
import TourBooking from "@iso/pages/admin/booking/tour/List";
import TourBookingCancel from "@iso/pages/admin/cancelRequest/tour/List.js";
import AirportTransferCancel from "@iso/pages/admin/cancelRequest/airportTransfer/List.js";
import Dashboard from "@iso/pages/admin/dashboard/Dashboard";
// import CreateTicketTours from "@iso/pages/admin/createTicketTours/CreateTicketTours";
// import TicketToursList from "@iso/pages/admin/ticketToursList";
import CreateTransportTours from "@iso/pages/admin/createTransportTours/CreateTransportTours";
import CreateTransportTicketTours from "@iso/pages/admin/createTransportTicketTours/CreateTransportTicketTours";
import TransportTicketToursList from "@iso/pages/admin/transportTicketToursList";
import TourBookingView from "../pages/admin/booking/tour/View";

import MonthlyExcursionList from "@iso/pages/admin/monthlyExcursion";
import CreateMonthlyExcursion from "@iso/pages/admin/createMonthlyExcursion/CreateMonthlyExcursion";

import ClosingTime from "@iso/pages/admin/setting/closingTime/Time.js";
import AirportTransferVoucher from "@iso/pages/admin/setting/voucher/AirportTransfer.js";

import TourTypeSetting from "@iso/pages/admin/tourTypeSetting/Setting.js";

import TourStop from "@iso/pages/admin/tourStop";

import TransportToursList from "@iso/pages/admin/transportToursList";
import CreateTicketGuideTours from "@iso/pages/admin/createTicketGuideTours/CreateTicketGuideTours";
import TicketGuideToursList from "@iso/pages/admin/ticketguideToursList";
import GeneralTransfer from "@iso/pages/admin/generalTransfer/BoardNotFound";
import TourCloseDay from "@iso/pages/admin/tourCloseDay/TourCloseDay";

import ServiceList from "@iso/pages/admin/airportTransfer/service";
import CityPrice from "@iso/pages/admin/airportTransfer/cityPrice";
import HotelPrice from "@iso/pages/admin/airportTransfer/hotelPrice";
import ExtraServiceList from "@iso/pages/admin/airportTransfer/extraService";
import AirportTransferView from "@iso/pages/admin/booking/airportTransfer/View.js";
import HotelBanner from "../pages/admin/setting/HotelBanner";

export default function AdminRoutes() {
  return (
    <>
      <Route exact path="" element={<Dashboard />} />
      <Route exact path="createHotels" element={<CreateHotels />} />
      <Route exact path="createHotels/:id" element={<CreateHotels />} />
      <Route exact path="setCommission/:id" element={<SetCommission />} />
      <Route exact path="hotels-list" element={<HotelsList />} />
      <Route exact path="createAirports/:id" element={<CreateAirports />} />
      <Route exact path="createAirports" element={<CreateAirports />} />
      <Route exact path="Airport-list" element={<AirportsList />} />
      <Route exact path="general_transfer" element={<GeneralTransfer />} />
      <Route exact path="create-monthly-excursion/:id" element={<CreateMonthlyExcursion />} />
      <Route exact path="create-monthly-excursion" element={<CreateMonthlyExcursion />} />
      <Route exact path="special-monthly-excursion" element={<MonthlyExcursionList />} />

      <Route exact path="tourBooking-view/:id" element={<TourBookingView />} />
      <Route
        exact
        path="createReceptionists"
        element={<CreateReceptionist />}
      />
      <Route
        exact
        path="createReceptionists/:id"
        element={<CreateReceptionist />}
      />
      <Route exact path="receptionists-List" element={<ReceptionistList />} />
      <Route exact path="createTours/:id" element={<CreateTours />} />
      <Route exact path="createTours" element={<CreateTours />} />
      <Route exact path="tours-list" element={<ToursList />} />
      {/* <Route
        exact
        path="createTicketTours/:id"
        element={<CreateTicketTours />}
      />
      <Route exact path="createTicketTours" element={<CreateTicketTours />} />
      <Route exact path="ticket-tours-list" element={<TicketToursList />} /> */}

      <Route exact path="createPrivateTours/:id" element={<CreateTransportTours />} />
      <Route exact path="createPrivateTours" element={<CreateTransportTours />} />
      <Route exact path="private-tours-list" element={<TransportToursList />} />

      <Route exact path="ticket-guide-tours-list" element={<TransportTicketToursList />} />
      <Route exact path="createTicketGuideTours/:id" element={<CreateTransportTicketTours />} />
      <Route exact path="createTicketGuideTours" element={<CreateTransportTicketTours />} />


      {/* <Route
        exact
        path="createTicketGuideTours/:id"
        element={<CreateTicketGuideTours />}
      />
      <Route exact path="createTicketGuideTours" element={<CreateTicketGuideTours />} />
      <Route exact path="ticket-guide-tours-list" element={<TicketGuideToursList />} /> */}

    <Route exact path="close-day/:id" element={<TourCloseDay />} />


      <Route exact path="tours-pricing/:id" element={<ToursPricing />} />

      <Route exact path="tour-stop/:id" element={<TourStop />} />


      <Route exact path="tours-schedule/:id" element={<ToursSchedule />} />
      <Route exact path="createCity/:id" element={<CreateCity />} />
      <Route exact path="createCity" element={<CreateCity />} />
      <Route exact path="city-list" element={<CityList />} />
      <Route exact path="createBusStop/:id" element={<CreateBusStop />} />
      <Route exact path="createBusStop" element={<CreateBusStop />} />
      <Route exact path="bus-stop-List" element={<BusStopList />} />
      <Route
        exact
        path="createVehiclePrice/:id"
        element={<CreateVehiclePrice />}
      />
      <Route exact path="createVehiclePrice" element={<CreateVehiclePrice />} />
      <Route exact path="vehicle-Price" element={<VehiclePriceList />} />
      <Route exact path="my-profile" element={<Profile />} />
      <Route path="resetPassword" element={<ResetPassword />} />
      <Route
        exact
        path="createTicketTours/:id"
        element={<CreatePrivateTours />}
      />
      <Route exact path="createTicketTours" element={<CreatePrivateTours />} />
      <Route exact path="ticket-tours-list" element={<PrivateToursList />} />
      <Route exact path="hotel-price/:id" element={<TourHotelPrice />} />
      <Route
        exact
        path="tour-setting"
        element={<TourTypeSetting />}
      />
      {/* <Route
        exact
        path="airport-transfer-reports"
        element={<AirportTransfer />}
      />
      <Route exact path="tour-booking-reports" element={<TourBooking />} /> */}
      <Route path="booking">
        <Route path="airport-reports" element={<AirportTransfer />} />
        <Route path="tour-reports" element={<TourBooking />} />
      </Route>
      <Route path="cancel-requests">
        <Route path="tour" element={<TourBookingCancel />} />
        <Route path="airportTransfer" element={<AirportTransferCancel/>}/>
      </Route>
      <Route path="airportTransfer">
      <Route path="view/:id" element={<AirportTransferView />} />
        <Route path="services" element={<ServiceList />} />
        <Route path="extraServices" element={<ExtraServiceList />} />
        <Route path="city-price" element={<CityPrice />} />
        <Route path="hotel-price" element={<HotelPrice />} />
      </Route>
      <Route path="setting">
        <Route path="closing-time" element={<ClosingTime />} />
        <Route path="hotel-banner" element={<HotelBanner />} />
        <Route path="airport-transfers-voucher" element={<AirportTransferVoucher />} />
      </Route>
    </>
  );
}
