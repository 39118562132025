import React from 'react';

import HelperText from '@iso/components/utility/helper-text';
import LayoutWrapper from '@iso/components/utility/layoutWrapper';
import PageHeader from '@iso/components/utility/pageHeader';
import Scrollbars from '@iso/components/utility/customScrollBar';
import CardWrapper, {Box} from './Lists.styles';
import TableWrapper from '../Tables/AntTables/AntTables.styles';
export default function Lists({title = '', Columns = [], Rows = [], scroll = {}, pagination = false, onRow = false, rowClassName='', handleTableChange}) {

  return (
    <LayoutWrapper>
      { title && <PageHeader>{title}</PageHeader> }
      <Box>
        <CardWrapper title={title}>
          {Rows.length === 0 ? (
            <HelperText text={`No ${title}`} />
          ) : (
            <div className="isoInvoiceTable">
              {/* <Scrollbars style={{width: '100%', height: 'calc(100vh - 70px)'}}> */}
                <TableWrapper
                  // rowSelection={rowSelection}
                  dataSource={Rows}
                  columns={Columns}
                  pagination={pagination}
                  scroll= {scroll}
                  className="invoiceListTable"
                  onRow={onRow}
                  rowClassName={rowClassName}
                  onChange={handleTableChange}

                />
              {/* </Scrollbars> */}
            </div>
          )}
        </CardWrapper>
      </Box>
    </LayoutWrapper>
  );
}
