import React from "react";
import Input from "@iso/components/uielements/input";
import DatePicker from "@iso/components/uielements/datePicker";
import Dropdown from "@iso/components/uielements/CustomDropdown";
import Button from "@iso/components/uielements/button";
import { DeleteOutlined } from "@ant-design/icons";
import moment from "moment";

import NATIONS from "../../constants/Nationality";
import { DOCUMENT_TYPE } from "../../constants";
import { Tag } from "antd";

const ClientDetailsForm = (props) => {
  const {
    index,
    data,
    clientSetting,
    handleBlur,
    handleChangeF,
    handleNationality,
    removeFormFields,
    view,
    handleDocumentType,
    setFieldValue
  } = props;
  const currentDate = moment().format("YYYY-MM-DD");
  const key = index;
  return (
    <div key={key}>
      {data && data.type && (
        <Tag style={{ margin: "0 7px" }} color={"green"} key={data.type}>
          {data.type}
        </Tag>
      )}

      <div className="RowDiv">
        {(clientSetting && clientSetting.firstName && (
          <div
            className="isoInputWrapper"
            style={{
              width: "50%",
              padding: "0 7px 0 7px",
            }}
          >
            <label className="label-style">Name</label>

            <Input
              size="large"
              placeholder="Name"
              type="text"
              name={"firstName"}
              value={data.firstName || ""}
              onChange={(e) => handleChangeF(key, `firstName`, e.target.value)}
              onBlur={handleBlur}
            />
            {data && data.firstName === "" && (
              <div className="invalid-input">Required</div>
            )}
          </div>
        )) ||
          null}

        {(clientSetting && clientSetting.surName && (
          <div
            className="isoInputWrapper"
            style={{
              width: "50%",
              padding: "0 7px 0 7px",
            }}
          >
            <label className="label-style">Surname</label>
            <Input
              size="large"
              placeholder="Surname"
              type="text"
              name="surName"
              value={data.surName || ""}
              onChange={(e) => handleChangeF(key, "surName", e.target.value)}
              onBlur={handleBlur}
            />
            {data && data.surName === "" && (
              <div className="invalid-input">Required</div>
            )}
          </div>
        )) ||
          null}

        {(clientSetting && clientSetting.dob && (
          <div
            className="isoInputWrapper"
            style={{
              width: "50%",
              padding: "0 7px 0 7px",
            }}
          >
            <label className="label-style">Date of Birth</label>
            {/* <Input
              type="date"
              name="dob"
              pattern="\d{2}-\d{2}-\d{4  }"
              value={data.dob ? moment(data.dob, "YYYY-MM-DD").format("YYYY-MM-DD") : ""}
              onChange={(e) => handleChangeF(key, "dob", e.target.value)}
              onBlur={handleBlur}
              max = {currentDate}
            /> */}

            <DatePicker
              size="large"
              placeholder="DD/MM/YYYY"
              name="dob"
              format={["DD/MM/YYYY", "DD-MM-YYYY", "DDMMYYYY"]}
              disabledDate={(current) =>
                moment(current).format("YYYY-MM-DD") > currentDate
              }
              defaultValue={data.dob ? moment(data.dob, "YYYY-MM-DD") : ""}
              onChange={(date, dateString) =>
                handleChangeF(key, "dob", dateString)
              }
              onBlur={handleBlur}
            />
            {(data && data.dob === null && (
              <div className="invalid-input">Required</div>
            )) ||
              (data && data.dob === "" && (
                <div className="invalid-input">Required</div>
              ))}
          </div>
        )) ||
          null}

        {(clientSetting && clientSetting.nationality && (
          <div
            className="isoInputWrapper"
            style={{
              width: "50%",
              padding: "0 7px 0 7px",
            }}
          >
            <label className="label-style">Nationality</label>

            <Dropdown
              showSearch={view.isDesiredWidth}
              options={NATIONS}
              onChange={(e) => handleNationality(key, e)}
              value={{ value: data.nationality, label: data.nationality } || ""}
              placeholder="Nationality"
            />
            {data && data.nationality === "" && (
              <div className="invalid-input">Required</div>
            )}
          </div>
        )) ||
          null}

        {(clientSetting && clientSetting.documentType && (
          <div
            className="isoInputWrapper"
            style={{
              width: "50%",
              padding: "0 7px 0 7px",
            }}
          >
            <label className="label-style">Document Type</label>
            <Dropdown
              showSearch={view.isDesiredWidth}
              options={DOCUMENT_TYPE}
              onChange={(e) => handleDocumentType(key, e)}
              value={
                { value: data.documentType, label: data.documentType } || ""
              }
              placeholder="Document Type"
            />
            {data && data.documentType === "" && (
              <div className="invalid-input">Required</div>
            )}
          </div>
        )) ||
          null}

        {(clientSetting && clientSetting.document && (
          <div
            className="isoInputWrapper"
            style={{
              width: "50%",
              padding: "0 7px 0 7px",
            }}
          >
            <label className="label-style">Document No</label>
            <Input
              size="large"
              placeholder="Document Number"
              type="text"
              name="document"
              value={data.document || ""}
              onChange={(e) => handleChangeF(key, "document", e.target.value)}
              onBlur={handleBlur}
            />
            {data && data.document === "" && (
              <div className="invalid-input">Required</div>
            )}
          </div>
        )) ||
          null}

        {(clientSetting && clientSetting.documentExpiry && (
          <div
            className="isoInputWrapper"
            style={{
              width: "50%",
              padding: "0 7px 0 7px",
            }}
          >
            <label className="label-style">Document Expiry</label>
            {/* <Input
              type="text"
              placeholder="DD/MM/YYYY"
              name="documentExpiry"
              value={data.documentExpiry ? data.documentExpiry : ""}
              maxLength={8}
              onChange={(e) =>
                handleChangeF(key, "documentExpiry", e.target.value)
              }
            /> */}

            <DatePicker
              size="large"
              placeholder="Document Expiry"
              name="documentExpiry"
              format={["DD/MM/YYYY", "DD-MM-YYYY", "DDMMYYYY"]}
              disabledDate={(current) =>
                moment(current).format("YYYY-MM-DD") < currentDate
              }
              defaultValue={
                data.documentExpiry
                  ? moment(data.documentExpiry, "YYYY-MM-DD")
                  : ""
              }
              onChange={(date, dateString) =>
                handleChangeF(key, "documentExpiry", dateString)
              }
              onBlur={handleBlur}
            />
            {(data && data.documentExpiry === null && (
              <div className="invalid-input">Required</div>
            )) ||
              (data && data.documentExpiry === "" && (
                <div className="invalid-input">Required</div>
              ))}
          </div>
        )) ||
          null}

        {removeFormFields && (
          <div className="InvoiceTableBtn">
            <Button
              onClick={() => removeFormFields(key, setFieldValue)}
              type="danger"
              icon={<DeleteOutlined />}
              size={5}
            ></Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientDetailsForm;
