import _ from 'lodash';

import * as T from '../ActionTypes';

const initState = {airports: null, loading: false};

export default function AirportReducer(state = initState, action) {
  switch (action.type) {
    case T.CREATE_AIRPORT: {
      let newAirportsList = [...state.airports];
      newAirportsList.push(action.payload);
      return {...state, airports: newAirportsList, loading: false};
    }
    case T.UPDATE_AIRPORT: {
      const airportsArray = _.filter(state.airports, (o) => {
        return o.id !== action.id;
      });
      airportsArray.push(action.payload);
      return {...state, airports: airportsArray, loading: false};
    }

    case T.GET_AIRPORTS_LIST: {
      return {...state, airports: action.payload, loading: false};
    }

    case T.DELETE_AIRPORT: {
      const airportsArray = _.filter(state.airports, (o) => {
        return o.id !== action.id;
      });
      return {...state, airports: airportsArray, loading: false};
    }

    case T.AIRPORT_LOADING: {
      return {...state, loading: true};
    }

    case T.GENERATE_AIRPORT_TRANSFERS_ADMIN_RECEIPT: {
      return {...state, loading: false};
    }

    case T.AIRPORT_ERROR: {
      return { ...state, loading: false };
    }

    default:
      return state;
  }
}
