import { Button } from "antd";
import React from "react";
import Map from "../../../../services/MapView";
import { ContactCardWrapper } from "./ContactCard.style";
import { useDispatch, useSelector } from "react-redux";
import { generateTourSuperAdminReceipt } from "@iso/redux/tours/actions";
import { Spin } from "antd";

const Invoice = React.forwardRef(({ currentTour }, ref) => {
  const dispatch = useDispatch();

  // const [loading, setLoading] = React.useState(false);
  // const [lat, setLat] = React.useState(null);

  // const [d, setd] = React.useState("none");
  // const [qrCode, setQR] = React.useState(null);
  // const handleReport = async (id) => {
  //   setLoading(true);
  //   setLat(currentTour.pickupLatitude)
  //   const qr = await QRCode.toDataURL(
  //     `https://maps.google.com/?q=${currentTour.pickupLatitude},${currentTour.pickupLongitude}`
  //   );
  //   setQR(qr);
  //   var p2 = new Promise(function (resolve, reject) {
  //     setd("inline-block");
  //     var element = document.getElementById("element-print");

  //     html2pdf().from(element).save();
  //     resolve(false);
  //   });

  //   p2.then(function (value) {
  //     setd("none");
  //     setLoading(value);
  //     return value;
  //   }).then(function (value) {
  //     setd("none");
  //     setLoading(value);
  //     window.close();
  //   });
  // };

  const handleReceipt = (tour) => {
    dispatch(generateTourSuperAdminReceipt(tour));
  };

  let Loading = useSelector((state) => {
    return state.Tours.loading;
  });

  return (
    <>
      <div className="PageContent" style={{ padding: "20" }} ref={ref}>
        <div className="OrderInfo">
          <div className="LeftSideContent">
            <h3 className="Title">Tour Booking</h3>
            <span className="InvoiceNumber">
              {currentTour && currentTour.tour && currentTour.tour.destination}
            </span>
          </div>
          <div className="RightSideContent">
            <Button
              onClick={() => handleReceipt(currentTour.id)}
              type="primary"
              className="mateInvoPrint"
            >
              {Loading ? (
                  <Spin />
              ) : (
                <span>Report</span>
              )}
            </Button>
          </div>
        </div>
        <div className="BillingInformation">
          <div className="LeftSideContent">
            <h3 className="Title">Details</h3>
            <ContactCardWrapper
              className="isoContactCard"
              style={{ padding: "0" }}
            >
              <div className="isoContactInfoWrapper">
                <div
                  className="isoContactCardInfos"
                  key={currentTour.clientName}
                >
                  <p className="isoInfoLabel"> Name </p>
                  <p className="isoInfoDetails">{currentTour.clientName}</p>
                </div>
                <div
                  className="isoContactCardInfos"
                  key={currentTour.clientEmail}
                >
                  <p className="isoInfoLabel"> Email </p>
                  <p className="isoInfoDetails">{currentTour.clientEmail}</p>
                </div>
                <div className="isoContactCardInfos" key={currentTour.date}>
                  <p className="isoInfoLabel"> Date </p>
                  <p className="isoInfoDetails">{currentTour.date}</p>
                </div>
                <div
                  className="isoContactCardInfos"
                  key={currentTour.paymentMethod}
                >
                  <p className="isoInfoLabel"> Payment Method </p>
                  <p className="isoInfoDetails">{currentTour.paymentMethod}</p>
                </div>
                <div
                  className="isoContactCardInfos"
                  key={currentTour.pickUpTime}
                >
                  <p className="isoInfoLabel"> pickUp Time </p>
                  <p className="isoInfoDetails">{currentTour.pickUpTime}</p>
                </div>
                <div className="isoContactCardInfos" key={currentTour.price}>
                  <p className="isoInfoLabel"> Price </p>
                  <p className="isoInfoDetails">{currentTour.price}</p>
                </div>
                <div
                  className="isoContactCardInfos"
                  key={currentTour.extraCharges}
                >
                  <p className="isoInfoLabel"> Extra Charges </p>
                  <p className="isoInfoDetails">{currentTour.extraCharges}</p>
                </div>
                <div className="isoContactCardInfos" key={currentTour.remarks}>
                  <p className="isoInfoLabel"> Remarks </p>
                  <p className="isoInfoDetails">{currentTour.remarks}</p>
                </div>
              </div>
            </ContactCardWrapper>
          </div>
        </div>
        <div>
          <div className="mapWrapper">
            <Map
              getLocation={(loc) => {
                // console.log("loc", loc);
              }}
              setLocation={{
                lat: currentTour.pickupLatitude,
                lng: currentTour.pickupLongitude,
              }}
              getQuery={() => {}}
            />
          </div>
        </div>
      </div>
      {/* <div id="element-print" style={{ display: d }}>
        <Report data={currentTour} qrCode={qrCode} lat={lat} />
      </div> */}
    </>
  );
});
export default Invoice;
