import React from 'react';
import {Layout, Button} from 'antd';
// import {Scrollbars} from 'react-custom-scrollbars';
import {useNavigate} from 'react-router-dom';

import SearchInput from '@iso/components/ScrumBoard/SearchInput/SearchInput';
import {Title, Header, HeaderSecondary} from './AppLayout.style';

const {Content} = Layout;

const AppLayout = ({children, setSearchText, history, match}) => {
  const navigate = useNavigate();
  return (
    <Layout style={{backgroundColor: '#fff'}}>
      <Header>
        <Title>Cities List</Title>

        <Button type="primary" onClick={() => navigate('/dashboard/createCity')}>
          Add new
        </Button>
      </Header>
      <HeaderSecondary>
        <SearchInput
          onChange={(value) => setSearchText(value)}
          onCross={(value) => {
            setSearchText(value);
          }}
        />
      </HeaderSecondary>

      <Content
        style={{
          padding: '0 24px',
        }}>
        {children}
      </Content>
    </Layout>
  );
};

export default AppLayout;
